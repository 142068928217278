import URI from '@dw/urijs-esm';

/**
 * @param {Object} data - Router data context
 * When signup query param found in URL,
 *  - Stores `amplitude-signup`: true into localStorage
 *  - Stores `amplitude-invited` value from invited query param.
 *  - Removes above 2 query params from URL & redirect to new URL.
 */
export const amplitudeSignupEventTrackingRouter = (data) => {
  return new Promise((resolve, reject) => {
    const queryParams = new URI(data.query).query(true);
    if(queryParams.signup) {

      const signup = queryParams.signup === 'true'? true: false;
      const invited = queryParams.invited === 'true'? true: false;

      localStorage.setItem('is-invited-user', invited);
      localStorage.setItem('amplitude-signup', signup);
      localStorage.setItem('amplitude-invited', invited);
      
      let uri = new URI();
      uri.pathname(data.path);
      uri.query(data.query);
      uri.removeSearch('signup');
      uri.removeSearch('invited');
      data.redirect = uri.toString().replace(`${uri.protocol()}:\/\/${uri.host()}`, '');
      resolve(data);
      return;
    }
    reject(data);
  });
}

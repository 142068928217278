import merge from 'lodash-es/merge';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import URI from '@dw/urijs-esm';
import { uuid, includes } from '../utils';
import { store } from '../store.js';
import find from 'lodash-es/find';
import filter from 'lodash-es/filter';
import * as router from '../redux/router';
import * as auth from '../redux/auth';
import * as board from '../redux/board';
import * as signup from '../redux/signup';
import * as GA4 from './ga4.js';
import firestoreRedux from '@dreamworld/firestore-redux';
import * as GTM from './gtm.js';

const excludeBrowserUserAgent = ['Prerender', 'HeadlessChrome'];

// Check amplitude event is track for current browser or not.
let isAmplitudeEventTrack = !includes(window.navigator.userAgent, excludeBrowserUserAgent);;

// Generate tab as a uuid() for common event properties.
const tabID = uuid();

const defaultProperties = {
  'user_agent': window.navigator.userAgent,
  'tab': tabID,
  'site': 'PWA'
};


/**
 * By default add `user_agent` property into amplitude event.
 * @param {String} name event name
 * @param {Object} properties event properties
 * @param {Object} options
 *  - @property {Boolean} trackReferrer track referrer details or not.
 */
export const logEvent = (name, properties = {}, { trackReferrer = false, outOfSession = false } = {}) => {
  const state = store.getState();
  let trackReferrerDetails = {};
  if (trackReferrer) {
    trackReferrerDetails = {
      'referrer_page': router.selectors.referrerPage(store.getState()),
      'referrer_page_trigger': router.selectors.referrerPageTrigger(store.getState())
    };
  }

  const sessionReplayProperties = window.sessionReplay && window.sessionReplay.getSessionReplayProperties() || {};
  const accountId = router.selectors.accountId(state);
  const accountDetails = accountId ? {'account_id': accountId}: {};
  const eventProperties = merge({}, sessionReplayProperties, defaultProperties, trackReferrerDetails, accountDetails, properties);
  GA4.logEvent(name, eventProperties);
  GTM.logEvent(name, eventProperties);

  if(!isAmplitudeEventTrack) {
    return;
  }

  window.amplitude && window.amplitude.getInstance().logEvent(name, eventProperties, null, null, outOfSession);
}

/**
 * By default add `board_id`, `role`, and `effective_role` property into amplitude event.
 * @param {String} name event name
 * @param {Object} properties event properties
 * @param {Boolean} privateBoard board is private or not.
 * @param {Object} options
 *  - @property {Boolean} trackReferrer track referrer details or not.
 */
export const logBoardEvent = async(name, properties = {}, privateBoard = false, { trackReferrer = false, outOfSession = false } = {}) => {
  let state = store.getState();
  const boardId = router.selectors.dialogBoardId(state) || router.selectors.pageBoardId(state);
  const accountId = router.selectors.accountId(state);
  const userId = auth.selectors.currentUserId(state);
  let defaultBoardProperties  = {
    'board_id': boardId,
    'account_id': accountId,
    'board_role': 'NONE',
    'board_effective_role': 'NONE'
  };

  if (!privateBoard && boardId) {
    let attrs = firestoreRedux.selectors.doc(state, 'boards', boardId);
    // Loads current board attrs if not loaded.
    if (isEmpty(attrs)) {
      try {
        const query = firestoreRedux.getDocById('boards', boardId, { once: true });
        attrs = await query.result;
      } catch(error) {}
    }

    state = store.getState();
    const allBoardTeamMembers = firestoreRedux.selectors.collection(state, 'board-team-members');
    let currentBoardTeamMembers = filter(allBoardTeamMembers, { boardId });
    // Load current board's team members if not loaded.
    if (isEmpty(currentBoardTeamMembers)) {
      try {
        const query = firestoreRedux.query('board-team-members', { where: [['boardId', '==', boardId]],  once: true });
        currentBoardTeamMembers = await query.result;
      } catch(error) {}
    }

    state = store.getState();
    const role = get(find(currentBoardTeamMembers, { userId }), 'role', 'NONE');
    const permission = board.selectors.boardEffectivePermission(state, boardId);
    const effectiveRole = permission ? permission.hasAdmin() ? 'BOARD_ADMIN':  permission.hasWrite() ? 'TEAM_MEMBER': permission.hasRead() ? 'VISITOR': 'NONE': 'NONE';
    defaultBoardProperties = {...defaultBoardProperties, ...{
      'board_role': role,
      'board_effective_role': effectiveRole,
      'board_type': attrs && attrs.type || 'NONE',
      'is_template': attrs && attrs.template || false,
      'board_privacy': attrs && attrs.privacy || 'NONE',
      'board_owner': attrs && attrs.ownerId === userId || false,
      'board_active': board.selectors.isBoardActive(state, boardId) || false,
      'is_copied_board': board.selectors.isCopiedBoard(state, boardId) || false,
      'is_getting_started_board':  attrs && attrs.gettingStarted || false,
    }};
  }
  logEvent(name, merge({}, defaultProperties, defaultBoardProperties, properties), { trackReferrer, outOfSession });
}

/**
 * Update `cards_added` user property.
 * @param {Number} count new added card count.
 * Please see (details)[https://docs.google.com/document/d/1Y4IN5PVa2M4cY2wo_fFG8ncNxGVXeksLW-UZ4aiBcnQ/edit#bookmark=id.x7z3tpormw0i]
 */
export const updateCardsAddedUserProperty = (count = 1) => {
  if(!isAmplitudeEventTrack) {
    return;
  }

  const amplitude = window.amplitude;
  if(!amplitude) {
    console.warn('Amplitude js is not loaded yet.')
    return;
  }

  let intialValueIdentify = new amplitude.Identify().setOnce('cards_added', 0);
  amplitude.getInstance().identify(intialValueIdentify);

  let identify = new amplitude.Identify().add('cards_added', count);
  amplitude.getInstance().identify(identify);
}

/**
 * Initialize amplitude.
 * @param {Object} params
 *  - @property {String} APIKey
 *  - @property {String} APIHost amplitude api end point host.
 */
export const init = ({APIKey, APIHost}) => {
  if(!isAmplitudeEventTrack) {
    return;
  }

  if(!APIKey) {
    isAmplitudeEventTrack = false;
    return;
  }

  if(APIHost) {
    window.amplitude && window.amplitude.getInstance().init(APIKey, '', {apiEndpoint: APIHost + '/M6LQu7v7qHLPV', includeUtm: true, includeReferrer: true, includeGclid: true, logAttributionCapturedEvent: true, headers: {
      'Cross-Origin-Resource-Policy': ''
    }});
    return;
  }

  window.amplitude && window.amplitude.getInstance().init(APIKey, '', { includeUtm: true, includeReferrer: true, includeGclid: true, logAttributionCapturedEvent: true, headers: {
    'Cross-Origin-Resource-Policy': ''
  }});
}

/**
 * Track Template event.
 * By default add `template_id`, and `is_loggedin` property into defualt properties event.
 * @param {String} name event name
 * @param {Object} properties event properties
 * @param {Object} options
 *  - @property {Boolean} trackReferrer track referrer details or not.
 */
export const logTemplateEvent = (name, properties = {}, { trackReferrer = false, outOfSession = false } = {}) => {
  const state = store.getState();
  const templateId = router.selectors.dialogBoardId(state) || router.selectors.pageBoardId(state);
  const userId = auth.selectors.currentUserId(state);

  const defaultTemplateProperties = {
    'template_id': templateId,
    'is_loggedin': !!userId
  };

  logEvent(name, merge({}, defaultProperties, defaultTemplateProperties, properties), { trackReferrer, outOfSession });
}

/**
 * Track video  event.
 * By default add `name`, `video_url` and 'page_url' property into defualt properties event.
 * @param {String} name event name
 * @param {Object} properties event properties
 * @param {Object} options
 *  - @property {Boolean} trackReferrer track referrer details or not.
 */
 export const logVideoEvent = (name, properties = {}, { trackReferrer = false, outOfSession = false } = {}) => {
  const state = store.getState();
  const videoUrl = router.selectors.videoUrl(state);
  const videoId = router.selectors.videoId(state);

  const uri = new URI();
  const pageUrl = uri.toString().replace(`${uri.protocol()}:\/\/${uri.host()}`, '');

  const defaultVideoProperties = {
    'name': videoId,
    'video_url': videoUrl,
    'page_url': pageUrl
  };

  logEvent(name, merge({}, defaultProperties, defaultVideoProperties, properties), { trackReferrer, outOfSession });
}


/**
 * Track signup event.
 * By default add `signup_source` property into defualt properties event.
 * @param {String} name event name
 * @param {Object} properties event properties
 * @param {Object} options
 *  - @property {Boolean} trackReferrer track referrer details or not.
 */
export const logSignupEvent = (name, properties = {}, { trackReferrer = false, outOfSession = false } = {}) => {
  const state = store.getState();
  const signupDetails = signup.selectors.signupDetails(state);
  const defaultSignupProperties = {
    'signup_source': signupDetails && signupDetails.source
  };
  logEvent(name, merge({}, defaultProperties, defaultSignupProperties, properties), { trackReferrer, outOfSession });
}

/**
 * Set GA4 user properties.
 * @param {Object} properties
 */
export const setUserProperties = (userId, properties, resetSession = false) => {
  GA4.setUserProperties(userId, properties);
  GTM.setUserProperties(userId, properties);
  if(!isAmplitudeEventTrack) {
    return;
  }

  if(resetSession || userId === null) {
    window.amplitude && window.amplitude.getInstance().resetSessionId();
    window.amplitude && window.amplitude.getInstance().regenerateDeviceId();
  }

  if(userId) {
    window.amplitude && window.amplitude.getInstance().setUserId(userId);
  }

  if(!isEmpty(properties)) {
    window.amplitude && window.amplitude.getInstance().setUserProperties(properties);
  }
}
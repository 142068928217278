
import { createSelector } from 'reselect';
import * as auth from '../auth';
import * as router from '../router';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as manageAccount from '../manage-account';
import moment from 'moment/src/moment';

import isEqual from 'lodash-es/isEqual';

// Memoize role hierarchy for better performance
const ROLE_HIERARCHY = {
  'ACCOUNT_OWNER': 1,
  'ACCOUNT_ADMIN': 2,
  'TEAM_MEMBER': 3,
  'VISITOR': 4
};

/**
 * Selects all active, relevant alerts for the current user and account
 * @param {Object} state - The Redux state
 * @param {String} accountId - Account ID (optional)
 * @param {Number} now - Current timestamp (optional)
 * @returns {Array} - Filtered and sorted alerts
 */
const docs = createSelector(
  (state, accountId, now) => firestoreRedux.selectors.allDocs(state, 'account-alerts') || {},
  (state, accountId, now) => accountId || router.selectors.accountId(state),
  (state, accountId, now) => auth.selectors.currentUserId(state),
  (state, accountId, now) => manageAccount.selectors.memberRole({state, accountId: accountId || router.selectors.accountId(state), userId: auth.selectors.currentUserId(state)}),
  (state, accountId, now = Date.now()) => now,
  (allDocs, accountId, userId, memberRole, now) => {
    // Early return if no docs or required params
    if (!allDocs || !accountId || !userId) {
      return [];
    }

    // If member role is not available, return empty array
    if (!memberRole || !ROLE_HIERARCHY[memberRole]) {
      return [];
    }

    const memberRoleIndex = ROLE_HIERARCHY[memberRole];
    const currentDate = moment(now);
    
    return Object.values(allDocs)
      .filter(doc => {
        // Skip invalid docs
        if (!doc || doc.accountId !== accountId) return false;
        
        // Skip expired docs
        if (doc.expiresAt && doc.expiresAt < now) return false;
        
        // Skip docs that require higher role
        if (doc.role) {
          const roleIndex = ROLE_HIERARCHY[doc.role];
          if (roleIndex === undefined || roleIndex < memberRoleIndex) return false;
        }
        
        // Check acknowledgement
        const userAck = doc.acknowledgements?.[userId];
        if (userAck) {
          switch (doc.repeatFrequency) {
            case 'ONCE': return false;
            case 'DAILY': return !moment(userAck).isSame(currentDate, 'day');
            case 'EVERY_VISIT': return true;
            default: return true;
          }
        }
        
        return true;
      })
      .sort((a, b) => {
        // Sort by priority first if available
        if (a.priority !== undefined && b.priority !== undefined) {
          return b.priority - a.priority;
        }
        
        // Then by role
        const aRoleIndex = a.role ? (ROLE_HIERARCHY[a.role] || 999) : 999;
        const bRoleIndex = b.role ? (ROLE_HIERARCHY[b.role] || 999) : 999;
        
        if (aRoleIndex !== bRoleIndex) {
          return aRoleIndex - bRoleIndex;
        }
        
        // Finally by creation date (newest first)
        return (b.createdAt || 0) - (a.createdAt || 0);
      });
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * Selects the first dialog alert data if available
 * @param {Object} state - The Redux state
 * @param {String} accountId - Account ID (optional)
 * @param {Number} now - Current timestamp (optional)
 * @returns {Object|null} - First alert or null if none available
 */
export const dialogData = createSelector(
  (state, accountId, now) => docs(state, accountId, now),
  (alerts) => alerts && alerts.length > 0 ? alerts[0] : null
);

/**
 * Checks if there is at least one dialog alert available
 * @param {Object} state - The Redux state
 * @param {String} accountId - Account ID (optional)
 * @param {Number} now - Current timestamp (optional)
 * @returns {Boolean} - Whether a dialog should be displayed
 */
export const dialogOpened = createSelector(
  (state, accountId, now) => dialogData(state, accountId, now),
  (alert) => {
    return !!(alert && alert.type);
  }
);

/**
 * Gets the current dialog alert type
 * @param {Object} state - The Redux state
 * @param {String} accountId - Account ID (optional)
 * @param {Number} now - Current timestamp (optional)
 * @returns {String|null} - The type of the first alert or null if none available
 */
export const dialogType = createSelector(
  (state, accountId, now) => dialogData(state, accountId, now),
  (alert) => alert ? alert.type : null
);
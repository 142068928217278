import { put, takeEvery, call, select, all, delay } from 'redux-saga/effects';
import * as router from '../router/index.js';
import * as app from '../app/index.js';
import * as actions from './actions.js';
import * as selectors from './selectors.js';
import * as signup from '../signup';
import * as accessibleAccountsSelectors from './accessible-accounts/selectors.js';
import * as forgotPassword from '../forgot-password/index.js';
import * as messages from '../messages';
import * as user from '../user';
import * as device from '../device';
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import * as amplitude from '../../analytics/amplitude.js';
import * as clarity from '../../analytics/clarity.js';
import { store } from '../../store';
import oauth2Login from './oauth2-login.js';
import { isNetworkError, requestApi } from '../../request-api';
import { getCookieVal, getIdWoPrefix } from '../../utils.js';
import get from 'lodash-es/get.js';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import forEach from 'lodash-es/forEach';
import moment from 'moment/src/moment.js';
import { show as showSnackBar } from '../../components/kerika-snackbar.js';
import i18next from '@dw/i18next-esm';
import URI from '@dw/urijs-esm';
import firestoreRedux from '@dreamworld/firestore-redux';
import * as lastSeenUpdateScheduler from './last-seen-update-scheulder.js';

let previousPage;
function* routeChangeHandler() {
  const state = yield select();
  const currentPage = router.selectors.pageName(state);
  if (currentPage === 'LOGIN_AND_SIGNUP' && previousPage !== currentPage) {
      yield put({ type: actions.LOGIN_AND_SIGNUP_PAGE_OPENED });
  } else if (previousPage === 'LOGIN_AND_SIGNUP') {
    yield put({ type: actions.LOGIN_AND_SIGNUP_PAGE_CLOSED });
  }
  previousPage = router.selectors.pageName(state);

  const installedApp = app.selectors.isInstalledApp(state);
  const filePreviewId = get(state, `router.page.params.file-preview`);
  const status = get(state, `router.page.params.status`);
  const service = get(state, `router.page.params.service`);
  if (installedApp && !!filePreviewId && !!status) {
    router.actions.setQueryParams({ status: undefined, ['file-preview-action']: undefined });
    if (status === 'ok') {
      yield put(actions._linkAdditionalAuthSuccess(service == 'microsoft'? 'microsoft': 'box'));
    } else {
      yield put(actions._linkAdditionalAuthFailed(service == 'microsoft'? 'microsoft': 'box', {code: status}));
    }
  }
}

/**
 * Watch router change.
 * Dispatch LOGIN_AND_SIGNUP_PAGE_OPENED, LOGIN_AND_SIGNUP_PAGE_CLOSED actions based on previous & current page.
 */
function* watchRouter() {
  //If page is already opened, check once.
  yield call(routeChangeHandler);
  yield takeEvery(router.actions.UPDATE_ROUTER, routeChangeHandler);
}

/**
 * Login into application as a K + K user.
 * Using email, password given in action payload.
 */
function* resetPassword(action) {
  yield call(login, {service: 'kerika', email: action.email, password: action.password});
}

/**
 * If installed app and service is google then
 *   - Login to googleplus through native API
 *   - Triggers server login API.
 * If service is kerika then
 *   - Login into oauth2 using api.
 *   - Login into application using iframe.
 * Otherwise
 *   - Login in childwindow.
 * Dispatch `LOGIN_SUCCESS` when login is success.
 * Dispath `LOGIN_FAILED` with proper error code.
 *  - `CANCELED`: user close child window
 *  - `TIMEOUT`: login is failed due to timeout
 *  - `USER_NOT_FOUND`: user is not exist.
 *  - `INVALID_PASSWORD`: password is wrong
 *  - `UNKNOWN`: unknown error.
 */
function* login(action) {
  const state = yield select();
  const service = action.service;
  let actualService = service;
  try {
    authLoginStartedPostMessage(actualService);
    if(app.selectors.oauth2LoginUsingNativeFlow(state, service)) {
      actualService = yield call(installedAppLogin, service);
    } else {
      actualService = yield call(pwaLogin, service, action.email, action.password, action.autoLogin);
    }

    localStorage.setItem('auth-service', actualService);
    yield put({ type: actions.LOGIN_SUCCESS, actualService});
    yield call(refreshRouter, actualService);
  } catch (error) {
    let errorCode = error && error.code || 'UNKNOWN';
    errorCode = errorCode === 'BAD_CREDENTIALS' ? 'INVALID_PASSWORD': errorCode === 'NOT_FOUND' ? 'USER_NOT_FOUND': errorCode;
    yield put({ type: actions.LOGIN_FAILED, error: errorCode, service });
    authLoginFailedPostMessage(actualService, errorCode);
    if(errorCode === 'CANCELED') {
      amplitude.logEvent('auth cancelled', {service: service === 'box' ? 'BOX': service === 'microsoft'? 'MICROSOFT': 'GOOGLE', trigger: router.selectors.signupLoginPageOpenTrigger(state)}, { trackReferrer: true });
    } else {
      const reason = errorCode === 'INVALID_PASSWORD' ? 'INVALID_CREDENTIALS': errorCode === 'USER_NOT_FOUND' ? 'ACCOUNT_DOESNT_EXISTS': errorCode;
      amplitude.logEvent('auth failed', {service: service === 'box' ? 'BOX': service === 'kerika' ? 'KERIKA': service === 'microsoft'? 'MICROSOFT': 'GOOGLE', trigger: router.selectors.signupLoginPageOpenTrigger(state), reason}, { trackReferrer: true });
      if(errorCode === 'TIMEOUT') {
        console.error('Login failed due to this:', {error, service});
      } else {
        console.warn('Login failed due to this:', {error, service});
      }
    }
  }
}

function authLoginStartedPostMessage(service) {
  const opener = window.top || window.opener;
  if(opener && router.selectors.embedLoginSignup(store.getState())) {
    opener.postMessage({
      type: 'AUTH_LOGIN_STARTED',
      service: service
    }, "*");
  }
}

function authLoginFailedPostMessage(service, code) {
  const opener = window.top || window.opener;
  if(opener && router.selectors.embedLoginSignup(store.getState())) {
    opener.postMessage({
      type: 'AUTH_LOGIN_FAILED',
      service: service,
      code: code
    }, "*");
  }
}

function authLoginSuccessPostMessage(service, opened = false) {
  const opener = window.top || window.opener;
  if(opener && router.selectors.embedLoginSignup(store.getState())) {
    opener.postMessage({
      type: 'AUTH_LOGIN_COMPLETED',
      service: service,
      opened: opened
    }, "*");
  }
}

/**
 * Wait till auth data available.
 */
function waitTillAuthDataAvailable() {
  let resolve, reject;
  const promise = new Promise((res, rej) => { resolve = res, reject = rej; });
  const unsubscribe = ReduxUtils.subscribe(store, `auth.user`, (userDetails)=> {
    if(!isEmpty(userDetails)) {
      unsubscribe && unsubscribe();
      resolve();
    }
  });
  return promise;
}

/**
 * When source is template and templateId is found, navigate to ?action=auto-create-board&template=${templateId}.
 * Otherwise refresh a router.
 */
function* refreshRouter(actualService) {
  const state = yield select();
  const templateId = router.selectors.templateId(state);
  const source = router.selectors.source(state);
  const force = router.selectors.force(state);
  const embedLoginSignup = router.selectors.embedLoginSignup(state);
  if((source === 'template' && templateId) || !embedLoginSignup) {
    yield call(actions.init);
    yield call(waitTillAuthDataAvailable);
    if(source === 'template' && templateId) {
      yield call(router.actions.setHashPath, '', true);
      yield call(router.actions.setQueryParams, {action: 'auto-create-board', template: templateId, force: force});
      return;
    }
    return;
  }
  authLoginSuccessPostMessage(actualService);
}

/**
 * pwa login for all service.
 */
function* pwaLogin(service, email, password, autoLogin) {
  const state = yield select();
  const config = app.selectors.config(state);
  const embedLoginSignup = router.selectors.embedLoginSignup(state);
  const installedApp = app.selectors.isInstalledApp(state);
  if(service == 'kerika') {
    yield call(requestApi, `${config.auth.baseUrl}/api/login?remember-me=true`, { method: 'POST', excludeErrors: [502, 400, 404], body: { username: email, password: password } })
  }
  const response = yield call(oauth2Login, {service, autoLogin, embedLogin: embedLoginSignup, installedApp});
  const signup = !response || response.signup === 'false' ? false : !!response.signup;
  if(signup) {
    const invited = response.invited === 'false' ? false: !!response.invited;

    localStorage.setItem('amplitude-signup', true);
    localStorage.setItem('amplitude-invited', invited);
    localStorage.setItem('is-invited-user', invited);
  }
  return response ? response.actualService || response.service: service;
}

/**
 * Login into google/box/microsoft or link additional auth for file-store.
 * When `linkAdditionalAuth`
 *  - For Google
 *    - After getting authCode, login through server API. Config path for that url: config.auth.additionalAuthApiUrl
 *  - For Box or Microsoft
 *    - Redirects to `{webAppBaseUrl}/link-additional-auth?service=box&redirect={currentURl}`
 * @param {String} service service. Possible values: 'google' or 'box' or 'microsoft'
 * @param {Boolean} linkAdditionalAuth Whether request is for link additional auth or not.
 * @returns
 */
function* installedAppLogin(service, linkAdditionalAuth) {
  if (service == 'box') {
    yield call(installedAppBoxLogin, linkAdditionalAuth);
    return 'box';
  }

  if (service == 'microsoft') {
    yield call(installedAppMicrosoftLogin, linkAdditionalAuth);
    return 'microsoft';
  }

  const response = yield call(installedAppGoogleLogin, linkAdditionalAuth);
  if (!linkAdditionalAuth) {
    yield call(setAmplitudeSignupData, response);
  }
  return response && (response.actualService || response.service) || service;
}

/**
 * Login into BOX through redirect URL.
 * @param {Boolean} linkAdditionalAuth `true` when request is for link additional auth.
 */
function* installedAppBoxLogin(linkAdditionalAuth) {
  const state = yield select();
  const config = app.selectors.config(state);
  const uri = new URI();
  const source = router.selectors.source(state);
  const templateAcId = app.selectors.templateAcId(state);
  const templateId = router.selectors.templateId(state);
  const force = router.selectors.force(state);
  uri.fragment('');
  uri.query('');
  const path = uri.path();
  if(path && path.startsWith('/blank-page')) {
    uri.path('');
  }

  if(source === 'template' && templateId) {
    if(path && path.startsWith('/blank-page')) {
      uri.path(`/${templateAcId}/board/${templateId}`);
    }
    uri.addQuery('action', 'auto-create-board');
    uri.addQuery('template', templateId);
    uri.addQuery('force', force);
  }

  const redirectURL = `${config.webAppBaseUrl}/en/redirect.html?redirect=${encodeURIComponent(uri.toString())}`;
  if (!linkAdditionalAuth) {
    window.location.href = `${config.apiBaseUrl}/login?s=box&redirect=${encodeURIComponent(redirectURL)}`;
  } else {
    window.location.href = `${config.webAppBaseUrl}/link-additional-auth?service=box&redirect=${encodeURIComponent(redirectURL)}`;
  }
}

/**
 * Login into MICROSOFT through redirect URL.
 * @param {Boolean} linkAdditionalAuth `true` when request is for link additional auth.
 */
function* installedAppMicrosoftLogin(linkAdditionalAuth) {
  const state = yield select();
  const config = app.selectors.config(state);
  const uri = new URI();
  const source = router.selectors.source(state);
  const templateAcId = app.selectors.templateAcId(state);
  const templateId = router.selectors.templateId(state);
  const force = router.selectors.force(state);
  uri.fragment('');
  uri.query('');
  const path = uri.path();
  if(path && path.startsWith('/blank-page')) {
    uri.path('');
  }

  if(source === 'template' && templateId) {
    if(path && path.startsWith('/blank-page')) {
      uri.path(`/${templateAcId}/board/${templateId}`);
    }
    uri.addQuery('action', 'auto-create-board');
    uri.addQuery('template', templateId);
    uri.addQuery('force', force);
  }

  const redirectURL = `${config.webAppBaseUrl}/en/redirect.html?redirect=${encodeURIComponent(uri.toString())}`;
  if (!linkAdditionalAuth) {
    window.location.href = `${config.apiBaseUrl}/login?s=microsoft&redirect=${encodeURIComponent(redirectURL)}`;
  } else {
    window.location.href = `${config.webAppBaseUrl}/link-additional-auth?service=microsoft&redirect=${encodeURIComponent(redirectURL)}`;
  }
}

/**
 * Gets google auth code through cordova plugin & login throu server api.
 * @param {Boolean} linkAdditionalAuth `true` when request is for link additional auth.
 */
function* installedAppGoogleLogin(linkAdditionalAuth) {
  const state = yield select();
  const config = app.selectors.config(state);
  const authCode = yield call(getGoogleAuthCode, config.auth.googleClientId);
  let url;
  if (!linkAdditionalAuth) {
    url = `${config.auth.loginApiUrl.replace('{service}', 'google').replace('{authCode}', authCode)}`;
  } else {
    url = `${config.auth.additionalAuthApiUrl.replace('{service}', 'google').replace('{authCode}', authCode)}`;
  }
  const response = yield call(requestApi, url, {excludeErrors: [409]});
  return response;
}

/**
 * Login to google through cordova plugin.
 * @param {String} googleClientId Client Id for google login.
 * @returns {String} Auth code
 */
function getGoogleAuthCode(googleClientId) {
  let resolve, reject;
  let promise = new Promise((res, rej) => { resolve = res, reject = rej; });

  window.plugins.googleplus.login({
    'scopes': 'email profile',
    'webClientId': googleClientId,
    'offline': true
  },
  function (obj) {
    resolve(obj.serverAuthCode);
  },
  function (error) {
    reject({code: 'CANCELED'});
    amplitude.logEvent('auth cancelled', {service: 'GOOGLE', trigger: router.selectors.signupLoginPageOpenTrigger(store.getState())}, { trackReferrer: true });
    console.warn('GooglePlus Login Error: ', error);
  });

  return promise;
}

/**
 * When signup, stores amplitude event data into localStorage.
 * @param {Object} data Api login response.
 */
function* setAmplitudeSignupData(data) {
  const signup = !data || data.signup === 'false' ? false : !!data.signup;
  if (signup) {
    const invited = data.invited === 'false' ? false : !!data.invited;

    localStorage.setItem('amplitude-signup', true);
    localStorage.setItem('amplitude-invited', invited);
    localStorage.setItem('is-invited-user', invited);
  }
}

/**
 * On join secret done.
 */
function* joinUsingSecretDone(action) {
  yield call(updateAmplitudeUserInvited);
  yield call(updateSignupDetails, action);
}

function* updateSignupDetails(action) {
  const state = yield select();
  let accountId = action && action.accountId || accessibleAccountsSelectors.accessibleAccounts(state)[0] || router.selectors.accountId(state);
  let boardId = action && action.boardId || router.selectors.pageBoardId(state);
  if(!accountId || localStorage.getItem('is-invited-user') !== 'true') {
    return;
  }

  localStorage.removeItem('is-invited-user');
  if(boardId) {
    yield put(signup.actions.updateSignupDetails('BOARD_INVITATION', {accountId: accountId, boardId: boardId}));
  } else {
    yield put(signup.actions.updateSignupDetails('ACCOUNT_INVITATION', {accountId: accountId}));
  }
}

/**
 * When user has single accessible account and user has not owned accounts then, sets `is_invited` value as true of amplitude user.
 */
function* updateAmplitudeUserInvited() {
  const state = yield select();
  const accessibleAccounts = selectors.currentUserAccessibleAccount(state);
  const ownedAccounts =  selectors.currentUserOwnedAccounts(state);
  const userId = selectors.currentUserId(state);

  if((!ownedAccounts || ownedAccounts.length === 0) && accessibleAccounts && Object.keys(accessibleAccounts).length <= 1) {
    const amplitudeSignup = localStorage.getItem('amplitude-signup') === 'true' || localStorage.getItem('amplitude-signup') === true;
    if(amplitudeSignup) {
      localStorage.setItem('amplitude-invited', true);
    } else {
      amplitude.setUserProperties(userId, {'is_invited': true});
    }
    localStorage.setItem('is-invited-user', true);
  }
}

/**
 * Set `non_profit` user property on amplitude.
 */
let unsubscribeNonProfitPropertyAccountId;
let unsubscribeNonProfitPropertyPlan;
let nonProfitLastUserId;
function* manageAmplitudeUserNonProfitProperty(action) {
  let state = yield select();
  const user = get(action.auth, 'user') || selectors.currentUser(state);
  const userId = user && user.id;
  if(!userId) {
    return;
  }

  if(nonProfitLastUserId === userId) {
    return;
  }

  nonProfitLastUserId = userId;
  unsubscribeNonProfitPropertyAccountId = ReduxUtils.subscribe(store, `auth.ownedAccounts`, (ownedAccounts)=> {
    const accountId = ownedAccounts && ownedAccounts[0];
    if(accountId) {
      unsubscribeNonProfitPropertyPlan = ReduxUtils.subscribe(store, `firebase.subscriptions.${accountId}.subscription.plan`, (plan)=> {
        amplitude.setUserProperties(userId, {'non_profit': plan === 'NON_PROFIT'});
      });
    } else {
      amplitude.setUserProperties(userId, {'non_profit': false});
    }
  });
}

function* updateAuth(action) {
  yield call(subscribeAuthData, action);

  const userId = get(action.auth, 'user.id');
  if(userId) {
    yield call(trackAmplitudeEvents, action);
    yield call(integrateClarity, action);
    yield call(integratgeAmplitudeSessionReplays, action);
    yield call(manageAmplitudeUserNonProfitProperty, action);

    //Track join account details.
    const state = yield select();
    const joinSecret = signup.selectors.joinSecret(state);
    if(!joinSecret) {
      yield call(updateSignupDetails);
    }
    yield call(updateUtmDetails)
  } else {
    unsubscribeNonProfitPropertyAccountId && unsubscribeNonProfitPropertyAccountId();
    unsubscribeNonProfitPropertyPlan && unsubscribeNonProfitPropertyPlan();
  }
}

//Intregrate amplitude Session Replays, see this for details: https://a.kerika.net/acc_4AkDDShTrU2He25uThV8UR/board/brd_2mBzl3SvQkTf7h20y6WRjw/crd_3d5Q9bY6enE5GDRCK3kdlx?tab=attachments
let lastAmplitudeSessionReplaysUserId;
let lastAmplitudeSessionReplaysSignupTime;
function* integratgeAmplitudeSessionReplays(action) {
  let state = yield select();
  const user = get(action.auth, 'user') || selectors.currentUser(state);
  const userId = user && user.id;
  const signupTime = user && user.signupTime;
  if(!userId || !signupTime) {
    return;
  }

  if(lastAmplitudeSessionReplaysSignupTime === signupTime && lastAmplitudeSessionReplaysUserId === userId) {
    return;
  }

  lastAmplitudeSessionReplaysUserId = userId;
  lastAmplitudeSessionReplaysSignupTime = signupTime;

  //wait till firestore config is available
  yield call(waitTillFirestoreConfigAvailable);
  state = yield select();
  if(!window.sessionReplay) {
    console.log(`amplitude - sessionReplays > js not found`);
    return;
  }

  const config = app.selectors.config(state);
  const sessionReplays = get(config, 'amplitude.sessionReplays') || {};
  const enabled = sessionReplays && sessionReplays.enabled || false;
  const APIKey = config.amplitudeAPIKey || '';
  if(!enabled || !APIKey) {
    console.log(`amplitude - sessionReplays > Initialization is disabled for the environment`);
    window.sessionReplay.shutdown();
    return;
  }

  const signupDate = moment(signupTime).format('YYYY-MM-DD');
  const currentDate = moment().format('YYYY-MM-DD');
  const enableForDays = sessionReplays && sessionReplays.enableForDays || 1;
  const enableForDaysDate = moment(signupTime).add(enableForDays - 1, 'day').format('YYYY-MM-DD');

  if(!moment(currentDate).isSame(signupDate, 'day') && !moment(currentDate).isBetween(signupDate, enableForDaysDate)) {
    console.log(`amplitude - sessionReplays > It's been more than ${enableForDays} days since the user signed up.`, JSON.stringify({signupDate, enableForDays}));
    window.sessionReplay.shutdown();
    return;
  }

  yield window.sessionReplay.init(APIKey, { 
    sampleRate: 1,
    sessionId: window.amplitude.getSessionId(),
    deviceId: device.selectors.getId(state)
  }).promise;
  console.log(`amplitude - sessionReplays > started`);
}

//Intregrate clarity, see this for details: https://a.kerika.net/C7r/board/N2O6/ZZ5g?tab=description
let lastClarityUserId;
let lastClaritySignupTime;
function* integrateClarity(action) {
  let state = yield select();
  const user = get(action.auth, 'user') || selectors.currentUser(state);
  const userId = user && user.id;
  const signupTime = user && user.signupTime;
  if(!userId || !signupTime) {
    return;
  }

  if(lastClaritySignupTime === signupTime && lastClarityUserId === userId) {
    return;
  }

  lastClarityUserId = userId;
  lastClaritySignupTime = signupTime;

  //wait till firestore config is available
  yield call(waitTillFirestoreConfigAvailable);
  state = yield select();
  const config = app.selectors.config(state);
  const clarityProjectId = get(config, 'clarity.projectId') || get(config, 'clarityProjectId');
  const signupDate = moment(signupTime).format('YYYY-MM-DD');

  if(!clarityProjectId) {
    console.debug(`clarity > Initialization is disabled for the environment`);
    return;
  }

  const currentDate = moment().format('YYYY-MM-DD');
  const enableForDays = get(config, 'clarityEnableForDays') || get(config, 'clarity.enableForDays') || 1;
  const enableForDaysDate = moment(signupTime).add(enableForDays - 1, 'day').format('YYYY-MM-DD');

  if(!moment(currentDate).isSame(signupDate, 'day') && !moment(currentDate).isBetween(signupDate, enableForDaysDate)) {
    console.debug(`clarity > It's been more than ${enableForDays} days since the user signed up.`, JSON.stringify({signupDate, enableForDays}));
    return;
  }

  clarity.init({APIKey: clarityProjectId});
  clarity.setUserId(userId);
  clarity.setTag('returningUser', moment(currentDate).isSame(signupDate, 'day') ? 'NO': 'YES');
}

/**
 * Wait till firestore config is not available.
 */
function waitTillFirestoreConfigAvailable() {
  let resolve, reject;
  const promise = new Promise((res, rej) => { resolve = res, reject = rej; });
  const unsubscribe = ReduxUtils.subscribe(store, `firestore.docs.app.config`, (config)=> {
    if(config !== undefined) {
      unsubscribe && unsubscribe();
      resolve(config);
    }
  });
  return promise;
}

/**
 * Track login Or signup event on amplitude based on auth data.
 */
function* trackAmplitudeEvents(action) {
  const userId = get(action.auth, 'user.id');
  if(!userId) {
    return;
  }

  // Set User Id
  amplitude.setUserProperties(userId);

  const amplitudeSignup = localStorage.getItem('amplitude-signup') === 'true' || localStorage.getItem('amplitude-signup') === true;
  if(amplitudeSignup) {
    trackAmplitudeSignupEvent(get(action.auth, 'user.signupTime'), userId);
  }

  const amplitudeLoginTracked = localStorage.getItem('amplitude-login-tracked') === 'true';
  if(!amplitudeSignup && !amplitudeLoginTracked) {
    trackAmplitudeLoginEvent();
  }
  localStorage.setItem('amplitude-login-tracked', true);
}

/**
 * Track signup event on amplitude based on auth data.
 */
function trackAmplitudeSignupEvent(signupTime, userId) {
  const amplitudeInvaited = localStorage.getItem('amplitude-invited') === 'true' || localStorage.getItem('amplitude-invited') === true;
  amplitude.setUserProperties(userId, {service: getLastSelectedAuthService(), 'is_invited': amplitudeInvaited, 'signup_time': moment(signupTime).format('YYYY-MM-DDTHH:mm:ss')});
  amplitude.logEvent('signup');
  localStorage.removeItem('amplitude-signup');
  localStorage.removeItem('amplitude-invited');
}

/**
 * Track login event on amplitude based on auth data.
 */
function trackAmplitudeLoginEvent() {
  amplitude.logEvent('login', {service: getLastSelectedAuthService()});
}

/**
 * @returns {String} service name based on `last-selected-auth-service` cookie value.
 */
function getLastSelectedAuthService() {
  const lastSelectedsAuthService = localStorage.getItem('auth-service') || getCookieVal('auth-service');
  return lastSelectedsAuthService === 'kerika'? 'KERIKA': lastSelectedsAuthService === 'box'? 'BOX': lastSelectedsAuthService === 'microsoft'? 'MICROSOFT': lastSelectedsAuthService === 'google-market-place' ? 'GOOGLE_MARKET_PLACE': 'GOOGLE';
}

function* linkAdditionalAuth({ service }) {
  const state = yield select();
  const installedApp = app.selectors.isInstalledApp(state);
  const embedLoginSignup = router.selectors.embedLoginSignup(state);
  try {
    if (app.selectors.oauth2LoginUsingNativeFlow(state, service)) {
      yield call(installedAppLogin, service, true);
    } else {
      yield call(oauth2Login, {service, linkAdditionalAuth: true, embedLogin: embedLoginSignup, installedApp});
    }
    if (!installedApp || service !== 'box' || service !== 'microsoft') {
      yield put(actions._linkAdditionalAuthSuccess(service));
    }
  } catch (error) {
    yield put(actions._linkAdditionalAuthFailed(service, error));
    const code =  error && error.code || 'UNKNOWN';
    if(code === 'EMAIL_NOT_AVAILABLE') {
      router.actions.setQueryParams({ 'attachment-sub-action': 'auth-identity-not-found', 'storage': service, 'file-preview-action': undefined }, false);
      return;
    }

    console.error("link additional auth is failed, due to this:", error);
    if(error && error.authFailed) {
      showSnackBar({message: code, type: 'ERROR'});
    }
  }
}

/**
 * Based on status, shows appropriate toast message. Possible values: already-linked-to-another-user', 'already-linked' or unknown
 * @param {*} param0 {error}
 */
function* linkAdditionalAuthFailed({ error }) {
  if (!error || error.code === 'CANCELED') {
    return;
  }
  //When BOX/MICROSOFT + Installed app, text resources is not loaded so loads it first.
  yield call(app.actions.initI18n);
  yield call(i18next.loadNamespaces.bind(i18next), ['attachments']);

  if (error.code === 'already-linked-to-another-user' || error.code === 'ALREADY_LINKED_TO_ANOTHER_USER') {
    showSnackBar({ message: i18next.t('attachments:filePreview.toast.linkedToAnotherUser'), type: 'ERROR' });
    return;
  }

  if (error.code === 'already-linked' || error.code === 'ALREADY_LINKED') {
    showSnackBar({ message: i18next.t('attachments:filePreview.toast.alreadyLinked'), type: 'ERROR' });
    return;
  }

  console.error('Link additional auth failed:', error);
}

let unsubscribeUtmDetails;
function* updateUtmDetails(){
  const state = yield select();
  const userId = selectors.currentUserId(state);
  const deviceId = device.selectors.getId(state);

  unsubscribeUtmDetails && unsubscribeUtmDetails();
  unsubscribeUtmDetails = ReduxUtils.subscribe(store, `firestore.docs.device-ui.${`du_${deviceId}`}.utmDetails`, (utmDetails) => {
    store.dispatch(signup.actions.updateUtmDetails({userId, deviceId, details: utmDetails}));
  });
}

/**
 * If installed app and service is google then
 *   - Signup to googleplus through native API
 *   - Triggers server signup API.
 * If service is kerika then
 * Otherwise
 *   - Signup in childwindow.
 * Dispatch `SIGNUP_SUCCESS` when Signup is success.
 * Dispath `SIGNUP_FAILED` with proper error code.
 *  - `CANCELED`: user close child window
 *  - `TIMEOUT`: Signup is failed due to timeout
 *  - `USER_ALREADY_EXIST`: user is already exist.
 *  - `INVALID_TOKEN`: token is invalid
 *  - `UNKNOWN`: unknown error.
 */
 function* freshUserSignup(action) {
  const state = yield select();
  const service = action.service;
  let actualService = service;
  let response;
  try {
    authLoginStartedPostMessage(actualService);
    if(app.selectors.oauth2LoginUsingNativeFlow(state, service)) {
      actualService = yield call(installedAppLogin, service);
    } else {
      response = yield call(pwaSignup, service, action.firstName, action.lastName, action.email, action.password);
      actualService = response.service;
    }

    localStorage.setItem('auth-service', actualService);
    yield put(actions._signupSuccess({service: actualService, requestId: response.requestId}));
    if(actualService !== 'kerika') {
      yield call(refreshRouter, actualService);
    } else {
      authLoginSuccessPostMessage(actualService, true);
    }
  } catch (error) {
    let errorCode = error && error.status === 409 ?  'USER_ALREADY_EXIST': error.code === 'CANCELED' ? 'CANCELED': error.code === 'EMAIL_NOT_AVAILABLE' ? 'EMAIL_NOT_AVAILABLE' : 'UNKNOWN';
    yield put(actions._signupFailed({service: actualService, error: errorCode, message: error && error.message}));
    authLoginFailedPostMessage(actualService, errorCode);
    if(error && error.code === 'CANCELED') {
      amplitude.logEvent('auth cancelled', {service: service === 'box' ? 'BOX': service === 'microsoft' ? 'MICROSOFT': 'GOOGLE', trigger: router.selectors.signupLoginPageOpenTrigger(state)}, { trackReferrer: true });
    } else {
      amplitude.logEvent('auth failed', {service: service === 'box' ? 'BOX': service === 'kerika' ? 'KERIKA': service === 'microsoft' ? 'MICROSOFT' : 'GOOGLE', trigger: router.selectors.signupLoginPageOpenTrigger(state), reason: errorCode}, { trackReferrer: true });
      console.warn('Signup failed:', error);
    }
  }
}

/**
 * pwa signup for all service.
 */
function* pwaSignup(service, firstName, lastName, email, password) {
  const state = yield select();
  const config = app.selectors.config(state);
  const embedLoginSignup = router.selectors.embedLoginSignup(state);
  const templateAcId = app.selectors.templateAcId(state);
  const force = router.selectors.force(state);
  const installedApp = app.selectors.isInstalledApp(state);
  let response;
  if(service == 'kerika') {
    const uri = new URI();
    const source = router.selectors.source(state);
    const templateId = router.selectors.templateId(state);
    uri.fragment('');
    uri.query('');
    const path = uri.path();
    if(path && path.startsWith('/blank-page')) {
      uri.path('');
    }
    if(source === 'template' && templateId) {
      if(path && path.startsWith('/blank-page')) {
        uri.path(`/${templateAcId}/board/${templateId}`);
      }
      uri.addQuery('action', 'auto-create-board');
      uri.addQuery('template', templateId);
      uri.addQuery('force', force);
    }
    const redirectUrl = `${config.apiBaseUrl}/login?s=${service}&success=${window.encodeURIComponent(uri.toString())}`;
    response = yield call(requestApi, `${config.auth.userUIBaseUrl}/api/users`, { method: 'POST', excludeErrors: [502, 409], body: { email, password, firstName, lastName, redirectUrl } });
  } else {
    response = yield call(oauth2Login, {service, embedLogin: embedLoginSignup, installedApp});
    const signup = !response || response.signup === 'false' ? false : !!response.signup;
    if(signup) {
      const invited = response.invited === 'false' ? false: !!response.invited;

      localStorage.setItem('amplitude-signup', true);
      localStorage.setItem('amplitude-invited', invited);
      localStorage.setItem('is-invited-user', invited);
    }
  }

  const requestId = get(response, 'emails.0.id');
  return { service: response.actualService || response.service || service, requestId };
}

/**
 * Resned verification email to user.
 */
function* resendVerificationEmail(ation) {
  const state = yield select();
  const id =  selectors.requestId(state);
  const user = selectors.signupUserInfo(state);
  const email = user && user.email;
  const config = app.selectors.config(state);
  try {
    yield call(requestApi, `${config.auth.userUIBaseUrl}/api/users/emails/${id}/resend-verification-email`, { method: 'POST', excludeErrors: [502, 409]})
    showSnackBar({ message: i18next.t('login-and-signup:signup.signupAcceptNotification.message.success')});
    yield put(actions._resendVerificationEmailSuccess());
  } catch (error) {
    const errorCode = error && error.status === 409 ?  'USER_ALREADY_EXIST': error && error.code || 'UNKNOWN';
    if(errorCode === 'USER_ALREADY_EXIST' || USER_ALREADY_EXIST === 'UNKNOWN') {
      showSnackBar({ message: errorCode === 'USER_ALREADY_EXIST' ? i18next.t('login-and-signup:error.USER_ALREADY_EXIST_WITH_EMAIL', {email}): i18next.t('login-and-signup:signup.signupAcceptNotification.message.failed'),  type: 'ERROR'});
    }
    yield put(actions._resendVerificationEmailFailed(errorCode));
  }
}

/**
 * Load join secret data when join secret found in url.
 */
 function* loadJoinSecretData(action) {
  try {
    const joinDetail = yield call(
      requestApi,
      `/user/users/by-join-secret?secret=${action.joinSecret}`,
      { excludeErrors: [404, 409] }
    );
    yield put(actions._getjoinSecretInfoSuccess(joinDetail));
  } catch (error) {
    yield put(
      actions._getjoinSecretInfoFailed((error && error.code) || 'UNKNOWN')
    );
  }
}

function* invitedUserSignup(action) {
  try {
    authLoginStartedPostMessage('kerika');
    yield call(requestApi, `/user/users/pre-verified-oauth-user`, {
      method: 'POST',
      excludeErrors: [502, 409],
      body: {
        password: action.password,
        firstName: action.firstName,
        lastName: action.lastName,
        secret: action.secret,
      },
    });
    yield put(
      actions._signupUsingJoinSecretSuccess({
        service: 'kerika',
        email: action.email,
        password: action.password,
      })
    );
  } catch (error) {
    let errorCode =
      error && error.status === 409 ? 'EMAIL_ALREADY_EXIST' : 'UNKNOWN';
    yield put(
      actions._signupUsingJoinSecretFailed({
        service: 'kerika',
        error: errorCode,
        message: error && error.message,
      })
    );
    authLoginFailedPostMessage('kerika', errorCode);
  }
}

/**
 * Updates `auth` when owned accountId changed.
 */
 function* subscribeAccounts() {
  ReduxUtils.subscribe(store, 'firestore.docs.accounts', () => {
    const state = store.getState();
    const ownedAccDetails = selectors.ownedAccountDetails(state) || {};
    const ownedAccDetailsId = get(ownedAccDetails, 'id', '') || '';

    const auth = get(state, `auth`, {});
    const lastOwnedAccDetails = get(auth, 'ownedAccounts', []);
    const ownedAccounts = ownedAccDetailsId ? [ownedAccDetailsId]: [];
    if (isEqual(lastOwnedAccDetails, ownedAccounts)) {
      return;
    }

    const newAuth = {...auth, ownedAccounts};
    store.dispatch(actions.updateAuth(newAuth));
  });
}

/**
 * Updates `auth` when accessible accounts changed.
 */
function* subscribeAccountTeamMembers() {
  let previousAccessibleAccounts = {};
  ReduxUtils.subscribe(store, 'firestore.docs.account-team-members', (members) => {
    members = members || {};
    const state = store.getState();
    const currentUserId = selectors.currentUserId(state);
    const _members = Object.values(members) || [];
    let accessibleAccounts = {};
    for(const member of _members) {
      if (member && member.userId === currentUserId) {
        accessibleAccounts[member.accountId] = member.role;
      }
    }

    if (isEqual(previousAccessibleAccounts, accessibleAccounts)) {
      return;
    }

    previousAccessibleAccounts = accessibleAccounts;
    const auth = get(state, 'auth', {});
    const newAuth = {...auth, accessibleAccounts};
    store.dispatch(actions.updateAuth(newAuth));
  });
}

let unsubscribeUserProperties;
function* subscribeUserProperties(userId) {
  const state = yield select();
  userId = userId || selectors.currentUserId(state);
  unsubscribeUserProperties = ReduxUtils.subscribe(store, `firestore.docs.user-properties.ups_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`, () => {
    if(!user.selectors.firstSessionEnd(store.getState())) {
      unsubscribeUserProperties && unsubscribeUserProperties();
      lastSeenUpdateScheduler.startTrackFirstSessionTime(true);
    }
  });
}

/**
 * Updates `auth` when accessible boards are changed.
 */
function* subscribeAccessibleBoards() {
  let prevAccessibleBoards = {};

  ReduxUtils.subscribe(store, 'firestore.docs.user-accessible-boards', (boards) => {
    boards = boards || {};
    const _boards = Object.values(boards) || [];
    let accessibleBoards = {};
    for (const board of _boards) {
      if (!accessibleBoards[board.accountId]) {
        accessibleBoards[board.accountId] = {};
      }
      accessibleBoards[board.accountId][board.boardId] = board.type;
    }

    if (isEqual(prevAccessibleBoards, accessibleBoards)) {
      return;
    }

    prevAccessibleBoards = accessibleBoards;
    const state = store.getState();
    const auth = get(state, 'auth', {});
    const newAuth = { ...auth, accessibleBoards };
    store.dispatch(actions.updateAuth(newAuth));
  });
}

let prevUserId;
/**
 * Loads current user's attrs & subscribes on owned accounts, accessible accounts, last accessed accounts.
 * @param {Objects} param0 Auth data of current logged in user.
 * @returns
 */
function* subscribeAuthData({ auth }) {
  const userId = get(auth, 'user.id');
  if (!userId) {
    return;
  }

  if (userId === prevUserId) {
    return;
  }
  prevUserId = userId;

  yield call(subscribeAccounts);
  yield call(subscribeAccountTeamMembers);
  yield call(subscribeAccessibleBoards);
  // Loads current user's favorite boards.
  firestoreRedux.query('favorite-boards', { id: 'favorite-boards', where: [['userId', '==', userId]], requesterId: 'favorite-boards' });

  //Loads current user's messages.
  yield put(messages.actions.load(userId));
  yield call(subscribeUserProperties, userId);
}

function* stopImpersonatedSessions() {
  const state = yield select();
  const userId = selectors.currentUserId(state);
  const config = app.selectors.config(state);
  const adminUIBaseUrl = config && config.adminUIBaseUrl;
  try {
    yield call(requestApi, `/user/users/stop-impersonate`, { method: 'POST' })
    yield put(actions._stopImpersonatedSessionsSuccess());
    yield delay(1000);
    window.location.href = `${adminUIBaseUrl}/#manage-user/${userId}`;
  } catch (error) {
    const code =  error && error.code || 'UNKNOWN';
    yield put(actions._stopImpersonatedSessionsFailed(code))
    if(isNetworkError(error)) {
      return;
    }
    console.error("auth > saga > stopImpersonatedSessions: failed due to this: ", error);
  }
}

/**
 * Init Saga.
 */
function* loginSaga() {
  yield all([
    call(watchRouter),
    takeEvery([actions.LOGIN, actions.SIGNUP_USING_JOIN_SECRET_SUCCESS], login),
    takeEvery(actions.SIGNUP, freshUserSignup),
    takeEvery(actions.RESEND_VERIFICATION_EMAIL, resendVerificationEmail),
    takeEvery(signup.actions.JOIN_USING_SECRET_DONE, joinUsingSecretDone),
    takeEvery(actions.UPDATE_AUTH, updateAuth),
    takeEvery(forgotPassword.actions.RESET_DONE, resetPassword),
    takeEvery(actions.LINK_ADDITIONAL_AUTH, linkAdditionalAuth),
    takeEvery(actions.LINK_ADDITIONAL_AUTH_FAILED, linkAdditionalAuthFailed),
    takeEvery(actions.GET_JOIN_SECRET_INFO, loadJoinSecretData),
    takeEvery(actions.SIGNUP_USING_JOIN_SECRET, invitedUserSignup),
    takeEvery(actions.STOP_IMPERSONATED_SESSIONS, stopImpersonatedSessions)
  ]);
}

export default loginSaga;
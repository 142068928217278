import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import forEach from 'lodash-es/forEach';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import sortBy from 'lodash-es/sortBy';
import isEqual from 'lodash-es/isEqual';

import { getUserName, sortMembers } from '../../components/utils.js';
import { getIdWoPrefix } from '../../utils';

import * as auth from '../auth';
import * as router from '../router';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as selectorCreator from '../selector-creator.js';

/**
 * @returns Account Settings e.g { privacy, wipLimit, convertGDocs }
 */
export const settings = selectorCreator.default({maxSize: 10})(
  ({state, accountId}) => {
    accountId = accountId || router.selectors.accountId(state);
    return firestoreRedux.selectors.doc(state, 'account-settings', `as_${getIdWoPrefix({id: accountId, prefix: 'acc_'})}`)
  },
  (settings) => {
    return settings || {};
  }
);

/**
 * @param {Object} state redux state
 * @returns {Object} account owner details.
 */
export const accountOwnerDetail = selectorCreator.default({maxSize: 100})(
  ({state, accountId}) => firestoreRedux.selectors.doc(state, 'accounts', accountId || router.selectors.accountId(state)),
  ({state, accountId}) => firestoreRedux.selectors.collection(state, 'users'),
  (accountDetails, users) => {
    const ownerId = accountDetails && accountDetails.ownerId;
    if(!ownerId) {
      return undefined;
    }

    return get(users, ownerId);
  }
);

/**
 * @param {Object} state redux state
 * @returns {String} account domain from account owner email.
 */
export const accountDomain = selectorCreator.default({maxSize: 100})(
  ({state, accountId}) => accountOwnerDetail({state, accountId}),
  (accountOwner) => {
    return accountOwner && accountOwner.email && accountOwner.email.split('@')[1] || '';
  }
);

/* @param {Number} accountId
 * @return { Array } Selector which return array of
 *                   alphabetical sorted list of account team member details
 */
export const membersList = selectorCreator.reselct.createSelector(
  ({state, accountId}) => get(state, `manage-account.team.${accountId}`, {}),
  ({state, accountId}) => firestoreRedux.selectors.collection(state, 'users'),
  ({state, accountId}) => firestoreRedux.selectors.collection(state, 'lastseen-details'),
  ({state, accountId}) => firestoreRedux.selectors.docsByQueryResult(state, `account-team-members-${accountId}`, 'account-team-members'),
  ({state, accountId}) => firestoreRedux.selectors.queryStatus(state, `account-team-members-${accountId}`),
  (teamDetails, users, lastseenDetail, members, queryStatus) => {
    if (members === undefined) {
      return undefined;
    }

    if (isEmpty(members)) {
      if(!queryStatus || queryStatus === 'PENDING') {
        return undefined;
      }
      return [];
    }

    let membersModel = [];
    forEach(members, (details) => {
      const id = details && details.userId;
      const user = get(users, id);
      const name = getUserName(user);
      const email = user && user.email || '';
      const pending = user && user.pending || false;

      const userLastSeenDetails = get(lastseenDetail, `ulsd_${getIdWoPrefix({id, prefix: 'usr_'})}`);
      const lastSeen = get(userLastSeenDetails, 'time', get(user, 'lastSeen'));
      const lastLogin = get(user, 'lastLoginTime');
      const ipAddress = get(userLastSeenDetails, 'ip', get(user, 'lastLoginIp'));
      const admin = details && details.role === 'ACCOUNT_ADMIN' || false;

      const sharedBoardCount = get(teamDetails, `userWiseBoardCount.${id}`, 0);
      membersModel.push({...details, ...{id, admin, name, email, lastSeen, lastLogin, ipAddress, sharedBoardCount, pending}});
    });
    return sortMembers(membersModel);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      equalityCheck: isEqual,
      maxSize: 200
    },
  }
);

/* @param {Number} accountId
 * @return { Array } Selector which return array of
 *                   alphabetical sorted list of account team member details
 *                   which has no email for given account.
 */
export const uninivitedMembersList = selectorCreator.default({maxSize: 50})(
  ({state, accountId}) => membersList({state, accountId}),
  (members) => {
    if (members === undefined) {
      return undefined;
    }

    if (isEmpty(members)) {
      return [];
    }

    return filter(members, function (member) { return member && !member.email && member.name });
  }
);

/* @param {Number} accountId
 * @return { Array } Selector which return array of
 *                   alphabetical sorted list of account team member details
 *                   which role is `TEAM_MEMBER` in account team for given account.
 */
export const teamMembersList = selectorCreator.default({maxSize: 50})(
  ({state, accountId}) => membersList({state, accountId}),
  (members) => {
    return members !== undefined ? filter(members, function (member) { return member && member.role === 'TEAM_MEMBER' }): undefined;
  }
);

/**
 * @param {Number} accountId
 * @return { Array } Selector which return array of
 *                   alphabetical sorted list of account team member details
 *                   which role is `VISITOR` in account team for given account.
 */
export const visitorsList = selectorCreator.default({maxSize: 50})(
  ({state, accountId}) => membersList({state, accountId}),
  (members) => {
    return members !== undefined ? filter(members, function (member) { return member && member.role === 'VISITOR' }): undefined;
  }
);

/**
 * @param {Number} accountId
 * @return { Array } Selector which return array of
 *                   alphabetical sorted list of account team member details
 *                   which is admin in account team for given account.
 */
export const adminsList = selectorCreator.default({maxSize: 50})(
  ({state, accountId}) => membersList({state, accountId}),
  (members) => {
    return members !== undefined ? filter(members, function (member) { return member && member.role === 'ACCOUNT_ADMIN' }): undefined;
  }
);

/**
 * @param {Object} state redux state
 * @param {String} email
 * @returns {Boolean} `true` when given email is match with one of the team member emails. `false` otherwise
 */
export const userIsExist = selectorCreator.reselct.createSelector(
  (state, email) => accountOwnerDetail({state, accountId: router.selectors.accountId(state)}),
  (state, email) => membersList({state, accountId: router.selectors.accountId(state)}),
  (state, email) => email,
  (ownerDetails, members, email) => {
    if(ownerDetails && ownerDetails.email === email) {
      return true;
    };

    return isEmpty(find(members, { email })) ? false: true;
  }
);

/**
 * @returns {Object} Selector which return member details(name, email, IP address, etc).
 * @param {Number} accountId
 * @param {Number} memberId
 */
export const memberDetails = selectorCreator.default({maxSize: 100})(
  ({state, accountId, memberId}) => membersList({state, accountId}),
  ({state, accountId, memberId}) => memberId,
  (members, memberId) => {
    return find(members, {id: memberId}) || {};
  }
);

/**
 * @returns {Object} Selector which return alphabetically sorted order of participated boards.
 * @param {Number} accountId
 * @param {Number} memberId
 */
export const participatedBoards = selectorCreator.default({maxSize: 100})(
  ({state, memberId, accountId}) => get(state, `manage-account.participatedBoards.${accountId || router.selectors.accountId(state)}.${memberId}`),
  (_participatedBoards) => {
    if(isEmpty(_participatedBoards)) {
      return _participatedBoards !== undefined ? []: _participatedBoards;
    }
    return sortBy(_participatedBoards, ['name']);
  }
);

/**
 * @param {Object} state redux state
 * @param {Number} memberId
 * @returns {Number} How many boards have the role of admin in the currently opened account of the given member.
 */
export const adminBoardsCount = selectorCreator.reselct.createSelector(
  (state, memberId) => participatedBoards({state, accountId: router.selectors.accountId(state), memberId}),
  (_participatedBoards) => {
    let count = 0;
    forEach(_participatedBoards, (board) => {
      if(board && board.role =='BOARD_ADMIN') {
        count++;
      }
    });
    return count;
  }
);

/**
 * @param {Object} state redux state
 * @param {Number} memberId
 * @returns {Number} How many boards have the role of team-member in the currently opened account of the given member.
 */
export const teamMemberBoardsCount = selectorCreator.reselct.createSelector(
  (state, memberId) => participatedBoards({state, accountId: router.selectors.accountId(state), memberId}),
  (_participatedBoards) => {
    let count = 0;
    forEach(_participatedBoards, (board) => {
      if(board && board.role =='TEAM_MEMBER') {
        count++;
      }
    });
    return count;
  }
);

/**
 * @param {Object} state redux state
 * @param {Number} memberId
 * @returns {Number} How many boards have the role of visitor in the currently opened account of the given member.
 */
export const visitorBoardsCount = selectorCreator.reselct.createSelector(
  (state, memberId) => participatedBoards({state, accountId: router.selectors.accountId(state), memberId}),
  (_participatedBoards) => {
    let count = 0;
    forEach(_participatedBoards, (board) => {
      if(board && board.role =='VISITOR') {
        count++;
      }
    });
    return count;
  }
);

/**
 * @param {Object} state redux state
 */
export const teamMemberCount = selectorCreator.reselct.createSelector(
  (state) => teamMembersList({state, accountId: router.selectors.accountId(state)}),
  (teamMembers) => {
    return teamMembers === undefined ? undefined: teamMembers && teamMembers.length || 0;
  }
);

/**
 * @param {Object} state redux state
 * @param {Number} accountId
 * @returns {Boolean} `true` current user is account admin for given account OR current account.
 */
export const isAccountAdmin = selectorCreator.reselct.createSelector(
  ({state, accountId}) => auth.selectors.currentUserId(state),
  ({state, accountId}) => firestoreRedux.selectors.doc(state, 'accounts', accountId || router.selectors.accountId(state)),
  ({state, accountId}) => adminsList({state, accountId: accountId || router.selectors.accountId(state)}),
  (userId, accountAttrs, adminsList) => {
    if(accountAttrs === undefined) {
      return undefined;
    }

    if(accountAttrs && accountAttrs.ownerId === userId) {
      return true;
    }

    if(adminsList === undefined) {
      return undefined;
    }

    const currentUserAccountDetails = find(adminsList, { userId });
    return currentUserAccountDetails && currentUserAccountDetails.userId ? true: false;
  }
);

/**
 * @param {Object} state redux state
 * @param {Number} accountId
 * @returns {Boolean} `true` current user is member of team or not.
 */
export const isMember = selectorCreator.reselct.createSelector(
  ({state, accountId}) => auth.selectors.currentUserId(state),
  ({state, accountId}) => firestoreRedux.selectors.doc(state, 'accounts', accountId || router.selectors.accountId(state)),
  ({state, accountId}) => membersList({state, accountId}),
  (userId, accountAttrs, membersList) => {
    if(accountAttrs === undefined) {
      return false;
    }

    if(accountAttrs && accountAttrs.ownerId === userId) {
      return true;
    }

    if(membersList === undefined) {
      return undefined;
    }

    const currentUserAccountDetails = find(membersList, { userId });
    return currentUserAccountDetails && currentUserAccountDetails.userId ? true: false;
  }
);

/**
 * @param {Object} state redux state
 * @param {String} accountId
 * @param {String} userId
 * @returns {String} `ACCOUNT_OWNER` if current user is account owner, `ACCOUNT_ADMIN` if current user is account admin, `TEAM_MEMBER` if current user is team member, `VISITOR` if current user is visitor, `null` if current user is not a member of the team.
 *                   `undefined` if account is not found.
 */
export const memberRole = selectorCreator.reselct.createSelector(
  ({state, accountId, userId}) => userId || auth.selectors.currentUserId(state),
  ({state, accountId, userId}) => firestoreRedux.selectors.doc(state, 'accounts', accountId || router.selectors.accountId(state)),
  ({state, accountId, userId}) => membersList({state, accountId}),
  ({state, accountId, userId}) => userId === auth.selectors.currentUserId(state) ? auth.selectors.currentUserAccessibleAccount(state): {},
  (userId, accountAttrs, membersList, accessibleAccounts) => {
    if(accountAttrs === undefined) {
      return undefined;
    }

    if(accountAttrs && accountAttrs.ownerId === userId) {
      return 'ACCOUNT_OWNER';
    }

    membersList = membersList || []
    const currentUserAccountDetails = find(membersList, { userId });
    if(currentUserAccountDetails && currentUserAccountDetails.role) {
      return currentUserAccountDetails.role;
    }
    
    // If role not found in membersList, check in accessibleAccounts
    const accountIdToCheck = accountAttrs && accountAttrs.id;
    if(accessibleAccounts && accountIdToCheck) {
      return accessibleAccounts[accountIdToCheck] || null;
    }
    
    return null;
  }
);
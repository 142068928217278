import { takeEvery, select, all, put, call, spawn, take } from 'redux-saga/effects';
import { store } from '../../store';
import * as app from '../app';
import * as auth from '../auth';
import * as router from '../router';
import * as actions from './actions';
import * as selectors from './selectors';
import * as utils from '../../utils';
import * as board from '../board';
import * as manageAccount from '../manage-account';
import firestoreRedux from '@dreamworld/firestore-redux';
import { ReduxUtils } from '@dw/pwa-helpers';
import { requestApi, isNetworkError } from '../../request-api';
import { downloadUrl } from '../../components/utils';
import checkoutSession from './checkout-session';
import stripeSetupPaymentMethod from './setup-payment-method';
import { openExternalLink } from '../../components/open-external-link.js';

import { show as showSnackBar } from '../../components/kerika-snackbar';
import i18next from '@dw/i18next-esm';

import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

/**
 * Manages notification dialogs based on subscription status changes.
 * Shows appropriate notifications and handles related actions when subscription status changes.
 */
let lastSubscriptionStatus;
function manageNotificationDialog(status) {
  const state = store.getState();
  const dialog = router.selectors.dialogName(state);
  const accountId = router.selectors.accountId(state);
  
  // Don't process if status hasn't changed
  if (status === lastSubscriptionStatus) {
    return;
  }
  
  // Close notification dialogs if subscription becomes active/trialing/past_due
  const activeStatuses = ['ACTIVE', 'TRIALING', 'PAST_DUE'];
  const notificationDialogs = ['TRIAL_END', 'SUBSCRIPTION_CANCELED', 'SUBSCRIPTION_UNPAID'];
  
  if (activeStatuses.includes(status) && notificationDialogs.includes(dialog)) {
    router.actions.setHashPath('');
  }
  
  // Load invoice data for payment-related statuses
  if (['PAST_DUE', 'UNPAID'].includes(status)) {
    store.dispatch(actions.loadLastInvoice(accountId));
  }
  
  // Close canceled subscription dialog if subscription no longer exists
  if (!status && dialog === 'SUBSCRIPTION_CANCELED') {
    router.actions.setHashPath('');
  }
  
  // Map subscription statuses to their corresponding dialog paths
  const statusToDialogPath = {
    'PAUSED': 'trial-end',
    'CANCELED': 'subscription-canceled',
    'UNPAID': 'subscription-unpaid'
  };
  
  try {
    const dialogPath = statusToDialogPath[status];
    if (dialogPath) {
      router.actions.setHashPath(dialogPath);
    } else if (status) {
      console.log(`Subscription status changed to: ${status}`);
    }
  } catch (error) {
    console.error('Failed to manage notification dialog:', error);
  }
  
  lastSubscriptionStatus = status;
}

function* cancelSubscription(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const value = action.value;
  try {
    yield call(requestApi, `/subscription-v4/subscriptions/${accountId}/cancel`, { method: 'POST', body: { cancelOn: value } });
    yield put(actions._cancelSubscriptionSuccess(accountId, value));
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    yield put(actions._cancelSubscriptionFailed(accountId, value, code));
    if(isNetworkError(error)) {
      return;
    }

    console.error('Failed to cancel subscription due to this: ', error);
  }
}

/**
 * Loads Subscriptions details from firestore.
 */
let unsubscribeSubscriptionStatus;
function* load(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  unsubscribeSubscriptionStatus && unsubscribeSubscriptionStatus();
  unsubscribeSubscriptionStatus = ReduxUtils.subscribe(store, `firestore.docs.subscriptions.sub_${utils.getIdWoPrefix({ id: accountId, prefix: 'acc_' })}.status`, (status) => {
    manageNotificationDialog(status);
  });

  yield spawn(_load, action);
}

function* _load(action) {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const accountId = action.accountId || router.selectors.accountId(state);
  const subscriptionId = `sub_${utils.getIdWoPrefix({ id: accountId, prefix: 'acc_' })}`;
  const requesterId = action.requesterId || `subscriptions-${accountId}`;

  try {
    let response;
    if (!userId) {
      response = yield call(requestApi, `/subscription-v4/subscriptions/${accountId}`);
    } else {
      const query = firestoreRedux.getDocById(`accounts/${accountId}/subscriptions`, subscriptionId, { requesterId, id: `subscriptions.${subscriptionId}` });
      response = yield query.result;
    }
    yield put(actions._loadSuccess(accountId, response));
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    yield put(actions._loadFailed(accountId, code));
    if (isNetworkError(error)) {
      return;
    }
    console.error('Failed to load subscription details due to this: ', error);
  }
}

/**
 * Stop live listening from firebase for subscriptions details.
 */
function* disconnect(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const requesterId = action.requesterId || `subscriptions-${accountId}`;
  firestoreRedux.cancelQueryByRequester(requesterId);
}

function* loadBillingDetails(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  try {
    const billingDetails = yield call(requestApi, `/subscription-v4/billings/${accountId}`);
    yield put(actions._loadBillingDetailsSuccess(accountId, billingDetails));
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    yield put(actions._loadBillingDetailsFailed(accountId, code));

    if(isNetworkError(error)) {
      return;
    }
    console.error('Failed to load billing details due to this: ', error);
  }
}

function* loadPricing(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  let pricingData = selectors._pricingData(state, accountId);
  try {
    //If pricing details already loaded, then no need to load again.
    if(!isEmpty(pricingData)){
      yield put(actions._loadPricingSuccess(accountId, pricingData));
      return;
    }

    pricingData = yield call(requestApi, `/subscription-v4/pricing-detail?accountId=${accountId}`);
    yield put(actions._loadPricingSuccess(accountId, pricingData));
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    yield put(actions._loadPricingFailed(accountId, code));

    if(isNetworkError(error)) {
      return;
    }
    console.error('Failed to load pricing details due to this: ', error);
  }
}

function* computeCharges(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const priceId = action.priceId;
  const additionalSeats = action.additionalSeats || 0;
  const chargesType = action.chargesType;
  try {
    const charges = yield call(requestApi, `/subscription-v4/subscriptions/${accountId}/compute-charges`, { method: 'POST', body: chargesType === 'CANCEL_SUBSCRIPTION' ? {}: { priceId, additionalSeats }, excludeErrors: [409, 401, 400, 404] });
    yield put(actions._computeChargesSuccess({accountId, data: charges}));
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    yield put(actions._computeChargesFailed({accountId, erorr: code}));

    if(isNetworkError(error)) {
      return;
    }
    console.error('Failed to compute charges due to this: ', error);
  }
}

function* changePlan(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const priceId = action.priceId;
  const additionalSeats = action.additionalSeats || 0;
  const autoRenew = action.autoRenew || false;
  const email = action.email || '';
  const wideLayout = app.selectors.wideLayout(state);
  const actionType = action.actionType;
  try {
    let response;
    if(actionType === 'CHANGE_PLAN') {
      response = yield call(requestApi, `/subscription-v4/subscriptions/${accountId}/change-plan`, { method: 'POST', body: { priceId, autoRenew } });
    } else {
      response = yield call(checkoutSession, {accountId, priceId, additionalSeats, autoRenew, email, wideLayout});
    }
    yield put(actions._changePlanSuccess({accountId, data: response}));
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    if(code === 'CANCELED') {
      yield put(actions._changePlanCancel({ accountId }));
      return;
    }
    
    yield put(actions._changePlanFailed({ accountId, error: code }));
    if(isNetworkError(error)) {
      return;
    }
    console.error('Failed to change plan due to this: ', error);
  }
}

/**
 * Handles successful plan changes and executes follow-up actions based on dialog parameters
 * @param {Object} action - The action that triggered this saga
 */
function* onChangePlanSuccess() {
  const state = yield select();
  const dialogParams = router.selectors.dialogParams(state) || {};
  const { 
    action: actionType, 
    type, 
    role, 
    accountId, 
    boardId, 
    memberId, 
    requestId,
    emails = '' 
  } = dialogParams;

  // If required parameters are missing, exit early
  if (!actionType || !type) {
    return;
  }

  // Convert email string to array, handling both empty strings and comma-separated lists
  const emailList = emails ? emails.split(',').filter(Boolean) : [];

  try {
    // Handle board team related actions
    if (type === 'board-team') {
      if (actionType === 'add') {
        yield put(board.actions.addMember({ boardId, emails: emailList, role, requestId }));
      } else if (actionType === 'upgrade') {
        yield put({type: board.actions.BOARD_TEAM_CHANGE_MEMBER_ROLE, boardId, userId: memberId, role});
      }
    } 
    // Handle account team related actions
    else if (type === 'account-team') {
      if (actionType === 'add') {
        yield put(manageAccount.actions.addMembers(accountId, emailList, role));
      } else if (actionType === 'upgrade') {
        yield put(manageAccount.actions.upgradeRole(accountId, memberId));
      }
    }
    // Log unhandled action types for debugging
    else {
      console.log(`Unhandled action type in onChangePlanSuccess: ${type}`);
    }
  } catch (error) {
    console.error('Error in onChangePlanSuccess:', error);
  }
}

/**
 * This currently only used for undo action.
 */
function* enableAutoRenew(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const inSameWindow = action.inSameWindow || false;
  try {
    yield call(requestApi, `/subscription-v4/subscriptions/${accountId}/enable-auto-renew`, { method: 'POST'});
    yield put(actions._enableAutoRenewSuccess(accountId, inSameWindow));
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    yield put(actions._enableAutoRenewFailed(accountId, inSameWindow, code));
    if(isNetworkError(error)) {
      return;
    }
    console.error('Failed to enable auto-renew due to this: ', error);
  }
}

/**
 * This currently only used for undo action.
 */
function* disableAutoRenew(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const inSameWindow = action.inSameWindow || false;
  try {
    yield call(requestApi, `/subscription-v4/subscriptions/${accountId}/disable-auto-renew`, { method: 'POST'});
    yield put(actions._disableAutoRenewSuccess(accountId, inSameWindow));
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    yield put(actions._disableAutoRenewFailed(accountId, inSameWindow, code));
    if(isNetworkError(error)) {
      return;
    }
    console.error('Failed to disable auto-renew due to this: ', error);
  }
}

function* changeAutoRenew(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const autoRenew = !!action.value;
  const confirm = !!action.confirm;
  const inSameWindow = !!action.inSameWindow;
  const dialogName = router.selectors.dialogName(state);
  const isAutoRenewPageOpened = router.selectors.pageName(state) === 'SUBSCRIPTION_AUTO_RENEW';
  
  try {
    // Handle auto-renew enable without confirmation
    if (autoRenew && !confirm) {
      router.actions.setHashPath('auto-renew-on', !!dialogName);
      yield put(actions._changeAutoRenewSuccess(accountId, autoRenew, inSameWindow));
      return;
    }

    if (!autoRenew) {
      // Disable auto-renew
      yield call(requestApi, `/subscription-v4/subscriptions/${accountId}/disable-auto-renew`, { method: 'POST'});
      yield put(actions._changeAutoRenewSuccess(accountId, autoRenew, inSameWindow));
      
      // Setup undo action with snackbar
      const actionButton = { 
        caption: i18next.t('buttons.undo'), 
        callback: () => store.dispatch(actions._enableAutoRenew(accountId, inSameWindow))
      };
      
      !isAutoRenewPageOpened && showSnackBar({ 
        message: i18next.t('subscription:planSummary.descriptions.professionalPlan.renewAutoOff'), 
        actionButton
      });
    } else {
      // For enabling auto-renew, payment method is required
      yield put(actions._changeAutoRenewFailed(accountId, autoRenew, inSameWindow, 'PAYMENT_METHOD_REQUIRED'));
    }
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    yield put(actions._changeAutoRenewFailed(accountId, autoRenew, inSameWindow, code));

    if (isNetworkError(error) || code === 'PAYMENT_METHOD_REQUIRED') {
      return;
    }
    console.error(`Failed to ${autoRenew ? 'enable' : 'disable'} auto-renew:`, error);
  }
}

function* changeAutoRenewFailed(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const error = action.error;
  const inSameWindow = action.inSameWindow || false;
  if(error === 'PAYMENT_METHOD_REQUIRED') {
    yield put(actions.setupPaymentMethod(accountId, inSameWindow));
  }
}

function* setupPaymentMethod(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const autoRenew = true;
  const inSameWindow = action.inSameWindow || false;
  const isAutoRenewPageOpened = router.selectors.pageName(state) === 'SUBSCRIPTION_AUTO_RENEW';
  const wideLayout = app.selectors.wideLayout(state);
  try {
    const response = yield call(stripeSetupPaymentMethod, accountId, autoRenew, inSameWindow, wideLayout);
    yield put(actions._setupPaymentMethodSuccess(accountId, response));
    const actionButton = { 
      caption: i18next.t('buttons.undo'), 
      callback: () => store.dispatch(actions._disableAutoRenew(accountId, inSameWindow))
    };
    
    !isAutoRenewPageOpened && showSnackBar({ message: i18next.t('subscription:planSummary.descriptions.professionalPlan.renewAutoOn'), actionButton });
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    if(code === 'CANCELED') {
      yield put(actions._setupPaymentMethodCancel(accountId));
      return;
    }
    yield put(actions._setupPaymentMethodFailed(accountId, code));
    if(isNetworkError(error)) {
      return;
    }
    console.error('Failed to setup payment method due to this: ', error);
  }
}

function* subscriptionAutoRenewPageOpened(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  yield put(actions.load(accountId));
}

function* openLastInvoicePaymentLink(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const config = app.selectors.config(state);
  try {
    const url = `${config.apiBaseUrl}/subscription-v4/subscriptions/${accountId}/resume-subscription`;
    openExternalLink({ href: url});
  } catch (error) {
    console.error('Failed to open last invoice payment link due to this: ', error);
  }
}

function* loadInvoices(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  try {
    const invoices = yield call(requestApi, `/subscription-v4/invoices/${accountId}`);
    yield put(actions._loadInvoicesSuccess(accountId, invoices));
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    yield put(actions._loadInvoicesFailed(accountId, code));

    if(isNetworkError(error)) {
      return;
    }
    console.error('Failed to load invoices due to this: ', error);
  }
}

function* loadSubscriptionHistory(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const requesterId = action.requesterId || `subscription-history-${accountId}`;
  try {
    const query = firestoreRedux.query(`subscription-history-items`, { id: `subscription-history-items_accountId-${accountId}`, where: [['accountId', '==', accountId]], requesterId });
    const response = yield query.result;
    yield put(actions._loadSubscriptionHistorySuccess(accountId, requesterId, response));
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    yield put(actions._loadSubscriptionHistoryFailed(accountId, requesterId, code));
    console.error('Failed to load subscription history due to this: ', error);
  }
}

function* loadNonProfitRequest(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const requesterId = action.requesterId || `non-profit-requests-${accountId}`;
  try {
    const query = firestoreRedux.getDocById(`accounts/${accountId}/non-profit-requests`, `npr_${utils.getIdWoPrefix({ id: accountId, prefix: 'acc_' })}`, { requesterId });
    const response = yield query.result;
    yield put(actions._loadNonProfitRequestSuccess(accountId, requesterId, response));
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    yield put(actions._loadNonProfitRequestFailed(accountId, requesterId, code));
    if(isNetworkError(error)) {
      return;
    }
    console.error('Failed to load non-profit request data due to this: ', error);
  }
}

let unsubscribeToLoadAdditionalData;
let previousLoadedUsers;
function* subscribeToLoadAdditionalData(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const requesterId = action.requesterId || `subscription-history-${accountId}`;
  unsubscribeToLoadAdditionalData && unsubscribeToLoadAdditionalData();
  unsubscribeToLoadAdditionalData = ReduxUtils.subscribe(store, `firestore.docs.subscription-history-items`, (historyItems) => {
    historyItems = historyItems ? Object.values(historyItems): [] || [];
    historyItems = historyItems.filter(item => item.accountId === accountId);
    
    // Extract actionBy and memberId from history items
    const userIds = new Set();
    historyItems.forEach(item => {
      // Add actionBy if available
      if (item.actionBy) {
        userIds.add(item.actionBy);
      }
      
      // Add memberId from detail if available
      if (item.detail && item.detail.memberId) {
        userIds.add(item.detail.memberId);
      }
    });

    // Convert to array and filter out users that are already loaded
    const previousLoadedUsersSet = new Set(previousLoadedUsers || []);
    const userIdsToLoad = [...userIds].filter(id => !previousLoadedUsersSet.has(id));

    // Only dispatch if there are new users to load
    if (userIdsToLoad.length > 0) {
      // Load each user's data from Firestore
      userIdsToLoad.forEach(userId => {
        firestoreRedux.getDocById('users', userId, { requesterId });
      });
      // Update the list of previously loaded users
      previousLoadedUsers = [...previousLoadedUsersSet, ...userIdsToLoad];
    }
  });
}

function* loadLastInvoice(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  try {
    const response = yield call(requestApi, `/subscription-v4/invoices/${accountId}/last-invoice`);
    yield put(actions._loadLastInvoiceSuccess(accountId, response));
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    yield put(actions._loadLastInvoiceFailed(accountId, code));
    
    if(isNetworkError(error)) {
      return;
    }
    console.error('Failed to load last invoice due to this: ', error);
  }
}

function* loadCreditBalance(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  try {
    const response = yield call(requestApi, `/subscription-v4/${accountId}/customer-balance-history`);
    yield put(actions._loadCreditBalanceSuccess(accountId, response));
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    yield put(actions._loadCreditBalanceFailed(accountId, code));

    if(isNetworkError(error)) {
      return;
    }
    console.error('Failed to load credit balance due to this: ', error);
  }
}

function* downloadInvoice(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const invoiceId = action.invoiceId;
  const invoice = selectors.invoice(state, accountId, invoiceId);
  const url = action.url || invoice?.invoicePdfUrl || '';
  if(!url) {
    console.error('Invoice PDF URL is not available to download invoice.');
    return;
  }

  try {
    downloadUrl(url);
  } catch (error) {
    console.error('Failed to download invoice due to this: ', error);
  }
}

function* downloadReceipt(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const invoiceId = action.invoiceId;
  const invoice = selectors.invoice(state, accountId, invoiceId);
  const url = action.url || invoice?.receiptPdfUrl || '';
  if(!url) {
    console.error('Receipt PDF URL is not available to download receipt.');
    return;
  }
  
  try {
    openExternalLink({ href: url });
  } catch (error) {
    console.error('Failed to download receipt due to this: ', error);
  }
}

function* makePaymentInvoice(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const invoiceId = action.invoiceId;
  const invoice = selectors.invoice(state, accountId, invoiceId);
  try {
    openExternalLink({ href: invoice.hostedInvoiceUrl });
  } catch (error) {
    console.error('Failed to make payment invoice due to this: ', error);
  }
}

function* updateBillingAddress(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const address = action.address;
  try {
    yield call(requestApi, `/subscription-v4/billings/${accountId}`, { method: 'PUT', body: address });
    yield put(actions._updateBillingAddressSuccess(accountId, address));
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    yield put(actions._updateBillingAddressFailed(accountId, code));
    if(isNetworkError(error)) {
      return;
    }
    console.error('Failed to update billing address due to this: ', error);
  }
}

function* nonProfitRequest(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  try {
    yield call(requestApi, `/subscription-v4/non-profits/${accountId}/request`, { method: 'POST' });
    yield put(actions._nonProfitRequestSuccess(accountId));
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    yield put(actions._nonProfitRequestFailed(accountId, code));
    if(isNetworkError(error)) {
      return;
    }
    console.error('Failed to submit non-profit request due to this: ', error);
  }
}

/**
 * Watch router change.
 * Dispatch PAGE_OPENED, PAGE_CLOSED or TAB_CHANGED actions based on previous & current URL.
 */
function* watchRouter() {
  //If page is already opened, check once.
  yield call(routeChangeHandler);
  yield takeEvery(router.actions.UPDATE_ROUTER, routeChangeHandler);
}

function* onSwitchToFreePlanDialogOpened(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  yield put(actions.load(accountId));
  yield put(actions.loadPricing(accountId));
  const subscription = selectors.subscription(state, accountId);
  if(subscription?.status !== 'TRIALING' || subscription?.status !== 'CANCELED') {
    yield put(actions.computeCharges({accountId, chargesType: 'CANCEL_SUBSCRIPTION'}));
  }
}

function* onPreviewUpcomingInvoiceDialogOpened(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const upcomingInvoice = selectors.upcomingInvoice(state, accountId);
  if(isEmpty(upcomingInvoice)) {
    yield put(actions.loadInvoices(accountId));
  }
}

function* onChangePlanDialogOpened(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  yield put(actions.checkPublicDomain(accountId));
  yield put(actions.load(accountId));
  yield put(actions.loadPricing(accountId));
  yield put(actions.loadNonProfitRequest(accountId));
}

/**
 * Checks whether current user's domain is puclic or not.
 */
function* checkPublicDomain() {
  const state = yield select();
  const publicDomainStatus = selectors.publicDomainStatus(state);

  //once public domain checking alredy done, no need to check again.
  if(publicDomainStatus === 'IN_PROGRESS' || publicDomainStatus === 'SUCCESS') {
    return;
  }

  try {
    const currentUserDomains = auth.selectors.currentUserDomains(state);
    const response = yield call(requestApi, `/subscription-v4/public-domains/is-public?domains=${currentUserDomains}`);
    yield put(actions._checkPublicDomainSuccess(response.publicDomain));
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    yield put(actions._checkPublicDomainFailed(code));
    if(isNetworkError(error)) {
      return;
    }
    console.error('check for public domain is failed due to this: ', error);
  }
}

function* requestInvoice(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const priceId = action.priceId;
  const additionalSeats = action.additionalSeats || 0;
  const autoRenew = action.autoRenew || false;
  try {
    const response = yield call(requestApi, `/subscription-v4/subscriptions/${accountId}/start-offline-subscription`, { method: 'POST', body: { priceId, additionalSeats, autoRenew } });
    yield put(actions._requestInvoiceSuccess({accountId, data: response}));
  } catch (error) {
    const code = error?.code || 'UNKNOWN';
    yield put(actions._requestInvoiceFailed({accountId, error: code}));
    if(isNetworkError(error)) {
      return;
    }
    console.error('Failed to request invoice due to this: ', error);
  }
}

/**
 * Refresh billing history after successful actions.
 */
function* refreshBillingHistory(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  yield put(actions.loadInvoices(accountId));
  yield put(actions.loadCreditBalance(accountId));
}

function* onSubscriptionRenewPage(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  yield put(actions.load(accountId));
  yield put(actions.loadPricing(accountId));
}

function* onSubscriptionAutoRenewPage(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  yield put(actions.load(accountId));
}

let previousPage;
let previousAccountId;
let previousDialog;
function* routeChangeHandler() {
  const state = yield select();
  const page = router.selectors.page(state);
  const currentPage = router.selectors.pageName(state);
  const accountId = router.selectors.accountId(state) || get(page, 'params.accountId') || get(page, 'params.accountid') || get(page, 'params.account-id') || '';
  const dialog = router.selectors.dialogName(state);
  if(accountId && dialog === 'SWITCH_TO_FREE_PLAN' && previousDialog !== 'SWITCH_TO_FREE_PLAN') {
    yield put({ type: actions.SWITCH_TO_FREE_PLAN_DIALOG_OPENED, accountId });
  }

  if(previousDialog === 'SWITCH_TO_FREE_PLAN' && dialog !== 'SWITCH_TO_FREE_PLAN') {
    yield put({ type: actions.SWITCH_TO_FREE_PLAN_DIALOG_CLOSED, accountId });
  }

  if(accountId && dialog === 'PREVIEW_UPCOMING_INVOICE' && previousDialog !== 'PREVIEW_UPCOMING_INVOICE') {
    yield put({ type: actions.PREVIEW_UPCOMING_INVOICE_DIALOG_OPENED, accountId });
  }

  if(previousDialog === 'PREVIEW_UPCOMING_INVOICE' && dialog !== 'PREVIEW_UPCOMING_INVOICE') {
    yield put({ type: actions.PREVIEW_UPCOMING_INVOICE_DIALOG_CLOSED, accountId });
  }

  if(accountId && dialog === 'CHANGE_PLAN' && previousDialog !== 'CHANGE_PLAN') {
    yield put({ type: actions.CHANGE_PLAN_DIALOG_OPENED, accountId });
  }

  if(previousDialog === 'CHANGE_PLAN' && dialog !== 'CHANGE_PLAN') {
    yield put({ type: actions.CHANGE_PLAN_DIALOG_CLOSED, accountId });
  }

  if(accountId && currentPage === 'SUBSCRIPTION_RENEW' && previousPage !== 'SUBSCRIPTION_RENEW') {
    yield put({ type: actions.SUBSCRIPTION_RENEW_PAGE_OPENED, accountId });
  }

  if(previousPage === 'SUBSCRIPTION_RENEW' && currentPage !== 'SUBSCRIPTION_RENEW') {
    yield put({ type: actions.SUBSCRIPTION_RENEW_PAGE_CLOSED, accountId });
  }

  if(accountId && currentPage === 'SUBSCRIPTION_AUTO_RENEW' && previousPage !== 'SUBSCRIPTION_AUTO_RENEW') {
    yield put({ type: actions.SUBSCRIPTION_AUTO_RENEW_PAGE_OPENED, accountId });
  }

  if(previousPage === 'SUBSCRIPTION_AUTO_RENEW' && currentPage !== 'SUBSCRIPTION_AUTO_RENEW') {
    yield put({ type: actions.SUBSCRIPTION_AUTO_RENEW_PAGE_CLOSED, accountId });
  }

  previousPage = currentPage;
  previousDialog = dialog;
  previousAccountId = accountId;
}

/**
 * Init Saga.
 */
function* saga() {
  yield all([
    spawn(watchRouter),
    takeEvery(actions.LOAD, load),
    takeEvery(actions.DISCONNECT, disconnect),
    takeEvery(actions.CANCEL_SUBSCRIPTION, cancelSubscription),
    takeEvery(actions.LOAD_BILLING_DETAILS, loadBillingDetails),
    takeEvery(actions.LOAD_PRICING_DETAILS, loadPricing),
    takeEvery(actions.COMPUTE_CHARGES, computeCharges),
    takeEvery(actions.CHANGE_PLAN, changePlan),
    takeEvery(actions.CHANGE_PLAN_SUCCESS, onChangePlanSuccess),
    takeEvery(actions.CHANGE_AUTO_RENEW, changeAutoRenew),
    takeEvery(actions.CHANGE_AUTO_RENEW_FAILED, changeAutoRenewFailed),
    takeEvery(actions.SETUP_PAYMENT_METHOD, setupPaymentMethod),
    takeEvery(actions.SUBSCRIPTION_AUTO_RENEW_PAGE_OPENED, subscriptionAutoRenewPageOpened),
    takeEvery(actions.OPEN_LAST_INVOICE_PAYMENT_LINK, openLastInvoicePaymentLink),
    takeEvery(actions.LOAD_INVOICES, loadInvoices),
    takeEvery(actions.LOAD_CREDIT_BALANCE, loadCreditBalance),
    takeEvery(actions.DOWNLOAD_INVOICE, downloadInvoice),
    takeEvery(actions.DOWNLOAD_RECEIPT, downloadReceipt),
    takeEvery(actions.SWITCH_TO_FREE_PLAN_DIALOG_OPENED, onSwitchToFreePlanDialogOpened),
    takeEvery(actions.CHANGE_PLAN_DIALOG_OPENED, onChangePlanDialogOpened),
    takeEvery(actions.SUBSCRIPTION_RENEW_PAGE_OPENED, onSubscriptionRenewPage),
    takeEvery(actions.SUBSCRIPTION_AUTO_RENEW_PAGE_OPENED, onSubscriptionAutoRenewPage),
    takeEvery(actions.LOAD_LAST_INVOICE, loadLastInvoice),
    takeEvery(actions.ENABLE_AUTO_RENEW, enableAutoRenew),
    takeEvery(actions.ENABLE_AUTO_RENEW_FAILED, changeAutoRenewFailed),
    takeEvery(actions.DISABLE_AUTO_RENEW, disableAutoRenew),
    takeEvery(actions.LOAD_SUBSCRIPTION_HISTORY, loadSubscriptionHistory),
    takeEvery(actions.LOAD_SUBSCRIPTION_HISTORY_SUCCESS, subscribeToLoadAdditionalData),
    takeEvery(actions.MAKE_PAYMENT_INVOICE, makePaymentInvoice),
    takeEvery(actions.UPDATE_BILLING_ADDRESS, updateBillingAddress),
    takeEvery(actions.UPDATE_BILLING_ADDRESS_SUCCESS, loadBillingDetails),
    takeEvery(actions.NON_PROFIT_REQUEST, nonProfitRequest),
    takeEvery(actions.LOAD_NON_PROFIT_REQUEST, loadNonProfitRequest),
    takeEvery(actions.CHECK_PUBLIC_DOMAIN, checkPublicDomain),
    takeEvery(actions.REQUEST_INVOICE, requestInvoice),
    takeEvery([actions.REQUEST_INVOICE_SUCCESS, actions.CHANGE_PLAN_SUCCESS, actions.CANCEL_SUBSCRIPTION_SUCCESS], refreshBillingHistory),
    takeEvery(actions.PREVIEW_UPCOMING_INVOICE_DIALOG_OPENED, onPreviewUpcomingInvoiceDialogOpened)
  ]);
}

export default saga;
import { takeEvery, all, put, call, select } from 'redux-saga/effects';
import { requestApi, isNetworkError } from '../../request-api.js';
import { trelloOauth2Login } from './trello-oauth2-login.js';
import moment from 'moment/src/moment';
import i18next from '@dw/i18next-esm';
import { getUserName } from '../../components/utils.js';
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import { store } from '../../store.js';
import { show as showSnackBar } from '../../components/kerika-snackbar.js';

import isEmpty from 'lodash-es/isEmpty';
import get from 'lodash-es/get';

import URI from '@dw/urijs-esm';

// Redux slice
import * as app from '../app';
import * as auth from '../auth';
import * as router from '../router';
import * as signup from '../signup';
import * as actions from './actions.js';
import * as selectors from './selectors.js';
import * as amplitude from '../../analytics/amplitude.js';
import firestoreRedux from '@dreamworld/firestore-redux';

function* authenticate(action) {
  const state = yield select();
  const config = app.selectors.config(state);
  const trelloApiKey = config && config.trelloApiKey;
  const trelloAuthorizationExpiration = config.trelloAuthorizationExpiration;
  const accountId = action.accountId || undefined;
  try {
    const authDetails = yield call(trelloOauth2Login, trelloApiKey, i18next.t('appName'), trelloAuthorizationExpiration);
    if (authDetails && authDetails.token) {
      yield call(router.actions.setQueryParams, {action: 'import-board', source: action.source, accountId: accountId ? accountId: null }, action.source == 'create-board');
      amplitude.logEvent('trello import boards auth completed', accountId ? {'account_id': accountId}: {});
      yield put(actions._authenticateSuccess(authDetails.token));
    } else {
      amplitude.logEvent('trello import boards auth failed', accountId ? {'account_id': accountId, reason: authDetails && authDetails.code || 'UNKNOWN'}: { reason: authDetails && authDetails.code || 'UNKNOWN' });
      yield put(actions._authenticateFailed('UNKNOWN'));
    }
  } catch (error) {
    let code = error && error.code || 'UNKNOWN';
    code = code === 'Token request rejected' ? 'CANCELED' : code;
    yield put(actions._authenticateFailed(code));
    if (code !== 'CANCELED') {
      amplitude.logEvent('trello import boards auth failed', accountId ? {'account_id': accountId, reason: error && error.code || 'UNKNOWN' }: { reason: error && error.code || 'UNKNOWN' });
      console.error("Trello authenticate failed due to this error", error);
    } else {
      amplitude.logEvent('trello import boards auth cancelled', accountId ? {'account_id': accountId}: {});
    }
  }
}

function* authenticateSuccess(action) {
  const state = yield select();
  const config = app.selectors.config(state);
  const userId = auth.selectors.currentUserId(state);
  const token = action.token;
  try {
    yield put(actions.resetImportBoardData());
    if(!userId) {
      const url = `${config.auth.loginApiUrl.replace('{service}', 'trello').replace('{authCode}', token)}`;
      const response = yield call(requestApi, url, {excludeErrors: [409]});
      yield call(setAmplitudeSignupData, response);
      yield call(auth.actions.init);
    }
    yield call(updateToken, token);
  } catch (error) {
    const code = error && error.code;
    if(code === 'ALREADY_LOGGED_IN') {
      try {
        yield call(updateToken, token);
      } catch (error) {
        console.error('Trello authenticate failed due to this error', error);
      }
    } else {
      console.error('Trello authenticate failed due to this error', error);
    }
  }
}

/**
 * When signup, stores amplitude event data into localStorage.
 * @param {Object} data Api trello login response.
 */
function* setAmplitudeSignupData(data) {
  const signup = !data || data.signup === 'false' ? false : !!data.signup;
  if (signup) {
    const invited = data.invited === 'false' ? false : !!data.invited;

    localStorage.setItem('amplitude-signup', true);
    localStorage.setItem('amplitude-invited', invited);
    localStorage.setItem('is-invited-user', invited);
  }
}

function* updateToken(token) {
  try {
    yield call(requestApi, `/import-board/auth-token`, { method: 'PUT', body: {
      token,
      service: "TRELLO",
      createdAt: window.parseInt(moment().format('x')),
      expiresAt: window.parseInt(moment().add(1, 'hours').format('x'))
    }});
    yield put(actions.loadDetails());
  } catch (error) {
    console.error('Trello authenticate failed due to this error ', error);
  }
}

function* loadBoardslist() {
  try {
    const data = yield call(requestApi, `/import-board/board-list/TRELLO`);
    yield put(actions._loadDetailsDone(data));
  } catch (error) {
    console.error('Gets Trello board-list failed due to this error', error);
  }
}

function* listenLoadTrelloBoardMessage() {
  const state = yield select();
  let embedImportTrello = router.selectors.embedTrelloImport(state);
  if(!embedImportTrello) {
    const params = new URI().query(true);
    embedImportTrello = params['embed-trello-import'] === 'true' || false;
    if(!embedImportTrello) {
      return;
    }
  }

  window.addEventListener('message', (e)=> {
    const type = e && e.data && e.data.type;
    const token = e && e.data && e.data.token;
    if(type === 'LOAD_IMPORT_TRELLO_BOARD_DATA' && token) {
      store.dispatch(actions._authenticateSuccess(token));
    }
  });
}

function* loadImportTrelloData(userId) {
  const state = yield select();
  userId = userId || auth.selectors.currentUserId(state);

  if(userId) {
    firestoreRedux.query('import-boards-request', { where: [['actionBy', '==', userId]], requesterId: `import-trello-boards` });
  } else {
    const unsubscribe = ReduxUtils.subscribe(store, `auth.user`, (user)=> {
      userId = user && user.id;
      if(userId) {
        unsubscribe && unsubscribe();
        firestoreRedux.query('import-boards-request', { where: [['actionBy', '==', userId]], requesterId: `import-trello-boards` });
      }
    });
  }
}

function* uploadFile(action) {
  const state = yield select();
  const file = action.file;
  const fileSizeInMB = file.size / 1024 / 1024;
  const appConfig = app.selectors.config(state);
  const uploadFileMaxSizeInMb = get(appConfig, 'importFileSize');
  if (uploadFileMaxSizeInMb && fileSizeInMB > uploadFileMaxSizeInMb) {
    showSnackBar({ message: i18next.t('signup:importTrelloInfoDialog.mode.uploadFile.errorMessage.fileSize', { size: uploadFileMaxSizeInMb }), type: 'ERROR'});
    amplitude.logEvent('import spreadsheet pick-file failed', { reason: 'FILE_IS_TOO_LONG' });
    yield put(actions._uploadFileFailed('FILE_IS_TOO_LONG'));
    return;
  }

  try {
    const formdata = new FormData();
    formdata.append('file', file);
    formdata.append('fileContentLength', file.size);
    yield call(requestApi, `/import-board/upload/file`, { method: 'POST', body: formdata, excludeErrors: [400] });
    amplitude.logEvent('import spreadsheet pick-file success');
    yield put(actions._uploadFileSuccess());
  } catch (error) {
    const code = error && error.code || 'UNKNOWN';
    yield put(actions._uploadFileFailed(code));
    amplitude.logEvent('import spreadsheet pick-file failed', { reason: code });
    if(isNetworkError(error)) {
      return;
    }
    
    if(code === 'FILE_IS_TOO_LONG') {
      showSnackBar({ message: i18next.t('signup:importTrelloInfoDialog.mode.uploadFile.errorMessage.fileSize', { size: uploadFileMaxSizeInMb }), type: 'ERROR'});
      return;
    }

    if(code === 'NOT_A_VALID_FORMATE') {
      showSnackBar({ message: i18next.t('signup:importTrelloInfoDialog.mode.uploadFile.errorMessage.validFormate'), type: 'ERROR'});
      return;
    }

    console.error('import board > upload file failed due to this error: ', error);
  }
}

function* importBoards(action) {
  const state = yield select();
  const currentUser = auth.selectors.currentUser(state);
  const userId = auth.selectors.currentUserId(state);
  const uninvitedMemberCount = action.uninvitedMemberCount || 0;
  let ownedAccounts = auth.selectors.currentUserOwnedAccounts(state);
  let currentUserAccountId = ownedAccounts && ownedAccounts[0];
  let accountId = action.accountId || currentUserAccountId;
  try {

    //Create user account if account is not exist.
    if(!accountId && !currentUserAccountId) {
      const accountName = currentUser ? `${getUserName(currentUser)}'s Account`: '';
      yield put(signup.actions.createAccount(accountName));
      ownedAccounts = yield call(waitTillOwnedAccountAvailable);
      accountId = ownedAccounts && ownedAccounts[0];
    }

    yield call(requestApi, `/import-board/TRELLO`, { method: 'POST', body: {
      accountId,
      boardIds: action.boards,
      uninvitedMemberCount
    }});

    yield call(loadImportTrelloData, userId);
    const importBoardDetails = yield call(waitTillImportBoardProccessComplete, accountId);
    const importBoardStatus = importBoardDetails && importBoardDetails.status;
    if (importBoardStatus === 'SUCCESS') {
      yield put(actions._importBoardsSuccess(accountId));
    } else if(importBoardStatus === 'FAILED') {
      yield put(actions._importBoardsFailed('UNKNOWN'));
      console.error('Trello import boards failed due to unknown error');
    }
  } catch (error) {
    const code = error && error.code || 'UNKNOWN';
    yield put(actions._importBoardsFailed(code));
    console.error('Trello import boards failed due to this error', error);
  }
}

/**
 * Wait till owned accounts data available.
 */
function waitTillOwnedAccountAvailable() {
  let resolve, reject;
  const promise = new Promise((res, rej) => { resolve = res, reject = rej; });
  const unsubscribe = ReduxUtils.subscribe(store, `auth.ownedAccounts`, (ownedAccounts)=> {
    if(!isEmpty(ownedAccounts)) {
      unsubscribe && unsubscribe();
      resolve(ownedAccounts);
    }
  });
  return promise;
}

function waitTillImportBoardProccessComplete(accountId) {
  let resolve, reject;
  const state = store.getState();
  const ownedAccounts = auth.selectors.currentUserOwnedAccounts(state);
  accountId = accountId || ownedAccounts && ownedAccounts[0];
  const promise = new Promise((res, rej) => { resolve = res, reject = rej; });
  const unsubscribe = ReduxUtils.subscribe(store, `firesore.docs.import-boards-request`, (importBoardDetails)=> {
    const status = selectors.importBoardsStatus(store.getState());
    if(status === 'SUCCESS' || status === 'FAILED') {
      unsubscribe && unsubscribe();
      resolve({status});
    }
  });
  return promise;
}

/**
 * Init Saga.
 */
function* importBoardSaga() {
  yield all([
    call(loadImportTrelloData),
    call(listenLoadTrelloBoardMessage),
    takeEvery(actions.AUTHENTICATE, authenticate),
    takeEvery(actions.AUTHENTICATE_SUCCESS, authenticateSuccess),
    takeEvery(actions.LOAD_DETAILS, loadBoardslist),
    takeEvery(actions.IMPORT_BOARDS, importBoards),
    takeEvery(actions.UPLOAD_FILE, uploadFile),
  ]);
}

export default importBoardSaga;

import get from "lodash-es/get";
import merge from "lodash-es/merge";
import isEqual from "lodash-es/isEqual";
import device from "current-device";
import i18next from '@dw/i18next-esm';
import camelCase from 'lodash-es/camelCase';
import { createSelector } from 'reselect';
import * as app from '../app';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as multipleLanguage from '../multiple-language';
import * as user from '../user';
import forEach from "lodash-es/forEach";
import orderBy from 'lodash-es/orderBy';
import filter from 'lodash-es/filter';
import uniq from 'lodash-es/uniq';
import isEmpty from 'lodash-es/isEmpty';
import sortBy from 'lodash-es/sortBy';
import find from "lodash-es/find";
import keyBy from "lodash-es/keyBy";

/**
 * @param { Object } state - Redux state detail object
 * @return { Boolean } - return `state.app.wideLayout` value
 */
export const wideLayout = (state) => get(state, "app.wideLayout");

/**
 * @param { Object } state Redux state detail object.
 *  @returns { Boolean } `true` when current device is desktop.
 */
export const isDesktopDevice = (state) => {
  return device.desktop();
};

/**
 * @param { Object } state Redux state detail object.
 *  @returns { Boolean } `true` when current device is mobile.
 */
export const isMobileDevice = (state) => {
  return device.mobile() && !isTabletDevice();
};

/**
 * @param { Object } state Redux state detail object.
 *  @returns { Boolean } `true` when current device is tablet.
 */
export const isTabletDevice = (state) => {
  return device.tablet();
};

/**
 * @param { Object } state Redux state detail object.
 * @returns { Boolean } `true` when current orientation mode is `landscape`.
 */
export const isLandscape = (state) => {
  return get(state, 'app.orientation') === 'landscape';
};

/**
 * @param { Object } state Redux state detail object.
 * @returns { Boolean } `true` when current orientation mode is `portrait`.
 */
export const isPortrait = (state) => {
  return get(state, 'app.orientation') === 'portrait';
}

/**
 * @param { Object } state Redux state detail object.
 *  @returns { Boolean } `true` when current device is iOS.
 */
export const isIOSDevice = (state) => {
  return device.ios();
};

/**
 * @param {Object} state Redux state
 * @returns `true` if touch device.
 */
export const isTouchDevice = (state) => {
  return get(state, `app.touchDevice`);
};

/**
 * @param {Object} state Redux state
 * @returns {Object} application firebase configration.
 */
export const firebaseConfig = (state) => firestoreRedux.selectors.doc(state, 'app', 'config') || {};

/**
 * @param {Object} state Redux state
 * @returns {Object} application configration.
 */
export const config = createSelector(
  (state) => get(state, "app.config", {}),
  firebaseConfig,
  (staticConfig, _firebaeConfig) => merge({}, staticConfig, _firebaeConfig),
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

/**
 * @param {Object} state state
 * @param {String} name video name
 * @returns {String} given video url from config.
 */
export const videoUrl = (state, name) => {
  return i18next.exists(`video:${camelCase(name)}`) ? i18next.t(`video:${camelCase(name)}`): '';
}

/**
 * @param {Object} state state
 * @returns {String} api base url.
 */
export const apiBaseUrl = (state) => {
  const appConfig = config(state);
  return appConfig && appConfig.apiBaseUrl || '';
}

/**
 * @param {Object} state state
 * @returns {String} webapp base url.
 */
export const webAppBaseUrl = (state) => {
  const appConfig = config(state);
  return appConfig && appConfig.webAppBaseUrl || '';
}

/**
 * @param {Object} state state
 * @param {String} name video name
 * @returns {Boolean} `true` when requested video is available on videos config.
 */
export const isVideoAvailable = (state, name) => {
  return !!videoUrl(state, name);
}

/**
 * @param {Object} state Redux state
 * @returns {Boolean} `true` when current app is installed app. `false` otherwise.
 */
export const isInstalledApp = (state) => {
  let installedAppUserAgent = get(state, "app.config.installedAppUserAgent");
  return (
    (installedAppUserAgent &&
      window.navigator.userAgent.includes(installedAppUserAgent)) ||
    false
  );
};


/**
 * @returns {String} The redirect endpoint configured for the application.
 */
export const redirectEndpoint = (state) => get(state, 'app.config.redirectEndpoint');

/**
 * @returns {Boolean} Installed app device is ready or not.
 */
export const installedAppDeviceReady = (state) => get(state, 'app.deviceReady') || false;

/**
 * @returns {Boolean} `true` when iOS device.
 * @param {Object} state Redux state
 */
export const isiOS = (state) => {
  return device.ios();
};

/**
 * @returns {Boolean} `true` when safari browser.
 * @param {Object} state Redux state
 */
export const isSafari = (state) => {
  const bowser = get(state, `app.bowser`);
  let browser = get(bowser, 'browser.name', '') || '';
  browser = browser.toLowerCase();
  return browser && browser.includes && browser.includes('safari') || false;
}

/**
 * @returns {Boolean} `true` when application is connected to server, `false` otherwise.
 * @param {Object} state Redux status.
 */
export const isConnectedToServer = (state) => get(state, `app.online`);

/**
 * @returns {Boolean} `true` when app is connected to server.
 */
export const isOnline = createSelector(
  isConnectedToServer,
  (connectedToServer) => connectedToServer
)

/**
 * @param {Objects} state Redux state
 * @returns {Timestamp} Last API network failure time.
 */
export const lastConnectionFailTime = (state) => get(state, `app.lastConnectionFailTime`);

/**
 * @param {Object} state Redux status.
 * @returns {String} website base URL.
 */
export const websiteBaseUrl = (state) =>
  get(state, "app.config.websiteBaseUrl");

/**
 * @param {Object} state Redux status.
 * @returns {Number} template acccount Id.
 */
export const templateAcId = createSelector(
  (state) => firestoreRedux.selectors.doc(state, 'board-settings', 'bs_0'),
  (boardSettings) => {
    return get(boardSettings, `accountId`);
  },
  {
    memoizeOptions: {
      maxSize: 10,
    },
  }
);

/**
 * @returns {Object} transalted board for given language.
 */
const languageTranslatedBoard = createSelector(
  (state, boardId, lang) => lang,
  (state, boardId, lang) => get(state, `board-translations.translatedBoards.${boardId}`),
  (lang, boards) => {
    const filterBoards = filter(boards, item => item && item.lang == lang);
    const board = filterBoards && filterBoards[0] || {}
    return board || {};
  }
);

const _defaultTemplateWhiteBoardId = createSelector(
  (state) => firestoreRedux.selectors.doc(state, 'board-settings', 'bs_0'),
  (boardSettings) => {
    const _oldDefault = get(boardSettings, `defaultTemplates.WHITEBOARD`);
    const _default = get(boardSettings, `defaultTemplates.en.WHITEBOARD`);
    return _default || _oldDefault || '';
  }
);

const _defaultLangTemplateWhiteBoardId = createSelector(
  (state) => firestoreRedux.selectors.doc(state, 'board-settings', 'bs_0'),
  (state) => multipleLanguage.selectors.currentLanguage(state),
  (boardSettings, currentLanguage) => {
    return get(boardSettings, `defaultTemplates.${currentLanguage}.WHITEBOARD`) || '';
  }
);

const _defaultTranslatedLangTemplateWhiteBoardId = createSelector(
  (state) => languageTranslatedBoard(state, _defaultTemplateWhiteBoardId(state), multipleLanguage.selectors.currentLanguage(state)),
  (board) => {
    return board && board.id || '';
  }
);

/**
 * @param {Object} state Redux state
 * @returns {Number} Default whiteboard template id based current user current lang.
 */
export const defaultTemplateWhiteBoardId = createSelector(
  (state) => _defaultTemplateWhiteBoardId(state),
  (state) => _defaultLangTemplateWhiteBoardId(state),
  (state) => _defaultTranslatedLangTemplateWhiteBoardId(state),
  (_default, _langTemplate, _translatedlangTemplate) => {
    return _translatedlangTemplate || _langTemplate || _default;
  }
);

const _defaultTemplateTaskBoardId = createSelector(
  (state) => firestoreRedux.selectors.doc(state, 'board-settings', 'bs_0'),
  (boardSettings) => {
    const _oldDefault = get(boardSettings, `defaultTemplates.TASKBOARD`);
    const _default = get(boardSettings, `defaultTemplates.en.TASKBOARD`);
    return _default || _oldDefault;
  }
);

const _defaultLangTemplateTaskBoardId = createSelector(
  (state) => firestoreRedux.selectors.doc(state, 'board-settings', 'bs_0'),
  (state) => multipleLanguage.selectors.currentLanguage(state),
  (boardSettings, currentLanguage) => {
    return get(boardSettings, `defaultTemplates.${currentLanguage}.TASKBOARD`);
  }
);

const _defaultTranslatedLangTemplateTaskBoardId = createSelector(
  (state) => languageTranslatedBoard(state, _defaultTemplateTaskBoardId(state), multipleLanguage.selectors.currentLanguage(state)),
  (board) => {
    return board && board.id || '';
  }
);

/**
 * @param {Object} state Redux state
 * @returns {Number} Default taskboard template id based current user current lang.
 */
export const defaultTemplateTaskBoardId = createSelector(
  (state) => _defaultTemplateTaskBoardId(state),
  (state) => _defaultLangTemplateTaskBoardId(state),
  (state) => _defaultTranslatedLangTemplateTaskBoardId(state),
  (_default, _langTemplate, _translatedlangTemplate) => {
    return _translatedlangTemplate || _langTemplate || _default;
  }
);

/**
 * @param {Object} state Redux state
 * @returns Current version of pwa.
 */
export const currentVersion = (state) => get(state, `app.version.current`);

/**
 * @param {Object} state Redux state
 * @returns {String} Status of version. Possible values: `LATEST`, `NEW_VERSION_AVAILABLE` or `UPDATE_IN_PROGRESS`.
 */
export const versionUpdateStatus = state => get(state, 'app.version.updateStatus');

/**
 * @param {Object} state Redux state.
 * @returns {Boolean} `true` when app is under maintenance.3
 */
export const isUnderMaintenance = state => {
  const maintenanceMode = firestoreRedux.selectors.doc(state, 'app', 'maintenance-mode');
  return get(maintenanceMode, 'active');
};

/**
 * @param {Object} state Redux state
 * @returns minimum version of installed app based on current device.
 */
export const installedAppMinVersion = (state) => get(state, `app.installedApp.${isiOS(state) ? 'ios': 'android'}.minVersion`);

/**
 * Application template category.
 * The categories are listed in the Sorted Order in which UI should represent at all the places. e.g. Dropdown, Grouping on the Explorer Page etc.
 */
let _previousTemplateCategory = [];
export const templateCategory = () => {
  const newTemplateCategory = [
    {
      name: 'Design',
      title: i18next.exists('templateCategory.Design') ? i18next.t('templateCategory.Design') || 'Design': 'Design'
    },
    {
      name: 'HR & Compliance',
      title: i18next.exists('templateCategory.HR & Compliance') ? i18next.t('templateCategory.HR & Compliance') || 'HR & Compliance': 'HR & Compliance'
    },
    {
      name: 'IT & Software',
      title: i18next.exists('templateCategory.IT & Software') ? i18next.t('templateCategory.IT & Software') || 'IT & Software': 'IT & Software'
    },
    {
      name: 'Marketing',
      title: i18next.exists('templateCategory.Marketing') ? i18next.t('templateCategory.Marketing') || 'Marketing': 'Marketing'
    },
    {
      name: 'Nonprofit',
      title: i18next.exists('templateCategory.Nonprofit') ? i18next.t('templateCategory.Nonprofit') || 'Nonprofit': 'Nonprofit'
    },
    {
      name: 'Operations & Management',
      title: i18next.exists('templateCategory.Operations & Management') ? i18next.t('templateCategory.Operations & Management') || 'Operations & Management': 'Operations & Management'
    },
    {
      name: 'Sales',
      title: i18next.exists('templateCategory.Sales') ? i18next.t('templateCategory.Sales') || 'Sales': ''
    },
    {
      name: 'Student or Teacher',
      title: i18next.exists('templateCategory.Student or Teacher') ? i18next.t('templateCategory.Student or Teacher') || 'Student or Teacher': 'Student or Teacher'
    },
    {
      name: 'Something Else',
      title: i18next.exists('templateCategory.Something Else') ? i18next.t('templateCategory.Something Else') || 'Something Else': 'Something Else'
    }
  ];

  if(isEqual(_previousTemplateCategory, newTemplateCategory)) {
    return _previousTemplateCategory;
  }

  _previousTemplateCategory = newTemplateCategory;
  return newTemplateCategory;
}


/**
 * Language wise font.
 * @returns {Object} <{langCode}: {fontFamilyLink, fontFamily}>
 */
let _previousLangFont = [];
export const langFont = () => {
  const newLangFont = {
    default: {
      link: "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600&display=swap",
      fontFamily: "'Noto Sans', 'Noto Serif', Arial, Verdana, helvetica, sans-serif;"
    },
    en: {
      link: "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600&display=swap",
      fontFamily: "'Roboto', 'Noto Sans', 'Noto Serif', Arial, Verdana, helvetica, sans-serif;"
    },
    bn: {
      link: "https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali:wght@300;400;500;600&display=swap",
      fontFamily: "'Noto Sans Bengali', 'Noto Sans', 'Noto Serif', Arial, Verdana, helvetica, sans-serif;"
    },
    ko: {
      link: "https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;600&display=swap",
      fontFamily: "'Noto Sans KR', 'Noto Sans', 'Noto Serif', Arial, Verdana, helvetica, sans-serif;"
    },
    zh: {
      link: "https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500;600&display=swap",
      fontFamily: "'Noto Sans Bengali', 'Noto Sans', 'Noto Serif', Arial, Verdana, helvetica, sans-serif;"
    },
    tl: {
      link: "https://fonts.googleapis.com/css2?family=Noto+Sans+Tagalog:wght@300;400;500;600&display=swap",
      fontFamily: "'Noto Sans Bengali', 'Noto Sans', 'Noto Serif', Arial, Verdana, helvetica, sans-serif;"
    },
    th: {
      link: "https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@300;400;500;600&display=swap",
      fontFamily: "'Noto Sans Bengali', 'Noto Sans', 'Noto Serif', Arial, Verdana, helvetica, sans-serif;"
    }
  };

  if(isEqual(_previousLangFont, newLangFont)) {
    return _previousLangFont;
  }

  _previousLangFont = newLangFont;
  return newLangFont;
}

/**
 * oauth2 login using native flow or not.
 */
export const oauth2LoginUsingNativeFlow = (state, service) => {
  if(!isInstalledApp(state)) {
    return false;
  }

  if(service === 'kerika' || service === 'KERIKA') {
    return false;
  }

  if(service === 'google' || service === 'google_market_place' || service === 'GOOGLE' || service === 'GOOGLE_MARKET_PLACE') {
    return true;
  }

  return isIOSDevice(state);
}

/**
 * Application job role based on user language.
 */
export const jobRole = createSelector(
  (state) => firestoreRedux.selectors.doc(state, 'user-property-definitions', 'job-role'),
  (state) => multipleLanguage.selectors.currentLanguage(state),
  (state) => multipleLanguage.selectors.systemDefaultLanguage(state),
  (doc, currentLanguage, defaultLanguage) => {
    const title= get(doc, `name.${currentLanguage}`, get(doc, `name.${defaultLanguage}`, '')) || '';
    const type = get(doc, 'type', '') || '';;
    const options = get(doc, 'options', []) || [];
    const newOptions = [];
    forEach(options, (option) => {
      const name = get(option, `label.${currentLanguage}`, get(option, `label.${defaultLanguage}`, '')) || '';
      const value = get(option, 'value');
      if(value) {
        newOptions.push({value, name});
      }
    });

    let _options = [];
    if(!isEmpty(newOptions)) {
      //Options sorting based on alphabet
      _options = sortBy(newOptions, (option) => option.name && option.name.toLowerCase() || '')
      //Something always shwos on last item.
      let somethingElseItem = find(_options, (option) => option && option.value === 'something-else');
      _options = filter(_options, (option) => option && option.value !== 'something-else');
      _options.push(somethingElseItem);
    }

    return { title, type, options: _options};
  },
  {
    memoizeOptions: {
      maxSize: 20,
      resultEqualityCheck: isEqual,
    },
  }
);

/**
 * Application has job role or not.
 */
export const hasJobRole = createSelector(
  (state) => jobRole(state),
  (jobRole) => {
    return jobRole && jobRole.options && jobRole.options.length > 0;
  },
  {
    memoizeOptions: {
      maxSize: 20,
      resultEqualityCheck: isEqual,
    },
  }
);

const useCaseBoardsLoaded = createSelector(
  (state) => firestoreRedux.selectors.queryStatus(state, `use-case-boards`),
  (queryStatus) => {
    return queryStatus && queryStatus === 'LIVE';
  }
);

const _useCaseBoards = createSelector(
  (state) => app.selectors.isMobileDevice(state),
  (state) =>  firestoreRedux.selectors.collection(state, 'use-case-boards'),
  (state) => firestoreRedux.selectors.collection(state, 'boards'),
  (state) => useCaseBoardsLoaded(state),
  (isMobileDevice, useCaseBoards, boards, useCaseBoardsLoaded) => {
    if (useCaseBoardsLoaded === false || (!isEmpty(useCaseBoards) && isEmpty(boards))) {
      return undefined;
    }
    
    let list = [];
    forEach(useCaseBoards, (useCaseBoard, id) => {
      const boardId = useCaseBoard && useCaseBoard.boardId;
      const attrs = get(boards, `${boardId}`);
      //Whiteboard use-case board is not shown in mobile
      if(isMobileDevice && attrs && attrs.type === 'WHITEBOARD') {
        return true;
      }

      if (isEmpty(attrs)) {
        return true;
      }
      
      const lang = useCaseBoard.language || useCaseBoard.lang || 'en';
      list.push({ id, boardId, ...useCaseBoard, name: attrs.name, lang, accountId: attrs.accountId, sortOrder: useCaseBoard.sortOrder || 0 });
    });
    
    return list;
  },
  {
    memoizeOptions: {
      maxSize: 20,
      resultEqualityCheck: isEqual,
    },
  }
);

/**
 * Available industries for use-case boards.
 */
export const availableIndustries = createSelector(
  (state) => _useCaseBoards(state),
  (state) => multipleLanguage.selectors.currentLanguage(state),
  (useCaseBoards, currentLanguage) => {
    let _langUseCaseBoards = filter(useCaseBoards, (item) => item && item.lang === currentLanguage);
    let _useCaseBoards = isEmpty(_langUseCaseBoards) ? useCaseBoards: _langUseCaseBoards;

    let list = [];
    forEach(_useCaseBoards, (useCaseBoard) => {
      const industries = useCaseBoard && !isEmpty(useCaseBoard.industries) ?useCaseBoard.industries: ['something-else'];
      forEach(industries, (industry) => {
        list.push(industry);
      });
    });

    return uniq(list);
  },
  {
    memoizeOptions: {
      maxSize: 20,
      resultEqualityCheck: isEqual,
    },
  }
);

export const categoryUseCaseBoard = createSelector(
  (state, category) => _useCaseBoards(state),
  (state, category) => category,
  (state, category) => multipleLanguage.selectors.currentLanguage(state),
  (state) => multipleLanguage.selectors.systemDefaultLanguage(state),
  (useCaseBoards, category, currentLanguage, defaultLanguage) => {
    if (useCaseBoards === undefined) {
      return;
    }
    
    let list = [];
    forEach(useCaseBoards, (useCaseBoard, id) => {
      const boardId = useCaseBoard && useCaseBoard.boardId;
      const industries = useCaseBoard && !isEmpty(useCaseBoard.industries) ? useCaseBoard.industries: ['something-else'];
      const industryBoard = category && industries.includes(category) || false;
      if(industryBoard) {
        list.push({ id, boardId, ...useCaseBoard, industries, sortOrder: useCaseBoard.useCaseBoard || 0, lowerCaseName: useCaseBoard.name ? useCaseBoard.name.toLowerCase() : '' });
      }
    });
    
    list = orderBy(list, ['sortOrder', 'lowerCaseName'], ['asc', 'asc']);
    //Remove unused filed, like: lowerCaseName.
    forEach(list, (item) => {
      delete item.lowerCaseName;
    });

    const langList = filter(list, (item) => item && item.lang === currentLanguage);
    const defaultList = filter(list, (item) => item && item.lang === defaultLanguage);
    return isEmpty(langList) ? defaultList: langList;
  },
  {
    memoizeOptions: {
      maxSize: 20,
      resultEqualityCheck: isEqual,
    },
  }
);

let _previousMaliciousUrls;
/**
 * Retrieves the list of malicious URLs from the Firestore document.
 * @param {Object} state - The Redux state.
 * @returns {Array<{url: string}>} - The list of malicious URLs.
 */
const maliciousUrls = (state) => {
  const doc = firestoreRedux.selectors.doc(state, `malicious-urls`, 'default');
  const urls = (doc && doc.urls) || [];
  if(isEqual(_previousMaliciousUrls, urls)) {
    return _previousMaliciousUrls;
  }

  _previousMaliciousUrls = urls;
  return urls;
}

let _previousMaliciousUrlsMap;
/**
 * Retrieves a map of malicious URLs from the Firestore document.
 * @param {Object} state - The Redux state.
 * @returns {Object<string, {url: string}>} - A map of malicious URLs, keyed by the URL.
 */
const maliciousUrlMap = (state) => {
  const urls = maliciousUrls(state);
  const urlMap = keyBy(urls, 'url');
  if(isEqual(_previousMaliciousUrlsMap, urlMap)) {
    return _previousMaliciousUrlsMap;
  }

  _previousMaliciousUrlsMap = urlMap;
  return urlMap;
}

/**
 * Checks if the given URL is considered malicious based on the list of malicious URLs.
 * @returns {boolean} - True if the URL is considered malicious, false otherwise.
 */
export const isMaliciousUrl = (state, url) => {
  const urlMap = maliciousUrlMap(state);
  return urlMap && urlMap[url] ? true : false;
}

/**
 * Retrieves the malicious redirect URL for the given URL, if it exists.
 * @returns {string} - The malicious redirect URL, or an empty string if the URL is not considered malicious.
 */
export const getMaliciousRedirectUrl = (state, url) => {
  const urlMap = maliciousUrlMap(state);
  return urlMap && urlMap[url] ? urlMap[url].redirectUrl : '';
}
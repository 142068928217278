import { sagaMiddleware, store } from '../../store';
import saga from './saga';

import * as _selectors from './selectors.js';
import * as _actions from './actions.js'
import * as _reducers from './reducers.js';

export const selectors = _selectors;
export const actions = _actions;

sagaMiddleware.run(saga);
store.addReducers({
  'subscription-v2': _reducers.subscription
});
import URI from '@dw/urijs-esm/index.js';
import { isSubscriptionRenewPage } from './validate-url.js';


export const subscriptionRenewPage = (data) => {
  return new Promise((resolve, reject) => {
    if(isSubscriptionRenewPage(data.path)) {
      let params = new URI().query(true);
      data.page = {
        name: 'SUBSCRIPTION_RENEW',
        params
      };
      resolve(data);
      return;
    }
    reject(data);
  });
}
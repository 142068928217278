const LOCAL_STORAGE_KEY = 'cardTitle.{boardId}';

const getLocalStoragekey = (boardId) => {
  const key = LOCAL_STORAGE_KEY.replace('{boardId}', boardId);
  return key;
}

/**
 * @param {Number} boardId
 * @param {String} value
 * Save new card text into local-storage for given board.
 */
export const save = (boardId, value) => {
  localStorage.setItem(getLocalStoragekey(boardId), value);
}

/**
 * @param {Number} boardId
 * @returns {String} new card text for given board.
 */
export const get = (boardId) => {
  return localStorage.getItem(getLocalStoragekey(boardId));
}

/**
 * @param {Number} boardId
 * Remove new card text from local-storage for given board.
 */
export const remove = (boardId) => {
  localStorage.removeItem(getLocalStoragekey(boardId));
}

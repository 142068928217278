import { put, takeEvery, select, debounce } from 'redux-saga/effects';
import { getIdWoPrefix } from '../../../utils';
import firestoreRedux from '@dreamworld/firestore-redux';
import isEqual from 'lodash-es/isEqual';
import forEach from 'lodash-es/forEach';
import * as app from '../../app';
import * as actions from './actions';
import * as selectors from './selectors';
import * as authSelectors from '../selectors';

import { requestApi, isNetworkError } from '../../../request-api.js';

/**
 * Load template account details and accessible account basic/team details from firestore.
 */
function* loadAccessibleAccountDetail() {
  let state = yield select();
  let accessibleAccounts = selectors.accessibleAccounts(state);
  const templateAcId = app.selectors.templateAcId(state);
  const userId = authSelectors.currentUserId(state);
  const allAccessibleAccounts = [...accessibleAccounts, templateAcId];
  forEach(allAccessibleAccounts, (id) => {
    if(accessibleAccounts && accessibleAccounts.includes(id)) {
      firestoreRedux.getDocById(`accounts/${id}/account-cloud-stores`, `acs_${getIdWoPrefix({ id, prefix: 'acc_' })}`);
      userId && firestoreRedux.query('account-user-preferences', { id: `account-user-preferences-${id}-${userId}`,  where: [['userId', '==', userId], ['accountId', '==', id]] });
      userId && firestoreRedux.query('user-account-ui', { where: [['userId', '==', userId], ['accountId', '==', id]]});
      firestoreRedux.getDocById(`accounts/${id}/subscriptions`, `sub_${getIdWoPrefix({ id, prefix: 'acc_' })}`);
    }
    firestoreRedux.getDocById('accounts', id);
  });
}

function* leaveAccount({accountId}) {
  const state = yield select();
  const userId = authSelectors.currentUserId(state);
  const ownedAccounts = authSelectors.currentUserOwnedAccounts(state);
  if(!accountId) {
    console.error("auth > leaveAccount: accountId is not found");
    yield put(actions._leaveAccountFailed(accountId, 'ACCOUNT_NOT_FOUNT'));
    return;
  }

  if(ownedAccounts  && ownedAccounts.includes(accountId)) {
    console.error("auth > leaveAccount: User can't leave owned account.");
    yield put(actions._leaveAccountFailed(accountId, 'USER_OWNED_ACCOUNT'));
    return;
  }
  try {
    yield requestApi(`/account/accounts/${accountId}/team/${userId}`, { method: 'DELETE' });
    yield put(actions._leaveAccountDone(accountId));
  } catch (error) {
    const code = error && error.code;
    yield put(actions._leaveAccountFailed(accountId, code));
    if(isNetworkError(error)) {
      return;
    }

    console.error(`auth > leaveAccout: failed due to this: accountId: ${accountId}, error: `, error);
  }
}

/**
 * Keeps watch on the accessible accounts of the current user, when it's changed dispatches
 *  an action `ACCESSIBLE_ACCOUNTS_CHANGED`.
 */
let lastVal = [];
function* watchAccessibleAccounts() {
  let state = yield select();
  let newVal = selectors.accessibleAccounts(state);
  if (!isEqual(lastVal, newVal)) {
    yield put({ type: actions.ACCESSIBLE_ACCOUNTS_CHANGED, accounts: newVal });
    lastVal = newVal;
  }
}

/**
 * Init Saga.
 */
function* accessibleAccountsSaga() {
  yield takeEvery(actions.ACCESSIBLE_ACCOUNTS_CHANGED, loadAccessibleAccountDetail);
  yield debounce(200, firestoreRedux.actions.QUERY_SNAPSHOT, watchAccessibleAccounts);
  yield takeEvery(actions.LEAVE_ACCOUNT, leaveAccount)
}

export default accessibleAccountsSaga;

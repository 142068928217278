import URI from '@dw/urijs-esm/index.js';
import { isSubscriptionAutoRenewPage } from './validate-url.js';


export const subscriptionAutoRenewPage = (data) => {
  return new Promise((resolve, reject) => {
    if(isSubscriptionAutoRenewPage(data.path)) {
      let params = new URI().query(true);
      data.page = {
        name: 'SUBSCRIPTION_AUTO_RENEW',
        params
      };
      resolve(data);
      return;
    }
    reject(data);
  });
}
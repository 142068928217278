import { requestApi } from '../../request-api';
import { isValidUrl } from '../../components/utils';
import * as router from '../router';

export const LOAD_BOARD_ATTACHMENTS = 'ATTACHMENTS_LOAD_BOARD_ATTACHMENTS';
export const DISCONNECT_BOARD_ATTACHMENTS = 'ATTACHMENTS_DISCONNECT_BOARD_ATTACHMENTS';
export const LOAD_CARD_ATTACHMENTS = 'ATTACHMENTS_LOAD_CARD_ATTACHMENTS';
export const DISCONNECT_CARD_ATTACHMENTS = 'ATTACHMENTS_DISCONNECT_CARD_ATTACHMENTS';

export const LOAD_FILE_INFO = 'ATTACHMENTS_LOAD_FILE_INFO';

export const CREATE_WEBLINK = 'ATTACHMENT_CREATE_WEBLINK';
export const CREATE_WEBLINK_DONE = 'ATTACHMENT_CREATE_WEBLINK_DONE';
export const CREATE_WEBLINK_FAILED = 'ATTACHMENT_CREATE_WEBLINK_FAILED';

export const CREATE_DOCUMENT = 'ATTACHMENT_CREATE_DOCUMENT';
export const CREATE_DOCUMENT_DONE = 'ATTACHMENT_CREATE_DOCUMENT_DONE';
export const CREATE_DOCUMENT_FAILED = 'ATTACHMENT_CREATE_DOCUMENT_FAILED';

export const UPLOAD_FILE = 'ATTACHMENT_UPLOAD_FILE';
export const UPLOAD_FILE_DONE = 'ATTACHMENT_UPLOAD_FILE_DONE';
export const UPLOAD_FILE_FAILED = 'ATTACHMENT_UPLOAD_FILE_FAILED';

export const UPLOAD_FILES = 'ATTACHMENT_UPLOAD_FILES';
export const UPLOAD_FILES_DONE = 'ATTACHMENT_UPLOAD_FILES_DONE';
export const UPLOAD_FILES_FAILED = 'ATTACHMENT_UPLOAD_FILES_FAILED';

export const UPLOAD_NEW_VERSION = 'ATTACHMENTS_UPLOAD_NEW_VERSION';
export const UPLOAD_NEW_VERSION_DONE = 'ATTACHMENTS_UPLOAD_NEW_VERSION_DONE';
export const UPLOAD_NEW_VERSION_FAILED = 'ATTACHMENTS_UPLOAD_NEW_VERSION_FAILED';

export const RENAME = 'ATTACHMENTS_RENAME';
export const RENAME_DONE = 'ATTACHMENTS_RENAME_DONE';
export const RENAME_FAILED = 'ATTACHMENTS_RENAME_FAILED';

export const DELETE = 'ATTACHMENTS_DELETE';

export const RESTORE = 'ATTACHMENTS_RESTORE';

export const DOWNLOAD = 'ATTACHMENTS_DOWNLOAD';

export const MOVE = 'ATTACHMENTS_MOVE';

export const REFRESH_CONTENT_DETAIL = 'ATTACHMENTS_REFRESH_CONTENT_DETAIL';

export const OPEN_ATTACHMENT = 'ATTACHMENTS_OPEN_ATTACHMENT';

/**
 * Creates new document.
 * @param {String} boardId Board Id
 * @param {String} cardId If attachment is card level then its given.
 * @param {String} title File name
 * @param {String} docType type of document. Possible values are: `DOC`, `SPREADSHEET`, `PRESENTATION`, `BOX_NOTE`, `ONE_NOTE`, `GOOGLE_FORM`.
 */
export const createDocument = ({ boardId, cardId, title, docType }) => {
  return {
    type: CREATE_DOCUMENT,
    boardId,
    cardId,
    title,
    docType
  }
}

/**
 * Uploads new file attachment.
 * @param {*}
 *  @property {*} file give a file to upload into container.
 *  @property {String} boardId Board Id
 *  @property {String} cardId If attachment is board level then `root` otherwise `cardId`
 */
export const uploadFile = ({ file, boardId, cardId }) => {
  return {
    type: UPLOAD_FILE,
    file,
    boardId,
    cardId
  };
}

/**
 * Requests to add new weblink attachment.
 * @param {String} boardId Board Id
 * @param {String} cardId If attachment is card level then it's given.
 * @param {String} url Web link
 */
export const createWebLink = ({ boardId, cardId, url }) => {
  if (!boardId || !url) {
    throw new Error('Passed parameters are wrong..');
  }

  //Remove leading and trailing space.
  url = url ? url.trim() : url;

  const regex = new RegExp("^(http|https)://", "i");
  if (!regex.test(url)) {
    url = `http://${url}`;
  }

  if (!isValidUrl(url)) {
    throw new Error('Invalid Link');
  }

  return {
    type: CREATE_WEBLINK,
    boardId,
    cardId,
    url
  }
}

/**
 * If given file size is greate then file max size in MB size then shows an error toast message and do nothing.
 * Sends request to upload a new version for given attachment.
 * When storage access is revoked for current account, then dispatch file store access revoked detected.
 * @param {String} file give a file to upload a new version for given attachment.
 * @param {String} boardId Board Id
 * @param {String} cardId CArd Id It's optional. If not given considers board attachment.
 * @param {String} id Attachment Id
 */
export const uploadNewVersion = ({ file, boardId, cardId, id }) => {
  return {
    type: UPLOAD_NEW_VERSION,
    file,
    boardId,
    cardId,
    id
  }
}

/**
 * Renames attachment title.
 * @param {String} boardId Board Id
 * @param {String} cardId Card Id. It's optional.
 * @property {String} id Attachment Id
 * @property {String} title Title of attachment
 */
export const rename = ({ id, boardId, cardId, title }) => {
  return {
    type: RENAME,
    id,
    boardId,
    cardId,
    title
  }
}

/**
 * Deletes attachment.
 * @param {*}
 *  @property {String} id Attachment Id
 *  @property {String} cardId Card Id. It's optional. When it's not provided, considers it board attachment.
 */
export const del = ({ id, cardId, boardId }) => {
  return {
    type: DELETE,
    id,
    cardId,
    boardId
  }
}

/**
 * Restores CANVAS.
 * @param {Number} containerId cardId / boardId
 * @param {Number} attachmentId AttachmentId
 */
export const restore = ({id, cardId}) => {
  return {
    type: RESTORE,
    id,
    cardId
  }
}

/**
 * When not installed app,
 *  - downloads attachment.
 * Otherwise
 *  - get signed link from server & opens it in new tab.
 * @param {String} cardId Card Id. It's optional. If not provided, it considers board attachment.
 * @param {String} id Attachment Id
 */
export const download = ({ cardId, id, title, contentLength }) => {
  return {
    type: DOWNLOAD,
    cardId,
    id,
    title,
    contentLength
  }
}

/**
 * UPdates order of the attachment.
 * @param {Object} oParams
 *  - @property {String} boardId Give the id of the board in which you want to move attachments.
 *  - @property {String} cardId Card Id. It is optional. When it's not provided, it considers board attachment.
 *  - @property {String} id Give the id of moved attachment.
 *  - @property {Number} newIndex New order of moved attachement.
 */
export const move = ({ boardId, cardId, id, newIndex }) => {
  return {
    type: MOVE,
    boardId,
    cardId,
    id,
    newIndex
  }
}

/**
 * Loads card attachment details from firestore.
 * @param {String} cardId - Id of Card ID whose attachmetns will be loaded.
 */
export const loadCardAttachments = (cardId) => {
  return {
    type: LOAD_CARD_ATTACHMENTS,
    cardId
  }
}

/**
 * Cancels card attachments query from firestore
 * @param {String} cardId Card IDrestore
 */
export const disconnectCardAttachments = (cardId) => {
  return {
    type: DISCONNECT_CARD_ATTACHMENTS,
    cardId
  }
}

/**
 * Loads board attachments data from firestore.
 * @param {String} boardId - Board ID
 */
export const loadBoardAttachments = (boardId) => {
  return {
    type: LOAD_BOARD_ATTACHMENTS,
    boardId
  }
}

/**
 * Disconnects board attachments data from firestore.
 * @param {String} boardId Board ID
 */
export const disconnectBoardAttachments = (boardId) => {
  return {
    type: DISCONNECT_BOARD_ATTACHMENTS,
    boardId
  }
}

/**
 * Loads file data for given `fileId` from firestore.
 * It's used to show warning, when user pastes cloud-store file link to add it as a weblink.
 * @param {String} fileId
 */
export const loadFileInfo = (fileId, url) => {
  return {
    type: LOAD_FILE_INFO,
    fileId,
    url
  }
}

/**
 * User has access to given or current board account storage or not.
 * @returns {Boolean} false when user has restricted to access a storage. otherwise true.
 */
export const userHasAccessOnStore = (boardId) => async (dispatch, getState) => {
  boardId = boardId || router.selectors.pageBoardId(getState());
  let res;
  try {
    res = await requestApi(`/file-store/refresh-board-folder-permission/${boardId}`, { method: 'PUT', excludeErrors: [409, 304] });
  } catch (error) {
    res = error;
  }
  return !(res && res.status == 409 && res.code == 'ACCESS_RESTRICTED');
}

/**
 * Uploads multiple files.
 * @param {*} param0
 *  @property {*} files Files selected by user.
 *  @property {String} boardId Board Id.
 *  @property {String} cardId Card Id. It's optional.
 *  @property {Boolean} dragndrop When user is uploading files through drag & drop, it should be `true`.
 */
export const uploadFiles = ({ files, boardId, cardId, dragndrop = false }) => {
  return {
    type: UPLOAD_FILES,
    files,
    boardId,
    cardId,
    dragndrop
  }
}

/**
 * Refreshes content.updatedAt/updatedBy detail for the card/board attachments whose type is FILE.
 * @param {*} param0
 *  @property {String} boardId Board Id
 *  @property {String} cardId Card Id.
 */
export const refreshContentDetail = ({ boardId, cardId }) => {
  return {
    type: REFRESH_CONTENT_DETAIL,
    boardId,
    cardId
  }
};

/**
 * Dispatch this action to open a attachment.
 * @param {Object} data
 *  @property {String} id attachment id
 *  @property {Enum} attachmentType attachment type
 *  @property {String} url attachment url
 *  @property {String} boardId optional.
 */
export const openAttachment = ({id, attachmentType, url, boardId}) => {
  return {
    type: OPEN_ATTACHMENT,
    id,
    attachmentType,
    url,
    boardId
  }
}
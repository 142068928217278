import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import i18next from '@dw/i18next-esm';
import { store } from '../../store';
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import firestoreRedux from '@dreamworld/firestore-redux';
import isEqual from 'lodash-es/isEqual';
import isEmpty from 'lodash-es/isEmpty';
import forEach from 'lodash-es/forEach';
import get from 'lodash-es/get';
import concat from 'lodash-es/concat';
import uniq from 'lodash-es/uniq';
import * as actions from './actions.js';

/**
 * Storage key of last text-resources-revision.
 */
const LOCAL_STORAGE_KEY = 'text-resources-revision.txtr_pwa';

function* init() {
  try {
    const textResourcesReq = firestoreRedux.getDocById(`text-resources-revision`, `txtr_pwa`);
    yield textResourcesReq.result;
    if(i18next.isInitialized) {
      yield call(listenTextResourcesUpdate);
    } else {
      i18next.on('initialized', () => {
        store.dispatch(actions.init());
      });
    }
  } catch (error) {
    console.error('text-resources > init > failed due to this: ', error);
  }
}

function getLocalStorageTextResourcesRevision() {
  try {
    return JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
  } catch(e) {
    return {}
  }
}

function setLocalStorageTextResourcesRevision(data) {
  try {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
  } catch(e) {}
}

async function deleteNameSpaceCache(lng, ns) {
  let path = i18next && i18next.options && i18next.options.backend && i18next.options.backend.loadPath || '/locales/{{lng}}/{{ns}}.json';
  lng = lng || i18next.language;
  path = path.replace('{{lng}}', lng);
  path = path.replace('{{ns}}', ns);
  const cache = await caches.open(`workbox-runtime-${window.location.protocol + '//' + window.location.host}/`);
	return await cache.delete(path, {ignoreSearch: true, ignoreMethod: true, ignoreVary: true});
}

async function getCurrentLoadedNameSpace() {
  const defaultNS = i18next && i18next.options && i18next.options.defaultNS || 'app';
  const currentLoaded = i18next && i18next.options && i18next.options.ns || [defaultNS];
  const currentNameSpace = [defaultNS];
  const cache = await caches.open(`workbox-runtime-${window.location.protocol + '//' + window.location.host}/`);
  const cacheKeys = await cache.keys();
  cacheKeys.forEach((key) => {
    const url = key.url;
    if(url && /\/locales\/.*\/*\.json$/.test(url)) {
      const fileName = url.replace(/^.*\/locales\/.*\//, '');
      const ns = fileName.replace('.json', '');
      currentNameSpace.push(ns);
    }
  });
  return uniq(concat(currentNameSpace, currentLoaded));
}

function* listenTextResourcesUpdate() {
  ReduxUtils.subscribe(store, 'firestore.docs.text-resources-revision.txtr_pwa', async(doc) => {
    if(isEmpty(doc) || !i18next.isInitialized) {
      return;
    }

    const prevDoc = getLocalStorageTextResourcesRevision();
    if(isEqual(doc, prevDoc)) {
      return;
    }

    if(isEmpty(prevDoc)) {
      setLocalStorageTextResourcesRevision(doc);
      return;
    }

    try {
      const cachesNS = await getCurrentLoadedNameSpace();
      forEach(doc, async(value, lang) => {
        const newFiles = get(value, `files`, {});
        const oldFiles = get(prevDoc, `${lang}.files`, {});
        forEach(newFiles, async(hash, fileName) => {
          const defaultNS = i18next && i18next.options && i18next.options.defaultNS || 'app';
          const currentNS = i18next && i18next.options && i18next.options.ns || [defaultNS];
          const currentLoaded = uniq(concat(currentNS, cachesNS));
          const ns = fileName.replace('.json', '');
          if (hash !== get(oldFiles, `${fileName}`)) {
            if(currentLoaded && currentLoaded.includes(ns)) {
              await deleteNameSpaceCache(lang, ns);
              if(currentNS && currentNS.includes(ns)) {
                await i18next.reloadResources(lang, ns);
              }
            }
          }
        });
      });
      setLocalStorageTextResourcesRevision(doc);
    } catch (error) {
      // Reset local storage revision is failed due to any error.
      setLocalStorageTextResourcesRevision(prevDoc);
    }
  });
}

/**
 * Init Saga.
 */
function* saga() {
  yield all([
    yield takeEvery(actions.INIT, init)
  ]);
}

export default saga;

import * as app from '../app';
import * as auth from '../auth';
import * as router from '../router';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as feature from '../feature';
import isEmpty from 'lodash-es/isEmpty';
import forEach from 'lodash-es/forEach';
import isEqual from 'lodash-es/isEqual';
import filter from 'lodash-es/filter';
import get from 'lodash-es/get';
import entityIdProvider from '../../entity-id-provider';
import moment from 'moment/src/moment';
import { createSelector } from 'reselect';
import { getCookieVal, setCookieVal } from '../../utils.js';

/**
 * @returns {String} device id if found in local storage, otherwise generate new id and set to localstorage.
 */
export const getId = (state) => {
  const cookieDeviceId = getCookieVal('device-id') || '';
  const storageDeviceId = window && window.localStorage && window.localStorage.getItem('device-id') || '';
  const id = cookieDeviceId || storageDeviceId || entityIdProvider.getId() || '';

  if(!storageDeviceId || id !== storageDeviceId) {
    window && window.localStorage && window.localStorage.setItem('device-id', id);
  }

  if(!cookieDeviceId || id !== cookieDeviceId) {
    setCookieVal('device-id', id, `.${window.location.host.replace('a.', '')}`, 365);
  }
  
  return id;
}

/**
 * @returns {String} device token from local storage.
 */
export const token = (state) => {
  return window && window.localStorage && window.localStorage.getItem('device-token') || '';
}

const queries = createSelector(
  (state, time) => firestoreRedux.selectors.queriesByRequester(state, 'devices'),
  (queries) => {
    return queries;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    }
  }
);

const hasQueries = createSelector(
  (state, time) => queries(state),
  (queries) => {
    return !isEmpty(queries);
  }
);

const pendingQueries = createSelector(
  (state, time) => queries(state),
  (queries) => {
    return filter(queries, { status: 'PENDING', collection: 'devices' });
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    }
  }
);

const hasPendingQueries = createSelector(
  (state, time) => pendingQueries(state),
  (pendingQueries) => {
    return !isEmpty(pendingQueries);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    }
  }
);

const doc = (state, time) => firestoreRedux.selectors.doc(state, 'devices', `ud_${getId(state)}`);

/**
 * @return {Boolean} ask permssion dialog is opened or not.
 */
export const permissionDialogOpened = createSelector(
  (state, time) => time,
  (state, time) => router.selectors.pageName(state),
  (state, time) => isAvailable(state),
  (state, time) => hasQueries(state),
  (state, time) => hasPendingQueries(state),
  (state, time) => doc(state),
  (state, time) => app.selectors.isInstalledApp(state),
  (state, time) => app.selectors.installedAppDeviceReady(state),
  (state, time) => firestoreRedux.selectors.queriesByRequester(state,`user-ui-${auth.selectors.currentUserId(state)}`),
  (state, time) => get(state, `device.notificationPermissionDialogPresented`),
  (time, pageName, isAvailable, hasQueries, hasPendingQueries, doc, isInstalledApp, deviceReady, userUiQueries, notificationPermissionDialogPresented) => {
    if(!isInstalledApp || !deviceReady) {
      return false;
    }

    if(notificationPermissionDialogPresented) {
      return false;
    }

    if(pageName === 'SIGNUP') {
      return false;
    }

    if(!isAvailable) {
      return false;
    }

    if(!window.cordova || !window.cordova.plugins || !window.cordova.plugins.firebase || !window.cordova.plugins.firebase.messaging) {
      return false;
    }

    const userUiPendingQueries = filter(userUiQueries, { status: 'PENDING', collection: 'user-ui' });
    if(isEmpty(userUiQueries) || !isEmpty(userUiPendingQueries)) {
      return false;
    }

    if(!hasQueries || hasPendingQueries) {
      return false;
    }

    const token = get(doc, 'fcm.token');
    const deniedAt = get(doc, 'fcm.deniedAt');
    if(!token && !deniedAt) {
      return true;
    }

    if(!deniedAt) {
      return false;
    }

    time = time || Date.now();
    if(moment(time).isSameOrAfter(moment(deniedAt).add(7, 'd'), 'day')) {
      return true;
    }

    return false;
  },
  {
    memoizeOptions: {
      maxSize: 100
    }
  }
);

export const isAvailable = (state) => {
  const userId = auth.selectors.currentUserId(state);
  if(!userId) {
    return false;
  }

  const accounts = auth.selectors.accessibleAccounts(state);
  let _isAvailable = false;
  forEach(accounts, (accountId) => {
    _isAvailable = feature.selectors.isAvailable(state, 'board-visitor-notification', accountId);
    if(_isAvailable) {
      return false;
    }
  });
  return _isAvailable;
}
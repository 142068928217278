export const LOAD = 'SUBSCRIPTION_V2_DETAILS_LOAD';
export const LOAD_SUCCESS = 'SUBSCRIPTION_V2_DETAILS_LOAD_SUCCESS';
export const LOAD_FAILED = 'SUBSCRIPTION_V2_DETAILS_LOAD_FAILED';

export const SUBSCRIPTION_RENEW_PAGE_OPENED = 'SUBSCRIPTION_V2_RENEW_PAGE_OPENED';
export const SUBSCRIPTION_RENEW_PAGE_CLOSED = 'SUBSCRIPTION_V2_RENEW_PAGE_CLOSED';

export const SUBSCRIPTION_AUTO_RENEW_PAGE_OPENED = 'SUBSCRIPTION_V2_AUTO_RENEW_PAGE_OPENED';
export const SUBSCRIPTION_AUTO_RENEW_PAGE_CLOSED = 'SUBSCRIPTION_V2_AUTO_RENEW_PAGE_CLOSED';

export const SWITCH_TO_FREE_PLAN_DIALOG_OPENED = 'SUBSCRIPTION_V2_SWITCH_TO_FREE_PLAN_DIALOG_OPENED';
export const SWITCH_TO_FREE_PLAN_DIALOG_CLOSED = 'SUBSCRIPTION_V2_SWITCH_TO_FREE_PLAN_DIALOG_CLOSED';

export const PREVIEW_UPCOMING_INVOICE_DIALOG_OPENED = 'SUBSCRIPTION_V2_PREVIEW_UPCOMING_INVOICE_DIALOG_OPENED';
export const PREVIEW_UPCOMING_INVOICE_DIALOG_CLOSED = 'SUBSCRIPTION_V2_PREVIEW_UPCOMING_INVOICE_DIALOG_CLOSED';

export const CHANGE_PLAN_DIALOG_OPENED = 'SUBSCRIPTION_V2_CHANGE_PLAN_DIALOG_OPENED';
export const CHANGE_PLAN_DIALOG_CLOSED = 'SUBSCRIPTION_V2_CHANGE_PLAN_DIALOG_CLOSED';

export const DISCONNECT = 'SUBSCRIPTION_V2_DETAILS_DISCONNECT';

export const CANCEL_SUBSCRIPTION = 'SUBSCRIPTION_V2_CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_V2_CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILED = 'SUBSCRIPTION_V2_CANCEL_SUBSCRIPTION_FAILED';

export const LOAD_BILLING_DETAILS = 'SUBSCRIPTION_V2_LOAD_BILLING_DETAILS';
export const LOAD_BILLING_DETAILS_SUCCESS = 'SUBSCRIPTION_V2_LOAD_BILLING_DETAILS_SUCCESS';
export const LOAD_BILLING_DETAILS_FAILED = 'SUBSCRIPTION_V2_LOAD_BILLING_DETAILS_FAILED';

export const LOAD_PRICING_DETAILS = 'SUBSCRIPTION_V2_LOAD_PRICING_DETAILS';
export const LOAD_PRICING_DETAILS_SUCCESS = 'SUBSCRIPTION_V2_LOAD_PRICING_DETAILS_SUCCESS';
export const LOAD_PRICING_DETAILS_FAILED = 'SUBSCRIPTION_V2_LOAD_PRICING_DETAILS_FAILED';

export const COMPUTE_CHARGES = 'SUBSCRIPTION_V2_COMPUTE_CHARGES';
export const COMPUTE_CHARGES_SUCCESS = 'SUBSCRIPTION_V2_COMPUTE_CHARGES_SUCCESS';
export const COMPUTE_CHARGES_FAILED = 'SUBSCRIPTION_V2_COMPUTE_CHARGES_FAILED';

export const CHANGE_PLAN = 'SUBSCRIPTION_V2_CHANGE_PLAN';
export const CHANGE_PLAN_SUCCESS = 'SUBSCRIPTION_V2_CHANGE_PLAN_SUCCESS';
export const CHANGE_PLAN_FAILED = 'SUBSCRIPTION_V2_CHANGE_PLAN_FAILED';
export const CHANGE_PLAN_CANCEL = 'SUBSCRIPTION_V2_CHANGE_PLAN_CANCEL';

export const ENABLE_AUTO_RENEW = 'SUBSCRIPTION_V2_ENABLE_AUTO_RENEW';
export const ENABLE_AUTO_RENEW_SUCCESS = 'SUBSCRIPTION_V2_ENABLE_AUTO_RENEW_SUCCESS';
export const ENABLE_AUTO_RENEW_FAILED = 'SUBSCRIPTION_V2_ENABLE_AUTO_RENEW_FAILED';

export const DISABLE_AUTO_RENEW = 'SUBSCRIPTION_V2_DISABLE_AUTO_RENEW';
export const DISABLE_AUTO_RENEW_SUCCESS = 'SUBSCRIPTION_V2_DISABLE_AUTO_RENEW_SUCCESS';
export const DISABLE_AUTO_RENEW_FAILED = 'SUBSCRIPTION_V2_DISABLE_AUTO_RENEW_FAILED';

export const CHANGE_AUTO_RENEW = 'SUBSCRIPTION_V2_CHANGE_AUTO_RENEW';
export const CHANGE_AUTO_RENEW_SUCCESS = 'SUBSCRIPTION_V2_CHANGE_AUTO_RENEW_SUCCESS';
export const CHANGE_AUTO_RENEW_FAILED = 'SUBSCRIPTION_V2_CHANGE_AUTO_RENEW_FAILED';

export const SETUP_PAYMENT_METHOD = 'SUBSCRIPTION_V2_SETUP_PAYMENT_METHOD';
export const SETUP_PAYMENT_METHOD_SUCCESS = 'SUBSCRIPTION_V2_SETUP_PAYMENT_METHOD_SUCCESS';
export const SETUP_PAYMENT_METHOD_FAILED = 'SUBSCRIPTION_V2_SETUP_PAYMENT_METHOD_FAILED';
export const SETUP_PAYMENT_METHOD_CANCEL = 'SUBSCRIPTION_V2_SETUP_PAYMENT_METHOD_CANCEL';

export const LOAD_INVOICES = 'SUBSCRIPTION_V2_LOAD_INVOICES';
export const LOAD_INVOICES_SUCCESS = 'SUBSCRIPTION_V2_LOAD_INVOICES_SUCCESS';
export const LOAD_INVOICES_FAILED = 'SUBSCRIPTION_V2_LOAD_INVOICES_FAILED';

export const LOAD_CREDIT_BALANCE = 'SUBSCRIPTION_V2_LOAD_CREDIT_BALANCE';
export const LOAD_CREDIT_BALANCE_SUCCESS = 'SUBSCRIPTION_V2_LOAD_CREDIT_BALANCE_SUCCESS';
export const LOAD_CREDIT_BALANCE_FAILED = 'SUBSCRIPTION_V2_LOAD_CREDIT_BALANCE_FAILED';

export const LOAD_SUBSCRIPTION_HISTORY = 'SUBSCRIPTION_V2_LOAD_SUBSCRIPTION_HISTORY';
export const LOAD_SUBSCRIPTION_HISTORY_SUCCESS = 'SUBSCRIPTION_V2_LOAD_SUBSCRIPTION_HISTORY_SUCCESS';
export const LOAD_SUBSCRIPTION_HISTORY_FAILED = 'SUBSCRIPTION_V2_LOAD_SUBSCRIPTION_HISTORY_FAILED';

export const DOWNLOAD_INVOICE = 'SUBSCRIPTION_V2_DOWNLOAD_INVOICE';

export const DOWNLOAD_RECEIPT = 'SUBSCRIPTION_V2_DOWNLOAD_RECEIPT';

export const MAKE_PAYMENT_INVOICE = 'SUBSCRIPTION_V2_MAKE_PAYMENT_INVOICE';

export const OPEN_LAST_INVOICE_PAYMENT_LINK = 'SUBSCRIPTION_V2_OPEN_LAST_INVOICE_PAYMENT_LINK';

export const MESSAGE_MARK_AS_KNOWN = 'SUBSCRIPTION_V2_MESSAGE_MARK_AS_KNOWN';

export const LOAD_LAST_INVOICE = 'SUBSCRIPTION_V2_LOAD_LAST_INVOICE';
export const LOAD_LAST_INVOICE_SUCCESS = 'SUBSCRIPTION_V2_LOAD_LAST_INVOICE_SUCCESS';
export const LOAD_LAST_INVOICE_FAILED = 'SUBSCRIPTION_V2_LOAD_LAST_INVOICE_FAILED';

export const UPDATE_BILLING_ADDRESS = 'SUBSCRIPTION_V2_UPDATE_BILLING_ADDRESS';
export const UPDATE_BILLING_ADDRESS_SUCCESS = 'SUBSCRIPTION_V2_UPDATE_BILLING_ADDRESS_SUCCESS';
export const UPDATE_BILLING_ADDRESS_FAILED = 'SUBSCRIPTION_V2_UPDATE_BILLING_ADDRESS_FAILED';

export const NON_PROFIT_REQUEST = 'SUBSCRIPTION_V2_NON_PROFIT_REQUEST';
export const NON_PROFIT_REQUEST_SUCCESS = 'SUBSCRIPTION_V2_NON_PROFIT_REQUEST_SUCCESS';
export const NON_PROFIT_REQUEST_FAILED = 'SUBSCRIPTION_V2_NON_PROFIT_REQUEST_FAILED';

export const LOAD_NON_PROFIT_REQUEST = 'SUBSCRIPTION_V2_LOAD_NON_PROFIT_REQUEST';
export const LOAD_NON_PROFIT_REQUEST_SUCCESS = 'SUBSCRIPTION_V2_LOAD_NON_PROFIT_REQUEST_SUCCESS';
export const LOAD_NON_PROFIT_REQUEST_FAILED = 'SUBSCRIPTION_V2_LOAD_NON_PROFIT_REQUEST_FAILED';

export const CHECK_PUBLIC_DOMAIN = 'SUBSCRIPTION_V2_CHECK_PUBLIC_DOMAIN';
export const CHECK_PUBLIC_DOMAIN_SUCCESS = 'SUBSCRIPTION_V2_CHECK_PUBLIC_DOMAIN_SUCCESS';
export const CHECK_PUBLIC_DOMAIN_FAILED = 'SUBSCRIPTION_V2_CHECK_PUBLIC_DOMAIN_FAILED';

export const REQUEST_INVOICE = 'SUBSCRIPTION_V2_REQUEST_INVOICE';
export const REQUEST_INVOICE_SUCCESS = 'SUBSCRIPTION_V2_REQUEST_INVOICE_SUCCESS';
export const REQUEST_INVOICE_FAILED = 'SUBSCRIPTION_V2_REQUEST_INVOICE_FAILED';

/**
 * Dispatched this action to load the subscriptions details for an account. 
 */
export const load = (accountId, requesterId) => ({
  type: LOAD,
  accountId,
  requesterId,
});

export const _loadSuccess = (accountId, data) => ({
  type: LOAD_SUCCESS,
  accountId,
  data,
});

export const _loadFailed = (accountId, error) => ({
  type: LOAD_FAILED,
  accountId,
  error,
});

/**
 * Dispatched this action to disconnect a subscription live data from an account.
 */
export const disconnect = (accountId, requesterId) => ({
  type: DISCONNECT,
  accountId,
  requesterId,
});

/**
 * Dispatched this action to cancel a subscription.
 * @param {String} accountId 
 * @param {Enum} value Possible values are `NOW` or `END_OF_PERIOD`.
 */
export const cancelSubscription = (accountId, value = 'NOW') => ({
  type: CANCEL_SUBSCRIPTION,
  accountId,
  value,
});

export const _cancelSubscriptionSuccess = (accountId, value) => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
  accountId,
  value,
});

export const _cancelSubscriptionFailed = (accountId, value, error) => ({
  type: CANCEL_SUBSCRIPTION_FAILED,
  accountId,
  value,
  error,
});

/**
 * Dispatched this action to load the billing details for an account.
 * @param {String} accountId 
 */
export const loadBillingDetails = (accountId) => ({
  type: LOAD_BILLING_DETAILS,
  accountId,
});

export const _loadBillingDetailsSuccess = (accountId, data) => ({
  type: LOAD_BILLING_DETAILS_SUCCESS,
  accountId,
  data,
});

export const _loadBillingDetailsFailed = (accountId, error) => ({
  type: LOAD_BILLING_DETAILS_FAILED,
  accountId,
  error,
});

/**
 * Dispatched this action to load the pricing plan list.  
 * @param {String} accountId 
 */
export const loadPricing = (accountId) => ({
  type: LOAD_PRICING_DETAILS,
  accountId,
});

export const _loadPricingSuccess = (accountId, data) => ({
  type: LOAD_PRICING_DETAILS_SUCCESS,
  accountId,
  data,
});

export const _loadPricingFailed = (accountId, error) => ({
  type: LOAD_PRICING_DETAILS_FAILED,
  accountId,
  error,
});

/**
 * Dispatched this action to compute the charges for a subscription.
 * @param {String} accountId 
 * @param {String} priceId
 * @param {Number} additionalSeats
 * @param {Enum} chargesType Possible values are `CANCEL_SUBSCRIPTION`, `CHANGE_PLAN`.
 */
export const computeCharges = ({accountId, priceId, additionalSeats, chargesType = 'CHANGE_PLAN'}) => ({
  type: COMPUTE_CHARGES,
  accountId,
  priceId,
  additionalSeats,
  chargesType
});

export const _computeChargesSuccess = ({accountId, data}) => ({
  type: COMPUTE_CHARGES_SUCCESS,
  accountId,
  data,
});

export const _computeChargesFailed = ({accountId, error}) => ({
  type: COMPUTE_CHARGES_FAILED,
  accountId,
  error,
});

/**
 * Dispatched this action to start a subscription.
 * @param {String} accountId 
 * @param {String} priceId
 * @param {Number} additionalSeats,
 * @param {Boolean} autoRenew
 * @param {String} email
 * @param {Enum} actionType Possible values are `CHANGE_PLAN`, `START_SUBSCRIPTION`.
 *  
 */
export const changePlan = ({accountId, priceId, additionalSeats, autoRenew, email, actionType = 'START_SUBSCRIPTION'}) => ({
  type: CHANGE_PLAN,
  accountId,
  priceId,
  additionalSeats,
  autoRenew,
  email,
  actionType
});

export const _changePlanSuccess = ({accountId, data}) => ({
  type: CHANGE_PLAN_SUCCESS,
  accountId,
  data,
});

export const _changePlanFailed = ({accountId, error}) => ({
  type: CHANGE_PLAN_FAILED,
  accountId,
  error,
});

export const _changePlanCancel = ({accountId}) => ({
  type: CHANGE_PLAN_CANCEL,
  accountId,
});

/**
 * Dispatched this action to change the auto renew on/off for given account.
 * @param { String } accountId 
 * @param { Boolean } value 
 * @param { Boolean } confirm
 * @returns 
 */
export const changeAutoRenew = (accountId, value, confirm = false, inSameWindow) => ({
  type: CHANGE_AUTO_RENEW,
  accountId,
  value,
  confirm,
  inSameWindow
});

export const _changeAutoRenewSuccess = (accountId, value, inSameWindow) => ({
  type: CHANGE_AUTO_RENEW_SUCCESS,
  accountId,
  value,
  inSameWindow
});

export const _changeAutoRenewFailed = (accountId, value, inSameWindow, error) => ({
  type: CHANGE_AUTO_RENEW_FAILED,
  accountId,
  value,
  error,
  inSameWindow
});

/**
 * Dispatched this action to setup a payment method for an account.
 */
export const setupPaymentMethod = (accountId, inSameWindow = false) => ({
  type: SETUP_PAYMENT_METHOD,
  accountId,
  inSameWindow
});

export const _setupPaymentMethodSuccess = (accountId, data, inSameWindow = false) => ({
  type: SETUP_PAYMENT_METHOD_SUCCESS,
  accountId,
  data,
  inSameWindow
});

export const _setupPaymentMethodFailed = (accountId, error, inSameWindow = false) => ({
  type: SETUP_PAYMENT_METHOD_FAILED,
  accountId,
  error,
  inSameWindow
});

export const _setupPaymentMethodCancel = (accountId, inSameWindow = false) => ({
  type: SETUP_PAYMENT_METHOD_CANCEL,
  accountId,
  inSameWindow
});

/**
 * Dispatched this action to load the invoices for an account.
 */
export const loadInvoices = (accountId) => ({
  type: LOAD_INVOICES,
  accountId,
});

export const _loadInvoicesSuccess = (accountId, data) => ({
  type: LOAD_INVOICES_SUCCESS,
  accountId,
  data,
});

export const _loadInvoicesFailed = (accountId, error) => ({
  type: LOAD_INVOICES_FAILED,
  accountId,
  error,
});

/**
 * Dispatched this action to load the credit balance for an account.
 */
export const loadCreditBalance = (accountId) => ({
  type: LOAD_CREDIT_BALANCE,
  accountId,
});

export const _loadCreditBalanceSuccess = (accountId, data) => ({
  type: LOAD_CREDIT_BALANCE_SUCCESS,
  accountId,
  data,
});

export const _loadCreditBalanceFailed = (accountId, error) => ({
  type: LOAD_CREDIT_BALANCE_FAILED,
  accountId,
  error,
});

/**
 * Dispatched this action to download an invoice.
 */
export const downloadInvoice = (accountId, invoiceId, url) => ({
  type: DOWNLOAD_INVOICE,
  accountId,
  invoiceId,
  url
});

/**
 * Dispatched this action to download an receipt.
 */
export const downloadReceipt = (accountId, invoiceId, url) => ({
  type: DOWNLOAD_RECEIPT,
  accountId,
  invoiceId,
  url
});

/**
 * Dispatch this action to make payment of specific invoice.
 */
export const makePayment = (accountId, invoiceId) => ({
  type: MAKE_PAYMENT_INVOICE,
  accountId,
  invoiceId,
});

/**
 * Dispatched this action to open the last invoice payment link.
 */
export const openLastInvoicePaymentLink = (accountId) => ({
  type: OPEN_LAST_INVOICE_PAYMENT_LINK,
  accountId,
});

/**
 * Dispatched this action to mark an alert message as known.
 */
export const messageMarkAsKnown = (accountId, name) => ({
  type: MESSAGE_MARK_AS_KNOWN,
  accountId,
  name,
});

/**
 * Dispatched this action to load the last invoice for an account.
 */
export const loadLastInvoice = (accountId) => ({
  type: LOAD_LAST_INVOICE,
  accountId,
});

export const _loadLastInvoiceSuccess = (accountId, data) => ({
  type: LOAD_LAST_INVOICE_SUCCESS,
  accountId,
  data,
});

export const _loadLastInvoiceFailed = (accountId, error) => ({
  type: LOAD_LAST_INVOICE_FAILED,
  accountId,
  error,
});

export const _enableAutoRenew = (accountId, inSameWindow) => ({
  type: ENABLE_AUTO_RENEW,
  accountId,
  inSameWindow
});

export const _enableAutoRenewSuccess = (accountId, inSameWindow) => ({
  type: ENABLE_AUTO_RENEW_SUCCESS,
  accountId,
  inSameWindow
});

export const _enableAutoRenewFailed = (accountId, inSameWindow, error) => ({
  type: ENABLE_AUTO_RENEW_FAILED,
  accountId,
  inSameWindow,
  error,
});

export const _disableAutoRenew = (accountId, inSameWindow) => ({
  type: DISABLE_AUTO_RENEW,
  accountId,
  inSameWindow
});

export const _disableAutoRenewSuccess = (accountId, inSameWindow) => ({
  type: DISABLE_AUTO_RENEW_SUCCESS,
  accountId,
  inSameWindow
});

export const _disableAutoRenewFailed = (accountId, inSameWindow, error) => ({
  type: DISABLE_AUTO_RENEW_FAILED,
  accountId,
  inSameWindow,
  error,
});

/**
 * Dispatched this action to load the subscription history for an account.
 *  - Currently, this action is dispatched when manage account page transactions tab is opened.
 */
export const loadSubscriptionHistory = (accountId, requesterId) => ({
  type: LOAD_SUBSCRIPTION_HISTORY,
  accountId,
  requesterId
});

export const _loadSubscriptionHistorySuccess = (accountId, requesterId, data) => ({
  type: LOAD_SUBSCRIPTION_HISTORY_SUCCESS,
  accountId,
  data,
  requesterId
});

export const _loadSubscriptionHistoryFailed = (accountId, requesterId, error) => ({
  type: LOAD_SUBSCRIPTION_HISTORY_FAILED,
  accountId,
  error,
  requesterId
});

/**
 * Dispatched this action to update the billing address for an account.
 */
export const updateBillingAddress = (accountId, address) => ({
  type: UPDATE_BILLING_ADDRESS,
  accountId,
  address
});

export const _updateBillingAddressSuccess = (accountId, address) => ({
  type: UPDATE_BILLING_ADDRESS_SUCCESS,
  accountId,
  address
});

export const _updateBillingAddressFailed = (accountId, address, error) => ({
  type: UPDATE_BILLING_ADDRESS_FAILED,
  accountId,
  address,
  error
});

/**
 * Dispatched this action to request for non-profit discount.
 * @param {String} accountId
 */
export const nonProfitRequest = (accountId) => ({
  type: NON_PROFIT_REQUEST,
  accountId
});

export const _nonProfitRequestSuccess = (accountId) => ({
  type: NON_PROFIT_REQUEST_SUCCESS,
  accountId
});

export const _nonProfitRequestFailed = (accountId, error) => ({
  type: NON_PROFIT_REQUEST_FAILED,
  accountId,
  error
});

/**
 * Dispatched this action to load the non-profit request data for an account.
 */
export const loadNonProfitRequest = (accountId, requesterId) => ({
  type: LOAD_NON_PROFIT_REQUEST,
  accountId,
  requesterId
});

export const _loadNonProfitRequestSuccess = (accountId, requesterId, data) => ({
  type: LOAD_NON_PROFIT_REQUEST_SUCCESS,
  accountId,
  requesterId,
  data
});

export const _loadNonProfitRequestFailed = (accountId, requesterId, error) => ({
  type: LOAD_NON_PROFIT_REQUEST_FAILED,
  accountId,
  requesterId,
  error
});

/**
 * Dispatched this action to check the current user domain is public or not.
 */
export const checkPublicDomain = () => ({
  type: CHECK_PUBLIC_DOMAIN
});

export const _checkPublicDomainSuccess = (value) => ({
  type: CHECK_PUBLIC_DOMAIN_SUCCESS,
  value
});

export const _checkPublicDomainFailed = (error) => ({
  type: CHECK_PUBLIC_DOMAIN_FAILED,
  error
});

/**
 * Dispatched this action to start a subscription using request invoice.
 * @param {String} accountId 
 * @param {String} priceId
 * @param {Number} additionalSeats,
 * @param {Boolean} autoRenew
 */
export const requestInvoice = ({accountId, priceId, additionalSeats, autoRenew}) => ({
  type: REQUEST_INVOICE,
  accountId,
  priceId,
  additionalSeats,
  autoRenew,
});

export const _requestInvoiceSuccess = ({accountId, data}) => ({
  type: REQUEST_INVOICE_SUCCESS,
  accountId,
  data,
});

export const _requestInvoiceFailed = ({accountId, error}) => ({
  type: REQUEST_INVOICE_FAILED,
  accountId,
  error,
});
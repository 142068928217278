import URI from '@dw/urijs-esm';
import set from 'lodash-es/set';

/**
 * This promise function sets dialog data from path after # in URL
 */
export const dialogRouter = (data) => {
  return new Promise((resolve, reject) => {
    const params = new URI(data.hash).query(true) || {};
    const name = _getDialogName(data.hash);
    data.dialog = {
      name,
      params
    }

    if(name === 'VIDEOS') {
      set(data, 'dialog.params.id', new URI(data.hash).segment()[1]);
    }

    if(name === 'LEAVE_ACCOUNT') {
      set(data, 'dialog.params.id', new URI(data.hash).segment()[1]);
    }

    resolve(data);
    return;
  });
}

/**
 * @returns {String} Dialong name
 * @param {String} path Path
 */
const _getDialogName = (path) => {
  const uri = new URI(path);
  switch (uri.segment()[0]) {
    case 'user-actions':
      return 'USER_ACTIONS';
    case 'account-switcher':
      return 'ACCOUNT_SWITCHER';
    case 'get-help':
      return 'GET_HELP';
    case 'card-actions':
      return 'CARD_ACTIONS';
    case 'date-picker':
      return 'DATE_PICKER'
    case 'profile':
      return 'PROFILE'
    case 'calendar':
      return 'CALENDAR'
    case 'messages':
      return 'MESSAGES';
    case 'broadcast-messages':
      return 'BROADCAST_MESSAGES';
    case 'change-plan':
      return 'CHANGE_PLAN';
    case 'switch-to-free-plan':
      return  'SWITCH_TO_FREE_PLAN';
    case 'request-non-profit':
      return 'REQUEST_NON_PROFIT';
    case 'manage-team-confirm':
      return 'MANAGE_TEAM_CONFIRM';
    case 'search':
      return 'SEARCH';
    case 'auth':
      return 'AUTH';
    case 'videos':
      return 'VIDEOS';
    case 'invite-account-team':
      return 'INVITE_ACCOUNT_TEAM';
    case 'join-board':
      return 'JOIN_BOARD_DIALOG';
    case 'translations':
      return 'TRANSLATIONS_DIALOG';
    case 'leave-app':
      return 'LEAVE_APP_DIALOG';
    case 'leave-account':
      return 'LEAVE_ACCOUNT';
    case 'trial-end': 
      return 'TRIAL_END';
    case 'subscription-canceled':
      return 'SUBSCRIPTION_CANCELED';
    case 'subscription-unpaid':
      return 'SUBSCRIPTION_UNPAID';
    case 'transfer-board-accept-confirm':
      return 'TRANSFER_BOARD_ACCEPT_CONFIRM';
    case 'auto-renew-on':
      return 'AUTO_RENEW_ON';
    case 'customer-balance':
      return 'CUSTOMER_BALANCE';
    case 'preview-upcoming-invoice':
      return 'PREVIEW_UPCOMING_INVOICE';
    default:
      return '';
  }
}

/**
 * @returns {String} dialog name based on current URL.
 */
export const getDialogName = () => {
  let dialogPath = window.location.href.split('#')[1];
  if(dialogPath) {
    return _getDialogName(dialogPath);
  }
  return '';
}

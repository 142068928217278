export const ACKNOWLEDGE = 'ACCOUNT_ALERTS_ACKNOWLEDGE';
export const ACKNOWLEDGE_SUCCESS = 'ACCOUNT_ALERTS_ACKNOWLEDGE_SUCCESS';
export const ACKNOWLEDGE_FAILED = 'ACCOUNT_ALERTS_ACKNOWLEDGE_FAILED';

export const INIT = 'ACCOUNT_ALERTS_INIT';

export const init = () => {
  return {
    type: INIT
  }
}

/**
 * Dispatch action to acknowledge an alert.
 * @param {String} accountId 
 * @param {Enum} alertType possible values: 'OFFLINE_PAYMENT_CHANGE_AUTO_RENEW_OFF'
 */
export const acknowledge = (accountId, alertType) => {
  return {
    type: ACKNOWLEDGE,
    accountId,
    alertType
  };
}

export const _acknowledgeSuccess = (accountId, alertType) => {
  return {
    type: ACKNOWLEDGE_SUCCESS,
    accountId,
    alertType
  };
}

export const _acknowledgeFailed = (accountId, alertType, error) => {
  return {
    type: ACKNOWLEDGE_FAILED,
    accountId,
    alertType,
    error
  };
}
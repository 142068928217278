/**
 * This module is used to manage known-features tooltip.
 * Behaviors:
 *  Saga:
 *    - On "UPDATE_AUTH", if user found it loads known-features for current user from firestore.
 *    - On "MARK_AS_KNOWN" action, it send firestore write request to set that feature for current user to `true`
 *  Selectors:
 *    - `knownFeatures`: selects known features for current user.
 */

import { sagaMiddleware, store } from '../../store';

import * as _actions from './actions';
import * as _selectors from './selectors';
import * as _reducers from './reducers.js';
import saga from './saga';

sagaMiddleware.run(saga);

export const actions = _actions;
export const selectors = _selectors;

store.addReducers({
  'known-features': _reducers.knownFeatures
});
import { default as installWorkbox } from '@dreamworld/workbox-installer';
import FirestoreLatestVersionUpdateChecker from '@dreamworld/workbox-installer/firestore-latest-version-update-checker';
import { store } from './store.js'; 
import * as app from './redux/app';
import * as device from './redux/device';
import * as amplitude from './analytics/amplitude.js';
import debounce from 'lodash-es/debounce';
import get from 'lodash-es/get';

const USER_INACTIVE_TIME = 10 * 60 * 1000; // 10 minutes
const LAST_ACTIVITY_DEBOUNCE_TIME = 500; // 0.5 second
const INACTIVITY_CHECKING_INTERVAL_TIME = 30000; // 30 seconds
let LAST_UPDATE_TIME;
let LAST_ACTIVITY_TIME;
let windowLoadedAt;
let MESSAGE_ELEMENT;
 
if (document.readyState === 'complete') {
  windowLoadedAt = new Date().getTime();
} else {
  window.addEventListener('load', () => { windowLoadedAt = new Date().getTime() });
}

/**
 * Possibles version status of an app.
 */
export const appVersionStatus = {
  LATEST: 'LATEST',
  NEW_VERSION_AVAILABLE: 'NEW_VERSION_AVAILABLE',
  UPDATE_IN_PROGRESS: 'UPDATE_IN_PROGRESS'
};

const confirmUpdate = ({ elMessage, latestVersion }) => {
  import('./components/kerika-app-updating-page.js').then(() => {
    store.dispatch({
      type: app.actions.UPDATE_SERVICE_WORKER_UPDATE_STATUS,
      value: appVersionStatus.NEW_VERSION_AVAILABLE
    });

    // Start user activity tracking, if she is inactive for 10 minutes, reload window automatically.
    LAST_UPDATE_TIME = new Date().getTime();
    MESSAGE_ELEMENT = elMessage;
    document.addEventListener('mousemove', updateLastActivityTime);
    document.addEventListener('keydown', updateLastActivityTime);
    document.addEventListener('visibilitychange', onVisibilityChange);
    setInterval(checkForInactivity, INACTIVITY_CHECKING_INTERVAL_TIME);
  }).catch((error) => {
    console.error('Failed to load kerika-app-updating-page', error);
  })
  
  return elMessage.onConfirm().then(() => {
    store.dispatch({
      type: app.actions.UPDATE_SERVICE_WORKER_UPDATE_STATUS,
      value: appVersionStatus.UPDATE_IN_PROGRESS
    });
  });
}

/**
 * Update user's last activity time.
 */
const updateLastActivityTime = debounce(() => {
  LAST_ACTIVITY_TIME = new Date().getTime();
}, LAST_ACTIVITY_DEBOUNCE_TIME);

/**
 * When user is inactive since 10 minutes, reload page.
 */
const checkForInactivity = () => {
  if (!LAST_UPDATE_TIME) {
    return;
  }

  const currentTime = new Date().getTime();
  const lastActiveTime = LAST_ACTIVITY_TIME || LAST_UPDATE_TIME;
  if (currentTime > (lastActiveTime + USER_INACTIVE_TIME)) {
    MESSAGE_ELEMENT && MESSAGE_ELEMENT.confirmResolve && MESSAGE_ELEMENT.confirmResolve();
    return;
  }
}

/**
 * When user wake from sleep mode, it checks for user's inactive time
 */
const onVisibilityChange = () => {
  if (!document.hidden) {
    checkForInactivity();
  }
}

const isServiceWorkerActivated = () => {
  const swController = get(navigator, 'serviceWorker.controller');
  let resolve, reject;
  const promise = new Promise((res, rej) => { resolve = res, reject = rej; });
  if(swController) {
    if(swController.state == 'activated') {
      resolve();
    } else {
      const statechangeListener = (e) => {
        if (swController && swController.state == 'activated') {
          resolve();
          swController.removeEventListener('statechange', statechangeListener);
        }
      };
      swController.addEventListener('statechange', statechangeListener);
    }
  }
  return promise;
}

const updateAppVersionsLatestStatus = async() => {
  const sw = get(navigator, 'serviceWorker');
  const swController = sw && sw.controller;
  if(!sw) {
    console.warn("service-worker is not supported.");
    store.dispatch({
      type: app.actions.UPDATE_SERVICE_WORKER_UPDATE_STATUS,
      value: appVersionStatus.LATEST
    });
    return;
  }

  if(!swController) {
    sw.addEventListener('controllerchange', async() => {
      await isServiceWorkerActivated();
      store.dispatch({
        type: app.actions.UPDATE_SERVICE_WORKER_UPDATE_STATUS,
        value: appVersionStatus.LATEST
      });
    });
    return;
  }
  
  await isServiceWorkerActivated();
  store.dispatch({
    type: app.actions.UPDATE_SERVICE_WORKER_UPDATE_STATUS,
    value: appVersionStatus.LATEST
  });
}

export const installWithLatestUpdateChecker = async (elMessage, options) => {
  const iOSDevice = options && options.iOSDevice || false;
  const isSafari = options && options.isSafari || false;
  const fsLatestVersionUpdateChecker = new FirestoreLatestVersionUpdateChecker({
    latestVersionDocumentPath: app.selectors.config(store.getState()).serviceWorker.latestVersionDocumentPath,
    latestVersionField: app.selectors.config(store.getState()).serviceWorker.latestVersionField,
    curVersion:app.selectors.currentVersion(store.getState())
  });

  const deviceId = device.selectors.getId();
  installWorkbox({
    url: '/service-worker.js',
    confirmUpdate: (latestVersion) => {
      return confirmUpdate({ elMessage, latestVersion });
    },
    getRedirectURL: () => {
      return iOSDevice || isSafari ? `${window.K.config.webAppBaseUrl}/en/redirect.html?redirect=${encodeURIComponent(window.location.href)}&delay=3000` : '';
    },
    getAnalytics: () => {
      return amplitude;
    },
    getDeviceId: () => {
      return deviceId;
    },
    updateChecker: fsLatestVersionUpdateChecker
  });

  //Update application status is LATEST 
  updateAppVersionsLatestStatus();
}
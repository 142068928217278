import isEmpty from 'lodash-es/isEmpty';
import forEach from 'lodash-es/forEach';
import cloneDeep from 'lodash-es/cloneDeep';
import sortBy from 'lodash-es/sortBy';
import findIndex from 'lodash-es/findIndex';
import find from 'lodash-es/find';
import get from 'lodash-es/get';
import moment from 'moment/src/moment';
import head from 'lodash-es/head';
import trim from 'lodash-es/trim';
import without from 'lodash-es/without';
import uniq from 'lodash-es/uniq';
import * as webUtils from '@dreamworld/web-util';
import { diffLines, diffWords, diffChars } from 'diff';
import { createSelector } from 'reselect';
import { Timestamp } from "firebase/firestore";

/**
 * Set focus to end of text
 * @param { Element } ele - Javascript element
 */
export const setFocusToEnd = (ele) => {
  if (typeof ele.selectionStart == "number") {
    ele.selectionStart = ele.selectionEnd = ele.value.length;
    ele.focus();
  } else if (typeof ele.createTextRange != "undefined") {
    ele.focus();
    let range = ele.createTextRange();
    range.collapse(false);
    range.select();
  }
}

/**
 * Validates email address.
 * @param {String} email Email address
 */
export const validateEmail = (email) => {
  var re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
};

/**
 * Get generated new uuid.
 * @public
 */
export const uuid = () => {
  let d = new Date().getTime();
  let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = Math.random() * 16;

    if(d > 0){
        r = (d + r)%16 | 0;
        d = Math.floor(d/16);
    } else {
        r = (d2 + r)%16 | 0;
        d2 = Math.floor(d2/16);
    }

    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

/**
 * @param {String} cname - Give cookie name to return a cookie value
 * @returns {String} cookie value.
 */
export const getCookieVal = (cname) => {
  if (!cname) {
    return;
  }

  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document && document.cookie || '');
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return;
}

/**
 * Set cookie value.
 */
export const setCookieVal = (cname, cvalue, domain, expiresDays) => {
  if (!cname) {
    return;
  }

  const d = new Date();
  expiresDays = expiresDays || 30;
  d.setTime(d.getTime() + (expiresDays*24*60*60*1000));
  const expires = `expires=${d.toUTCString()}`;
  const domainStr = domain || window.location.host;
  document.cookie = `${cname}=${cvalue};path=/;domain=${domainStr};${expires}`;
}

/**
 * @param {Object} e passed event object of click.
 * @returns {String} if click is triggered from a tag then return url of a tag.
 */
export const findUrl = (e) => {
  let aPath = e.composedPath && e.composedPath() || e.path || [];
  let sNewUrl;
  if(!isEmpty(aPath)) {
    forEach(aPath, function(el) {
      if(el.tagName === 'A') {
        sNewUrl = el.href;
        return false;
      }
    });
  }

  let el = e.srcElement || e.target;
  if(!sNewUrl && el && el.tagName === 'A') {
    sNewUrl = el.href;
  }

  return sNewUrl;
}

/**
 * @return Time zone id e.g. IST|Indian Standard Time for India
 */
export const getTimezoneID = () => {
  let date = new Date();
  let dateStr = date.toString().trim();
  let timezoneId = "";
  let startIndex = dateStr.lastIndexOf('(');
  if (startIndex > 0)
      timezoneId = dateStr.substring(startIndex + 1, dateStr.lastIndexOf(')'));

  return timezoneId;
}

/**
 * Updates order of the moved items based on it's before/after items.
 * @param {Array} allItems all items of the list where item will be moved.
 * @param {Array} movedItems List of items which is moved by the user.
 * @param {String} before Id of the item before which moved items will be placed. If it's not given, items will be placed at bottom of the list.
 * @param {String} sortByKey key name by which items are sorted. It's optional. Default is 'order'
 * @returns {Array} Moved items with it's updated order.
 */
export const getNewOrder = ({ allItems, movedItems, before, sortByKey = 'order', ignoreAllItemsMove = false }) => {
  if (!allItems || isEmpty(movedItems)) {
    console.warn('getNewOrder: Mandatory fields are not provided', { allItems, movedItems });
    return;
  }

  if (!Array.isArray(allItems) || !Array.isArray(movedItems)) {
    console.warn('getNewOrder: Please provide valid array of allItems & movedItems', { allItems, movedItems });
    return;
  }

	// Clones items before mutations.
  let allItemsClone = cloneDeep(allItems);
  let movedItemsClone = cloneDeep(movedItems);
  allItemsClone = sortBy(allItemsClone, sortByKey);
	movedItemsClone = sortBy(movedItemsClone, sortByKey);

  // Removes moved items from the list.
  forEach(movedItemsClone, (item) => {
    const removeIndex = findIndex(allItemsClone, { id: item.id });
    if (removeIndex >= 0) {
      allItemsClone.splice(removeIndex, 1);
    }
  })

  // Inserts moved items at `before` index.
  const beforeIndex = before && find(allItemsClone, {id: before}) ? findIndex(allItemsClone, { id: before }) : undefined;
  const beforeItem = beforeIndex !== undefined ? get(allItemsClone, beforeIndex) : undefined;
  const beforeOrder = get(beforeItem, sortByKey);
  const afterIndex = beforeIndex === undefined ? (allItemsClone.length - 1) : beforeIndex ? beforeIndex - 1 : undefined;
  const afterItem = afterIndex !== undefined ? get(allItemsClone, afterIndex) : undefined;
  const afterOrder = get(afterItem, sortByKey);

  let orderDiffBetweenEachMovedItem;
  let startOrder;
  if(beforeOrder !== undefined && beforeOrder !== null && afterOrder !== undefined && afterOrder !== null){ // When item(s) moved between the list items.
    const median = Math.floor((beforeOrder + afterOrder) / 2);
    if (median > (afterOrder + movedItemsClone.length)) {
      orderDiffBetweenEachMovedItem = Math.floor((beforeOrder - afterOrder) / (movedItemsClone.length + 1));
      startOrder = afterOrder + orderDiffBetweenEachMovedItem;
    }
  } else if (beforeOrder !== undefined && beforeOrder !== null) { // When item(s) moved at top of the list.
    orderDiffBetweenEachMovedItem = 1000;
    startOrder = beforeOrder - (1000 * (movedItemsClone.length));
  } else if (afterOrder !== undefined && afterOrder !== null) {  // When item(s) moved at the bottom of the list.
    orderDiffBetweenEachMovedItem = 1000;
    startOrder = afterOrder + orderDiffBetweenEachMovedItem;
  }

  // Updates order of only moved items.
  if ((orderDiffBetweenEachMovedItem !== undefined && startOrder !== undefined) || ignoreAllItemsMove) {
    orderDiffBetweenEachMovedItem = orderDiffBetweenEachMovedItem || 1000;
    if(startOrder === undefined || startOrder === null) {
      const first = head(allItemsClone);
      const order = first && first[sortByKey] || 0;
      startOrder = order - 1000;
    }
    forEach(movedItemsClone, (item) => {
      item[sortByKey] = startOrder;
      startOrder = startOrder + orderDiffBetweenEachMovedItem
    })
    return movedItemsClone;
  }

  // Inserts moved items & updates order of all the items.
  allItemsClone.splice(beforeIndex, 0, ...movedItemsClone);
  let i = 0;
  forEach(allItemsClone, (item) => {
    item[sortByKey] = i;
    i = i + 1000;
  })

  return allItemsClone;
}


/**
 * @returns {Number} today timestamp.
 */
export const getTodayTime = () => {
  return window.parseInt(moment().endOf('day').format('x')) || null;
}

/**
 * @returns {Number} tommorrow timestamp
 */
export const getTomorrowTime = () => {
  return window.parseInt(moment().add(1, 'day').endOf('day').format('x')) || null;
}

/**
 * @returns {Number} End of this week timestamp.
 */
export const getEndOfThisWeekTime = () => {
  return window.parseInt(moment().add(1, 'week').startOf('week').endOf('day').format('x')) || null;
}

/**
 * @returns {Number} End of next week timestamp.
 */
export const getEndOfNextWeekTime = () => {
  return window.parseInt(moment().add(2, 'week').startOf('week').endOf('day').format('x')) || null;
}

/**
 * @returns {Number} End of this month timestamp.
 */
export const getEndOfThisMonthTime = () => {
  return window.parseInt(moment().endOf('month').format('x')) || null;
}

/**
 * @returns {Number} End of next month timestamp.
 */
export const getEndOfNextMonthTime = () => {
  return window.parseInt(moment().add(1, 'month').endOf('month').format('x')) || null;
}

/**
 * Opens virtual keyboard.
 */
export const openVirtualKeyboard = () => {
  webUtils.openVirtualKeyboard();
}

/**
 * Returs a Promise which is resolved when the entry animation for the element is completed.
 * - ELement is identified from the event object (e.g. e.target)
 * - If the element has implemented animation (e.g. dw-icon-button), then returned Promise is resolved
 *   only after the animation is completed.
 * - In case, element hasn't implemented animation, then Promise is resolved immediately.
 *
 * Future Enhancement:
 * - Argument could be either an element or an event.
 * - Animatable element is searched from the event propagation path
 *
 * @param {Object} e An Event
 * @returns {Promise}
 */
export const waitForEntryAnimation = (e) => {
  if(e.target && e.target.waitForEntryAnimation) {
    return e.target.waitForEntryAnimation
  }
  return Promise.resolve();
}

/**
 * Checks if `value` is in `collection` or not.
 * @param {Array|Object|string} collection
 * @param {Array|Object|string} value
 * @returns {Boolean} if value is in collection then true, otherwise `false`.
 */
export const includes = (collection, value) => {
  if(typeof value === 'object') {
    let match = false;
    forEach(value, (word) => {
      if(collection.includes(word)) {
        match = true;
        return false;
      }
    });
    return match;
  }

  return collection.includes(value);
}

/**
 * @param {Number} timestamp - Passed time stamp value.
 * @param {String} format - Passed date format(optional). When not passed `format` arguments then default format use `'MMMM DD, YYYY h:mm a'`.
 * @return {String} - Return Date based on `timestamp` and `format` argument using moment library
 */
export const getFormatDate = (timestamp, format) => {
  if(!timestamp) {
    return '';
  }
  if(!format){
    format = 'MMMM DD, YYYY';
  }
  return moment(timestamp).format(format);
}

/**
 * Please see [details](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString)
 * @return {String} language-sensitive representation of this number.
 */
export const getLocaleString = (number, lang = 'en', minimumFractionDigits = 2, maximumFractionDigits = 2) => {
  if(!number){
    return (0).toLocaleString(lang, { minimumFractionDigits, maximumFractionDigits });
  }
  return number.toLocaleString(lang, {maximumFractionDigits, minimumFractionDigits});
}

/**
 * @param {String} value
 * @returns {Boolean} given value is hash number e.g. `#123`.
 */
export const isHashNumber = (value) => {
  return value && /^\#\s{0,}[0-9]+$/.test(value);
}

/**
 * @param {String} value
 * @returns {String} remove empty spaces from string.
 *                   e.g. 1. input: ` #123 ` output: `#123`
 *                        2. input: `#   123` output: `#123`.
 */
export const removeSpaces = (value) => {
  return value ? value.replace(/\s/g, '') : '';
}

/**
 * @param{}
 *  @property {String} id Entity Id.
 *  @property {String} prefix Prefix which should be removed.
 * @returns {String} uuid without prefix.
 */
export const getIdWoPrefix = createSelector(
  ({id, prefix}) => id,
  ({id, prefix}) => prefix,
  (id, prefix)=> {
    return id && id.replace(`${prefix}`, '') || id;
  },
  {
    memoizeOptions: {
      maxSize: 5000
    }
  }
)

/**
 * @params {*}
 *  @property {String} oldString Old string
 *  @property {String} newString New String
 *  @property {String} by Difference by. Possible values: 'CHARS', 'WORD', 'LINE'. Default is 'WORD'.
 */
export const getTextDifference = createSelector(
  ({ oldString, newString, by }) => oldString,
  ({ oldString, newString, by }) => newString,
  ({ oldString, newString, by }) => by,
  (oldString, newString, by)=> {
    oldString = oldString || '';
    newString = newString || '';
    by = by || 'WORD';

    let diff;
    if (by === 'LINE') {
      diff = diffLines(oldString, newString);
    } else if (by === 'CHAR') {
      diff = diffChars(oldString, newString);
    } else {
      diff = diffWords(oldString, newString);
    }

    const preEl = document.createElement('pre');
    const fragment = document.createDocumentFragment();
    const space = document.createTextNode(' ')
    diff.forEach((part) => {
      const el = part.added ? document.createElement('ins') :
                part.removed ? document.createElement('del') :
          document.createElement('span');
      el.appendChild(space);
      el.appendChild(document.createTextNode(part.value));
      fragment.appendChild(el);
    });

    preEl.appendChild(fragment);
    return preEl.innerHTML;
  },
  {
    memoizeOptions: {
      maxSize: 200
    }
  }
);

/**
 * @returns {Number} convert firestore date to js timestamp.
 */
export const convertFirestoreTimestamp = (seconds, nanoseconds) => {
  try {
    if(!nanoseconds) {
      return seconds * 1000;
    }
    const __timestamp = new Timestamp(seconds, nanoseconds);
    return new Date(__timestamp.toDate()).getTime();
  } catch (error) {
    return seconds * 1000;
  }
}

/**
 * @param {String} email multiple emails, seprated by comma(,), space, or enter(\n).
 * @returns {Array} emails array.
 */
export const getEmails = (email) => {
  email = trim(email);
  let emails = email.split(/(?:,| |\n)+/);
  emails = without(emails, '');
  emails = uniq(emails);
  return emails || [];
}

/**
 * Validate multiple emails.
 */
export const validateEmails = (emails) => {
  emails = typeof emails === 'string' ? getEmails(emails) : emails || '';
  if (isEmpty(emails)) {
    return false;
  }

  let allValidEmails = true;
  forEach(emails, (email) => {
    if (!validateEmail(email)) {
      allValidEmails = false;
      return false;
    }
  });
  return allValidEmails;
};

/*
 * @returns {String} language code before `_`.
 */
export const getLanguageCode = (code) => {
  return code && code.split('_')[0] || '';
}
import { sagaMiddleware } from '../../store';
import saga from './saga';

import * as _selectors from './selectors.js';
import * as _actions from './actions.js';

sagaMiddleware.run(saga);

export const selectors = _selectors;
export const actions = _actions;

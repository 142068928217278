import { takeEvery, all, call, select, put, take, fork, cancel, debounce } from 'redux-saga/effects';
import * as app from '../app';
import * as auth from '../auth';
import * as router from '../router/index.js';
import * as subscription from '../subscription';
import firestoreRedux from '@dreamworld/firestore-redux';
import * as actions from './actions.js';
import * as selectors from './selectors.js';
import * as knownFeatures from '../known-features';
import { requestApi, isNetworkError } from "../../request-api.js"
import { show as showToast } from '../../components/kerika-snackbar.js';
import i18next from '@dw/i18next-esm/index.js';
import get from 'lodash-es/get.js';
import isEqual from 'lodash-es/isEqual.js';
import forEach from 'lodash-es/forEach.js';
import isEmpty from 'lodash-es/isEmpty.js';
import merge from 'lodash-es/merge.js';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import values from 'lodash-es/values';
import * as firebaseRedux from '@dw/firebase-redux';
import { getIdWoPrefix } from '../../utils';
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';

import { store } from '../../store';

/**
 * Use this to stop live listening to the data of the manage account from firestore when the user leaves the page.
 */
let lastOpenedAccountId;

/**
 * Sends server request to update account settings.
 * API Path: /account/accounts/settings/{$accountId},  method: PUT, body: { "boardPrivacy": 1, "useWipLimit": false, "convertGdocs": false }
 * @param {Object} action action payload.
 */
function* updateSettings(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  requestApi(`/account/accounts/settings/${accountId}`, { method: 'PUT', body: action.settings });
}

/**
 * Sends server request to add new members in account team.
 * API: POST /account/accounts/{$accountId}/team
 * @param {Object} action action payload.
 */
function* addMembers(action) {
  const state = yield select();
  const emails = action.emails || [];
  try {
    yield requestApi(`/account/accounts/${action.accountId}/team`, { method: 'POST', body: {emails, role: action.role}, excludeErrors: [409, 400] });
    if(emails && emails.length > 1) {
       emails[emails.length - 1] = i18next.t('seprated.and') + ' ' + emails[emails.length - 1];
      const emailString = emails.join(', ');
      showToast({ message: i18next.t('manage-account:manageUsers.accountTeam.addMemberDialog.toast.emails.success.multiple', { emails: emailString, role: i18next.t(`roles.${action.role}`) })});
   } else {
     showToast({ message: i18next.t('manage-account:manageUsers.accountTeam.addMemberDialog.toast.emails.success.single', {email: emails[0], role: i18next.t(`roles.${action.role}`) }) });
   }
    yield put({ type: actions.TEAM_ADD_MEMBERS_DONE, accountId: action.accountId, emails, role: action.role });
  } catch (error) {
    yield put({ type: actions.TEAM_ADD_MEMBERS_FAILED, accountId: action.accountId, emails, role: action.role });

    if(error && error.code === 'OWNER_NOT_ALLOWED') {
      showToast({ type: 'ERROR', message: i18next.t('manage-account:manageUsers.accountTeam.addMemberDialog.toast.ownerNotAllowed')});
    }

    if(error && error.code === 'MEMBER_ALREADY_EXISTS') {
      if(emails && emails.length > 1) {
         showToast({ type: 'ERROR', message: i18next.t('manage-account:manageUsers.accountTeam.addMemberDialog.toast.emails.alreadyInTeam.multiple')});
      } else {
        showToast({ type: 'ERROR', message: i18next.t('manage-account:manageUsers.accountTeam.addMemberDialog.toast.emails.alreadyInTeam.single',{email: emails[0]})});
      }
    }

    if (error && error.code === 'NO_SUBSCRIPTION_AVAILABLE' && !app.selectors.isInstalledApp(state)) {
      store.dispatch(subscription.actions.updatePendingActionData({ accountId: action.accountId, role: action.role, emails, action: 'add', type: 'account-team'}));
      yield call(router.actions.removeActionParam, 'add-member');
      yield call(router.actions.openManageTeamConfirmDialog, 'add', 'account-team', emails.length || 1);
    }
  }
}

/**
 * Sends server request to make admin for given member in givne account.
 * @param {Object} action action payload.
 */
function* makeAdmin(action) {
  const state = yield select();
  const accountId = action.accountId;
  const memberId = action.memberId;
  const allAccTeamMembers = firestoreRedux.selectors.collection(state, 'account-team-members');
  const currentMemberDoc = find(allAccTeamMembers, { accountId, userId: memberId });
  const newMemberDoc = merge({}, currentMemberDoc, {role: 'ACCOUNT_ADMIN'});
  const localWritePath = `accounts/${accountId}/account-team-members`;
  try {
    firestoreRedux.save(localWritePath, newMemberDoc, { localWrite: true });
    yield call(changeRole, accountId, memberId, 'ACCOUNT_ADMIN');
    yield put({ type: actions.MAKE_ADMIN_DONE, accountId, memberId });
    const memberDetails = selectors.memberDetails({state, accountId, memberId});
    const name = memberDetails && (memberDetails.name || memberDetails.email) || '';
    showToast({message: i18next.t('manage-account:manageUsers.accountTeam.item.toast.makeAdmin', { name })});
  } catch (error) {
    firestoreRedux.save(localWritePath, currentMemberDoc, { localWrite: true });
    yield put({ type: actions.MAKE_ADMIN_FAILED, accountId, memberId, error });
  }
}

/**
 * Sends server request to remove as an admin for given member in givne account.
 * @param {Object} action action payload.
 */
function* removeAdmin(action) {
  const state = yield select();
  const accountId = action.accountId;
  const memberId = action.memberId;
  const allAccTeamMembers = firestoreRedux.selectors.collection(state, 'account-team-members');
  const currentMemberDoc = find(allAccTeamMembers, { accountId, userId: memberId });
  const newMemberDoc = merge({}, currentMemberDoc, {role: 'TEAM_MEMBER'});
  const localWritePath = `accounts/${accountId}/account-team-members`;
  try {
    firestoreRedux.save(localWritePath, newMemberDoc, { localWrite: true });
    yield call(changeRole, accountId, memberId, 'TEAM_MEMBER');
    yield put({ type: actions.REMOVE_ADMIN_DONE, accountId, memberId });
    const memberDetails = selectors.memberDetails({state, accountId, memberId});
    const name = memberDetails && (memberDetails.name || memberDetails.email) || '';
    showToast({message: i18next.t('manage-account:manageUsers.accountTeam.item.toast.removeAdmin', { name })});
  } catch (error) {
    firestoreRedux.save(localWritePath, currentMemberDoc, { localWrite: true });
    yield put({ type: actions.REMOVE_ADMIN_FAILED, accountId, memberId, error });
  }
}

/**
 * Sends requests server to upgrade member role.
 * @param {Object} action action payload.
 */
function* upgradeRole(action) {
  const state = yield select();
  const accountId = action.accountId;
  const memberId = action.memberId;
  const allAccTeamMembers = firestoreRedux.selectors.collection(state, 'account-team-members');
  const currentMemberDoc = find(allAccTeamMembers, { accountId, userId: memberId });
  const newMemberDoc = merge({}, currentMemberDoc, {role: 'TEAM_MEMBER'});
  const localWritePath = `accounts/${accountId}/account-team-members`;
  try {
    firestoreRedux.save(localWritePath, newMemberDoc, { localWrite: true });
    yield call(changeRole, accountId, memberId, 'TEAM_MEMBER');
    yield put({ type: actions.UPGRADE_ROLE_DONE, accountId, memberId });
    const memberDetails = selectors.memberDetails({state, accountId, memberId});
    const name = memberDetails && (memberDetails.name || memberDetails.email) || '';
    showToast({
      message: i18next.t('manage-account:manageUsers.accountTeam.item.toast.upgrade', { name }), actionButton: {
        caption: i18next.t('buttons.undo'),
        callback: () => {changeRole(accountId, memberId, 'VISITOR')}
      }
    });
  } catch (error) {
    firestoreRedux.save(localWritePath, currentMemberDoc, { localWrite: true });
    yield put({ type: actions.UPGRADE_ROLE_FAILED, accountId, memberId, error });
    if (error && error.code === 'NO_SUBSCRIPTION_AVAILABLE' && !app.selectors.isInstalledApp(state)) {
      store.dispatch(subscription.actions.updatePendingActionData({ accountId: action.accountId, memberId: action.memberId, action: 'upgrade', type: 'account-team'}));

      yield call(router.actions.openManageTeamConfirmDialog, 'upgrade', 'account-team');
    }
  }
}

/**
 * Change given role of given member for given account.
 * API: POST /account/accounts/${accountId}/team/${memberId}/role
 * @param {Number} accountId
 * @param {Number} memberId
 * @param {String} role
 */
function changeRole(accountId, memberId, role) {
  return requestApi(`/account/accounts/${accountId}/team/${memberId}/role`, { method: 'PUT', body: {role}, excludeErrors: [409] });
}

/**
 * Sends requests server to downgrade member role.
 * @param {Object} action action payload.
 */
function* downgradeRole(action) {
  const state = yield select();
  const accountId = action.accountId;
  const memberId = action.memberId;
  const allAccTeamMembers = firestoreRedux.selectors.collection(state, 'account-team-members');
  const currentMemberDoc = find(allAccTeamMembers, { accountId, userId: memberId });
  const newMemberDoc = merge({}, currentMemberDoc, {role: 'VISITOR'});
  const localWritePath = `accounts/${accountId}/account-team-members`;
  try {
    firestoreRedux.save(localWritePath, newMemberDoc, { localWrite: true });
    yield call(changeRole, accountId, memberId, 'VISITOR');
    yield put({ type: actions.DOWNGRADE_ROLE_DONE, accountId, memberId });
    const memberDetails = selectors.memberDetails({state, accountId, memberId});
    const name = memberDetails && (memberDetails.name || memberDetails.email) || '';
    showToast({
      message: i18next.t('manage-account:manageUsers.accountTeam.item.toast.downgrade', { name })
    });
  } catch (error) {
    firestoreRedux.save(localWritePath, currentMemberDoc, { localWrite: true });
    yield put({ type: actions.DOWNGRADE_ROLE_FAILED, accountId, memberId, error });
  }
}

/**
 * Sends requests server to remove a member.
 * API: DELETE /account/accounts/${accountId}/team/${memberId}.
 * @param {Object} action action payload.
 */
function* removeMember(action) {
  const state = yield select();
  const accountId = action.accountId;
  const memberId = action.memberId;
  const allAccTeamMembers = firestoreRedux.selectors.collection(state, 'account-team-members');
  const currentMemberDoc = find(allAccTeamMembers, { accountId, userId: memberId });
  const localWritePath = `accounts/${accountId}/account-team-members`;
  try {
    firestoreRedux.delete(localWritePath, currentMemberDoc.id, { localWrite: true });
    yield call(requestApi, `/account/accounts/${accountId}/team/${memberId}`, { method: 'DELETE' });
    yield put({ type: actions.MEMBER_REMOVE_DONE, accountId, memberId });
  } catch (error) {
    firestoreRedux.save(localWritePath, currentMemberDoc, { localWrite: true });
    yield put({ type: actions.MEMBER_REMOVE_FAILED, accountId, memberId, error });
  }
}

/**
 * Sends server request to update account name.
 * API: PUT /account/accounts/{$accountId}
 * @param {Object} action action payload.
 */
function* rename(action) {
  const state = yield select();
  const accountId = action.accountId;
  const localWritePath = `accounts`;
  const currentDoc = firestoreRedux.selectors.doc(state, 'accounts', accountId);
  const newDoc = merge({}, currentDoc, {name: action.name});
  try {
    firestoreRedux.save(localWritePath, newDoc, { localWrite: true });
    yield requestApi(`/account/accounts/${accountId}`, { method: 'PATCH', body: {name: action.name} });
    yield put({ type: actions.RENAME_DONE, accountId, name: action.name });
  } catch (error) {
    firestoreRedux.save(localWritePath, currentDoc, { localWrite: true });
    yield put({ type: actions.RENAME_FAILED, accountId, name: action.name, error });
  }
}

let previousPage;
let previousTab;
let previousMemberAction;
function* routeChangeHandler() {
  const state = yield select();
  const page = router.selectors.page(state);
  const currentPage = router.selectors.pageName(state);
  const currentTab = get(page, 'params.tab');
  const currentMemberAction = get(page, 'params.member-action');
  if (currentPage === 'MANAGE_ACCOUNT') {
    if (previousPage !== currentPage) {
      yield put({ type: actions.PAGE_OPENED });
    } else if(currentTab !== previousTab) {
      yield put({ type: actions.TAB_CHANGED });
    }

    if(previousMemberAction !== currentMemberAction && (currentMemberAction === 'make-admin' || currentMemberAction === 'remove-admin'|| currentMemberAction === 'view' || currentMemberAction === 'downgrade' || currentMemberAction === 'remove')) {
      yield put({ type: actions.PARTICIPATED_BOARDS_DATA_LOAD, accountId: router.selectors.accountId(state), memberId: get(page, 'params.id')});
    }

  } else if (previousPage === 'MANAGE_ACCOUNT') {
    yield put({ type: actions.PAGE_CLOSED });
  }
  previousPage = router.selectors.pageName(state);
  previousTab = get(page, 'params.tab');
  previousMemberAction = get(page, 'params.member-action');
}

/**
 * Invoked when account team is changed.
 */
function* teamChanged(action) {
  const state = yield select();
  if(selectors.isAccountAdmin({state})) {
    yield call(loadManageAccountData);
  }
}

/**
 * Load manage account data based on currently opened tab.
 */
function* loadManageAccountData(action) {
  const state = yield select();
  const page = router.selectors.page(state);
  const tab = get(page, 'params.tab');
  const accountId = router.selectors.accountId(state);
  if(lastOpenedAccountId != accountId) {
    if(lastOpenedAccountId) {
      yield call(disconnectManageAccountData, lastOpenedAccountId);
      yield call(disconnectSubscriptionsDetails, lastOpenedAccountId);
    }
    lastOpenedAccountId = accountId;
  }

  if(tab === 'settings') {
    yield call(loadAccountSettingsData);
  } else {
    yield call(loadAccountSummaryData);
  }

  yield call(loadManageUsersData, tab);
}

/**
 * Load participated boards details of given member for given acocount in action payload.
 * @param {Object} action action payload
 */
function* loadParticipatedBoards(action) {
  const accountId = action.accountId;
  const memberId = action.memberId;
  try {
    const response = yield call(requestApi, `/board/user-shared-boards/${accountId}/${memberId}`, { excludeErrors: [404] });
    let participatedBoards = {};
    if(!isEmpty(response)) {
      forEach(response, (board)=> {
        if(board) {
          const lastUpdated  = board.lastUpdated || 0
          participatedBoards[board.id] = {...board, ...{lastUpdated}};
        }
      });
    }
    yield put({ type: actions.PARTICIPATED_BOARDS_DATA_LOADED, accountId, memberId, data: participatedBoards});
  } catch (error) {
    if(error && error.status == 404) {
      router.actions.setQueryParams({'member-action': null, id: null});
      return;
    }
    console.error("Load participated boards details failed due to: ", error);
  }
}

/**
 * Loads `account-settings` data from firebase.
 * Path: /account-settings/{accountId}
 */
function* loadAccountSettingsData() {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  const userId = auth.selectors.currentUserId(state);
  const woPrefixAccountId = getIdWoPrefix({ id: accountId, prefix: 'acc_' });
  firestoreRedux.getDocById('account-settings', `as_${woPrefixAccountId}`, { requesterId: `manage-account-${accountId}`});
  firestoreRedux.getDocById(`accounts/${accountId}/account-cloud-stores`, `acs_${woPrefixAccountId}`, { requesterId: `manage-account-${accountId}` });
  firestoreRedux.getDocById(`users/${userId}/cloud-store-emails`, `cse_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`, { requesterId: `manage-account-${accountId}` });
  firestoreRedux.query('user-cloud-stores', { where: [['userId', '==', userId]], requesterId: `manage-account-${accountId}` });
}

/**
 * Loads account team details from firestore.
 * Load a member participated boards counts
 *  - through API `GET /board/user-wise-boards-count/${accountId}`
 */
function* loadManageUsersData(tab) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  try {
    yield put({ type: actions.TEAM_DATA_LOAD, accountId });
    const membersQuery = firestoreRedux.query('account-team-members', { id: `account-team-members-${accountId}`, where: [['accountId', '==', accountId]], requesterId: `manage-account-${accountId}` });
    let members = yield membersQuery.result;
    members = isEmpty(members) ? []: members;

    for (let i = 0; i < members.length; i++) {
      const member = members[i];
      const userId = member && member.userId;
      if(userId) {
        try {
          const userDoc = firestoreRedux.getDocById('users', userId, { requesterId: `manage-account-${accountId}` });
          const userlastseenDoc = firestoreRedux.getDocById(`users/${userId}/lastseen-details`, `ulsd_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`, { requesterId: `manage-account-${accountId}` });
          yield userDoc.result;
          yield userlastseenDoc.result;
        } catch (error) {
          console.error("Loads account team details is failed due to this: ", error);
        }
      }
    }
    
    let response = {};
    if(tab === 'users') {
      response = yield call(requestApi, `/board/user-wise-boards-count/${accountId}`, { method: 'GET'});
    }
    yield put({ type: actions.TEAM_DATA_LOADED, accountId, data: response});
  } catch (error) {
    console.error("Loads account team details is failed due to this: ", error);
  }
}

/**
 * Loads account basic details from firebase.
 * Path: /accounts/${accountId}/attrs
 */
function* loadAccountSummaryData() {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  const query = firestoreRedux.getDocById('accounts', accountId, { requesterId: `manage-account-${accountId}` });
  const attrs = yield query.result;

  // Load account owner details.
  if(attrs && attrs.ownerId) {
    firestoreRedux.getDocById('users', attrs.ownerId, { requesterId: `manage-account-${accountId}` });
  }
}

/**
 * Checks whether current user's domain is puclic or not.
 */
function* checkForPublicDomain() {
  const state = yield select();
  try {
    const currentUserDomains = auth.selectors.currentUserDomains(state);
    const response = yield call(requestApi, `/subscription/public-domains/is-public?domains=${currentUserDomains}`);
    yield put(subscription.actions.updatePublicDomain(response.publicDomain));
  } catch (error) {
    if(isNetworkError(error)) {
      return;
    }
    console.error('check for public domain is failed due to this: ', error);
  }
}

/**
 * Loads account team details from firestore.
 * Path: /kerika-accounts/accounts/${accountId}/team
 */
function* loadAccountTeam({requesterId}) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  firestoreRedux.query('account-team-members', { id: `account-team-members-${accountId}` , where: [['accountId', '==', accountId]], requesterId });
}

/**
 * Stop live listening from firestore for account team.
 */
function* disconnectAccountTeam({requesterId}) {
  firestoreRedux.cancelQueryByRequester(requesterId);
}

/**
 * Disconnects `manage account data` from firestore.
 */
function* disconnectManageAccountData(accountId) {
  const state = yield select();
  accountId = accountId || lastOpenedAccountId || router.selectors.accountId(state);
  firestoreRedux.cancelQueryByRequester(`manage-account-${accountId}`);
}

/**
 * Invite members into given account.
 */
function* inviteMembers(action) {
  const state = yield select();
  const accountId = action.accountId;
  const members = action.members;
  try {
    yield put(knownFeatures.actions.markAsKnown('INVITED_TRELLO_TEAM_DESKTOP'));
    forEach(members, (email, id) => {
      const currentMemberDoc = firestoreRedux.selectors.doc(state, 'users', id);
      const newMemberDoc = merge({}, currentMemberDoc, {email});
      firestoreRedux.save('users', newMemberDoc, { localWrite: true });
    });
    yield call(requestApi, `/account/accounts/${accountId}/invite-uninvited-member`, { method: 'POST', body: members });
    const membersModel = Object.values(members);
    const email = membersModel && membersModel[0] || '';
    const isSingleUSer = membersModel && membersModel.length === 1;
    showToast({ message: i18next.t(isSingleUSer ? 'manage-account:inviteMembersDialog.toast.success.single': 'manage-account:inviteMembersDialog.toast.success.multiple', { email }) });
    yield put(actions._inviteMembersDone(accountId, members));
  } catch (error) {
    const code = error && error.code;
    yield put(actions._inviteMembersFailed(accountId, members, code));
    if (isNetworkError(error)) {
      return;
    }

    //Remove users email local writes.
    forEach(members, (email, id) => {
      const currentMemberDoc = firestoreRedux.selectors.doc(state, 'users', id);
      firestoreRedux.save('users', currentMemberDoc, { localWrite: true });
    });
    console.error("invite members is failed due to this: ", error);
  }
}

/**
 * Resend invitation to given members for given account.
 */
function* resendInvitation(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const members = action.members || [];
  if(isEmpty(members)) {
    console.warn('Resend account invitation > empty members passed.');
    return;
  }

  try {
    yield call(requestApi, `/account/accounts/${accountId}/resend-invitation`, { method: 'POST', body: { userIds: members } });
    showToast({message: i18next.t('manage-account:manageUsers.accountTeam.item.toast.resendInvitation')});
    yield put(actions._resendInvitationSuccess(accountId, members));
  } catch (error) {
    const code = error && error.code || 'UNKNOWN';
    yield put(actions._resendInvitationFailed(accountId, members, code));
    if(isNetworkError(error)){
      return;
    }
    console.error('Resend account invitation > failed due to this: ', error);
  }
}

/**
 * Watch router change.
 * Dispatch PAGE_OPENED, PAGE_CLOSED or TAB_CHANGED actions based on previous & current URL.
 */
function* watchRouter() {
  //If page is already opened, check once.
  yield call(routeChangeHandler);
  yield takeEvery(router.actions.UPDATE_ROUTER, routeChangeHandler);
}

function* loadSubscriptionsDetails(action) {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  const isInstalledApp = app.selectors.isInstalledApp(state);
  if(!isInstalledApp) {
    yield call(startUpdatePendingInvoicesCount);
    yield call(checkForPublicDomain);
    yield call(loadSubscriptionsBillingHistoryDetails);
    yield put(subscription.actions.loadBillingAddress(`manage-account-subscription-billing-address-${accountId}`));
  }
  yield put(subscription.actions.load(`manage-account-subscription-details-${accountId}`));
}

function* loadSubscriptionsBillingHistoryDetails() {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  const page = router.selectors.page(state);
  const tab = get(page, 'params.tab');
  if(tab === 'transactions') {
    yield put(subscription.actions.loadTransactions(`manage-account-subscription-transactions-${accountId}`));
    yield put(subscription.actions.loadInvoices(`manage-account-subscription-invoices-${accountId}`));
  }
}

function* disconnectSubscriptionsDetails(accountId) {
  const state = yield select();
  accountId = accountId || lastOpenedAccountId || router.selectors.accountId(state);
  yield call(stopUpdatePendingInvoicesCount, accountId);
  yield put(subscription.actions.disconnect(`manage-account-subscription-details-${accountId}`));
  yield put(subscription.actions.disconnectBillingAddress(`manage-account-subscription-billing-address-${accountId}`));
  yield put(subscription.actions.disconnectInvoices(`manage-account-subscription-invoices-${accountId}`));
  yield put(subscription.actions.disconnectTransactions(`manage-account-subscription-transactions-${accountId}`));
}

let queryPendingInvoicesUnsubscribe;
function* startUpdatePendingInvoicesCount() {
  try {
    console.log("startUpdatePendingInvoicesCount --> start");
    const state = yield select();
    const accountId = router.selectors.accountId(state);
    firebaseRedux.read(`pending-invoice-count-${accountId}`, [`/subscriptions/${accountId}/invoices`]);
    queryPendingInvoicesUnsubscribe = ReduxUtils.subscribe(store, `firebase.subscriptions.${accountId}.invoices`, (invoices) => {
      invoices = invoices || [];
      const filterInvoices = filter(invoices, {status: 'PENDING'});
      const invoicesModel = values(filterInvoices);
      const count = invoicesModel.length || 0;
      const previousCount = subscription.selectors.pendingInvoicesCount(store.getState());
      console.log("startUpdatePendingInvoicesCount --> invoice updates", {filterInvoices, previousCount, count});
      if(previousCount !== count) {
        store.dispatch(subscription.actions.updatePendingInvoicesCount(count));
      }
    });
  } catch (error) {
    console.error("startUpdatePendingInvoicesCount > failed due to this: ", error);
  }
}

function* stopUpdatePendingInvoicesCount(accountId) {
  try {
    const state = yield select();
    accountId = accountId || lastOpenedAccountId || router.selectors.accountId(state);
    store.dispatch(firebaseRedux.actions.disconnect(`pending-invoice-count-${accountId}`));
    console.log("startUpdatePendingInvoicesCount --> end");
    queryPendingInvoicesUnsubscribe && queryPendingInvoicesUnsubscribe();
    queryPendingInvoicesUnsubscribe = null;
  } catch (error) {
    console.error("stopUpdatePendingInvoicesCount > failed due to this: ", error);
  }
}

/**
 * Keeps watch on the accessible accounts of the current user, when it's changed dispatches
 *  an action `ACCESSIBLE_ACCOUNTS_CHANGED`.
 */
let lastVal;
function* watchAccountTeam() {
  let state = yield select();
  const accountId = router.selectors.accountId(state);
  const allAccTeamMembers = firestoreRedux.selectors.collection(state, 'account-team-members');
  const newVal = filter(allAccTeamMembers, { accountId });
  if (!isEqual(lastVal, newVal)) {
    if(lastVal !== undefined) {
      yield put({ type: actions.TEAM_CHANGED, team: newVal});
    }
    lastVal = newVal;
  }
}

/**
 * Manages load/disconnect account-settings data & update settings.
 */
function* manageAccountFlow() {
  while (yield take(actions.PAGE_OPENED)) {
    const task = yield fork(function* () {
      yield all([
        call(loadManageAccountData),
        call(loadSubscriptionsDetails),
        takeEvery(actions.UPDATE_SETTINGS, updateSettings),
        takeEvery(actions.RENAME, rename),
        takeEvery(actions.TAB_CHANGED, loadManageAccountData),
        takeEvery(actions.TAB_CHANGED, loadSubscriptionsBillingHistoryDetails),
        takeEvery(actions.TEAM_CHANGED, teamChanged),
        takeEvery(actions.TEAM_ADD_MEMBERS, addMembers),
        takeEvery(actions.PARTICIPATED_BOARDS_DATA_LOAD, loadParticipatedBoards),
        takeEvery(actions.UPGRADE_ROLE, upgradeRole),
        takeEvery(actions.DOWNGRADE_ROLE, downgradeRole),
        takeEvery(actions.MEMBER_REMOVE, removeMember),
        takeEvery(router.actions.ACCOUNT_CHANGED, loadSubscriptionsDetails),
        takeEvery(router.actions.ACCOUNT_CHANGED, loadManageAccountData),
        takeEvery(actions.MAKE_ADMIN, makeAdmin),
        takeEvery(actions.REMOVE_ADMIN, removeAdmin)
      ]);
    })
    yield take(actions.PAGE_CLOSED)
    yield call(disconnectManageAccountData);
    yield call(disconnectSubscriptionsDetails);
    yield cancel(task);
  }
}

/**
 * Init Saga.
 */
function* saga() {
  yield all([
    call(watchRouter),
    call(manageAccountFlow),
    debounce(200, firestoreRedux.actions.QUERY_SNAPSHOT, watchAccountTeam),
    takeEvery(actions.TEAM_LOAD, loadAccountTeam),
    takeEvery(actions.TEAM_DISCONNECT, disconnectAccountTeam),
    takeEvery(actions.INVITE_MEMBERS, inviteMembers),
    takeEvery(actions.RESEND_INVITATION, resendInvitation)
  ]);
}

export default saga;
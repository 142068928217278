import * as actions from "./actions";
import { ReduxUtils } from '@dw/pwa-helpers/index.js';
const INITIAL_STATE = {};

export const subscription = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.MESSAGE_MARK_AS_KNOWN:
      state = ReduxUtils.replace(state, `messages.${action.accountId}.${action.name}.knownAt`, Date.now());
      return ReduxUtils.replace(state, `messages.${action.accountId}.${action.name}.known`, true);
    case actions.CHECK_PUBLIC_DOMAIN:
      state = ReduxUtils.replace(state, `publicDomain.status`, 'IN_PROGRESS');
      return ReduxUtils.replace(state, `publicDomain.error`, null);
    case actions.CHECK_PUBLIC_DOMAIN_SUCCESS:
      state = ReduxUtils.replace(state, `publicDomain.status`, 'SUCCESS');
      return ReduxUtils.replace(state, `publicDomain.value`, action.value);
    case actions.CHECK_PUBLIC_DOMAIN_FAILED:
      state = ReduxUtils.replace(state, `publicDomain.status`, 'FAILED');
      return ReduxUtils.replace(state, `publicDomain.error`, action.error);
    case actions.LOAD:
      state = ReduxUtils.replace(state, `${action.accountId}.subscription.status`, 'IN_PROGRESS');
      return ReduxUtils.replace(state, `${action.accountId}.subscription.error`, null);
    case actions.LOAD_SUCCESS:
      state = ReduxUtils.replace(state, `${action.accountId}.subscription.status`, 'SUCCESS');
      return ReduxUtils.replace(state, `${action.accountId}.subscription.data`, action.data);
    case actions.LOAD_FAILED:
      state = ReduxUtils.replace(state, `${action.accountId}.subscription.status`, 'FAILED');
      return ReduxUtils.replace(state, `${action.accountId}.subscription.error`, action.error);
    case actions.LOAD_PRICING_DETAILS:
      state = ReduxUtils.replace(state, `${action.accountId}.pricing.status`, 'IN_PROGRESS');
      return ReduxUtils.replace(state, `${action.accountId}.pricing.error`, null);
    case actions.LOAD_PRICING_DETAILS_SUCCESS:
      state = ReduxUtils.replace(state, `${action.accountId}.pricing.status`, 'SUCCESS');
      return ReduxUtils.replace(state, `${action.accountId}.pricing.data`, action.data);
    case actions.LOAD_PRICING_DETAILS_FAILED:
      state = ReduxUtils.replace(state, `${action.accountId}.pricing.status`, 'FAILED');
      return ReduxUtils.replace(state, `${action.accountId}.pricing.error`, action.error);
    case actions.LOAD_BILLING_DETAILS:
      state = ReduxUtils.replace(state, `${action.accountId}.billingDetails.status`, 'IN_PROGRESS');
      return ReduxUtils.replace(state, `${action.accountId}.billingDetails.error`, null);
    case actions.LOAD_BILLING_DETAILS_SUCCESS:
      state = ReduxUtils.replace(state, `${action.accountId}.billingDetails.status`, 'SUCCESS');
      return ReduxUtils.replace(state, `${action.accountId}.billingDetails.data`, action.data);
    case actions.LOAD_BILLING_DETAILS_FAILED:
      state = ReduxUtils.replace(state, `${action.accountId}.billingDetails.status`, 'FAILED');
      return ReduxUtils.replace(state, `${action.accountId}.billingDetails.error`, action.error);
    case actions.COMPUTE_CHARGES:
      state = ReduxUtils.replace(state, `${action.accountId}.charges.status`, 'IN_PROGRESS');
      state = ReduxUtils.replace(state, `${action.accountId}.charges.priceId`, action.priceId);
      state = ReduxUtils.replace(state, `${action.accountId}.charges.additionalSeats`, action.additionalSeats);
      state = ReduxUtils.replace(state, `${action.accountId}.charges.chargesType`, action.chargesType);
      return ReduxUtils.replace(state, `${action.accountId}.charges.error`, null);
    case actions.COMPUTE_CHARGES_SUCCESS:
      state = ReduxUtils.replace(state, `${action.accountId}.charges.status`, 'SUCCESS');
      return ReduxUtils.replace(state, `${action.accountId}.charges.data`, action.data);
    case actions.COMPUTE_CHARGES_FAILED:
      state = ReduxUtils.replace(state, `${action.accountId}.charges.data`, undefined);
      state = ReduxUtils.replace(state, `${action.accountId}.charges.status`, 'FAILED');
      return ReduxUtils.replace(state, `${action.accountId}.charges.error`, action.error);
    case actions.CHANGE_PLAN:
      state = ReduxUtils.replace(state, `${action.accountId}.changePlan.status`, 'IN_PROGRESS');
      state = ReduxUtils.replace(state, `${action.accountId}.changePlan.priceId`, action.priceId);
      state = ReduxUtils.replace(state, `${action.accountId}.changePlan.additionalSeats`, action.additionalSeats);
      state = ReduxUtils.replace(state, `${action.accountId}.changePlan.autoRenew`, action.autoRenew);
      state = ReduxUtils.replace(state, `${action.accountId}.changePlan.email`, action.email);
      state = ReduxUtils.replace(state, `${action.accountId}.changePlan.actionType`, action.actionType);
      return ReduxUtils.replace(state, `${action.accountId}.changePlan.error`, null);
    case actions.CHANGE_PLAN_SUCCESS:
      state = ReduxUtils.replace(state, `${action.accountId}.changePlan.status`, 'SUCCESS');
      return ReduxUtils.replace(state, `${action.accountId}.changePlan.data`, action.data);
    case actions.CHANGE_PLAN_FAILED:
      state = ReduxUtils.replace(state, `${action.accountId}.changePlan.status`, 'FAILED');
      return ReduxUtils.replace(state, `${action.accountId}.changePlan.error`, action.error);
    case actions.CHANGE_PLAN_CANCEL:
      return ReduxUtils.replace(state, `${action.accountId}.changePlan.status`, 'CANCELLED');
    case actions.CHANGE_AUTO_RENEW:
      state = ReduxUtils.replace(state, `${action.accountId}.changeAutoRenew.status`, 'IN_PROGRESS');
      state = ReduxUtils.replace(state, `${action.accountId}.changeAutoRenew.value`, action.autoRenew);
      return ReduxUtils.replace(state, `${action.accountId}.changeAutoRenew.error`, null);
    case actions.CHANGE_AUTO_RENEW_SUCCESS:
      state = ReduxUtils.replace(state, `${action.accountId}.changeAutoRenew.status`, 'SUCCESS');
      return ReduxUtils.replace(state, `${action.accountId}.changeAutoRenew.data`, action.data);
    case actions.CHANGE_AUTO_RENEW_FAILED:
      state = ReduxUtils.replace(state, `${action.accountId}.changeAutoRenew.status`, 'FAILED');
      return ReduxUtils.replace(state, `${action.accountId}.changeAutoRenew.error`, action.error);
    case actions.SETUP_PAYMENT_METHOD:
      state = ReduxUtils.replace(state, `${action.accountId}.setupPaymentMethod.status`, 'IN_PROGRESS');
      state = ReduxUtils.replace(state, `${action.accountId}.setupPaymentMethod.inSameWindow`, action.inSameWindow);
      return ReduxUtils.replace(state, `${action.accountId}.setupPaymentMethod.error`, null);
    case actions.SETUP_PAYMENT_METHOD_SUCCESS:
      state = ReduxUtils.replace(state, `${action.accountId}.setupPaymentMethod.status`, 'SUCCESS');
      return ReduxUtils.replace(state, `${action.accountId}.setupPaymentMethod.data`, action.data);
    case actions.SETUP_PAYMENT_METHOD_FAILED:
      state = ReduxUtils.replace(state, `${action.accountId}.setupPaymentMethod.status`, 'FAILED');
      return ReduxUtils.replace(state, `${action.accountId}.setupPaymentMethod.error`, action.error);
    case actions.SETUP_PAYMENT_METHOD_CANCEL:
      return ReduxUtils.replace(state, `${action.accountId}.setupPaymentMethod.status`, 'CANCELLED');
    case actions.LOAD_INVOICES:
      state = ReduxUtils.replace(state, `${action.accountId}.invoices.status`, 'IN_PROGRESS');
      return ReduxUtils.replace(state, `${action.accountId}.invoices.error`, null);
    case actions.LOAD_INVOICES_SUCCESS:
      state = ReduxUtils.replace(state, `${action.accountId}.invoices.status`, 'SUCCESS');
      return ReduxUtils.replace(state, `${action.accountId}.invoices.data`, action.data);
    case actions.LOAD_INVOICES_FAILED:
      state = ReduxUtils.replace(state, `${action.accountId}.invoices.status`, 'FAILED');
      return ReduxUtils.replace(state, `${action.accountId}.invoices.error`, action.error);
    case actions.LOAD_CREDIT_BALANCE:
      state = ReduxUtils.replace(state, `${action.accountId}.customerBalance.status`, 'IN_PROGRESS');
      return ReduxUtils.replace(state, `${action.accountId}.customerBalance.error`, null);
    case actions.LOAD_CREDIT_BALANCE_SUCCESS:
      state = ReduxUtils.replace(state, `${action.accountId}.customerBalance.status`, 'SUCCESS');
      return ReduxUtils.replace(state, `${action.accountId}.customerBalance.data`, action.data);
    case actions.LOAD_CREDIT_BALANCE_FAILED:
      state = ReduxUtils.replace(state, `${action.accountId}.customerBalance.status`, 'FAILED');
      return ReduxUtils.replace(state, `${action.accountId}.customerBalance.error`, action.error);
    case actions.LOAD_LAST_INVOICE:
      state = ReduxUtils.replace(state, `${action.accountId}.lastInvoice.status`, 'IN_PROGRESS');
      return ReduxUtils.replace(state, `${action.accountId}.lastInvoice.error`, null);
    case actions.LOAD_LAST_INVOICE_SUCCESS:
      state = ReduxUtils.replace(state, `${action.accountId}.lastInvoice.status`, 'SUCCESS');
      return ReduxUtils.replace(state, `${action.accountId}.lastInvoice.data`, action.data);
    case actions.LOAD_LAST_INVOICE_FAILED:
      state = ReduxUtils.replace(state, `${action.accountId}.lastInvoice.status`, 'FAILED');
      return ReduxUtils.replace(state, `${action.accountId}.lastInvoice.error`, action.error);
    case actions.NON_PROFIT_REQUEST:
      state = ReduxUtils.replace(state, `${action.accountId}.nonProfitRequest.status`, 'IN_PROGRESS');
      return ReduxUtils.replace(state, `${action.accountId}.nonProfitRequest.error`, null);
    case actions.NON_PROFIT_REQUEST_SUCCESS:
      return ReduxUtils.replace(state, `${action.accountId}.nonProfitRequest.status`, 'SUCCESS');
    case actions.NON_PROFIT_REQUEST_FAILED:
      state = ReduxUtils.replace(state, `${action.accountId}.nonProfitRequest.status`, 'FAILED');
      return ReduxUtils.replace(state, `${action.accountId}.nonProfitRequest.error`, action.error);
    case actions.REQUEST_INVOICE:
      state = ReduxUtils.replace(state, `${action.accountId}.requestInvoice.status`, 'IN_PROGRESS');
      state = ReduxUtils.replace(state, `${action.accountId}.requestInvoice.priceId`, action.priceId);
      state = ReduxUtils.replace(state, `${action.accountId}.requestInvoice.additionalSeats`, action.additionalSeats);
      state = ReduxUtils.replace(state, `${action.accountId}.requestInvoice.autoRenew`, action.autoRenew);
      return ReduxUtils.replace(state, `${action.accountId}.requestInvoice.error`, null);
    case actions.REQUEST_INVOICE_SUCCESS:
      state = ReduxUtils.replace(state, `${action.accountId}.requestInvoice.status`, 'SUCCESS');
      return ReduxUtils.replace(state, `${action.accountId}.requestInvoice.data`, action.data);
    case actions.REQUEST_INVOICE_FAILED:
      state = ReduxUtils.replace(state, `${action.accountId}.requestInvoice.status`, 'FAILED');
      return ReduxUtils.replace(state, `${action.accountId}.requestInvoice.error`, action.error);
    case actions.UPDATE_BILLING_ADDRESS:
      state = ReduxUtils.replace(state, `${action.accountId}.updateBillingAddress.status`, 'IN_PROGRESS');
      return ReduxUtils.replace(state, `${action.accountId}.updateBillingAddress.error`, null);
    case actions.UPDATE_BILLING_ADDRESS_SUCCESS:
      state = ReduxUtils.replace(state, `${action.accountId}.updateBillingAddress.status`, 'SUCCESS');
      return ReduxUtils.replace(state, `${action.accountId}.updateBillingAddress.data`, action.data);
    case actions.UPDATE_BILLING_ADDRESS_FAILED:
      state = ReduxUtils.replace(state, `${action.accountId}.updateBillingAddress.status`, 'FAILED');
      return ReduxUtils.replace(state, `${action.accountId}.updateBillingAddress.error`, action.error);
    case actions.CANCEL_SUBSCRIPTION:
      state = ReduxUtils.replace(state, `${action.accountId}.cancelSubscription.status`, 'IN_PROGRESS');
      return ReduxUtils.replace(state, `${action.accountId}.cancelSubscription.data`, null);
    case actions.CANCEL_SUBSCRIPTION_SUCCESS:
      state = ReduxUtils.replace(state, `${action.accountId}.cancelSubscription.status`, 'SUCCESS');
      return ReduxUtils.replace(state, `${action.accountId}.cancelSubscription.data`, action.data);
    case actions.CANCEL_SUBSCRIPTION_FAILED:
      state = ReduxUtils.replace(state, `${action.accountId}.cancelSubscription.status`, 'FAILED');
      return ReduxUtils.replace(state, `${action.accountId}.cancelSubscription.data`, action.data);
    default:
      return state;
  }
};
import { LitElement, html, css } from 'lit';
import { show as showSnackBar, hide as hideSnackBar } from './kerika-snackbar';
import { installMediaQueryWatcher } from 'pwa-helpers/media-query.js';
import { connect } from '@dreamworld/pwa-helpers/connect-mixin';
import firestoreRedux from '@dreamworld/firestore-redux';
import './new-version-notification/kerika-new-version-available-message.js';
import { installWithLatestUpdateChecker as installServiceWorker, appVersionStatus } from '../install-sw-with-firebase.js';
import * as utils from '../utils.js';
import isEmpty from 'lodash-es/isEmpty';
import once from 'lodash-es/once';
import get from 'lodash-es/get';
import forEach from 'lodash-es/forEach';
import localize from '@dw/pwa-helpers/localize';
import i18next from '@dw/i18next-esm';
import { init as intializeSentry, configureScope as configureScopeForSentry } from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations/esm/captureconsole';
import { Dedupe as DedupeIntegration } from '@sentry/integrations/esm/dedupe';

// This element is connected to the Redux store.
import { store } from '../store.js';

import { initializeApp } from 'firebase/app';

// These are the actions needed by this element.
import * as app from '../redux/app';
import * as device from '../redux/device';
import * as auth from '../redux/auth';
import * as router from '../redux/router';
import * as attachments from '../redux/attachments';
import * as accountPreferences from '../redux/account-preferences';
import * as board from '../redux/board';
import * as signup from '../redux/signup';
import * as downloadApp from '../redux/download-app';
import * as importBoard from '../redux/import-board';
import * as feature from '../redux/feature';
import * as multipleLanguage  from '../redux/multiple-language';
import * as textResources from '../redux/text-resources';
import * as subscriptionV2 from '../redux/subscription-v2';
import * as accountAlerts from '../redux/account-alerts';

// Analytics imports.
import * as amplitude from '../analytics/amplitude.js';
import * as linkedIn from '../analytics/linkedin.js';
import * as GA4 from '../analytics/ga4.js';
import * as gtm from '../analytics/gtm.js';

// Plugins
import * as FCM from '../redux/cordova-plugins/firebase-messaging.js';
import * as ionicDeepLink from '../redux/cordova-plugins/ionic-deep-link.js';
import * as orientation from '../orientation.js';
import { clipboard } from '../components/clip-board';
import { changeI18NextLanguage, init as i18NextInit, setBaseLang as i18NextSetBaseLang, setSupportedLanguages as i18NextSetSupportedLanguages } from './i18next.js';

import { to64, from64 } from '../redux/router/routers/router-utils.js';
import URI from '@dw/urijs-esm';

// Application theme.
import { theme } from '../theme/theme';

import { DwIcon } from '@dreamworld/dw-icon/dw-icon';
import * as appIcons from '../components/icons/app.js';
DwIcon.addIcons({
  'google_drive': appIcons.googleDrive,
  'box_drive': appIcons.boxLogo,
  'kerika_drive': appIcons.kerikaDrive,
  'microsoft_drive': appIcons.microsoftDrive,
  'beenhere': appIcons.beenhere,
  'beenhere_active': appIcons.beenhereActive,
});

//Components.
import '../feature-tooltip/feature-tooltip';
import '../user-signup/import-board-dialog.js';
import '../user-signup/use-case-boards-dialog.js';
import './kerika-loader';
import './login-and-signup/kerika-login-and-signup-dialog.js';
import './login-and-signup/create-board-in-progress-dialog.js';

/**
 * This is an application shell page.
 */

class KerikaPWA extends connect(store)(localize(i18next)(LitElement)) {
  static get styles() {
    return [
      theme,
      css`
        :host {
          display: block;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          background-color: var(--page-background-color);
        }

        :host([page-name='LOADING']) {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 100dvh;
        }

        :host([page-name="BLANK_PAGE"]) {
          background-color: var(--primary-background-color);
        }

        kerika-loader {
          position: absolute;
        }

        :host(:not([dialog-name='CARD']):not([page-name='LOGIN_AND_SIGNUP']):not([page-name='FORGOT_PASSWORD']):not([page-name='FORCE_UPDATE_APP']))
          .page {
          background-color: var(--page-background-color);
        }

        .page {
          padding-top: var(--app-header-height-narrow);
          min-height: 100dvh;
          box-sizing: border-box;
        }

        :host([wide-layout]) .page {
          padding-top: var(--app-header-height-wide);
        }

        :host(:not([page-name='USE_CASE_BOARD_COPYING']):not([page-name='ACCOUNT_PREFERENCES']):not([page-name='LOGIN_AND_SIGNUP']):not([page-name='FORGOT_PASSWORD']):not([page-name='SIGNUP']):not([page-name='PREFERRED_STORE_SELECTION']):not([page-name='SUBSCRIPTION_RENEW']):not([page-name='SUBSCRIPTION_AUTO_RENEW'])[wide-layout])
          .page {
          padding-top: var(--multi-header-height-wide);
        }

        :host(:not([wide-layout])[page-name='BOARD_EXPLORER']) .page,
        :host(:not([wide-layout])[page-name='VIEWS']) .page {
          padding-bottom: var(--kerika-bottom-navigation-height);
        }

        /* For IOS padding is overlapping "app-header" so gave padding to placeholder content explicitly. */
        kerika-board-placeholder,
        kerika-card-placeholder {
          padding-top: 0px !important;
        }

        kerika-new-version-available-message,
        subscription-message {
          display: none;
        }

        :host(:not([embed-login-signup]):not([embed-import-trello])) kerika-new-version-available-message[selected],
        subscription-message[selected] {
          display: block;
        }

        landscape-page {
          position: absolute;
          inset: 0;
        }

        iframe#canvasPageContentIframe {
          display: none;
          border: none;
          width: 100%;
          height: calc(100dvh - var(--whiteboard-page-header-height, 0px));
          margin-top: var(--whiteboard-page-header-height, 0px);
        }

        :host([page-name='BOARD'][page-type='WHITEBOARD']) iframe#canvasPageContentIframe {
          display: block;
        }

        :host([page-name='BOARD'][page-type='WHITEBOARD'][private-page]) iframe#canvasPageContentIframe {
          display: none;
        }

        :host([page-name='BOARD'][page-type='WHITEBOARD']) iframe#canvasPageContentIframe {
          --whiteboard-page-header-height: calc(var(--single-header-height-narrow) + 2px);
        }

        :host([wide-layout][page-name='BOARD'][page-type='WHITEBOARD']) iframe#canvasPageContentIframe {
          --whiteboard-page-header-height: calc(var(--multi-header-height-wide) + 2px);
        }

        :host(:not(installed-app)[c-page-view][page-name='BOARD'][page-type='WHITEBOARD']) iframe#canvasPageContentIframe {
          --whiteboard-page-header-height: 0px;
        }

        :host([page-name='BOARD'][page-type='WHITEBOARD']) {
          background-color: var(--mdc-theme-surface);
        }

        :host([page-name='BOARD'][page-type='WHITEBOARD']) .page {
          min-height: unset;
        }

        :host([page-name='BOARD'][page-type='WHITEBOARD'][private-page]) .page {
          min-height: 100dvh;
        }

        :host([page-name='BOARD'][page-type='WHITEBOARD']) kerika-board-placeholder {
          height: var(--whiteboard-page-header-height, 0) !important;
        }

        :host([page-name='BOARD'][page-type='TASKBOARD']) kerika-board-placeholder {
          position: absolute;
        }

        :host([page-name="UNDER_MAINTENANCE"][embed-login-signup]) kerika-under-maintenance-page.page,
        :host([page-name="UNDER_MAINTENANCE"][embed-import-trello]) kerika-under-maintenance-page.page {
          padding-top: 24px !important;
        }
      `,
    ];
  }

  get loadingPageTemplate() {
    if (this._pageName === 'LOADING') {
      return html`<kerika-loader></kerika-loader>`;
    }
  }

  get headerTemplate() {
    if (this._embedLoginSignup && !this.currentUserId) {
      return;
    }

    if(this._embedImportTrello || this._embedUseCaseBoards) {
      return '';
    }

    if(this._isBlanckPage()) {
      return;
    }

    if (
      this._pageName === 'LOGIN_AND_SIGNUP' ||
      this._pageName === 'FORGOT_PASSWORD' ||
      this._pageName === 'LOADING' ||
      this._pageName === 'OFFLINE' ||
      this._pageName === 'PREFERRED_STORE_SELECTION' ||
      this._pageName === 'SUBSCRIPTION_RENEW' ||
      this._pageName === 'SUBSCRIPTION_AUTO_RENEW' ||
      this._pageName === 'FORCE_UPDATE_APP' ||
      this._pageName === 'UNSUBSCRIBE' ||
      this._pageName === 'BLANK_PAGE'
    ) {
      return;
    }

    if (
      !this._wideLayout &&
      (this._pageName === 'BOARD_EXPLORER' ||
        this._pageName === 'BOARD' ||
        this._pageName === 'VIEWS' ||
        this._pageName === 'JOIN_BOARD' ||
        this._pageName === 'ACCOUNT_PREFERENCES' ||
        this._pageName === 'MANAGE_ACCOUNT')
    ) {
      return;
    }

    if(this._pageName === 'JOIN_BOARD' && !this.currentUserId) {
      return;
    }

    //Whiteboard c-page view.
    if (this._isCpageView) {
      return;
    }

    return html`<kerika-app-header .pageName="${this._pageName}"> </kerika-app-header>`;
  }

  get pageTemplate() {
    if (this._pageName === 'BROWSER_NOT_SUPPORTED') {
      return html` <kerika-browser-compatibility class="page"> </kerika-browser-compatibility> `;
    }

    if (this._pageName === 'FORCE_UPDATE_APP') {
      return html` <kerika-force-update-app-page class="page"> </kerika-force-update-app-page>`;
    }

    if (this._pageName === 'UNDER_MAINTENANCE') {
      return html` <kerika-under-maintenance-page class="page"> </kerika-under-maintenance-page> `;
    }

    if (this._pageName === 'OFFLINE') {
      return html` <offline-page> </offline-page> `;
    }

    if (this._pageName === 'APP_UPDATING') {
      return html` <kerika-app-updating-page class="page"></kerika-app-updating-page> `;
    }

    if (this._pageName === 'SIGNUP') {
      return html`
        <user-signup .scrollLock=${!this._wideLayout && (this._actionDialogOpened || !!this._dialogName)} class="page"> </user-signup>
      `;
    }

    if (this._pageName === 'LOGIN_AND_SIGNUP') {
      return html`
        <login-and-signup-page .scrollLock=${!this._wideLayout && (this._actionDialogOpened || !!this._dialogName)} class="page">
        </login-and-signup-page>
      `;
    }

    if (this._pageName === 'FORGOT_PASSWORD') {
      return html`
        <forgot-password-page .scrollLock=${!this._wideLayout && (this._actionDialogOpened || !!this._dialogName)} class="page">
        </forgot-password-page>
      `;
    }

    if(this._pageName === 'JOIN_BOARD') {
      return html`
        <join-board-page .scrollLock=${!this._wideLayout && (this._actionDialogOpened || !!this._dialogName)} class="page">
        </join-board-page>
      `;
    } 

    if(this._pageName === 'USE_CASE_BOARD_COPYING') {
      return html`
        <use-case-board-copying-page
          ?wide-layout=${this._wideLayout}
          .scrollLock=${!this._wideLayout && (this._actionDialogOpened || !!this._dialogName)} 
          class="page">
        </use-case-board-copying-page>
      `;
    } 

    if (this._pageName === 'BOARD' && this._cardDetailDialogReferrerPage !== 'VIEWS') {
      return html`
        <kerika-board-placeholder
          class="page"
          .scrollLock=${(!this._wideLayout || this._isTabletDevice) && (this._actionDialogOpened || !!this._dialogName)}
          .active=${true}
        >
        </kerika-board-placeholder>
      `;
    }

    if (this._pageName == 'VIEWS' || this._cardDetailDialogReferrerPage == 'VIEWS') {
      return html`
        <view-page
          class="page"
          .scrollLock=${(!this._wideLayout || this._isTabletDevice) && (this._actionDialogOpened || !!this._dialogName)}
          .active="${this._dialogName !== 'CARD'}"
          ?wide-layout=${this._wideLayout}
        >
        </view-page>
      `;
    }

    if (this._pageName === 'BOARD_EXPLORER') {
      if (this._wideLayout) {
        return html` <explorer-page class="page"> </explorer-page> `;
      }

      return html`
        <board-list-placeholder
          class="page"
          .scrollLock=${this._actionDialogOpened || !!this._dialogName}
          ?wide-layout=${this._wideLayout}
        ></board-list-placeholder>
      `;
    }

    if (this._pageName === 'NOT_FOUND') {
      return html` <kerika-404 class="page"> </kerika-404> `;
    }

    if (this._pageName === 'LEAVE_APP') {
      return html` <leave-app-done-page class="page" ?wide-layout=${this._wideLayout}> </leave-app-done-page> `;
    }

    if(this._pageName === 'UNSUBSCRIBE') {
      return html`<unsubscribe-emails-page class="page" ?wide-layout=${this._wideLayout}></unsubscribe-emails-page>`
    }

    if (this._pageName === 'ACCOUNT_PREFERENCES') {
      if (this._wideLayout) {
        return html`
          <account-preferences-desktop-page
            ?selected="${this._pageName === 'ACCOUNT_PREFERENCES'}"
            @switch-account="${this.__openAccountSwitcherDialog}"
            class="page"
          ></account-preferences-desktop-page>
        `;
      }
      return html`
        <account-preferences-page
          .scrollLock=${!this._wideLayout && (this._actionDialogOpened || !!this._dialogName)}
          .active=${true}
          class="page"
          ?selected="${this._pageName === 'ACCOUNT_PREFERENCES'}"
        >
        </account-preferences-page>
      `;
    }

    if (this._pageName === 'AUTHORIZE_FILE_STORE') {
      return html`
        <authorize-file-store-page
          active
          class="page"
          ?widelayout=${this._wideLayout}
          ?selected="${this._pageName === 'AUTHORIZE_FILE_STORE'}"
        >
        </authorize-file-store-page>
      `;
    }

    if (this._pageName === 'PREFERRED_STORE_SELECTION') {
      return html`
        <preferred-store-selection-page active class="page" ?selected="${this._pageName === 'PREFERRED_STORE_SELECTION'}">
        </preferred-store-selection-page>
      `;
    }

    if (this._pageName === 'SUBSCRIPTION_RENEW') {
      return html`
        <subscription-renew-page active class="page" ?selected="${this._pageName === 'SUBSCRIPTION_RENEW'}">
        </subscription-renew-page>
      `;
    }

    if (this._pageName === 'SUBSCRIPTION_AUTO_RENEW') {
      return html`
        <subscription-auto-renew-page active class="page" ?selected="${this._pageName === 'SUBSCRIPTION_AUTO_RENEW'}">
        </subscription-auto-renew-page>
      `;
    }

    if (this._pageName === 'MANAGE_ACCOUNT') {
      return html`
        <manage-account-page
          class="page"
          .scrollLock=${!!this._actionDialogOpened || !!this._dialogName}
          .active=${true}
          @switch-account="${this.__openAccountAdminSwitcherDialog}"
          ?selected="${this._pageName === 'MANAGE_ACCOUNT'}"
        >
        </manage-account-page>
      `;
    }
    return '';
  }

  get landscapeTemplate() {
    if(this._embedImportTrello || this._embedUseCaseBoards || this._embedLoginSignup) {
      return;
    }
    
    return this._landscapeView ? html`<landscape-page></landscape-page>` : '';
  }

  get downloadAppDialogTemplate() {
    if (!this._downloadDialogOpened || this._impersonatedUser) {
      return;
    }
    return html`<download-app-dialog .opened=${true}></download-app-dialog>`;
  }

  get accountAlertsNotificationDialogTemplate() {
    if (!this._accountAlertsDialogOpened || this._impersonatedUser) {
      return;
    }

    return html`
      <account-alerts-notification-dialog 
        .type=${'modal'}
        .placement=${this._wideLayout ? 'center': 'bottom'}
        .opened=${true}>
      </account-alerts-notification-dialog>`;
  }

  get preferredLanguageDialogTemplate() {
    if(!this._preferredLanguageDialogOpened || this._pageName === 'LEAVE_APP') {
      return;
    }
    return html`
      <language-selection-dialog
        .type=${'modal'}
        .fitHeight=${!this._wideLayout}
        .placement=${this._wideLayout ? 'center': 'bottom'}
        .opened=${true}>
      </language-selection-dialog>`;
  }

  get messagesDialogTemplate() {
    if (!(this._dialogName === 'MESSAGES' && (!this._wideLayout || (this._wideLayout && this._messagesTriggerBtn)))) {
      return '';
    }

    return html`
      <messages-dialog
        .triggerElement=${this._messagesTriggerBtn}
        .appendTo=${document.body}
        .opened=${true}
        .popoverPlacement=${'bottom-end'}
        .headerStyle=${this._wideLayout ? 'CLOSE' : 'BACK'}
        .type=${this._wideLayout ? 'popover' : 'fit'}
      >
      </messages-dialog>
    `;
  }

  get broadcastMessagesDialogTemplate() {
    if (!(this._dialogName === 'BROADCAST_MESSAGES' && (!this._wideLayout || (this._wideLayout && this._broadcastMessagesTriggerBtn)))) {
      return '';
    }

    return html`
      <broadcast-message-dialog
        .triggerElement=${this._broadcastMessagesTriggerBtn}
        .appendTo=${document.body}
        .opened=${true}
        .popoverPlacement=${'bottom-end'}
        .headerStyle=${'CLOSE'}
        .type=${this._wideLayout ? 'popover' : 'modal'}
        .placement="${this._wideLayout ? 'center' : 'bottom'}"
     >
      </broadcast-message-dialog>
    `;
  }

  get importTrelloBoardDialogTemplate() {
    if (this._action !== 'import-board' && !this._embedImportTrello) {
      return '';
    }
    return html`
      <import-board-dialog
        ?wide-layout=${this._wideLayout}
        .opened=${true}
        .type=${this._isDesktopDevice || this._isTabletDevice ? 'modal' : 'fit'}
        .placement=${this._isDesktopDevice || this._isTabletDevice ? 'center' : 'bottom'}
        .headerStyle=${'CLOSE'}
      >
      </import-board-dialog>
    `;
  }

  get useCaseBardsDialogTemplate() {
    if(this._action !== 'use-case-boards' && !this._embedUseCaseBoards) {
      return '';
    }

    return html`
      <use-case-boards-dialog
        ?wide-layout=${this._isDesktopDevice || this._isTabletDevice || this._wideLayout}
        .opened=${true}
        .type=${'modal'}
        .fitHeight=${!this._isDesktopDevice && !this._isTabletDevice}
        .placement=${this._isDesktopDevice || this._isTabletDevice ? 'center': 'bottom'}
        .headerStyle=${'CLOSE'}>
      </use-case-boards-dialog>
    `;
  }

  get notificationPermissionDialog() {
    if(!this._notificationPermissionDialogOpened || this._impersonatedUser) {
      return;
    }

    return html`
      <kerika-notification-permission-dialog
        .type=${'modal'}
        .placement=${this._wideLayout ? 'center': 'bottom'}
        .opened=${true}>
      </kerika-notification-permission-dialog>
    `;
  }

  get inviteTrelloMemberDialogTemplate() {
    if (this._dialogName !== 'INVITE_ACCOUNT_TEAM') {
      return;
    }

    return html`
      <invite-account-team-dialog class="invite-account-team-dialog" .type=${this._wideLayout ? 'modal' : 'fit'} .opened=${true}>
      </invite-account-team-dialog>
    `;
  }

  get joinBoardDialogTemplate() {
    if (this._dialogName !== 'JOIN_BOARD_DIALOG' || this._pageName !== 'BOARD') {
      return;
    }

    return html`
      ${this._joinBoardAction === 'regenerate-link' ? html`
        <join-board-regenerate-link-dialog
          class="join-board-regenerate-link-dialog" 
          .opened=${true}
          .type=${'modal'}
          .placement=${this._wideLayout ? 'center': 'bottom'}>
        </join-board-regenerate-link-dialog>
      `: ''}
      <join-board-dialog 
        class="join-board-dialog" 
        .type=${'modal'} 
        .placement=${this._wideLayout ? 'center': 'bottom'} 
        .headerStyle=${this._wideLayout ? 'CLOSE' : 'BACK'}
        .fitHeight=${!this._wideLayout}
        .opened=${true}>
      </join-board-dialog>
    `;
  }

  get manageTranslationsBoardDialogTemplate() {
    if (this._dialogName !== 'TRANSLATIONS_DIALOG' || this._pageName !== 'BOARD') {
      return;
    }

    return html`
      <manage-board-translations-dialog 
        class="manage-board-translations-dialog" 
        .type=${'modal'} 
        .placement=${this._wideLayout ? 'center': 'bottom'} 
        .headerStyle=${this._wideLayout ? 'CLOSE' : 'BACK'}
        .fitHeight=${!this._wideLayout}
        .opened=${true}>
      </manage-board-translations-dialog>
    `;
  }

  get importTrelloBoardInfoDialogTemplate() {
    if (this._action !== 'import-board-info') {
      return '';
    }

    return html`
      <import-board-info-dialog
        ?wide-layout=${this._wideLayout}
        .opened=${true}
        .isTabletDevice=${this._isTabletDevice}
        .type=${this._isDesktopDevice || this._isTabletDevice ? 'modal' : 'fit'}
        .placement=${this._isDesktopDevice || this._isTabletDevice ? 'center' : 'bottom'}
        .headerStyle=${'CLOSE'}
      >
      </import-board-info-dialog>
    `;
  }

  get importTrelloBoardDataInfoDialogTemplate() {
    if (this._action !== 'import-board-data-info') {
      return '';
    }

    return html`
      <import-board-data-info-dialog
        ?wide-layout=${this._wideLayout}
        .opened=${true}
        .isTabletDevice=${this._isTabletDevice}
        .type=${this._isDesktopDevice || this._isTabletDevice ? 'modal' : 'fit'}
        .placement=${this._isDesktopDevice || this._isTabletDevice ? 'center' : 'bottom'}
        .headerStyle=${'CLOSE'}
      >
      </import-board-data-info-dialog>
    `;
  }

  get trialEndNotificationDialogTemplate() {
    if (this._dialogName !== 'TRIAL_END') {
      return '';
    }


    return html`
      <trial-end-notification-dialog
        ?wide-layout=${this._wideLayout}
        .type=${'modal'}
        .placement=${this._wideLayout ? 'center' : 'bottom'}
        .opened=${true}
      >
      </trial-end-notification-dialog>
    `;
  }

  get kerikaVideoTutorialDialogTemplate() {
    if (this._dialogName !== 'VIDEOS') {
      return '';
    }

    return html` <kerika-video-dialog> </kerika-video-dialog> `;
  }

  get kerikaLeaveAccountDialogTemplate() {
    if (this._dialogName !== 'LEAVE_ACCOUNT') {
      return '';
    }

    return html` <kerika-leave-account-dialog .type=${'modal'} .opened=${true}> </kerika-leave-account-dialog> `;
  }

  get cardMoveToDoneConfirmDialogTemplate() {
    return html`
      <card-move-to-done-confirm-dialog
        .opened=${false}
        .placement=${this._wideLayout ? 'center': 'bottom'}
        .wideLayout=${this._wideLayout}
        .type="${'modal'}">
      </card-move-to-done-confirm-dialog>
    `;
  }

  get joinAccountWelcomeDialogTemplate() {
    if (!this._joinAccountWelcomeDialogOpened || this._impersonatedUser) {
      return;
    }

    return html`
      <join-account-welcome-dialog .type=${'modal'} .placement=${this._wideLayout ? 'center' : 'bottom'} .opened=${true}>
      </join-account-welcome-dialog>
    `;
  }

  get useTemplateWelcomeDialogTemplate() {
    if (!this._useTemplateWelcomeDialogOpened || this._impersonatedUser) {
      return;
    }

    return html`
      <use-template-welcome-dialog .type=${'modal'} .placement=${this._wideLayout ? 'center' : 'bottom'} .opened=${true}>
      </use-template-welcome-dialog>
    `;
  }

  get cardDetailDialogTemplate() {
    if (this._dialogName !== 'CARD') {
      return;
    }

    return html`
      <kerika-card-placeholder class="page" .scrollLock=${!this._wideLayout && this._actionDialogOpened}></kerika-card-placeholder>
    `;
  }

  get subscriptionDialogsTemplate() {
    if (this._dialogName === 'SWITCH_TO_FREE_PLAN') {
      return html`
        <switch-to-free-plan-dialog
          .type=${'modal'} 
          ?wide-layout=${this._wideLayout}
          .placement=${this._wideLayout ? 'center' : 'bottom'}
          .opened=${true}>
        </switch-to-free-plan-dialog>
      `;
    }

    if(this._dialogName === 'PREVIEW_UPCOMING_INVOICE') {
      return html`
        <preview-upcoming-invoice-dialog
          .type="${this._wideLayout ? 'modal' : 'fit'}"
          .placement=${this._wideLayout ? 'center' : 'bottom'}
          .opened=${true}>
        </preview-upcoming-invoice-dialog>
      `;
    }

    if(this._dialogName === 'AUTO_RENEW_ON') {
      return html`
        <subscription-auto-renew-on-confirm-dialog
          .type=${'modal'}
          .placement=${this._wideLayout ? 'center' : 'bottom'}
          .opened=${true}>
        </subscription-auto-renew-on-confirm-dialog>
      `;
    }

    if (this._dialogName === 'REQUEST_NON_PROFIT') {
      return html`
        <request-plan-non-profit-dialog 
          .type=${'modal'} 
          .placement=${this._wideLayout ? 'center' : 'bottom'} 
          .opened=${true}>
        </request-plan-non-profit-dialog>
      `;
    }

    if (!this._isInstalledApp && (this._dialogName === 'CHANGE_PLAN')) {
      return html`
        <subscription-buy-dialog
          .type=${this._wideLayout ? 'modal': 'fit'}
          .placement=${this._wideLayout ? 'center' : 'bottom'}
          .dialogName=${this._dialogName}
          .fitHeight=${!this._wideLayout}
          .opened=${this._dialogName === 'CHANGE_PLAN'}>
        </subscription-buy-dialog>
      `;
    }

    if (this._dialogName === 'MANAGE_TEAM_CONFIRM') {
      return html`
        <manage-team-confirm-switch-to-paid-plan-dialog 
          .type=${'modal'} 
          .placement=${this._wideLayout ? 'center' : 'bottom'}
          .fitHeight=${!this._wideLayout}
          .opened=${this._dialogName === 'MANAGE_TEAM_CONFIRM'}>
        </manage-team-confirm-switch-to-paid-plan-dialog>
      `;
    }

    if(this._dialogName === 'SUBSCRIPTION_UNPAID') {
      return html`
        <subscription-unpaid-notification-dialog
          .type=${'modal'}
          .placement=${this._wideLayout ? 'center' : 'bottom'}
          .opened=${true}>
        </subscription-unpaid-notification-dialog>
      `;
    }

    if(this._dialogName === 'SUBSCRIPTION_CANCELED') {
      return html`
        <subscription-canceled-notification-dialog
          .type=${'modal'}
          .placement=${this._wideLayout ? 'center' : 'bottom'}
          .opened=${true}>
        </subscription-canceled-notification-dialog>
      `;
    }
  }

  get searchDialogTemplate() {
    if (this._dialogName !== 'SEARCH') {
      return;
    }

    return html`
      ${this._wideLayout && this.__searchTriggerEl
        ? html`
            <search-popover-dialog
              .appendTo=${document.body}
              .type="${'popover'}"
              .triggerElement=${this.__searchTriggerEl}
              .hasOverlay=${true}
              .showTrigger=${true}
              .popoverOffset=${[0, 1]}
              .boundaryPadding=${0}
              .excludeOutsideClickFor=${'search-input store-restricted search-filter-dialog snackbar-container litepicker search-filter-custom-select-dialog dialog__content'}
              .opened=${true}
              .popoverPlacement="${'bottom-end'}"
            >
            </search-popover-dialog>
          `
        : html` <search-fit-dialog .opened=${true} .type="${'fit'}"></search-fit-dialog> `}
      ${this._searchAction === 'filters'
        ? html`
            <search-filter-dialog
              class="search-filter-dialog"
              .type=${this._wideLayout ? 'modal' : 'fit'}
              .headerStyle=${this._wideLayout ? 'CLOSE' : 'BACK'}
              .placement=${this._wideLayout ? 'center' : 'bottom'}
              .queryParamName=${'search-action'}
              .queryParamValue=${'filters'}
              .opened=${this._searchAction === 'filters'}
            >
            </search-filter-dialog>
          `
        : ''}
    `;
  }

  get preferredStoreSelectionDialogTemplate() {
    if (this._attachmentAction != 'prefferred-store-selection') {
      return;
    }

    return html`
      <preferred-store-selection-dialog
        class="preferred-store-selection-dialog"
        noCancelOnEscKey
        noCancelOnOutsideClick
        .type=${'modal'}
        .queryParamValue=${'prefferred-store-selection'}
        .queryParamName=${'attachment-action'}
        .opened=${this._attachmentAction == 'prefferred-store-selection'}
        .placement=${this._wideLayout ? 'center' : 'bottom'}
      >
      </preferred-store-selection-dialog>
    `;
  }

  get kerikaDriveSetupPendingTemplate(){
    if(this._attachmentSubAction !== 'drive-setup-pending') {
      return;
    }
    
    return html `
      <kerika-drive-setup-pending
        .type=${'modal'}
        class="kerika-drive-setup-pending"
        .queryParamValue=${'drive-setup-pending'}
        .queryParamName=${'attachment-sub-action'}
        .opened=${true}
        .placement=${this._wideLayout ? 'center' : 'bottom'}>
      </kerika-drive-setup-pending>
    `
  }

  get cloudStoreAuthNotFoundInfoTemplate(){
    if(this._attachmentSubAction !== 'auth-identity-not-found') {
      return;
    }
    
    return html `
      <cloud-store-auth-identity-not-found-info-dialog
        .type=${'modal'}
        class="cloud-store-auth-identity-not-found-info-dialog"
        .queryParamValue=${'auth-identity-not-found'}
        .queryParamName=${'attachment-sub-action'}
        .opened=${true}
        .placement=${this._wideLayout ? 'center' : 'bottom'}>
      </cloud-store-auth-identity-not-found-info-dialog>
    `
  }

  get changeFileStorageDialogTemplate() {
    if (this._action != 'change-file-storage') {
      return;
    }

    return html`
      <change-file-storage-dialog
        class="change-file-storage-dialog"
        .type=${'modal'}
        .queryParamValue=${'change-file-storage'}
        .queryParamName=${'action'}
        .opened=${this._action == 'change-file-storage'}
        .placement=${this._wideLayout ? 'center' : 'bottom'}>
      </change-file-storage-dialog>
    `;
  }

  get fileStoreAccessRevokedDialogTemplate() {
    if (this._attachmentAction != 'file-store-access-revoked') {
      return;
    }

    return html`
      <file-store-access-revoked-dialog
        class="file-store-access-revoked-dialog"
        noCancelOnEscKey
        noCancelOnOutsideClick
        .type=${'modal'}
        .queryParamValue=${'file-store-access-revoked'}
        .queryParamName=${'attachment-action'}
        .opened=${this._attachmentAction == 'file-store-access-revoked'}
        .placement=${this._wideLayout ? 'center' : 'bottom'}
      >
      </file-store-access-revoked-dialog>
    `;
  }

  get cloudStoreAccessRevokedInfoDialog() {
    if (this._action !== 'store-restricted') {
      return;
    }

    return html`
      <cloud-store-access-restricted-info-dialog
        class="store-restricted"
        .queryParamValue="${'store-restricted'}"
        .placement=${this._wideLayout ? 'center' : 'bottom'}
        .opened=${true}
      >
      </cloud-store-access-restricted-info-dialog>
    `;
  }

  get dialogTemplate() {
    if (this._pageName == 'BROWSER_NOT_SUPPORTED' || this._pageName == 'UNDER_MAINTENANCE') {
      return '';
    }

    return html`
      ${this.cardDetailDialogTemplate} ${this.profileDialogTemplate}  ${this.leaveKerikaDialogTemplate} ${this.getHelpDialogTemplate} ${this.createAccountDialogTemplate}
      ${this.createBoardDialogTemplate} ${this.createBoardInProgressDialogTemplate} ${this.loginAndSignupDialogTemplate}
      ${this.boardSwitcherDialogTemplate} ${this.accountSwitcherDialogTemplate} ${this.cardActionToolbarTemplate}
      ${this.moveCardDialogTemplate} ${this.calendarDialogTemplate} ${this.subscriptionDialogsTemplate}
      ${this.useTemplateWelcomeDialogTemplate} ${this.kerikaVideoTutorialDialogTemplate} ${this.joinAccountWelcomeDialogTemplate}
      ${this.searchDialogTemplate} ${this.messagesDialogTemplate} ${this.preferredStoreSelectionDialogTemplate}${this.kerikaDriveSetupPendingTemplate}
      ${this.fileStoreAccessRevokedDialogTemplate} ${this.cloudStoreAccessRevokedInfoDialog} ${this.cloudStoreAuthNotFoundInfoTemplate} ${this.inviteTrelloMemberDialogTemplate}
      ${this.importTrelloBoardDialogTemplate} ${this.importTrelloBoardInfoDialogTemplate} ${this.notificationPermissionDialog}
      ${this.useCaseBardsDialogTemplate}
      ${this.preferredLanguageDialogTemplate}
      ${this.changeFileStorageDialogTemplate}
      ${this.datePickerDialog}
      ${this.broadcastMessagesDialogTemplate}
      ${this.kerikaLeaveAccountDialogTemplate}
      ${this.cardMoveToDoneConfirmDialogTemplate}
      ${this.joinBoardDialogTemplate}
      ${this.manageTranslationsBoardDialogTemplate}
      ${this.importTrelloBoardDataInfoDialogTemplate}
      ${this.trialEndNotificationDialogTemplate}
      ${this.boardTransferAcceptConfirmationDialogTemplate}
      ${this.customerBalanceHistoryDialogTemplate}
    `;
  }

  get profileDialogTemplate() {
    if (this._dialogName !== 'PROFILE') {
      return;
    }

    if (this._wideLayout) {
      return html` <profile-desktop-dialog wide-layout .opened=${true}> </profile-desktop-dialog> `;
    }

    return html`
      <profile-dialog .opened=${true} .placement="${'bottom'}" .headerStyle="${'BACK'}" .fitHeight="${true}">
      </profile-dialog>
    `;
  }

  get leaveKerikaDialogTemplate() {
    if(this._dialogName === 'LEAVE_APP_DIALOG'){
      return html `<leave-app-dialog wide-layout .opened=${true}></leave-app-dialog>`
    }
    return;
  }

  get boardTransferAcceptConfirmationDialogTemplate() {
    if (this._dialogName !== 'TRANSFER_BOARD_ACCEPT_CONFIRM') {
      return;
    }

    return html`
      <board-transfer-accept-confirm-dialog
        .type=${'modal'}
        .placement=${this._wideLayout ? 'center' : 'bottom'}
        .opened=${true}>
      </board-transfer-accept-confirm-dialog>
    `;
  }

  get customerBalanceHistoryDialogTemplate() {
    if(this._dialogName !== 'CUSTOMER_BALANCE') {
      return;
    }

    return html `
      <customer-balance-history-dialog
        .type="${this._wideLayout ? 'modal' : 'fit'}"
        .placement=${'center'}
        .opened=${true}>
      </customer-balance-history-dialog>
    `
  }

  get getHelpDialogTemplate() {
    if (this._dialogName !== 'GET_HELP') {
      return;
    }

    return html`
      <get-help-dialog
        .opened=${true}
        .type="${this._wideLayout ? 'modal' : 'fit'}"
        .placement=${'center'}
        .wideLayout="${this._wideLayout}"
        .headerStyle=${this._wideLayout ? 'CLOSE' : 'BACK'}
      >
      </get-help-dialog>
    `;
  }

  get createAccountDialogTemplate() {
    if (this._action !== 'create-account' || this._ownedAccountId) {
      return;
    }
    return html`
      <create-account-dialog
        .opened=${true}
        .type=${'modal'}
        .placement=${this._wideLayout ? 'center' : 'bottom'}
        .fitHeight=${!this._wideLayout}
        .headerStyle=${this._wideLayout ? 'CLOSE' : 'BACK'}
      >
      </create-account-dialog>
    `;
  }

  get createBoardDialogTemplate() {
    if (this._action !== 'create-board' || (this._writableAccessibleAccountsLength < 1 && !this._autoCreateAccount)) {
      return;
    }

    return html`
      <create-board-dialog .opened=${true} .type=${this._wideLayout ? 'modal' : 'fit'} .headerStyle=${this._wideLayout ? 'CLOSE' : 'BACK'}>
      </create-board-dialog>
    `;
  }

  get createBoardInProgressDialogTemplate() {
    if (this._action !== 'auto-create-board') {
      return '';
    }

    return html`
      <create-board-in-progress-dialog
        .opened=${true}
        .type=${this._embedLoginSignup ? 'fit' : 'modal'}
        .queryParamValue=${'auto-create-board'}
        .placement=${this._wideLayout ? 'center' : 'bottom'}
        .fitHeight=${!this._wideLayout}
        .showMessage=${this._force}
      >
      </create-board-in-progress-dialog>
    `;
  }

  get loginAndSignupDialogTemplate() {
    if (this._dialogName !== 'AUTH' && !this._embedLoginSignup) {
      return '';
    }

    return html`
      <kerika-login-and-signup-dialog
        .opened=${true}
        .dialogName=${'AUTH'}
        ?fit-dialog-hide-overlay=${this._embedLoginSignup ? true: false}
        ?fit-dialog-no-animation=${this._embedLoginSignup ? true: false}
        .type=${this._embedLoginSignup ? 'fit' : this._wideLayout ? 'modal' : 'fit'}
        .headerStyle=${'CLOSE'}
      >
      </kerika-login-and-signup-dialog>
    `;
  }

  get _messagesTriggerBtn() {
    const appHeaderEl = this.renderRoot.querySelector('kerika-app-header');
    return appHeaderEl && appHeaderEl.renderRoot && appHeaderEl.renderRoot.querySelector('messages-notification');
  }

  get _broadcastMessagesTriggerBtn() {
    const appHeaderEl = this.renderRoot.querySelector('kerika-app-header');
    return appHeaderEl && appHeaderEl.renderRoot && appHeaderEl.renderRoot.querySelector('broadcast-messages-notification');
  }

  get __boardSwitcherTriggerButton() {
    const appHeader = this.renderRoot.querySelector('kerika-app-header');
    return appHeader && appHeader.renderRoot && appHeader.renderRoot.querySelector('.board-switcher-btn');
  }

  get __searchTriggerEl() {
    const appHeader = this.renderRoot.querySelector('kerika-app-header');
    return appHeader && appHeader.renderRoot && appHeader.renderRoot.querySelector('search-input');
  }

  get __filePreviewTemplate() {
    if (!this._filePreviewId) {
      return;
    }
    return html` <file-preview .attachmentId="${this._filePreviewId}"></file-preview> `;
  }

  get boardSwitcherDialogTemplate() {
    if (this._action !== 'board-switcher' || (this._wideLayout && !this.__boardSwitcherTriggerButton)) {
      return;
    }

    return html`
      <board-switcher-dialog
        .hasOverlay=${true}
        .appendTo=${document.body}
        .type=${this._wideLayout ? 'popover' : 'modal'}
        .popoverPlacement=${'bottom-start'}
        .triggerElement=${this.__boardSwitcherTriggerButton}
        title="${this._wideLayout ? i18next.t('boardSwitcherDialog.title.desktop') : i18next.t('boardSwitcherDialog.title.mobile')}"
        ?opened=${true}
        .placement="${'bottom'}"
      >
      </board-switcher-dialog>
    `;
  }

  get accountSwitcherDialogTemplate() {
    if (this._dialogName !== 'ACCOUNT_SWITCHER') {
      return;
    }

    return html`
      <account-switcher-dialog
        .hasOverlay=${true}
        @change="${(e) => {
          this.__switchAccount(e.detail.accountId);
        }}"
        .type=${this._wideLayout ? 'popover' : 'modal'}
        .popoverPlacement=${'bottom-start'}
        .mobileMode=${true}
        .triggerElement="${this._accountSwitcherTriggerEl}"
        .opened=${true}
        .placement="${'bottom'}"
      >
      </account-switcher-dialog>
    `;
  }

  get cardActionToolbarTemplate() {
    if (
      this._dialogName !== 'CARD_ACTIONS' ||
      !this._cardActionsDialogAccountId ||
      !this._cardActionsDialogBoardId ||
      !this._cardActionsDialogCardId ||
      !this._cardActionsDialogColumnId
    ) {
      return;
    }
    return html`
      <card-action-toolbar
        @pick-date=${(e) => {
          this._openDatePicker(e.detail.callback);
        }}
        .opened=${true}
        .accountId=${this._cardActionsDialogAccountId}
        .cardId=${this._cardActionsDialogCardId}
        .boardId=${this._cardActionsDialogBoardId}
        .columnId=${this._cardActionsDialogColumnId}
      ></card-action-toolbar>
    `;
  }

  get moveCardDialogTemplate() {
    if (
      this._action !== 'move-card' ||
      this._cardActionsDialogCardId == undefined ||
      !this._cardActionsDialogColumnId ||
      !this._cardActionsDialogBoardId
    ) {
      return;
    }

    return html`
      <move-card-dialog
        noCancelOnEscKey
        noCancelOnOutsideClick
        queryParamValue="move-card"
        ?opened=${true}
        .cardId=${this._cardActionsDialogCardId}
        .boardId=${this._cardActionsDialogBoardId}
        .columnId=${this._cardActionsDialogColumnId}
        placement="bottom"
        headerStyle="BACK"
        fit-height
      >
      </move-card-dialog>
    `;
  }

  get datePickerDialog() {
    if(this._dialogName !== 'DATE_PICKER') {
      return;
    }

    return html`
      <kerika-date-picker-dialog id="date-picker" .placement=${'bottom'} .type=${'modal'}></kerika-date-picker-dialog>
    `;
  }

  get calendarDialogTemplate() {
    if (!this._wideLayout || this._dialogName !== 'CALENDAR') {
      return;
    }
    return html` <calendar-dialog wide-layout .opened=${true}> </calendar-dialog> `;
  }

  get subscriptionMessageTemplate() {
    if(this._impersonatedUser ||this._embedImportTrello || this._embedUseCaseBoards || this._embedLoginSignup || !this._showsSubscriptionMessage) {
      return;
    }

    if(this._pageName === 'BROWSER_NOT_SUPPORTED' || this._pageName === 'UNDER_MAINTENANCE' || this._versionUpdateStatus === 'NEW_VERSION_AVAILABLE') {
      return;
    }

    return html`<subscription-message ?selected=${true}></subscription-message>`;
  }

  render() {
    return html`
      <kerika-snackbar class="snackbar-container" .mobile=${!this._wideLayout}></kerika-snackbar>
      ${this.headerTemplate} ${this.loadingPageTemplate} ${this.pageTemplate} ${this.dialogTemplate} ${this.landscapeTemplate}
      ${this.downloadAppDialogTemplate}
      ${this.accountAlertsNotificationDialogTemplate}
      ${!this._impersonatedUser ? html`
        <feature-tooltip .for=${'PAGE'} .wideLayout=${this._wideLayout} .hideTip=${!this._wideLayout && this._actionDialogOpened}></feature-tooltip>
        <feature-tooltip .for=${'DIALOG'} .wideLayout=${this._wideLayout}></feature-tooltip>
      `: ''}
      <kerika-new-version-available-message
        .wideLayout=${this._wideLayout}
        .type=${this._pageName !== 'BROWSER_NOT_SUPPORTED' && this._pageName !== 'UNDER_MAINTENANCE' && this._versionUpdateStatus === 'NEW_VERSION_AVAILABLE' ? this._forceUpdateServiceWorker ? 'blocking': 'non-blocking': ''}
        ?selected=${this._pageName !== 'BROWSER_NOT_SUPPORTED' &&
        this._pageName !== 'UNDER_MAINTENANCE' &&
        this._versionUpdateStatus === 'NEW_VERSION_AVAILABLE'}
      >
      </kerika-new-version-available-message>
      ${!this._wideLayout &&
      (!this._actionDialogOpened || this._action === 'board-switcher') &&
      (this._dialogName !== 'USER_ACTIONS' || this._wideLayout) &&
      (this._pageName === 'VIEWS' || this._pageName === 'BOARD_EXPLORER')
        ? html` <kerika-bottom-navigation active></kerika-bottom-navigation> `
        : ''}
      ${this.__filePreviewTemplate}
      ${this.subscriptionMessageTemplate}
    `;
  }

  get _elNewVersionMessage() {
    return this.renderRoot.querySelector('kerika-new-version-available-message');
  }

  constructor() {
    super();
    window.kerikaPWA = this;
    window.kerikaPWA.from64 = from64; 
    window.kerikaPWA.to64 = to64;

    //Compute query params
    const params = new URI().query(true);
    window.kerikaPWA.embedLoginSignup = params['embed-login-signup'] === 'true' || false;
    window.kerikaPWA.embedTrelloImport = params['embed-trello-import'] === 'true' || false;
    window.kerikaPWA.embedUseCaseBoards = params['embed-use-case-boards'] === 'true' || false;

    this.doNotDelayRendering = true;

    const state = store.getState();
    this._impersonatedUser = auth.selectors.impersonatedUser(state);
    this._appConfig = app.selectors.config(state);
    this._embedLoginSignup = router.selectors.embedLoginSignup(state);
    this._embedImportTrello = router.selectors.embedTrelloImport(state);
    this._embedUseCaseBoards = router.selectors.embedUseCaseBoards(state);
    this._deviceId = device.selectors.getId();

    if (isEmpty(this._appConfig)) {
      throw new Error('Application environment configuration is not found.');
    }

    //Sets application layout & detect touch device.
    installMediaQueryWatcher(`(min-width: 768px)`, once((matches) => store.dispatch(app.actions.updateLayout(matches))));
    store.dispatch(app.actions.detectTouchDevice());

    // Intialize sentry error tracking.
    this._initializeSentryErrorTracking();

    // Initialize Analytics
    this.__initializeAmplitude();
    this.__initalizGtm();
    this.__initalizeGA4();
    this.__initalizeLinkdIn();

    if(this.__isDisableServiceWorker()) {
      this.__unregisterServiceWorker();
    }

    //Validates browser is supported
    if (!this._isBrowserSupported()) {
      //Init i18next
      this.__i18nextInit(true);
      this._browserNotSupporterd = true;
      this.__managePageName();
      this.__loadFragment('BROWSER_NOT_SUPPORTED');
      return;
    }

    // Initialize Firebase app
    const firebaseConfig = {
      apiKey: this._appConfig.firebaseApiKey,
      authDomain: this._appConfig.firebaseAuthDomain,
      projectId: this._appConfig.firebaseProjectId,
      databaseURL: this._appConfig.firebaseDatabaseURL,
    };
    const firebaseApp = initializeApp(firebaseConfig);

    if(this._isLogoutPage()) {
      this.logout(true, this._appConfig.websiteBaseUrl);
      return;
    }

    // Intialzie firestore redux.
    firestoreRedux.init({ store, firebaseApp, reauthorize: auth.actions.signInIntoFirebaseAuth });

    // Loads app data from firestore.
    store.dispatch(app.actions.loadAppData());

    // Loads toggle feature data from firestore.
    store.dispatch(feature.actions.load());

    // Loads device details from firestore.
    store.dispatch(device.actions.init());

    // Loads multiple-language data from firestore.
    store.dispatch(multipleLanguage.actions.load());

    // init text-resources.
    store.dispatch(textResources.actions.init());

    //Authentication
    auth.actions.init();

    //Install router
    router.actions.init(store);

    // Init accounts alerts
    store.dispatch(accountAlerts.actions.init());

    //Init i18next
    this.__i18nextInit();

    this._onPostMessage = this._onPostMessage.bind(this);
  }

  static get properties() {
    return {
      /**
       * Currently active page name.
       */
      _pageName: { type: String, reflect: true, attribute: 'page-name' },

      /**
       * Currently active/opened page type
       */
      _pageType: { type: String, reflect: true, attribute: 'page-type' },

      /**
       * Currently opened dialog name.
       */
      _dialogName: { type: String, reflect: true, attribute: 'dialog-name' },

      /**
       * Currently opened dialog name.
       */
      _actionDialogName: { type: String },

      /**
       * Read from redux state
       * Status of application version status
       */
      _versionUpdateStatus: { type: String },

      /*
       * While user views app in landscape mode, sets `true`
       */
      _landscapeView: { type: Boolean },

      /**
       * `true` when layout is wide.
       */
      _wideLayout: { type: Boolean, reflect: true, attribute: 'wide-layout' },

      /**
       * When tablet device, it is `true`
       */
      _isTabletDevice: { type: Boolean, reflect: true, attribute: 'tablet' },

      /**
       * Current login user id.
       */
      currentUserId: { type: String },

      /**
       * embeded login page is opened or not.
       */
      _embedLoginSignup: { type: Boolean, reflect: true, attribute: 'embed-login-signup' },

      /**
       * `true` when `download-app-dialog` need to be presented.
       */
      _downloadDialogOpened: { type: Boolean },

      /**
       * `true` when `account-alerts-notification-dialog` need to be presented.
       */
      _accountAlertsDialogOpened: { type: Boolean },

      /**
       * `true` when `preferred-language-dialog` need to be presented.
       */
      _preferredLanguageDialogOpened: { type: Boolean },

      /*
       * `false` when app is not connected to the internet.
       */
      _online: { type: Boolean },

      /**
       * Used to change application background color based on account prefernces.
       */
      _appBackgroundColor: { type: String },

      /**
       * Value of action query param.
       */
      _action: { type: String },

      /**
       * Value of join-board-action query param.
       */
      _joinBoardAction: { type: String },

      /**
       * `true` when one any action dialog is opened.
       */
      _actionDialogOpened: { type: Boolean },

      /**
       * Count of current user's writable accessible accounts.
       */
      _writableAccessibleAccountsLength: { type: Number },

      /**
       * It's `true` when user wants to create her own account after signup completed.
       */
      _autoCreateAccount: { type: Boolean },

      /**
       * Current user's owned Account Id.
       */
      _ownedAccountId: { type: String },

      /**
       * Card Id for `card-action-toolbar`.
       * It's computed from router data.
       */
      _cardActionsDialogCardId: { type: String },

      /**
       * Board Id for `card-action-toolbar`.
       * It's computed from router data.
       */
      _cardActionsDialogBoardId: { type: String },

      /**
       * Column Id for `card-action-toolbar`.
       * It's computed from router data.
       */
      _cardActionsDialogColumnId: { type: String },

      /**
       * Account Id for `card-action-toolbar`
       * It's computed from router data.
       */
      _cardActionsDialogAccountId: { type: String },

      /**
       * Page from which card detail dialog is opened.
       * It's computed form queyry param ?referrer-page=VIEWS
       * At present it's set only when it's opened from views.
       */
      _cardDetailDialogReferrerPage: { type: String },

      /**
       * forcefully create a auto-board or not.
       */
      _force: { type: Boolean, reflect: true, attribute: 'force' },

      /**
       * `true` when `use-template-welcome-dialog` need to be presented.
       */
      _useTemplateWelcomeDialogOpened: { type: Boolean },

      /**
       * `true` when `join-account-welcome-dialog` need to be presented.
       */
      _joinAccountWelcomeDialogOpened: { type: Boolean },

      /**
       * Value of search-action query param.
       */
      _searchAction: { type: String },

      /**
       * Attachment Id for which file preview is shown.
       * It's computed from `file-preview` query param.
       */
      _filePreviewId: { type: Number },

      /**
       * login user accessible accounts details.
       */
      _accessibleAccountsDetail: { type: Object },

      /**
       * Value of attachment-action query param.
       */
      _attachmentAction: { type: String },

      /**
       * Value of attachment-sub-action query param.
       */
      _attachmentSubAction: { type: String },

      /**
       * `true` when currently opened page is c-page. `false` otherwise.
       */
      _isCpageView: { type: Boolean, reflect: true, attribute: 'c-page-view' },

      /**
       * Current account id.
       */
      _accountId: { type: Number },

      /**
       * Import trello boards status.
       */
      _importTrelloBoardStatus: { type: String },

      /**
       * `true` when `kerika-notification-permission-dialog` need to be presented.
       */
      _notificationPermissionDialogOpened: { type: Boolean },

      /**
       * embeded import trello flow is trigger or not.
       */
      _embedImportTrello: { type: Boolean, reflect: true, attribute: "embed-import-trello" },

      /**
       * embeded use case boards flow is trigger or not.
       */
      _embedUseCaseBoards: { type: Boolean, reflect: true, attribute: "embed-use-case-boards" },

      /**
       * Applications supported languages.
       */
      _supportedLanguages: { type: Array },

      /**
       * Applications base language.
       */
      _baseLang: { type: String },

      /**
       * preferred language of users.
       */
      _preferredLang: { type: String },

      
      /**
       * current language of users.
       */
      _currentLang: { type: String },

      /**
       * subscription message is shown or not.
       */
      _showsSubscriptionMessage: { type: Boolean },

      /**
       * imporsonated user view or not.
       */
      _impersonatedUser: { type: Boolean },

      /**
       * `true` if currently accessed board page is an private
       */
      _privatePage: { type: Boolean, reflect: true, attribute: 'private-page' },

      /**
       * Import trello board summary.
       * Use to shows a import trello board toast.
       */
      _importTrelloBoardSummary: { type: Object },

      _forceUpdateServiceWorker: { type: Boolean },
    };
  }

  connectedCallback() {
    super.connectedCallback && super.connectedCallback();
    if (app.selectors.isInstalledApp(store.getState())) {
      FCM.init();
      ionicDeepLink.init();
      this.__mobileScreenLock();
    }
    window.addEventListener('contextmenu', this._oncontextmenu);
    window.addEventListener('message', this._onPostMessage);
    window.addEventListener('dragover', this.__onDragOver, false);
    window.addEventListener('drop', this.__onDrop, false);
    this.__oni18NextInit();

    this._setDeviceId();

    //Last time called push notification time in timestam.
    this._recomputePushNotificationTime = Date.now();
    this.__recomputePushNotificationOnInterval = this.__recomputePushNotificationOnInterval.bind(this);
    this.__recomputePushNotificationOnInterval();

    //Last time called account alerts dialog time in timestamp.
    this._recomputeAccountAlertsDialogTime = Date.now();
    this.__recomputeAccountAlertsDialogOnInterval = this.__recomputeAccountAlertsDialogOnInterval.bind(this);
    this.__recomputeAccountAlertsDialogOnInterval();
  }

  _setDeviceId() {
    device.selectors.getId();
  }

  __recomputePushNotificationOnInterval() {
    this.__recomputePushNotificationTimer && clearTimeout(this.__recomputePushNotificationTimer);
    if(!this.isConnected) {
      return;
    }

    if(!this._isInstalledApp) {
      return;
    }
    const state = store.getState();
    this._recomputePushNotificationTime = Date.now();
    this.__computePushNotification(state, this._recomputePushNotificationTime);
    this.__recomputePushNotificationTimer = setTimeout(this.__recomputePushNotificationOnInterval, 1000 * 60 * 20);
  }

  __computePushNotification(state, time) {
    if(!this._isInstalledApp) {
      this._notificationPermissionDialogOpened = false;
      return;
    }
    this._notificationPermissionDialogOpened = !this._preferredLanguageDialogOpened && !this._dialogName && !this._action && !this._embedLoginSignup && !this._embedImportTrello  && !this._embedUseCaseBoards && !this._useTemplateWelcomeDialogOpened && !this._joinAccountWelcomeDialogOpened && device.selectors.permissionDialogOpened(state, time);
  }

  __recomputeAccountAlertsDialogOnInterval() {
    this.__recomputeAccountAlertsDialogTimer && clearTimeout(this.__recomputeAccountAlertsDialogTimer);
    if(!this.isConnected) {
      return;
    }

    const state = store.getState();
    this._recomputeAccountAlertsDialogTime = Date.now();
    this.__computeaccountAlertsDialogOpened(state, this._accountId, this._recomputeAccountAlertsDialogTime);
    this.__recomputeAccountAlertsDialogTimer = setTimeout(this.__recomputeAccountAlertsDialogOnInterval, 1000 * 60 * 20);
  }

  __computeaccountAlertsDialogOpened(state, accountId, time) {
    this._accountAlertsDialogOpened = !this._joinAccountWelcomeDialogOpened && 
                                      !this._useTemplateWelcomeDialogOpened && 
                                      !this._downloadDialogOpened && 
                                      !this._preferredLanguageDialogOpened && 
                                      !this._embedImportTrello && 
                                      !this._embedLoginSignup && 
                                      accountAlerts.selectors.dialogOpened(state, accountId, time || Date.now()) || false;
  }

  async __oni18NextInit() {
    await app.actions.initI18n();
    if(i18next && i18next.isInitialized && this._currentLang && this._currentLang !== i18next.language) {
      changeI18NextLanguage(this._currentLang);
    }
  }

  disconnectedCallback() {
    window.removeEventListener('contextmenu', this._oncontextmenu);
    window.removeEventListener('message', this._onPostMessage);
    window.removeEventListener('dragover', this.__onDragOver, false);
    window.removeEventListener('drop', this.__onDrop, false);
    this.__recomputePushNotificationTimer && clearTimeout(this.__recomputePushNotificationTimer);
    this._recomputePushNotificationTime = undefined;
    super.disconnectedCallback && super.disconnectedCallback();
  }

  willUpdate(changedProps) {
    super.willUpdate && super.willUpdate(changedProps);

    // Reloads when app removed from under maintenance.
    if (changedProps.get('_pageName') === 'UNDER_MAINTENANCE') {
      window.location.reload();
      return;
    }

    if (changedProps.has('_pageName')) {
      this._pageName && this.__loadFragment(this._pageName);
    }

    if(changedProps.has('_preferredLang') || changedProps.has('currentUserId')) {
      if(this.currentUserId && this._preferredLang) {
        amplitude.setUserProperties(this.currentUserId, {'language': this._preferredLang});
      }
    }

    if (changedProps.has('_dialogName')) {
      this._dialogName && this.__loadFragment(this._dialogName);
    }

    if (changedProps.has('_actionDialogName')) {
      this._actionDialogName && this.__loadFragment(this._actionDialogName);
    }

    if (changedProps.has('_embedLoginSignup') && this._embedLoginSignup) {
      this.__loadFragment('SIGNUP_LOGIN_DIALOG');
    }

    if (changedProps.has('_filePreviewId') && this._filePreviewId) {
      this.__loadFragment('FILE_PREVIEW');
    }

    if(changedProps.has('_notificationPermissionDialogOpened') && this._notificationPermissionDialogOpened) {
      this.__loadFragment('NOTIFICATION_PERMISSION_DIALOG');
    }

    if(changedProps.has('_attachmentSubAction')) {
      if(this._attachmentSubAction === 'drive-setup-pending') {
        this.__loadFragment('DRIVE_SETUP_PENDING');
      }
      if(this._attachmentSubAction === 'auth-identity-not-found') {
        this.__loadFragment('CLOUD_STORE_AUTH_IDENTITY_NOT_FOUND_INFO');
      }
    }

    if(changedProps.has('_joinBoardAction')) {
      if(this._joinBoardAction === 'regenerate-link') {
        this.__loadFragment('JOIN_BOARD_REGENERATE_LINK_DIALOG');
      }
    }

    if (
      changedProps.has('_pageName') &&
      this._pageName !== 'LOGIN_AND_SIGNUP' &&
      this._pageName !== 'FORGOT_PASSWORD' &&
      this._pageName !== 'LOADING'
    ) {
      this.__loadFragment('APP_HEADER');
    }

    if (changedProps.has('_landscapeView') && this._landscapeView) {
      this.__loadFragment('LANDSCAPE');
    }

    if (changedProps.has('_downloadDialogOpened') && this._downloadDialogOpened) {
      this.__loadFragment('DOWNLOAD_DIALOG');
    }

    if(changedProps.has('_accountAlertsDialogOpened') && this._accountAlertsDialogOpened) {
      this.__loadFragment('ACCOUNT_ALERTS_DIALOG');
    }

    if(changedProps.has('_showsSubscriptionMessage') && this._showsSubscriptionMessage) {
      this.__loadFragment('SUBSCRIPTION_MESSAGE');
    }

    if (changedProps.has('_preferredLanguageDialogOpened') && this._preferredLanguageDialogOpened) {
      this.__loadFragment('PREFERRED_LANGUAGE_SELECTION_DIALOG');
    }

    if (changedProps.has('_useTemplateWelcomeDialogOpened') && this._useTemplateWelcomeDialogOpened) {
      this.__loadFragment('USE_TEMPLATE_WELCOME_DIALOG');
    }

    if (changedProps.has('_joinAccountWelcomeDialogOpened') && this._joinAccountWelcomeDialogOpened) {
      this.__loadFragment('JOIN_ACCOUNT_WELCOME_DIALOG');
    }
  }

  firstUpdated(changedProps) {
    super.firstUpdated && super.firstUpdated(changedProps);
    if (window.navigator.serviceWorker && !this.__isDisableServiceWorker()) {
      const state = store.getState();
      const iOSDevice = app.selectors.isIOSDevice(state);
      const isSafari = app.selectors.isSafari(state);
      installServiceWorker(this._elNewVersionMessage, { iOSDevice, isSafari }); 
    }
  }

  update(changedProps) {
    if (changedProps.has('_baseLang')) {
      i18NextSetBaseLang(this._baseLang);
      if(this._baseLang && i18next && i18next.isInitialized) {
        changeI18NextLanguage(multipleLanguage.selectors.currentLanguage(store.getState()));
      }
    }

    if (changedProps.has('_supportedLanguages')) {
      i18NextSetSupportedLanguages(this._supportedLanguages);
      if(i18next && i18next.isInitialized) {
        changeI18NextLanguage(multipleLanguage.selectors.currentLanguage(store.getState()));
      }
    }

    if(changedProps.has('_preferredLang')) {
      if(i18next && i18next.isInitialized && this._preferredLang) {
        changeI18NextLanguage(this._preferredLang);
      }
    }

    if(changedProps.has('_currentLang')) {
      if(i18next && i18next.isInitialized && this._currentLang) {
        changeI18NextLanguage(this._currentLang);
      }
    }

    if(changedProps.has('currentUserId')) {
      this._trackLoginUserOnSentry();
    }

    if(changedProps.has('language')) {
      this.__setHtmlLang();
      this.__loadLangFont();
    }
    super.update && super.update(changedProps);
  }

  updated(changedProps) {
    super.updated && super.updated(changedProps);
    if(changedProps.has('language') || changedProps.has('_importTrelloBoardSummary')) {
      this.__showImportTrelloBoardSummaryToast();
    }

    if (changedProps.has('_online')) {
      this.__manageOfflinePageAndToast(this._online, changedProps.get('_online'));
    }

    if ((changedProps.has('currentUserId') || changedProps.has('_embedLoginSignup')) && this._embedLoginSignup && this.currentUserId) {
      this._dispatchAlreadyLoginEvent();
    }

    if (
      changedProps.has('currentUserId') ||
      changedProps.has('_accountId') ||
      changedProps.has('_appBackgroundColor') ||
      changedProps.has('_pageName') ||
      changedProps.has('_dialogName') ||
      changedProps.has('_cardDetailDialogReferrerPage')
    ) {
      this.__changeBackgroundColor();
    }

    if (
      changedProps.has('_accessibleAccountsDetail') ||
      changedProps.has('_accountId') ||
      changedProps.has('_pageName') ||
      changedProps.has('_attachmentAction')
    ) {
      this.__showChangePreferredStoreToast(changedProps.get('_accessibleAccountsDetail'));
    }

    //Stop live listening on canvas iframe.
    if (changedProps.has('_pageType')) {
      const iframe = this.shadowRoot.querySelector('#canvasPageContentIframe');
      if (this._pageType !== 'WHITEBOARD' && changedProps.get('_pageType') === 'WHITEBOARD') {
        if (iframe && iframe.contentWindow) {
          iframe.contentWindow.postMessage(
            {
              type: 'DESTROY_CANVAS',
            },
            '*'
          );
        }
      }

      if (this._pageType === 'WHITEBOARD') {
        if (iframe && iframe.contentWindow) {
          iframe.contentWindow.postMessage(
            {
              type: 'SHOW_CANVAS',
            },
            '*'
          );
        }
      }
    }

    if (changedProps.has('_importTrelloBoardStatus')) {
      if (this._importTrelloBoardStatus === 'IN_PROGRESS') {
        if (this._action !== 'import-board' && this._importTrelloBoardActionBy && this.currentUserId && this._importTrelloBoardActionBy == this.currentUserId) {
          router.actions.setQueryParams({ action: 'import-board' });
        }
      }

      if (changedProps.get('_importTrelloBoardStatus') === 'IN_PROGRESS' && this._importTrelloBoardStatus === 'FAILED') {
        if (this._action === 'import-board') {
          router.actions.setQueryParams({ action: undefined, source: undefined, accountId: undefined });
        }
      }

      if (changedProps.get('_importTrelloBoardStatus') === 'IN_PROGRESS' && this._importTrelloBoardStatus === 'SUCCESS') {
        if (this._action === 'import-board') {
          router.actions.setQueryParams({ action: undefined, source: undefined, accountId: undefined });
        }
        this._oldImportTrelloBoardStatus = this._importTrelloBoardStatus;
        if (this._embedImportTrello) {
          store.dispatch(signup.actions.updateSignupDetails('TRELLO', {}));
          this._trelloImportCompleted();
        } else {
          const state = store.getState();
          const ownedAccounts = auth.selectors.currentUserOwnedAccounts(state);
          const accountId = ownedAccounts && ownedAccounts[0];
          store.dispatch(signup.actions.updateSignupDetails('TRELLO', {}));
          if (accountId && this._source !== 'create-board') {
            const accountHomePageUrl = app.selectors.wideLayout(state)
              ? `/home/favorite/boards`
              : `/${accountId}/home/favorite/boards`;
            router.actions.navigate(accountHomePageUrl, true);
          }
        }
      }
    }
  }

  get cardMoveToDoneConfirmDialog() {
    return this.shadowRoot.querySelector('card-move-to-done-confirm-dialog');
  }

  /**
   * Intialize amplitude.
   * @private
   */
  __initializeAmplitude() {
    if(this._impersonatedUser) {
      return;
    }
    const config = app.selectors.config(store.getState());
    amplitude.init({APIKey: config.amplitudeAPIKey, APIHost: config.amplitudeAPIHost});
  }

  /**
   * Intialize gtm.
   * @private
   */
  __initalizGtm() {
    if(this._impersonatedUser) {
      return;
    }
    const config = app.selectors.config(store.getState());
    const ApiKey = config.GTMApiKey;
    const extraParams = config.GTMExtraParams || '';
    gtm.init(ApiKey, extraParams);
  }

  /**
   * Initalize ga4.
   * @private
   */
  __initalizeGA4() {
    if(this._impersonatedUser) {
      return;
    }
    const config = app.selectors.config(store.getState());
    const GA4ApiKey = this.__isLocalEnvironment() ? 'G-16SPS70FZW': config.GA4ApiKey;
    const debugGA4 = config.debugGA4 === 'true' || config.debugGA4 === true;
    GA4.init(GA4ApiKey, debugGA4);
  }

  /**
   * Intialize linkd-in.
   * @private
   */
  __initalizeLinkdIn() {
    if(this._impersonatedUser) {
      return;
    }
    const config = app.selectors.config(store.getState());
    const linkedInPartnerId = config && config.linkedInPartnerId || '';
    linkedIn.init(linkedInPartnerId);
  }

  /**
   * Service worker is disable or not.
   * @returns {Boolean} `true` When current app is installed app
   *                            OR applition run locally using localhost OR proxy-url,
   *                    `false` otherwise.
   * @private
   */
  __isDisableServiceWorker() {
    return this.__isEnabledProxy() || this.__isLocalEnvironment();
  }

  /**
   * Application locally run or not.
   * @private
   */
  __isLocalEnvironment() {
    return window.location.host.indexOf('localhost') !== -1;
  }

  /**
   * Local proxy enabled or not.
   * @private
   */
  __isEnabledProxy() {
    return !!utils.getCookieVal('proxy_url');
  }

  /**
   * When service worker is register then Unregister service worker and reload a page.
   * @private
   */
  __unregisterServiceWorker() {
    if(!window.navigator || !window.navigator.serviceWorker){
      return;
    }

    window.navigator.serviceWorker.getRegistration().then(async (registration)=>{
      if(!registration) return;
      await registration.unregister();
      window.location.reload();
    });
  }

  /**
   * Dispatch post-message to complete trello import.
   */
  _trelloImportCompleted() {
    if (!this._embedImportTrello) {
      return;
    }

    const opener = window.top || window.opener;
    if (opener) {
      opener.postMessage(
        {
          type: 'IMPORT_TRELLO_BOARD_DIALOG_MODE_CHANGED',
          mode: 'SUCCESS',
        },
        '*'
      );
    }
  }

  /**
   * Shows a import trello board summary toast.
   * @private
   */
  __showImportTrelloBoardSummaryToast() {
    if(isEmpty(this._importTrelloBoardSummary)) {
      hideSnackBar('import-trello-board-summary', true);
      return;
    }

    const total = this._importTrelloBoardSummary && this._importTrelloBoardSummary.total || 0;
    const completed = this._importTrelloBoardSummary && this._importTrelloBoardSummary.completed || 0;
    if(total === completed) {
      hideSnackBar('import-trello-board-summary', true);
      return;
    }

    showSnackBar({
      id: `import-trello-board-summary`,
      message: i18next.t('toast.importTrelloBoardSummary', { m: completed, n: total }),
      timeout: 0,
      hideDismissBtn: true,
    });
  }

  /**
   * Shows a change preferred store toast.
   * @private
   */
  __showChangePreferredStoreToast(oldAccounts) {
    forEach(oldAccounts, (account) => {
      const accountId = get(account, 'id');
      if (accountId) {
        hideSnackBar(`change-preferred-store-is-in-progress-${accountId}`, true);
      }
    });

    if (
      this._attachmentAction === 'prefferred-store-selection' ||
      this._pageName === 'AUTHORIZE_FILE_STORE' ||
      this._pageName === 'PREFERRED_STORE_SELECTION' ||
      this._pageName === 'SUBSCRIPTION_RENEW' ||
      this._pageName === 'SUBSCRIPTION_AUTO_RENEW' ||
      this._pageName === 'BROWSER_NOT_SUPPORTED' ||
      this._pageName === 'UNDER_MAINTENANCE' ||
      this._pageName === 'APP_UPDATING' ||
      this._pageName === 'FORCE_UPDATE_APP' ||
      this._pageName === 'OFFLINE'
    ) {
      return;
    }

    forEach(this._accessibleAccountsDetail, (account) => {
      const accountId = get(account, 'id');
      const accountName = get(account, 'name');
      const nextPreferredStore = get(account, 'fileStore.nextPreferredStore');
      if (!!nextPreferredStore && (!this._accountId || (this._accountId && this._accountId == accountId))) {
        showSnackBar({
          id: `change-preferred-store-is-in-progress-${accountId}`,
          message: i18next.t('toast.changePreferredStore', { name: accountName }),
          timeout: 0,
          hideDismissBtn: true,
        });
      } else {
        hideSnackBar(`change-preferred-store-is-in-progress-${accountId}`, true);
      }
    });
  }

  __changeBackgroundColor() {
    const color = this.__getPageBackgroundColor();
    this.style.setProperty('--page-background-color', color);
    document.body.style.backgroundColor = color;
    this.__setPageBackgroundColor(color);
  }

  __setHtmlLang() {
    const html = document.querySelector('html');
    if(html) {
      html.lang = this.language || 'en';
    }
  }

  __loadLangFont() {
    const font = app.utils.getLangFont(this.language || 'en');
    this.__changeFontFamilyLink(font.link);
    this.__setFontFamily(font.fontFamily);
  }

  __changeFontFamilyLink(fontLink) {
    if(!fontLink) {
      return;
    }

    const preloadFamilyFont = document.querySelector('#preloadFamilyFont');
    const stylesheetFamilyFont = document.querySelector('#stylesheetFamilyFont');
    if(preloadFamilyFont && preloadFamilyFont.href != fontLink) {
      preloadFamilyFont.href = fontLink;
    }

    if(stylesheetFamilyFont && stylesheetFamilyFont.href != fontLink) {
      stylesheetFamilyFont.href = fontLink;
    }
  }

  __setFontFamily(fontFamily) {
    if(!fontFamily) {
      return;
    }

    const html = document && document.documentElement;
    if(html) {
      html.style.setProperty('--font-family', fontFamily);
      html.setAttribute("style", `--font-family: ${fontFamily}`);
    }
  }

  /**
   * @returns {String} page-background-color based on `currentUserId`, `_accountId`, `_pageName`, and `_appBackgroundColor` properties.
   * @private
   */
  __getPageBackgroundColor() {
    if(this._pageName === 'BLANK_PAGE' || this._isBlanckPage()) {
      return '#FFFFFF';
    }

    if (
      this._pageName === 'AUTHORIZE_FILE_STORE' ||
      this._pageName === 'PREFERRED_STORE_SELECTION' ||
      this._pageName === 'SUBSCRIPTION_RENEW' ||
      this._pageName === 'SUBSCRIPTION_AUTO_RENEW' ||
      this._pageName === 'BROWSER_NOT_SUPPORTED' ||
      this._pageName === 'UNDER_MAINTENANCE' ||
      this._pageName === 'APP_UPDATING' ||
      this._pageName === 'FORCE_UPDATE_APP' ||
      this._pageName === 'OFFLINE' ||
      this._pageName === 'VIEWS' ||
      (this._dialogName === 'CARD' && this._cardDetailDialogReferrerPage === 'VIEWS')
    ) {
      return '#D8E3DF';
    }

    if (!this.currentUserId || !this._accountId) {
      return '#D8E3DF';
    }

    const color = this._appBackgroundColor || localStorage && localStorage.getItem && localStorage.getItem(`background-color.${this._accountId}.${this.currentUserId}`) || '#D8E3DF';
    return color;
  }

  /**
   * Set background-color to local storage for next time shows this user to quick.
   * @private
   */
  __setPageBackgroundColor(color) {
    if (this._accountId && this.currentUserId) {
      const key = `background-color.${this._accountId}.${this.currentUserId}`;
      if (color != '#D8E3DF' && color) {
        localStorage && localStorage.setItem && localStorage.setItem(key, color);
      } else {
        localStorage && localStorage.removeItem && localStorage.removeItem(key);
      }
    }
  }

  async __i18nextInit(skipWait = false) {
    if(!skipWait) {
      const systemLanguageReq = firestoreRedux.getDocById(`system-language`, `default`);
      await systemLanguageReq.result;
    }

    const state = store.getState();
    this._supportedLanguages = multipleLanguage.selectors.supportedLanguagesCode(state) || ['en'];
    this._baseLang = multipleLanguage.selectors.defaultLanguage(state) || 'en';
    i18NextInit({state, supportedLanguages: this._supportedLanguages, baseLang: this._baseLang});
  }

  async __loadFragment(module) {
    try {
      switch (module) {
        case 'SIGNUP':
          await import('../user-signup/user-signup.js');
          break;
        case 'LOGIN_AND_SIGNUP':
          await import('./login-and-signup/login-and-signup-page.js');
          break;
        case 'FORGOT_PASSWORD':
          await import('./forgot-password-page.js');
          break;
        case 'JOIN_BOARD':
          await import('./kerika-board-page/join-board/join-board-page.js');
        case 'USE_CASE_BOARD_COPYING':
          await import('./kerika-board-page/use-case-board-copying-page.js');
        case 'BOARD':
          await import('./kerika-board-page/kerika-board-placeholder.js');
          break;
        case 'VIEWS':
          await import('../views/view-page.js');
          if (!this._wideLayout) {
            await import('./kerika-bottom-navigation.js');
          }
          break;
        case 'APP_HEADER':
          await import('./kerika-app-header/kerika-app-header.js');
          break;
        case 'BOARD_EXPLORER':
          if (this._wideLayout) {
            await import('../board-explorer/desktop/explorer-page.js');
          } else {
            await import('../board-explorer/board-list-placeholder.js');
            await import('./kerika-bottom-navigation.js');
          }
          break;
        case 'NOT_FOUND':
          await import('./kerika-404.js');
          break;
        case 'USER_ACTIONS':
          await import('./user/user-action-toolbar.js');
          break;
        case 'BROWSER_NOT_SUPPORTED':
          await import('./kerika-browser-compatibility.js');
          break;
        case 'LANDSCAPE':
          await import('./landscape-page.js');
          break;
        case 'DOWNLOAD_DIALOG':
          await import('./download-app-dialog.js');
          break;
        case 'PREFERRED_LANGUAGE_SELECTION_DIALOG':
          await import('./multiple-language/language-selection-dialog.js');
          break;
        case 'PROFILE':
          if (this._wideLayout) {
            await import('../board-explorer/profile/desktop/profile-desktop-dialog.js');
          } else {
            await import('../board-explorer/profile/profile-dialog.js');
          }
          break;
        case 'FORCE_UPDATE_APP':
          await import('./kerika-force-update-app-page.js');
          break;
        case 'UNDER_MAINTENANCE':
          await import('./kerika-under-maintenance-page.js');
          break;
        case 'OFFLINE':
          await import('./offline-page.js');
          break;
        case 'LEAVE_APP':
          await import('./leave-app-done-page.js');
          break;
        case 'UNSUBSCRIBE': 
          await import('./unsubscribe-emails-page.js');
          break;
        case 'GET_HELP':
          await import('./get-help-dialog.js');
          break;
        case 'CREATE_ACCOUNT':
          await import('../create-account/create-account-dialog.js');
          break;
        case 'CREATE_BOARD':
          await import('../create-board/create-board-dialog.js');
          break;
        case 'IMPORT_BOARD_INFO':
          await import('../user-signup/import-board-info-dialog.js');
          break;
        case 'IMPORT_BOARD_DATA_INFO': 
          await import('../user-signup/import-board-data-info-dialog.js');
          break;
        case 'INVITE_ACCOUNT_TEAM':
          await import('../components/invite-account-team-dialog.js');
          break;
        case 'JOIN_BOARD_DIALOG':
          await import('./kerika-board-page/join-board/join-board-dialog.js');
          break;
        case 'TRANSLATIONS_DIALOG':
          await import('./kerika-board-page/setting/manage-board-translations-dialog.js');
          break;
        case 'JOIN_BOARD_REGENERATE_LINK_DIALOG':
          await import('./kerika-board-page/join-board/join-board-regenerate-link-dialog.js');
          break;
        case 'AUTO_CREATE_BOARD':
          await import('./login-and-signup/create-board-in-progress-dialog.js');
          break;
        case 'BOARD_SWITCHER':
          await import('./kerika-board-page/board-switcher-dialog.js');
          break;
        case 'ACCOUNT_SWITCHER':
          await import('./account-switcher-dialog.js');
          break;
        case 'CARD_ACTIONS':
          await import('./kerika-board-page/card-action-toolbar.js');
          break;
        case 'CARD':
          await import('../card-details/kerika-card-placeholder.js');
          break;
        case 'MOVE_CARD':
          await import('../card-details/move-card-dialog.js');
          break;
        case 'ACCOUNT_PREFERENCES':
          if (this._wideLayout) {
            await import('./account-preferences/account-preferences-desktop-page.js');
          } else {
            await import('./account-preferences/account-preferences-page.js');
          }
          break;
        case 'MANAGE_ACCOUNT':
          await import('../manage-account/manage-account-page.js');
          break;
        case 'PREFERRED_STORE_SELECTION':
          await import('./file-store/preferred-store-selection-page.js');
          break;
        case 'SUBSCRIPTION_RENEW':
          await import('../subscription-v2/subscription-renew-page.js');
          break;
        case 'SUBSCRIPTION_AUTO_RENEW':
          await import('../subscription-v2/auto-renew/subscription-auto-renew-page.js');
          break;
        case 'CHANGE_FILE_STORAGE':
          await import('./file-store/change-file-storage-dialog.js');
          break;
        case 'AUTHORIZE_FILE_STORE':
          await import('./authorize-file-store-page.js');
          break;
        case 'CALENDAR':
          await import('../calendar/calendar-dialog.js');
          break;
        case 'SWITCH_TO_FREE_PLAN':
          await import('../subscription-v2/switch-to-free-plan-dialog.js');
          break;
        case 'PREVIEW_UPCOMING_INVOICE': 
          await import('../subscription-v2/billing-history/preview-upcoming-invoice-dialog.js');
          break;
        case 'SUBSCRIPTION_CANCELED':
          await import('../subscription-v2/cycle/subscription-canceled-notification-dialog.js');
          break;
        case 'SUBSCRIPTION_UNPAID':
          await import('../subscription-v2/cycle/subscription-unpaid-notification-dialog.js');
          break;
        case 'REQUEST_NON_PROFIT':
          await import('../subscription-v2/request-plan-non-profit-dialog.js');
          break;
        case 'MANAGE_TEAM_CONFIRM':
          await import('../subscription-v2/manage-team-confirm-switch-to-paid-plan-dialog.js');
          break;
        case 'CHANGE_PLAN':
          await import('../subscription-v2/subscription-buy-dialog.js');
          break;
        case 'USE_TEMPLATE_WELCOME_DIALOG':
          await import('./kerika-board-page/use-template-welcome-dialog.js');
          break;
        case 'VIDEOS':
          await import('../components/kerika-video-dialog.js');
          break;
        case 'LEAVE_ACCOUNT':
          await import('../components/kerika-leave-account-dialog.js');
          break;
        case 'JOIN_ACCOUNT_WELCOME_DIALOG':
          await import('../user-signup/join-account-welcome-dialog.js');
          break;
        case 'STORE_RESTRICTED':
          await import('../attachments/cloud-store-access-restricted-info-dialog.js');
          break;
        case 'TRANSFER_BOARD_ACCEPT_CONFIRM':
          await import('../board-transfer/board-transfer-accept-confirm-dialog.js')
          break;
        case 'AUTO_RENEW_ON':
          await import('../subscription-v2/auto-renew/subscription-auto-renew-on-confirm-dialog.js');
          break;
        case 'SEARCH':
          if (this._wideLayout) {
            await import('../search/search-popover-dialog.js');
          } else {
            await import('../search/search-fit-dialog.js');
          }
          break;
        case 'SEARCH_FILTERS':
          await import('../search/search-filter-dialog.js');
          break;
        case 'MESSAGES':
          await import('../messages/messages-dialog.js');
          break;
        case 'BROADCAST_MESSAGES':
          await import('../broadcast-message/broadcast-message-dialog.js');
          break;
        case 'FILE_PREVIEW':
          await import('../attachments/file-preview.js');
          break;
        case 'LEAVE_APP_DIALOG':
          await import('../board-explorer/profile/desktop/leave-app-dialog.js');
          break;
        case 'SUBSCRIPTION_MESSAGE':
          await import('../subscription-v2/cycle/subscription-message.js');
          break;
        case 'DRIVE_SETUP_PENDING':
          await import('./file-store/kerika-drive-setup-pending.js');
          break;
        case 'NOTIFICATION_PERMISSION_DIALOG':
          await import('./kerika-notification-permission-dialog.js');
          break;
        case 'CLOUD_STORE_AUTH_IDENTITY_NOT_FOUND_INFO':
          await import('../attachments/cloud-store-auth-identity-not-found-info-dialog.js');
          break;
        case 'TRIAL_END': 
          await import('../subscription-v2/cycle/trial-end-notification-dialog.js');
          break;
        case 'CUSTOMER_BALANCE':
          await import('../subscription-v2/billing-history/customer-balance-history-dialog.js');
          break;
        case 'ACCOUNT_ALERTS_DIALOG': 
          await import('./account-alerts-notification-dialog.js');
          break;
        default:
          break;
      }
    } catch (err) {
      console.error(`${this._deviceId}: Failed to import file for module ${module}`, err);
    }
  }

  /**
   * Logout from application
   */
  async logout(skipNavigation, redirectUrl) {
    await store.dispatch(auth.actions.logout({skipNavigation}));
    if(redirectUrl) {
      window.open(redirectUrl, '_self');
    }
  }

  /**
   * Change application default language
   * @param { String } lang - Application language. e.g. 'en', 'hi', 'gu'
   */
  changeLanguage(lang) {
    changeI18NextLanguage(lang);
  }

  /**
   * Invoked whenever redux state is changed
   * @param {Object} state - Redux store
   */
  stateChanged(state) {
    this._privatePage = board.selectors.privateBoard(state);
    this._wideLayout = app.selectors.wideLayout(state);
    this._isTabletDevice = app.selectors.isTabletDevice(state);
    this._isDesktopDevice = app.selectors.isDesktopDevice(state);
    this._landscapeView = app.selectors.isLandscape(state) && app.selectors.isMobileDevice(state);

    this.currentUserId = auth.selectors.currentUserId(state);
    this._accountId = router.selectors.accountId(state);

    this._source = router.selectors.source(state);

    this._forceUpdateServiceWorker = !app.selectors.isValidVersion(state);
    this._versionUpdateStatus = app.selectors.versionUpdateStatus(state);
    
    this._forceUpdateAppMode = !app.installedApp.selectors.isValidVersion(state);
    this._isInstalledApp = app.selectors.isInstalledApp(state);
    this._underMaintenance = app.selectors.isUnderMaintenance(state);

    this._embedLoginSignup = router.selectors.embedLoginSignup(state);
    this._embedImportTrello = router.selectors.embedTrelloImport(state);
    this._embedUseCaseBoards = router.selectors.embedUseCaseBoards(state);

    this._page = router.selectors.page(state);
    this._pageType = router.selectors.pageType(state);
    this._isCpageView = router.selectors.isCpageView(state);
    this.__managePageName();

    this._dialog = router.selectors.dialog(state);
    this.__manageDialogName();

    this._signupCompleted = signup.selectors.signupCompleted(state);

    this._supportedLanguages = multipleLanguage.selectors.supportedLanguagesCode(state);
    this._baseLang = multipleLanguage.selectors.defaultLanguage(state);
    this._preferredLang = multipleLanguage.selectors.preferredLanguage(state);
    this._presentLangSelection = multipleLanguage.selectors.presentLangSelection(state);
    this._preferredLanguageDialogOpened = !this._embedImportTrello && !this._embedLoginSignup && this.currentUserId && (!this._preferredLang || this._presentLangSelection === true) && this._signupCompleted;

    this._currentLang = multipleLanguage.selectors.currentLanguage(state);

    this._downloadDialogOpened =  !this._preferredLanguageDialogOpened && !this._embedImportTrello && !this._embedLoginSignup && downloadApp.selectors.downloadAppDialogOpen(state);

    this._online = app.selectors.isOnline(state);
    const pageParams = get(this._page, 'params');
    const dialogParams = get(this._dialog, 'params');
    const params = { ...pageParams, ...dialogParams };
    const chatAction = get(params, 'chat-action', '');
    const chatSubAction = get(params, 'chat-sub-action', '');
    this._action = get(params, 'action', '');
    this._searchAction = params['search-action'] || '';
    this._attachmentAction = get(params, 'attachment-action', '');
    this._attachmentSubAction = get(params, 'attachment-sub-action', '');
    this._joinBoardAction = get(params, 'join-board-action') || '';
    
    if (this._searchAction === 'filters') {
      this.__loadFragment('SEARCH_FILTERS');
    }

    if (this._dialogName === 'CARD_ACTIONS' || this._action === 'move-card') {
      this._cardActionsDialogCardId = params.cardId;
      this._cardActionsDialogAccountId = params.accountId;
      this._cardActionsDialogBoardId = params.boardId;
      this._cardActionsDialogColumnId = params.columnId;
    }

    this._appBackgroundColor = accountPreferences.selectors.backgroundColor(state);
    this._cardDetailDialogReferrerPage = get(params, 'referrer-page', '');
    this._actionDialogOpened = !!this._action || !!this._attachmentAction || !!chatAction || !!chatSubAction;
    const writableAccessibleAccounts = auth.selectors.writableAccessibleAccountsDetails(state);
    this._writableAccessibleAccountsLength = (writableAccessibleAccounts && writableAccessibleAccounts.length) || 0;
    this._autoCreateAccount = !!get(pageParams, 'account-name') && !!get(pageParams, 'auto-create-account');
    const ownedAccounts = auth.selectors.currentUserOwnedAccounts(state);
    this._ownedAccountId = ownedAccounts && ownedAccounts[0];
    this.__manageActionDialogName();
    this._force = router.selectors.force(state);

    this._filePreviewId = attachments.selectors.filePreviewId(state);
    this._accessibleAccountsDetail = auth.selectors.accessibleAccountsDetails(state);

    this._importTrelloBoardActionBy = importBoard.selectors.importTrelloBoardActionBy(state);
    this._importTrelloBoardAccountId = importBoard.selectors.importTrelloBoardAccountId(state);
    this._importTrelloBoardAccountOwnerId = importBoard.selectors.importTrelloBoardAccountOwnerId(state);
    this._importTrelloBoardSummary = importBoard.selectors.boardSummary(state);
    const newImportTrelloBoardStatus = importBoard.selectors.importBoardsStatus(state);
    if (this._importTrelloBoardStatus !== newImportTrelloBoardStatus) {
      this._oldImportTrelloBoardStatus = this._importTrelloBoardStatus;
      this._importTrelloBoardStatus = newImportTrelloBoardStatus;
    }

    this._useTemplateWelcomeDialogOpened = !this._preferredLanguageDialogOpened && !this._dialogName && !this._action && !this._embedImportTrello && !this._embedLoginSignup && !this._embedUseCaseBoards && board.selectors.useTemplateWelcomeDialogOpened(state);
    this._joinAccountWelcomeDialogOpened = !this._preferredLanguageDialogOpened && !this._dialogName && !this._action && !this._embedImportTrello && !this._embedLoginSignup && !this._embedUseCaseBoards && signup.selectors.joinAccountWelcomeDialogOpened(state);

    this._showsSubscriptionMessage = !!subscriptionV2.selectors.alertMessageType(state);
    this._impersonatedUser = auth.selectors.impersonatedUser(state);
    this.__computePushNotification(state, this._recomputePushNotificationTime);
    this.__computeaccountAlertsDialogOpened(state, this._accountId, this._recomputeAccountAlertsDialogTime);
  }

  /**
   * Init sentry error tracking code.
   * @protected
   */
  _initializeSentryErrorTracking() {
    //Return if application is run in development environment
    if (this._appConfig.environment === 'development') {
      return;
    }

    const environment = this._appConfig.environment || 'test';
    const version = this._appConfig.version || '';

    //Integrate Sentry error tracking tool (https://docs.sentry.io/quickstart)
    intializeSentry({
      dsn: this._appConfig.sentryDSN,
      release: version,
      environment: environment,
      attachStacktrace: true,
      integrations: [
        new CaptureConsole({
          levels: ['error'],
        }),
        new DedupeIntegration(),
      ],
      ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured', /\bResizeObserver\b/, 'access-cookie not found'],
    });

    configureScopeForSentry((scope) => {
      scope.setTag('device_id', this._deviceId);
      scope.setTag('environment', environment);
      scope.setTag('version', version);
    });
  }

  /**
   * Track user id on Sentry with error logs.
   * @protected
   */
  _trackLoginUserOnSentry() {
    if (this.currentUserId) {
      configureScopeForSentry((scope) => {
        scope.setUser({
          id: this.currentUserId,
        });
      });
      return;
    }

    configureScopeForSentry((scope) => {
      scope.setUser(null);
    });
  }

  /**
   * @returns {Boolean} `true` when url is logout.
   */
  _isLogoutPage() {
    return window.location.pathname === '/app/v1/logout' || window.location.pathname === '/app/v1/logout/' || window.location.pathname === '/logout/' || window.location.pathname === '/logout';
  }

  /**
   * @returns {Boolean} `true` when url is blanck-page.
   */
  _isBlanckPage() {
    return window.location.pathname === '/blank-page' || window.location.pathname === '/blank-page/';
  }

  /**
   * Validate browser is supported or not.
   * @return {Boolean} - `false` when any of the following is true.
   *                      - iOS (Safari) < 13
   *                      - svg is not supported
   *                      - HTML5 History API is not supported
   *                      - LocalStorage is not supported
   * @protected
   */
  _isBrowserSupported() {
    const state = store.getState();
    const bowser = state && state.app && state.app.bowser || {};
    if (bowser.os.name === 'iOS' && bowser.name === 'Safari' && bowser.browser.version < '13') {
      console.error("The browser is not supported.", JSON.stringify(bowser));
      return false;
    }
    
    try {
      const pushStateFeature = !!window.history.pushState;
      const objectAssignFeature = !!Object.assign;
      const localStorageFeature = !!(window.localStorage && window.localStorage.setItem) || false;
      const svgFeature = !!(document && document.createElementNS && document.createElementNS('http://www.w3.org/2000/svg', "svg").createSVGRect);
      const resizeObserverFeature = !!window.ResizeObserver && typeof window.ResizeObserver === 'function';
  
      if(pushStateFeature && objectAssignFeature && localStorageFeature && svgFeature && resizeObserverFeature) {
        return true;
      }
  
      console.error("The feature is not supported.", JSON.stringify({featrue: { pushState: pushStateFeature, objectAssign: objectAssignFeature, localStorage: localStorageFeature, svg: svgFeature, ResizeObserver: resizeObserverFeature }, device: bowser}));
      return false;
    } catch (error) {
      console.error("The feature is not supported.", JSON.stringify({error: error, device: bowser}));
      return false;
    }
  }

  /**
   * Manage page name
   * @private
   */
  __managePageName() {
    if (this._browserNotSupporterd) {
      this._pageName = 'BROWSER_NOT_SUPPORTED';
      return;
    }

    if (this._isInstalledApp && this._forceUpdateAppMode) {
      this._pageName = 'FORCE_UPDATE_APP';
      return;
    }

    if (this._underMaintenance) {
      this._pageName = 'UNDER_MAINTENANCE';
      return;
    }

    if (this._offlinePage) {
      this._pageName = 'OFFLINE';
      return;
    }

    if (!self._embedUseCaseBoards && !this._embedImportTrello && !this._embedLoginSignup && this._versionUpdateStatus == appVersionStatus.UPDATE_IN_PROGRESS) {
      this._pageName = 'APP_UPDATING';
      return;
    }

    this._pageName = get(this._page, 'name', '');
  }

  /**
   * Manage dialog name.
   * @private
   */
  __manageDialogName() {
    if (this._pageName === 'BROWSER_NOT_SUPPORTED' || this._pageName === 'APP_UPDATING') {
      this._dialogName = '';
      return;
    }

    this._dialogName = get(this._dialog, 'name', '');
  }

  /**
   * Imports & opens `kerika-date-picker-dialog`.
   * @param {Object} callback Callback function
   */
  async _openDatePicker(callback) {
    try {
      await import('../components/kerika-date-picker/kerika-date-picker-dialog.js');
      await this.updateComplete;
      const datePicker = this.renderRoot.querySelector('#date-picker');
      datePicker && datePicker.open();
      datePicker && datePicker.addEventListener('date-changed', (e) => {
        callback(get(e, 'detail.timeStamp'));
        router.actions.back();
      }, { once: true });
    } catch (err) {
      console.warn('Error in importing kerika-date-picker-dialog: ', err);
    }
  }

  /**
   * Opens account switcher dialog.
   * @param {Object} e Event
   */
  __openAccountSwitcherDialog(e) {
    const triggerEl = e.detail.triggerElement;
    this._accountSwitcherTriggerEl = triggerEl;
    router.actions.openAccountSwitcherDialog();
  }

  /**
   * Opens account admin switcher dialog.
   * @param {Object} e Event
   */
  __openAccountAdminSwitcherDialog(e) {
    const triggerEl = e.detail.triggerElement;
    this._accountSwitcherTriggerEl = triggerEl;
    router.actions.openAccountSwitcherDialog(false, 'admin');
  }

  /**
   * Manage action dialog name.
   */
  __manageActionDialogName() {
    if (this._action === 'create-account') {
      this._actionDialogName = 'CREATE_ACCOUNT';
    } else if (this._action === 'create-board') {
      this._actionDialogName = 'CREATE_BOARD';
    } else if (this._action === 'auto-create-board') {
      this._actionDialogName = 'AUTO_CREATE_BOARD';
    } else if (this._action === 'signup-login') {
      this._actionDialogName = 'SIGNUP_LOGIN_DIALOG';
    } else if (this._action === 'board-switcher') {
      this._actionDialogName = 'BOARD_SWITCHER';
    } else if (this._action === 'move-card') {
      this._actionDialogName = 'MOVE_CARD';
    } else if (this._action === 'store-restricted') {
      this._actionDialogName = 'STORE_RESTRICTED';
    } else if (this._action === 'import-board-info') {
      this._actionDialogName = 'IMPORT_BOARD_INFO';
    } else if (this._action === 'change-file-storage') {
      this._actionDialogName = 'CHANGE_FILE_STORAGE';
    } else if(this._action === 'import-board-data-info') {
      this._actionDialogName = 'IMPORT_BOARD_DATA_INFO';
    }

    return this._actionDialogName || '';
  }

  /**
   * Switches account based on router data.
   * @param {String} accountId Account Id
   */
  __switchAccount(accountId) {
    if (!accountId) {
      return;
    }
    if (this._pageName === 'ACCOUNT_PREFERENCES' && this._wideLayout) {
      router.actions.navigate(`/${accountId}/preferences/general`);
      return;
    }

    if (this._pageName === 'MANAGE_ACCOUNT' && this._wideLayout) {
      router.actions.navigate(`/${accountId}/manage-account/summary`);
      return;
    }

    router.actions.navigate(`/${accountId}/home/favorite/boards`);
  }

  /**
   * Mobile Orientation lock as a `portrait`.
   * @private
   */
  __mobileScreenLock() {
    if (app.selectors.isMobileDevice(store.getState())) {
      orientation.lockPortrait();
    }
  }

  /**
   * Show app connectivity warning toast.
   * @private
   */
  __showConnectivityToast() {
    if (this._online === undefined || this._underMaintenance || this._offlinePage || this._online) {
      return;
    }
    showSnackBar({
      id: 'connectivity-warning',
      message: i18next.t('toast.appDisconnected'),
      timeout: 0,
      hideDismissBtn: true,
      textColor: 'var(--warning-color)',
    });
  }

  /**
   * Hides connectivity warning toast.
   * @private
   */
  __hideConnectivityToast() {
    hideSnackBar('connectivity-warning', true);
  }

  /**
   * Shows offline page when app is offline initially. When app becomes offline in realtime, shows toast.
   * @param {Boolean} val Current online status of the app.
   * @param {Boolean} oldVal Previous online status of the app.
   * @private
   */
  __manageOfflinePageAndToast(val, oldVal) {
    if (oldVal === undefined && val === false) {
      this._offlinePage = true;
      this.__managePageName();
      return;
    }

    if (oldVal === true && val === false) {
      this.__showConnectivityToast();
      return;
    }

    if (val === true) {
      this.__hideConnectivityToast();
      this._offlinePage = false;
      this.__managePageName();
      return;
    }
  }

  _onPostMessage(e) {
    if (!this._embedLoginSignup && !this._embedImportTrello && !this._embedUseCaseBoards) {
      return;
    }

    const type = e && e.data && e.data.type;
    if(type === 'LANGUAGE_CHANGED') {
      const lang = e && e.data && e.data.lang;
      if(lang) {
        router.actions.setQueryParams({'lang': lang});
        this._embedUseCaseBoards && store.dispatch(app.actions.loadUseCaseBoards());
      }
      return;
    }

    const signupMode = (e && e.data && e.data.signupMode) || false;
    if (type === 'LOGIN_SIGNUP_MODE_CHANGED' || type === 'USE_TEMPLATE') {
      if (this.currentUserId) {
        this._dispatchAlreadyLoginEvent();
      } else {
        const template = e && e.data && e.data.template;
        const referrerPage = (e && e.data && e.data.referrerPage) || '';
        const referrerPageTrigger = (e && e.data && e.data.referrerPageTrigger) || '';
        const force = (e && e.data && e.data.force) || false;
        if (type === 'USE_TEMPLATE' && template) {
          router.actions.useTemplate(
            {
              id: template,
              params: {
                template: template,
                'referrer-page': referrerPage,
                'referrer-page-trigger': referrerPageTrigger,
                embedLoginSignup: true,
                mode: signupMode ? 'signup' : 'login',
                force,
              },
            },
            true
          );
        } else {
          router.actions.openAuthDialog({ mode: signupMode ? 'signup' : 'login', embedLoginSignup: true }, true);
        }
      }
    }
  }

  _dispatchAlreadyLoginEvent() {
    if (!this._embedLoginSignup) {
      return;
    }

    const opener = window.top || window.opener;
    if (this.currentUserId && opener) {
      opener.postMessage(
        {
          type: 'AUTH_LOGIN_COMPLETED',
          service: utils.getCookieVal('auth-service'),
        },
        '*'
      );
    }
  }

  /**
   * When content editable then not prevent context menu event.
   * Otherwise prevent contect menu evebt.
   * @protected
   */
  _oncontextmenu(e) {
    let path = (e.composedPath && e.composedPath()) || e.path || [];
    let preventEvent = true;
    forEach(path, function (el) {
      if (
        el.tagName === 'A' ||
        el.tagName === 'INPUT' ||
        el.tagName === 'TEXTAREA' ||
        (el.hasAttribute && el.hasAttribute('contenteditable')) ||
        (el.hasAttribute && el.hasAttribute('allow-contextmenu'))
      ) {
        if (app.selectors.isInstalledApp(store.getState()) && el.tagName === 'A') {
          clipboard.copy(el.href);
          showSnackBar({ message: i18next.t('toast.copyLink') });
        }
        preventEvent = false;
        return;
      }
    });

    if (preventEvent) {
      e.preventDefault();
    }
  }

  /**
   * Stop to open drag file/link into browser new tab.
   * @private
   */
  __onDragOver(event) {
    event.preventDefault && event.preventDefault();
  }

  /**
   * Stop to open drag file/link into browser new tab.
   * @private
   */
  __onDrop(event) {
    event.preventDefault && event.preventDefault();
  }
}

window.customElements.define('kerika-pwa', KerikaPWA);

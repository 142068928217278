import { put, takeEvery, take, call, select, all, fork, cancel, delay, takeLatest } from 'redux-saga/effects';
import firestoreRedux from '@dreamworld/firestore-redux';
import get from 'lodash-es/get';
import forEach from 'lodash-es/forEach';
import isEmpty from 'lodash-es/isEmpty';
import find from 'lodash-es/find';
import URI from '@dw/urijs-esm';
import { logEvent as amplitudeLogEvent } from '../../../analytics/amplitude.js';

// These are the actions.
import * as actions from './actions';
import * as app from '../../app';
import * as auth from '../../auth';
import * as router from '../../router';
import * as board from '../../board';
import * as multipleLanguage from '../../multiple-language';
import entityIdProvider from '../../../entity-id-provider';
import { getIdWoPrefix } from '../../../utils';
import * as signup from '../../signup';
import { isSystemTemplate } from '../selectors.js';

// These are the custom request Api.
import { requestApi } from '../../../request-api';
import { store } from '../../../store';

let previousDialog;
function* routeChangeHandler() {
  let state = yield select();
  let currentDialog = router.selectors.actionName(state);
  if (currentDialog === 'create-board') {
    if (previousDialog !== currentDialog) {
      yield put({ type: actions.CREATE_BOARD_DIALOG_OPENED });
    }
  } else if (previousDialog === 'create-board') {
    yield put({ type: actions.CREATE_BOARD_DIALOG_CLOSED });
  }
  previousDialog = router.selectors.actionName(state);
}

/**
 * Send request to create a new Board.
 * Open board if successfully create a board.
 * @param {Object} action action payload.
 */
function* createNewBoard(action) {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  const accountId = action.accountId;
  try {
    const ownedAccounts = auth.selectors.currentUserOwnedAccounts(state);
    const isOwnAccount = ownedAccounts && ownedAccounts.includes(accountId);

    let privacy = action.boardPrivacy;

    // Live listing is not needed, so only read data from firebase for 'board created' amplitude event.
    try {
      if(!privacy) {
        const accSettingsReq = firestoreRedux.getDocById('account-settings', `as_${accountId.replace('acc_', '')}`, {
          once: true
        });
        const accSettings = yield accSettingsReq.result;
        privacy = get(accSettings, 'boardPrivacy');
      }
    } catch (error) {}

    //Load default account settings for default board privacy.
    try {
      if(!privacy) {
        const defaultAccSettingsReq = firestoreRedux.getDocById('account-settings', `default`, {
          once: true
        });
        const defaultAccSettings = yield defaultAccSettingsReq.result;
        privacy = get(defaultAccSettings, 'boardPrivacy');
      }
    } catch (error) {}

    const templateDetailsReq = firestoreRedux.getDocById('boards', action.templateId, { once: true, waitTillSucceed: true });
    const templateDetails = yield templateDetailsReq.result;
    privacy = privacy || 'PRIVATE';

    const createBoardEventDetails = { 
      'template_id': action.templateId,
      'template_name': templateDetails && templateDetails.privacy === 'PUBLIC' ? templateDetails.name : 'NA',
      'is_template_from_template_account': !!isSystemTemplate(state, action.templateId),
      'type': action.boardType,
      'privacy': privacy,
      'in_own_account': isOwnAccount
    };

    amplitudeLogEvent('board created', createBoardEventDetails);
    const body = { name: action.name, accountId, privacy, lang: 'en' };
    const response = yield call(requestApi, `/board/boards/${action.templateId}`, { method: 'POST', body });
    // Makes template favorite.
    const allFavBoards = firestoreRedux.selectors.collection(state, 'favorite-boards');
    const currentFavBoardDoc = find(allFavBoards, { boardId: action.templateId });
    if (isEmpty(currentFavBoardDoc)) {
      yield put(board.actions.updateFavorite(action.templateId, true));
    }

    // Saves last used account in user-ui.
    firestoreRedux.save(`users/${userId}/user-ui`, {
      id: `ucbp_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`,
      userId,
      type: 'user-ui-create-board-preferences',
      lastUsedAccount: accountId,
    }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });

    const allUserAccUiDocs = firestoreRedux.selectors.collection(state, 'user-account-ui');
    const createBoardPreference = find(allUserAccUiDocs, { accountId, userId, type: 'account-ui-create-board-preferences' }) || {};
    const lastUsedTemplate = get(createBoardPreference, `lastUsedTemplate`, {});
    const id = get(createBoardPreference, 'id', `acbp_${entityIdProvider.getId()}`);
    lastUsedTemplate[[action.boardType]] = action.templateId;

    // Saves last used board type & last used template into user-account-ui.
    firestoreRedux.save(`users/${userId}/user-account-ui`, {
      id,
      userId,
      accountId,
      type: 'account-ui-create-board-preferences',
      lastUsedType: action.boardType,
      lastUsedTemplate,
    }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });

    //Open board.
    yield call(openBoard, response.id, action.accountId, action.boardType, action.boardPrivacy, action.templateId);
  } catch (err) {
    console.error(err);
    yield put({ type: actions.CREATE_BOARD_FAILED });
  }
}

/**
 * Open board
 * @param {Number} boardId Board Id
 * @param {Number} accountId Account Id
 * @param {String} boardType Board Type
 */
function* openBoard(boardId, accountId, boardType, boardPrivacy, templateId) {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const currentAction = router.selectors.actionName(state);
  const embedLoginSignup = router.selectors.embedLoginSignup(state);
  const currentPageName = router.selectors.pageName(state);
  const page = router.selectors.page(state);
  const filters = get(page, 'params.filters');
  let isExporerPageContextChange = false;

  if(!embedLoginSignup && currentPageName === "BOARD_EXPLORER" && filters && !filters.favorite && !filters.views) {
    isExporerPageContextChange = true;
    const uri = new URI();
    const tab = filters.template ? 'templates' : filters.views ? 'views' : 'boards';
    uri.path(`/home/favorite/${tab}`);
    router.actions.navigate(uri.toString(), true);
  }

  //Wait for board & it's member's data is ready.
  const boardRequest = firestoreRedux.getDocById('boards', boardId, { once: true, waitTillSucceed: true });
  const membersQuery = firestoreRedux.query('board-team-members', {
    where: [['boardId', '==', boardId]],
    once: true,
    waitTillSucceed: true,
  });

  yield boardRequest.result;
  yield membersQuery.result;

  // Update user signup details
  const ownedAccounts = auth.selectors.currentUserOwnedAccounts(state);
  const accessibleAccountsCount = auth.selectors.accessibleAccountsCount(state);
  const isOwnAccount = ownedAccounts && ownedAccounts.includes(accountId);
  if (isOwnAccount && accessibleAccountsCount <= 1 && currentAction === 'auto-create-board') {
    const request = firestoreRedux.query('user-accessible-boards', { where: [['userId', '==', userId], ['accountId', '==', accountId], ['boardId', '==', boardId]], waitTillSucceed: true, once: true });
    yield request.result;
    const accessibleBoards = get(state,`auth.accessibleBoards.${accountId}`);
    const accessibleBoardsCount = !isEmpty(accessibleBoards) && Object.keys(accessibleBoards).length || 0;
    if(accessibleBoardsCount <= 1) {
      yield put(signup.actions.updateSignupDetails('USE_TEMPLATE', {templateId: templateId, boardId: boardId}));
    }
  }

  yield put({ type: actions.CREATE_BOARD_DONE, accountId, boardId, boardType });
  const navigateUrl = `/${accountId}/board/${boardId}`;
  if (currentAction === 'auto-create-board' && embedLoginSignup) {
    yield delay(1000);
    useTemplateCompletedPostMessage(navigateUrl);
  } else {
    if(isExporerPageContextChange) {
      const uri = new URI();
      uri.removeQuery('action');
      uri.removeQuery('template');
      uri.removeQuery('embed-login-signup');
      uri.removeQuery('force');
      uri.removeQuery('fresh-user');
      uri.removeQuery('auto-create-account');
      uri.removeQuery('account-name');
      uri.removeQuery('action-type');
      router.actions.navigate(uri.toString(), true);
      router.actions.navigate(navigateUrl);
    } else {
      router.actions.navigate(navigateUrl, true);
    }
  }
}

function useTemplateCompletedPostMessage(url) {
  const uri = new URI();
  uri.path(url);
  uri.removeQuery('embed-login-signup');
  uri.removeQuery('action');
  uri.removeQuery('force');
  uri.removeQuery('template');
  uri.removeQuery('fresh-user');
  uri.removeQuery('auto-create-account');
  uri.removeQuery('account-name');
  uri.removeQuery('action-type');
  const opener = window.top || window.opener;
  if(opener && router.selectors.embedLoginSignup(store.getState())) {
    opener.postMessage({
      type: 'USE_TEMPLATE_COMPLETED',
      url: uri.toString()
    }, "*");
  }
}

/**
 * Load create new board detail.
 * When auto-create-account, loads default templates for create-board-dialog.
 */
function* initLoadCreateBoardData() {
  let state = yield select();
  let accountId;
  const autoCreateAccount = get(state, 'router.page.params.auto-create-account') === 'true';
  if (!autoCreateAccount) {
    if (app.selectors.wideLayout(state)) {
      accountId = auth.selectors.lastUsedAccount(state);
    } else {
      accountId = router.selectors.accountId(state);
    }

    let writableAccessibleAccounts = auth.selectors.writableAccessibleAccounts(state);
    if (writableAccessibleAccounts && writableAccessibleAccounts.indexOf(accountId) === -1) {
      accountId = auth.selectors.accountWithWritePermission(state);
    }
  }
  yield put(actions.loadCreateBoardDialogData(accountId));
}

/**
 * Load account detail.
 */
function* loadCreateBoardDialogData(action) {
  const state = yield select();
  const accountId = action.accountId;
  const templateAcId = app.selectors.templateAcId(state);
  try {
    yield call(disconnectFirestore);

    if(templateAcId) {
      const defaultLanguage = multipleLanguage.selectors.defaultLanguage(state) || 'en';
      const currentLanguage = multipleLanguage.selectors.currentLanguage(state);
      const langs = defaultLanguage === currentLanguage ? [defaultLanguage] : [defaultLanguage, currentLanguage];
      const query = firestoreRedux.query('boards', {
        where: [
          ['template', '==', true],
          ['accountId', '==', templateAcId],
          ['status', '==', 'ACTIVE'],
          ['privacy', '==', 'PUBLIC'],
          ['lang', 'in', langs]
        ],
        requesterId: 'create-board'
      });
      yield query.result;
    }

    if (accountId) {
      // Loads account settings & user-account-ui data.
      yield call(loadAccountSettings, accountId);
      const accessibleBoards = get(state, `auth.accessibleBoards.${accountId}`);
      forEach(accessibleBoards, (type, boardId) => {
        boardId && firestoreRedux.getDocById('boards', boardId);
      });
    }

    yield call(loadDefaultAccountSettings);
    yield put({
      type: actions.CREATE_BOARD_DIALOG_LOAD_DATA_DONE,
      accountId: accountId || templateAcId,
    });
  } catch (error) {
    console.warn('Failed to load create board details: ', error);
  }
}

function* loadCreateTemplateDialogData({accountId}) {
  try {
    yield call(disconnectFirestore);
    if (accountId) {
      yield call(loadAccountSettings, accountId);
    }
    yield call(loadDefaultAccountSettings);
    yield put({
      type: actions.CREATE_TEMPLATE_DIALOG_LOAD_DATA_DONE,
      accountId
    });
  } catch (error) {
    console.warn('Failed to load create template details: ', error);
  }
}

/**
 * Loads acount settings for default privacy.
 */
function* loadAccountSettings(accountId) {
  if(!accountId) {
    return;
  }
  const query = firestoreRedux.getDocById(`account-settings`, `as_${accountId.replace('acc_', '')}`, { requesterId: 'create-board' });
  return yield query.result;
}

/**
 * Loads defualt acount settings for default privacy.
 */
function* loadDefaultAccountSettings() {
  const query = firestoreRedux.getDocById(`account-settings`, `default`, { requesterId: 'create-board' });
  return yield query.result;
}

/**
 * Disconnect from firestore.
 */
function* disconnectFirestore() {
  firestoreRedux.cancelQueryByRequester('create-board');
}

/**
 * Manage create a new board details.
 */
function* createNewBoardFlow() {
  while (yield take([actions.CREATE_BOARD_DIALOG_OPENED])) {
    let task = yield fork(function* () {
      yield all([call(initLoadCreateBoardData)]);
    });
    yield take([actions.CREATE_BOARD_DIALOG_CLOSED]);
    yield cancel(task);
    yield call(disconnectFirestore);
  }
}

/**
 * Watch router change.
 * Dispatch CREATE_BOARD_DIALOG_OPENED, CREATE_BOARD_DIALOG_CLOSED actions based on previous & current URL.
 */
function* watchRouter() {
  //If dialog is already opened, check once.
  yield call(routeChangeHandler);
  yield takeEvery(router.actions.UPDATE_ROUTER, routeChangeHandler);
}

/**
 * Init Saga.
 */
function* createBoardSaga() {
  yield all([
    call(watchRouter),
    call(createNewBoardFlow),
    takeEvery(actions.CREATE_BOARD, createNewBoard),
    takeLatest(actions.CREATE_BOARD_DIALOG_LOAD_DATA, loadCreateBoardDialogData),
    takeLatest(actions.CREATE_TEMPLATE_DIALOG_LOAD_DATA, loadCreateTemplateDialogData)
  ]);
}

export default createBoardSaga;

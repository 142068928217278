import { createSelector } from 'reselect';
import { getIdWoPrefix } from '../../utils';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty.js';
import moment from 'moment/src/moment.js';
import isEqual from 'lodash-es/isEqual.js';

//Redux
import * as app from '../app';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import * as router from '../router';
import * as auth from '../auth';
import { convertFirestoreTimestamp } from '../../utils.js';

/**
 * @param { Object } state - Redux state detail object
 * @returns { Object } - subscription details for given/current account.
 */
export const subscription = createSelector(
  (state, accountId) => auth.selectors.currentUserId(state),
  (state, accountId) => firestoreRedux.selectors.doc(state, 'subscriptions', `sub_${getIdWoPrefix({ id: accountId || router.selectors.accountId(state), prefix: 'acc_' })}`),
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.subscription.data`),
  (userId, subscription, _subscription) => userId ? subscription: _subscription || {}
);

const subsriptionQueryStatus = (state, accountId) => firestoreRedux.selectors.queryStatus(state, `subscriptions.sub_${getIdWoPrefix({ id: accountId || router.selectors.accountId(state), prefix: 'acc_' })}`);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - Status of the subscription details API call.
 */
export const subscriptionStatus = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.subscription.status`),
  (status) => status || 'IN_PROGRESS'
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Object } - status of subscription of given/currently opened account.
 */
export const status = createSelector(
  (state, accountId) => subscription(state, accountId),
  (subscription) => subscription?.status
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } account currency.
 */
export const currency = createSelector(
  (state, accountId) => firestoreRedux.selectors.doc(state, 'accounts', accountId || router.selectors.accountId(state)),
  (attrs) => attrs?.currency || 'USD'
);

export const _pricingData = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.pricing.data`),
  (data) => data || {}
);
/**
 * @param { Object } state - Redux state detail object
 * @returns { Object } - Pricing details for given/current account.
 *  - This is an actual response to the API call to get the pricing details. 
 *  - see response example: https://drive.google.com/file/d/1-DFWKIPV8iQRpb1Uh9fkmvTgLib222iZ/view 
 */
export const pricing = createSelector(
  (state, accountId) => _pricingData(state, accountId),
  (pricingDetails) => pricingDetails && pricingDetails.countryPrice || {}
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - Status of the pricing details API call.
 */
export const pricingStatus = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.pricing.status`),
  (status) => status || 'IN_PROGRESS'
);

/**
 * @returns {Object} current user's plan details.
 *  - @param {String} currencySymbol - Currency symbol.
 *  - @param {String} name - Plan name.
 *  - @param {Number} price - Price of the plan.
 *  - @param {String} interval - Interval (MONTHLY/YEARLY).
 *  - @param {String} stripePriceId - Stripe price ID.
 */
export const planDetails = createSelector(
  (state, accountId) => subscription(state, accountId),
  (state, accountId) => subsriptionQueryStatus(state, accountId),
  (state, accountId) => pricing(state, accountId),
  (state, accountId) => pricingStatus(state, accountId),
  (subscription, subsriptionQueryStatus, pricing, status) => {
    if ((isEmpty(pricing) && status !== 'SUCCESS') || (isEmpty(subscription) && subsriptionQueryStatus === 'PENDING')) {
      return;
    }

    const priceId = subscription?.stripePriceId;
    const currencySymbol = pricing?.currencySymbol || '$';
    if (!priceId) {
      return { plan: 'SOLO', currencySymbol };
    }

    const plans = pricing?.plans || {};
    for (const plan in plans) {
      const prices = plans[plan] || [];
      const price = prices.find(price => price.stripePriceId === priceId);
      if (!isEmpty(price)) {
        return { currencySymbol, name: plan.toUpperCase(), price: price.price, interval: price.interval, stripePriceId: price.stripePriceId };
      }
    }

    return { plan: 'SOLO', currencySymbol };
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { String } - Plan name for given/current account.
 */
export const plan = createSelector(
  (state, accountId) => planDetails(state, accountId),
  (planDetails) => {
    if(planDetails === undefined) {
      return;
    }
    return planDetails?.name || 'SOLO'
  }
);

/**
 * Available plans for given account.
 * @returns {Array} - List of available plans for given account.
 *  - @param {String} name - Plan name.
 *  - @param {Number} price - Price of the plan.
 *  - @param {String} currencySymbol - Currency symbol.
 *  - @param {String} stripePriceId - Stripe price ID.
 *  - @param {String} interval - Interval (monthly/yearly).
 */
export const availablePlans = createSelector(
  (state, accountId, interval) => pricing(state, accountId),
  (state, accountId, interval) => pricingStatus(state, accountId),
  (state, accountId, interval) => interval || 'MONTHLY',
  (pricing, status, interval) => {
    if(status !== 'SUCCESS') {
      return;
    }

    const plans = [{ name: 'SOLO' }];
    const _plans = pricing?.plans || {};
    const currencySymbol = pricing?.currencySymbol || '$';
    for (const plan in _plans) {
      const _plan = { name: plan.toUpperCase(), currencySymbol};
      const prices = _plans[plan] || [];
      const price = prices.find(price => price.interval === interval);
      _plan.price = price.price;
      _plan.stripePriceId = price.stripePriceId;
      plans.push(_plan);
    }
    return plans || [];
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Object } - charges for the subscription.
 *  - @param { Number } seats - Number of seats.
 *  - @param { Number } rate - Rate per seat in cent.
 *  - @param { Number } subTotal - sub-total amount in cent (seats * rate).
 *  - @param { Array } totalDiscountAmounts[] - Discount details.
 *    - @param { Number } amount - Discount amount in cent.
 *    - @param { String } name - Discount name.
 *    - @param { String } percentage - Discount percentage.
 *  - @param { Number } totalExcludingTax - subtotal excluding tax amount in cent.
 *  - @param { Array } totalTaxAmounts[]
 *    - @param { Number } amount - Tax amount in cent.
 *    - @param { Number } taxableAmount - Taxable amount in cent.
 *    - @param { String } percentage - Tax percentage.
 *    - @param { String } name - Tax name.
 *    - @param { String } jurisdiction - jurisdiction (tax-applied city or state name)
 *  - @param { Number } total - Total amount in cent.
 *  - @param { Number } startingBalance - customer starting balance in cent.
 *  - @param { Number } endingBalance - customer ending balance in cent.
 *  - @param { Number } appliedBalance - applied balance in cent.
 *  - @param { Number } amountDue - Amount due in cent.
 *  - @param { String } expiry - Expiry date of the new subsription.
 */
export const charges = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.charges.data`),
  (charges) => charges || {}
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - Status of the charges computation API call.
 */
export const chargesStatus = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.charges.status`),
  (status) => status || ''
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Object } - start subsription process details.
 *  - @param { Number } seats - Number of seats.
 *  - @param { String } priceId - Pricing ID.
 *  - @param { String } interval - Interval (monthly/yearly).
 *  - @param { Boolean } autoRenewal - Auto-renewal flag.
 *  - @param { String } accountId - Account ID.
 *  - @param { Enum } plan - Plan name.
 */
export const changePlan = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.changePlan.data`),
  (data) => data || {}
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - Status of the change plan. it can be 'IN_PROGRESS', 'SUCCESS', 'ERROR'.
 */
export const changePlanStatus = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.changePlan.status`),
  (status) => status || ''
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - change plan process is failed due to this error.
 *                   - POSSIBLE VALUES: 'ALREADY_SUBSCRIBED', 'UNAUTHORIZED','ACCOUNT_NOT_FOUND', 'UNKNOWN', 'PRICE_NOT_MATCH_WITH_COUNTRY'
 */
export const changePlanError = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.changePlan.error`),
  (error) => error || ''
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Object } - billing details for given/current account.
 *  e.g. { "name": "shakil mansuri", "phone": "+917405567017", "organization": null, "address": { "line1": "Near primay school", "line2": "Panchayat Street", "city": "Bharuch", "state": "GJ", "postalCode": "392012", "country": "IN"}, "billingEmails": [], "localTax": { "type": null, "value": null } }
 */
export const billingDetails = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.billingDetails.data`),
  (billingDetails) => billingDetails || {}
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - Status of the billing details API call.
 */
export const billingDetailsStatus = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.billingDetails.status`),
  (status) => status || 'IN_PROGRESS'
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - Status of the change auto renew process.
 */
export const changeAutoRenewStatus = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.changeAutoRenew.status`),
  (status) => status || ''
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - change auto renew process is failed due to this error.
 */
export const changeAutoRenewError = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.changeAutoRenew.error`),
  (error) => error || ''
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - Status of the setup payment method process.
 */
export const setupPaymentMethodStatus = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.setupPaymentMethod.status`),
  (status) => status || ''
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - setup payment method process is failed due to this error.
 */
export const setupPaymentMethodError = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.setupPaymentMethod.error`),
  (error) => error || ''
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Boolean } - setup payment method process is in same window.
 */
export const setupPaymentMethodInSameWindow = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.setupPaymentMethod.inSameWindow`),
  (inSameWindow) => inSameWindow || false
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Array } - List of invoices for given/current account.
 *  - @param { String } id - Invoice ID.
 *  - @param { String } number - Invoice number.
 *  - @param { Enum } status - Invoice status. Possible values: 'PAID', 'CLOSED', 'FAILED', 'FAILED_RETRING', 'OVERDUE', 'PENDING'
 *  - @param { Enum } stripeStatus - Stripe status. Possible values: 'paid', 'pending', 'failed', 'overdue', 'closed', 'failed_retring'.
 *  - @param { Number } amountDue - Amount due in cent.
 *  - @param { Number } total - Total amount in cent.
 *  - @param { String } currency - Currency of the invoice.
 *  - @param { Timestamp } createdAt - Invoice creation date.
 *  - @param { Timestamp } dueDate - Invoice due date.
 *  - @param { Timestamp } paidAt - Invoice paid date.
 *  - @param { Object } paymentMethodDetails - Payment details.
 *    - @param { String } type - Payment method type.
 *    - @param { String } displayTemplate - Display template for payment method.
 *    - @param { Object } * - Payment method specific details based on type.
 *  - @param { Object } failure - Failure details.
 *    - @param { String } code - Failure code.
 *    - @param { String } message - Failure message.
 *  - @param { Timestamp } nextPaymentAttempt - Next payment attempt date.
 *  - @param { Number } attemptCount - Number of payment attempts.
 *  - @param { String } notes - Invoice notes.
 *  - @param { String } invoicePdfUrl - Invoice PDF URL.
 *  - @param { String } receiptPdfUrl - Receipt PDF URL.
 *  - @param { String } hostedInvoiceUrl - Hosted invoice
 */
export const invoices = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.invoices.data.invoices`),
  (invoices) => {
    if (!invoices || invoices.length === 0) {
      return [];
    }

    return invoices.map(invoice => {
      const isPaid = invoice.status === 'paid' || invoice.paid || invoice.paid_out_of_band;
      const isClosed = invoice.status === 'void' || invoice.status === 'uncollectible';
      const isPastDue = invoice.status === 'open' && invoice.due_date && invoice.due_date < Date.now();
      const isRetrying = invoice.status === 'open' && invoice.attempted && invoice.next_payment_attempt;
      const isFailed = invoice.status === 'open' && invoice.attempted && !invoice.next_payment_attempt;

      const status = isPaid ? 'PAID' : 
                    isClosed ? 'CLOSED' : 
                    isRetrying ? 'FAILED_RETRING' :
                    isFailed ? 'FAILED' :
                    isPastDue ? 'OVERDUE' : 'PENDING';

      const paidAt = convertFirestoreTimestamp(invoice?.status_transitions?.paid_at || 
                    invoice?.status_transitions?.voided_at || 
                    invoice?.status_transitions?.marked_uncollectible_at || 0);

      const createdAt = convertFirestoreTimestamp(invoice.effective_at || 
                        invoice.automatically_finalizes_at || 
                        invoice?.status_transitions?.finalized_at || 
                        invoice.created || 
                        0);
      // Extract payment method details from invoice for UI display
      const paymentMethodDetails = {};
      
      // Set basic payment type
      if (invoice?.charge?.payment_method_details?.type) {
        paymentMethodDetails.type = invoice.charge.payment_method_details.type;
        
        // Extract details based on payment method type
        const details = invoice.charge.payment_method_details;
        
        // Handle card payments
        if (details.type === 'card' && details.card) {
          paymentMethodDetails.card = {
            brand: details.card.brand || '',
            last4: details.card.last4 || '',
            expMonth: details.card.exp_month,
            expYear: details.card.exp_year
          };
          // Add template for card display
          paymentMethodDetails.displayTemplate = `${details.card.brand} - ${details.card.last4} ${details.card.exp_month}/${details.card.exp_year}`;
        }
        
        // Handle ACH credit transfers
        if (details.type === 'ach_credit_transfer' && details.ach_credit_transfer) {
          paymentMethodDetails.ach_credit_transfer = {
            accountNumber: details.ach_credit_transfer.account_number,
            routingNumber: details.ach_credit_transfer.routing_number,
            bankName: details.ach_credit_transfer.bank_name,
            swiftCode: details.ach_credit_transfer.swift_code
          };

          // Add template for ACH credit transfer display
          paymentMethodDetails.displayTemplate = `${details.ach_credit_transfer.bank_name} - ${details.ach_credit_transfer.account_number}`;
        }
        
        // Handle ACH debit
        if (details.type === 'ach_debit' && details.ach_debit) {
          paymentMethodDetails.ach_debit = {
            bankName: details.ach_debit.bank_name || '',
            last4: details.ach_debit.last4 || '',
            routingNumber: details.ach_debit.routing_number
          };
          // Add template for ACH debit display
          paymentMethodDetails.displayTemplate = `${details.ach_debit.bank_name} - ${details.ach_debit.last4}`;
        }

        // Handle us_bank_account
        if (details.type === 'us_bank_account' && details.us_bank_account) {
          paymentMethodDetails.us_bank_account = {
            bankName: details.us_bank_account.bank_name || '',
            last4: details.us_bank_account.last4 || '',
            routingNumber: details.us_bank_account.routing_number
          };
          // Add template for ACH debit display
          paymentMethodDetails.displayTemplate = `${details.us_bank_account.bank_name} - ${details.us_bank_account.last4}`;
        }
        
        // Handle bank transfers
        if (details.type === 'bank_transfer' && details.bank_transfer) {
          paymentMethodDetails.bank_transfer = {
            type: details.bank_transfer.type,
            bankName: details.bank_transfer.bank_name,
            reference: details.bank_transfer.reference
          };
          // Add template for bank transfer display
          paymentMethodDetails.displayTemplate = `${details.bank_transfer.bank_name} - Ref: ${details.bank_transfer.reference}`;
        }
        
        // Add UPI payments (common in India)
        if (details.type === 'upi' && details.upi) {
          paymentMethodDetails.upi = {
            vpa: details.upi.vpa
          };
          // Add template for UPI display
          paymentMethodDetails.displayTemplate = `UPI - ${details.upi.vpa}`;
        }
        
        // Handle wallets (PayPal, ApplePay, etc)
        if (details.type === 'wallet' && details.wallet) {
          paymentMethodDetails.wallet = {
            type: details.wallet.type
          };
          // Add template for wallet display
          paymentMethodDetails.displayTemplate = `${details.wallet.type || 'Wallet'}`;
        }
      }

      const failure = {
        code: invoice?.charge?.failure_code || '',
        message: invoice?.charge?.failure_message || ''
      }

      // Calculate applied balance with proper fallback
      const startingBalance = invoice.starting_balance || 0;
      const endingBalance = invoice.ending_balance || 0;
      const appliedBalance = startingBalance ? startingBalance - endingBalance : 0;

      return {
        id: invoice.id,
        number: invoice.number,
        status,
        stripeStatus: invoice.status,
        amountDue: invoice.amount_due,
        total: invoice.total,
        currency: invoice.currency || 'usd',
        createdAt,
        dueDate: convertFirestoreTimestamp(invoice.due_date || 0),
        paidAt,
        paymentMethodDetails,
        failure,
        nextPaymentAttempt: invoice.next_payment_attempt,
        notes: invoice.notes,
        invoicePdfUrl: invoice.invoice_pdf,
        receiptPdfUrl: invoice?.charge?.receipt_url,
        hostedInvoiceUrl: invoice.hosted_invoice_url,
        attemptCount: invoice.attempt_count,
        appliedBalance
      };
    }).sort((a, b) => {
      // Enhanced sorting: ensure we handle all cases even if timestamps are missing
      const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
      const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
      return dateB - dateA; // Newest first
    });
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Object } - Upcoming invoice details for given/current account.
 *  - @param { String } id - Invoice ID.
 *  - @param { Number } createdAt - Invoice creation date.
 *  - @param { String } currency - Invoice currency.
 *  - @param { Array } lines[] - Invoice line items.
 *    - @param { String } description - Line item description.
 *    - @param { Number } quantity - Line item quantity.
 *    - @param { String } currency - Line item currency.
 *    - @param { Number } unitAmount - Line item unit amount in cent.
 *    - @param { Number } amount - Line item amount in cent.
 *    - @param { String } plan - Line item plan name.
 *  - @param { Number } subTotal - Sub-total amount in cent.
 *  - @param { Array } totalDiscountAmounts[] - Discount details.
 *    - @param { Number } amount - Discount amount in cent.
 *    - @param { String } name - Discount name.
 *    - @param { String } percentage - Discount percentage.
 *  - @param { Number } totalExcludingTax - Sub-total excluding tax amount in cent.
 *  - @param { Array } totalTaxAmounts[] - Tax details.
 *    - @param { Number } amount - Tax amount in cent.
 *    - @param { Number } taxableAmount - Taxable amount in cent.
 *    - @param { String } percentage - Tax percentage.
 *    - @param { String } name - Tax name.
 *    - @param { String } jurisdiction - Tax jurisdiction.
 *  - @param { Number } total - Total amount in cent.
 *  - @param { Number } appliedBalance - Applied balance in cent.
 *  - @param { Number } amountDue - Amount due in cent.
 */
export const upcomingInvoice = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.invoices.data.upcomingInvoice`),
  (upcomingInvoice) => {
    if (!upcomingInvoice || isEmpty(upcomingInvoice)) {
      return {};
    }

    const lines = upcomingInvoice?.lines?.data || [];
    const _newLines = lines.map(line => ({
      id: line.id || '',
      description: line.description || '',
      quantity: line.quantity || 0,
      currency: line.currency || 'usd',
      unitAmount: line.unit_amount_excluding_tax || line.unit_amount || 0,
      amount: line.amount || 0,
    }));

    const totalDiscountAmounts = upcomingInvoice?.total_discount_amounts || [];
    const _newTotalDiscountAmounts = totalDiscountAmounts.map(discount => ({
      id: get(discount, 'discount.id', ''),
      amount: discount.amount || 0,
      name: get(discount, 'discount.coupon.name', ''),
      percentage: get(discount, 'discount.coupon.percent_off', '')
    }));

    // Process tax amounts with improved property access
    const totalTaxAmounts = upcomingInvoice?.total_tax_amounts || [];
    const _newTotalTaxAmounts = totalTaxAmounts.map(tax => ({
      amount: tax.amount || 0,
      taxableAmount: tax.taxable_amount || 0,
      percentage: get(tax, 'tax_rate.effective_percentage', ''),
      id: get(tax, 'tax_rate.id', ''),
      name: get(tax, 'tax_rate.display_name', ''),
      jurisdiction: get(tax, 'tax_rate.jurisdiction', '')
    }));

    // Calculate applied balance with proper fallback
    const startingBalance = upcomingInvoice.starting_balance || 0;
    const endingBalance = upcomingInvoice.ending_balance || 0;
    const appliedBalance = startingBalance ? startingBalance - endingBalance : 0;

    const createdAt = convertFirestoreTimestamp(upcomingInvoice.effective_at || upcomingInvoice.automatically_finalizes_at || upcomingInvoice?.status_transitions?.finalized_at || upcomingInvoice.created || 0);

    return {
      id: upcomingInvoice.id || '',
      createdAt,
      lines: _newLines,
      currency: upcomingInvoice.currency || 'usd',
      subTotal: upcomingInvoice.subtotal || 0,
      totalDiscountAmounts: _newTotalDiscountAmounts,
      totalExcludingTax: upcomingInvoice.total_excluding_tax || upcomingInvoice.subtotal || 0,
      totalTaxAmounts: _newTotalTaxAmounts,
      total: upcomingInvoice.total || 0,
      appliedBalance,
      amountDue: upcomingInvoice.amount_due || 0,
    };
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Object } - Invoice details for given invoice.
 */
export const invoice = createSelector(
  (state, accountId, invoiceId) => invoices(state, accountId).find(invoice => invoice.id === invoiceId),
  (invoice) => invoice || {}
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - Status of the invoices API call.
 */
export const invoicesStatus = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.invoices.status`),
  (status) => status || 'IN_PROGRESS'
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Number } - Balance of the customer.
 */
export const customerBalance = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.customerBalance.data.balance`),
  (balance) => balance || 0
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - Status of the customer balance API call.
 */
export const customerBalanceStatus = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.customerBalance.status`),
  (status) => status || 'IN_PROGRESS'
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Array } - List of customer balance transactions.
 *  - @param { String } id - Transaction ID.
 *  - @param { Timestamp } createdAt - Transaction creation date.
 *  - @param { Enum } type - Transaction type. Possible values: adjustment, applied_to_invoice, credit_note, initial, invoice_overpaid, invoice_too_large, invoice_too_small, unspent_receiver_credit, or unapplied_from_invoice
 *  - @param { Number } amount - Transaction amount in cent.
 *  - @param { String } notes - Transaction description.
 *  - @param { String } currency - Currency of the transaction.
 *  - @param { Number } endingBalance - Ending balance after transaction in cent.
 *  - @param { Object } invoice - Invoice details.
 *    - @param { String } id - Invoice ID.
 *    - @param { String } number - Invoice number.
 *    - @param { String } invoicePdfUrl - Invoice PDF URL.
 */
export const customerBalanceTransactions = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.customerBalance.data.transactions`),
  (items) => {
    if (!items || items.length === 0) {
      return [];
    }

    return items.map(item => {
      return {
        id: item.id,
        type: item.type,
        createdAt: item.created,
        amount: item.amount,
        currency: item.currency,
        endingBalance: item.ending_balance,
        notes: item.description,
        invoice: {
          id: item?.invoice?.id || '',
          number: item?.invoice?.number || '',
          invoicePdfUrl: item?.invoice?.invoice_pdf || ''
        }
      };
    }).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Number } - Number of pending invoices.
 * - It's a count of pending invoices.
 */
export const pendingInvoiceCount = createSelector(
  (state, accountId) => firestoreRedux.selectors.doc(state, 'accounts', accountId || router.selectors.accountId(state)),
  (attrs) => attrs?.pendingInvoiceCount || 0
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Number } - Total amount of pending invoices.
 */
export const pendingInvoicesAmount = createSelector(
  (state, accountId) => invoices(state, accountId),
  (invoices) => invoices
    .filter(invoice => invoice.stripeStatus === 'open')
    .reduce((acc, invoice) => acc + invoice.amount_due, 0)
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Array } - List of subscription history items.
 *  - @param { String } id - Item ID.
 *  - @param { String } accountId - Account ID.
 *  - @param { String } subscriptionId - Subscription ID.
 *  - @param { Enum } type - Item type. Possible values: AUTO_RENEW_CHANGED, PLAN_CHANGED, BILLING_CONTACTS_CHANGED, SUBSCRIBED, CANCELED, SEATS_CHANGED
 *  - @param { String } actionBy - Action performed by userId
 *  - @param { Timestamp } actionAt - Action performed at.
 *  - @param { Object } detail - Item detail. [see](https://git.kerika.net/kerikav4/subscription-server/-/blob/master/docs/models.md#itemdetail)
 */
const subscriptionsHistoryItems = createSelector(
  (state, accountId) => firestoreRedux.selectors.docsByQueryResult(state, `subscription-history-items_accountId-${accountId || router.selectors.accountId(state)}`, 'subscription-history-items'),
  (items) => {
    if (!items || items.length === 0) {
      return [];
    }

    return items.map(item => {
      return {
        id: item.id,
        accountId: item.accountId,
        subscriptionId: item.subscriptionId,
        type: item.type,
        actionBy: item.actionBy,
        actionAt: item.actionAt || 0,
        detail: item.detail
      };
    }).sort((a, b) => new Date(b.actionAt) - new Date(a.actionAt));
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - Status of the subscription history items API call.
 */
const subscriptionsHistoryItemsStatus = createSelector(
  (state, accountId) => firestoreRedux.selectors.queryStatus(state, `subscription-history-items_accountId-${accountId || router.selectors.accountId(state)}`),
  (status) => status || 'PENDING'
);

/**
 * @param { Object } state - Redux state detail object
 * @param { String } accountId - Account ID.
 * @returns { Array } - List of subscription history items merged with invoices and sorting on date.
 *  e.g.  [
            {
              id: "in_1NxYZaABCDEF123456",
              number: "INV-0001",
              status: "PAID",
              stripeStatus: "paid",
              amount_due: 10000, // $100.00 in cents
              total: 10000,
              currency: "usd",
              createdAt: 1672531200000, // Jan 1, 2023
              paymentMethodDetails: {
                type: "card",
                displayTemplate: "visa - 4242 12/24"
              },
              itemType: "INVOICE",
              timestamp: 1672531200000
            },
            {
              itemType: "HISTORY",
              timestamp: 1671926400000, // Dec 25, 2022
              date: "2022-12-25",
              actionAt: 1671926400000,
              items: [
                {
                  id: "hist_123",
                  accountId: "acc_123456",
                  subscriptionId: "sub_1234",
                  type: "PLAN_CHANGED",
                  actionBy: "user_123",
                  actionAt: 1671926400000, // Dec 25, 2022
                  detail: {
                  }
                },
                {
                  id: "hist_124",
                  accountId: "acc_123456",
                  subscriptionId: "sub_1234",
                  type: "SEATS_CHANGED",
                  actionBy: "user_123",
                  actionAt: 1671925200000, // Also Dec 25, 2022, but slightly earlier
                  detail: {
                  }
                }
              ]
            },
            {
              id: "in_1NxYZaABCDEF789012",
              number: "INV-0002",
              status: "PAID",
              stripeStatus: "paid",
              amount_due: 5000, // $50.00 in cents
              total: 5000,
              currency: "usd",
              createdAt: 1669852800000, // Dec 1, 2022
              paymentMethodDetails: {
                type: "card",
                displayTemplate: "visa - 4242 12/24"
              },
              itemType: "INVOICE",
              timestamp: 1669852800000
            }
          ]
 */
export const billingHistory = createSelector(
  (state, accountId) => invoices(state, accountId),
  (state, accountId) => subscriptionsHistoryItems(state, accountId),
  (invoices, historyItems) => {
    if (!invoices?.length && !historyItems?.length) {
      return [];
    }
    
    // Process invoices to match format for merging
    const processedInvoices = invoices.map(invoice => ({
      ...invoice,
      itemType: 'INVOICE',
      // Use invoice's createdAt as the sorting timestamp
      timestamp: invoice.createdAt || 0
    }));
    
    // Process history items to match format for merging
    const processedHistoryItems = historyItems.map(item => ({
      ...item,
      itemType: 'HISTORY',
      // Use history item's actionAt as the sorting timestamp
      timestamp: item.actionAt || 0
    }));
    
    // First, combine all items and sort by timestamp
    const allItems = [...processedInvoices, ...processedHistoryItems]
      .sort((a, b) => b.timestamp - a.timestamp);

    // Now group same-day history items
    const result = [];
    const groupedByDate = {};
    
    allItems.forEach(item => {
      if (item.itemType === 'INVOICE') {
        // Keep invoices separate, don't group them
        result.push(item);
      } else {
        // For history items, we need to group them between invoices
        if (!result.length) {
          // If no items yet, start with this history item
          const historyGroup = {
            itemType: 'HISTORY',
            timestamp: item.timestamp,
            date: moment(item.timestamp).format('YYYY-MM-DD'),
            items: [item],
            actionAt: item.actionAt
          };
          
          result.push(historyGroup);
        } else {
          // Check if the last item is an invoice or a history group
          const lastItem = result[result.length - 1];
          
          if (lastItem.itemType === 'HISTORY') {
            // Add to existing history group
            lastItem.items.push(item);
            // Update timestamp if this one is more recent
            if (item.timestamp > lastItem.timestamp) {
              lastItem.timestamp = item.timestamp;
              lastItem.actionAt = item.actionAt;
            }
          } else {
            // Last item was an invoice, create a new history group
            const historyGroup = {
              itemType: 'HISTORY',
              timestamp: item.timestamp,
              date: moment(item.timestamp).format('YYYY-MM-DD'),
              items: [item],
              actionAt: item.actionAt
            };
            
            result.push(historyGroup);
          }
        }
      }
    });
    
    // Add the grouped history items to results
    Object.values(groupedByDate).forEach(group => {
      result.push(group);
    });
    
    // Final sort to ensure everything is in correct order
    return result.sort((a, b) => b.timestamp - a.timestamp);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - Status of the billing history API call.
 */
export const billingHistoryStatus = createSelector(
  (state, accountId) => invoicesStatus(state, accountId),
  (state, accountId) => subscriptionsHistoryItemsStatus(state, accountId),
  (invoicesStatus, historyItemsStatus) => invoicesStatus === 'IN_PROGRESS' || historyItemsStatus === 'IN_PROGRESS' ? 'IN_PROGRESS' : 'SUCCESS'
);

/**
 * @param {Object} state Redux state
 * @returns {Boolean} current user emill is from public domains or not.
 */
export const publicDomain = (state) => get(state, `subscription-v2.publicDomain.value`, false);

/**
 * @param {Object} state Redux state
 * @returns {Enum} public domain status. possible values: 'IN_PROGRESS', 'SUCCESS', 'ERROR'
 */
export const publicDomainStatus = (state) => get(state, `subscription-v2.publicDomain.status`, '');

// ================================== START: Subscription Messages ==================================
export const messageKnown = (state, accountId, name) => {
  const message = get(state, `subscription-v2.messages.${accountId || router.selectors.accountId(state)}.${name}`, {});
  return get(message, 'known', false) || false;;
};

export const messageKnownAt = (state, accountId, name) => {
  const message = get(state, `subscription-v2.messages.${accountId || router.selectors.accountId(state)}.${name}`, {});
  return get(message, 'knownAt', 0) || 0;
};

export const trilEndsDays = (state) => {
  const _subscription = subscription(state);
  const trialEnds = _subscription?.trialEnds;
  return moment(trialEnds).diff(moment(), 'days');
}

export const expireDays = (state) => {
  const _subscription = subscription(state);
  const currentPeriodEnd = _subscription?.expirationDate;
  return moment(currentPeriodEnd).diff(moment(), 'days');
}

const autoRenewOffMessageAvailable = (state) => {
  const _status = status(state);
  if(!_status) {
    return false;
  }

  if(_status !== 'ACTIVE') {
    return false;
  }

  const _expireDays = expireDays(state);
  if(_expireDays > 7) {
    return false;
  }

  const _subscription = subscription(state);

  const _autoRenewal = _subscription.autoRenewal;
  if(_autoRenewal) {
    return false;
  }

  const _cancelOnExpire = _subscription.cancelOnExpire;
  if(_cancelOnExpire) {
    return false;
  }

  const _messageKnown = messageKnown(state, router.selectors.accountId(state), 'AUTO_RENEW_OFF');
  if(!_messageKnown) {
    return true;
  }

  const _messageKnownAt = messageKnownAt(state, router.selectors.accountId(state), 'AUTO_RENEW_OFF');
  return !moment(_messageKnownAt).isSame(moment(), 'day');
}

const trialNearExpireMessageAvailable = (state) => {
  const _status = status(state);
  if(!_status) {
    return false;
  }

  if(_status !== 'TRIALING') {
    return false;
  }

  const _trilEndsDays = trilEndsDays(state);
  if(_trilEndsDays > 7) {
    return false;
  }

  const _messageKnown = messageKnown(state, router.selectors.accountId(state), 'TRIAL_NEAR_EXPIRE');
  if(!_messageKnown) {
    return true;
  }

  const _messageKnownAt = messageKnownAt(state, router.selectors.accountId(state), 'TRIAL_NEAR_EXPIRE');
  return !moment(_messageKnownAt).isSame(moment(), 'day');
}

const pastDueMessageAvailable = (state) => {
  const _status = status(state);
  if(!_status) {
    return false;
  }

  if(_status !== 'PAST_DUE') {
    return false;
  }

  const _messageKnown = messageKnown(state, router.selectors.accountId(state), 'PAST_DUE');
  return !_messageKnown;
}

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - alert message type
 *                   - Possible values: 'TRIAL_NEAR_EXPIRE', 'AUTO_RENEW_OFF', 'PAST_DUE'
 */
export const alertMessageType = (state) => {
  if(trialNearExpireMessageAvailable(state)) {
    return 'TRIAL_NEAR_EXPIRE';
  }

  if(autoRenewOffMessageAvailable(state)) {
    return 'AUTO_RENEW_OFF';
  }

  if(pastDueMessageAvailable(state)) {
    return 'PAST_DUE';
  }

  return '';
}
// ================================== END: Subscription Messages ==================================

/**
 * @param { Object } state - Redux state detail object
 * @returns { Object } - last invoice details for given/current account.
 *  - @param { Number } amount - Amount of the invoice.
 *  - @param { String } currencySymbol - Currency of the invoice.
 */
export const lastInvoice = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.lastInvoice.data`),
  (lastInvoice) => lastInvoice || {}
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - Status of the last invoice API call.
 */
export const lastInvoiceStatus = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.lastInvoice.status`),
  (status) => status || 'IN_PROGRESS'
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - Status of non-profit request API call.
 */
export const nonProfitRequestStatus = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.nonProfitRequest.status`),
  (status) => status || ''
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { String } - Error for non-profit request API call.
 */
export const nonProfitRequestError = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.nonProfitRequest.error`),
  (error) => error || ''
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Object } - Non-profit request details for given/current account from Firestore.
 */
export const nonProfitRequest = createSelector(
  (state, accountId) => firestoreRedux.selectors.doc(state, 'non-profit-requests', `npr_${getIdWoPrefix({ id: accountId || router.selectors.accountId(state), prefix: 'acc_' })}`),
  (request) => request || {}
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Boolean } - given account is non-profit or not.
 */
export const nonProfitAccount = createSelector(
  (state, accountId) => firestoreRedux.selectors.doc(state, 'accounts', accountId || router.selectors.accountId(state)),
  (state, accountId) => app.selectors.config(state),
  (account, config) => {
    if(!account || !config) {
      return false;
    }

    const coupon = get(account, 'coupon', '');
    const nonProfitCoupon = get(config, 'nonProfitCoupon', '');
    return coupon && coupon === nonProfitCoupon || false;
  }
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - Status of the request invoice.
 */
export const requestInvoiceStatus = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.requestInvoice.status`),
  (status) => status || ''
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { String } - Error for request invoice.
 */
export const requestInvoiceError = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.requestInvoice.error`),
  (error) => error || ''
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Boolean } - Whether offline payment is enabled for given account.
 */
export const offlinePaymentEnabled = createSelector(
  (state, accountId) => firestoreRedux.selectors.doc(state, 'accounts', accountId || router.selectors.accountId(state)) || {},
  (account) => account?.offlinePaymentEnabled || false
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { Enum } - Status of the update billing address API call.
 */
export const updateBillingAddressStatus = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.updateBillingAddress.status`),
  (status) => status || ''
);

/**
 * @param { Object } state - Redux state detail object
 * @returns { String } - Error for update billing address API call.
 */
export const updateBillingAddressError = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.updateBillingAddress.error`),
  (error) => error || ''
);

/**
 * @returns { Object } - request invoice.
 */
export const requestInvoice = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.requestInvoice.data`),
  (data) => data || {}
);

export const cancelSubscriptionStatus = createSelector(
  (state, accountId) => get(state, `subscription-v2.${accountId || router.selectors.accountId(state)}.cancelSubscription.status`),
  (status) => status || ''
)
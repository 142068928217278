import { takeEvery, all, call, select, put } from 'redux-saga/effects';
import * as actions from './actions.js';

import firestoreRedux from '@dreamworld/firestore-redux';
import * as auth from '../auth';
import * as router from '../router';

import { requestApi, isNetworkError } from '../../request-api.js';

function* acknowledge(action) {
  const state = yield select();
  const accountId = action.accountId || router.selectors.accountId(state);
  const alertType = action.alertType;
  const queryId = `account-alerts-${accountId}`;
  const userId = auth.selectors.currentUserId(state);
  
  if (!userId || !accountId || !alertType) {
    console.error('Account alerts acknowledgment failed: Missing required parameters', { userId, accountId, alertType });
    yield put(actions._acknowledgeFailed(accountId, alertType, 'INVALID_PARAMETERS'));
    return;
  }

  const collectionPath = `accounts/${accountId}/account-alerts`;
  const allDocs = firestoreRedux.selectors.allDocs(state, 'account-alerts') || [];
  const currentDoc = allDocs.find(doc => doc.accountId === accountId && doc.type === alertType);
  const docId = currentDoc?.id || `aa_${getIdWoPrefix({id: accountId, prefix: 'acc_'})}_${alertType}`;
  const acknowledgements = currentDoc?.acknowledgements ? { ...currentDoc.acknowledgements } : {};
  acknowledgements[userId] = Date.now();
  const doc = {
    ...currentDoc,
    id: docId,
    accountId,
    type: alertType,
    acknowledgements,
  };
  
  try {
    // Optimistically update local state
    firestoreRedux.save(collectionPath, doc, { localWrite: true });
    
    // Send request to server
    yield call(requestApi, `/account/accounts/${accountId}/alerts/acknowledge`, { method: 'PUT', body: { type: alertType } });
    
    // If successful, dispatch success action
    yield put(actions._acknowledgeSuccess(accountId, alertType));
    
  } catch (error) {
    // Revert optimistic update
    if (currentDoc) {
      firestoreRedux.save(collectionPath, currentDoc, { localWrite: true });
    } else {
      firestoreRedux.delete(collectionPath, docId, { localWrite: true, queryId });
    }
    
    const code = error?.code || 'UNKNOWN';
    yield put(actions._acknowledgeFailed(accountId, alertType, code));
    
    if (isNetworkError(error)) {
      return;
    }
    console.error('Failed to acknowledge account due to this: ', error);
  }
}

/**
 * Load account alerts from firestore.
 */
function* load({accountId}) { 
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  accountId = accountId || router.selectors.accountId(state);

  if(userId && accountId) {
    try {
      const query = firestoreRedux.query('account-alerts', { id: `account-alerts-${accountId}`,  where: [['accountId', '==', accountId]], requesterId: `account-alerts-${accountId}` });
      yield query.result;
    } catch (error) {
      console.error('Failed to load account alerts due to this: ', error);
    }
  }
}

/**
 * Disconnect from firestore.
 */
function* disconnect({accountId}) {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const currentAccountId = router.selectors.accountId(state);
  accountId = accountId || currentAccountId;

  if (userId && accountId) {
    firestoreRedux.cancelQueryByRequester(`account-alerts-${accountId}`);
  }
}

function* accountChanged(action) {
  const currentAccountId = action && action.currentAccountId;
  const prevAccountId = action && action.prevAccountId;
  if(prevAccountId) {
    yield call(disconnect, {accountId: prevAccountId})
  }

  if(currentAccountId) {
    yield call(load, {accountId: currentAccountId});
  }
}

function* init({currentAccountId}) {
  const state = yield select()
  currentAccountId = currentAccountId || router.selectors.accountId(state);
  yield call(load, {accountId: currentAccountId});
}

/**
 * Init Saga.
 */
function* saga() {
  yield all([
    takeEvery([actions.INIT, auth.actions.UPDATE_AUTH, router.actions.ACCOUNT_ADDED], init),
    takeEvery(router.actions.ACCOUNT_CHANGED, accountChanged),
    takeEvery(actions.ACKNOWLEDGE, acknowledge),
  ]);
}

export default saga;
import URI from '@dw/urijs-esm/urijs-esm.js';

const WINDOW_WIDTH_THRESHOLD = 767;
const MOBILE_CHILD_WINDOW_WIDTH = 400;
const MOBILE_CHILD_WINDOW_HEIGHT = 500;
const DESKTOP_WINDOW_WIDTH = 1200;
const DESKTOP_WINDOW_HEIGHT = 800;
const CHECK_INTERVAL = 1000;
const CHILD_WINDOW_FEATURES = "titlebar=no, toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, directories=no, status=no";

export const setupPaymentMethod = (accountId, autoRenew, inSameWindow, wideLayout = false) => {
  return setupPaymentMethodInChildWindow(accountId, autoRenew, inSameWindow, wideLayout);
};

let __childWindow;

const setupPaymentMethodInChildWindow = (accountId, autoRenew, inSameWindow, wideLayout) => {
  const redirectURL = getSetupPaymentMethodUrl(accountId, autoRenew, inSameWindow);

  if (inSameWindow) {
    window.location.href = redirectURL;
    return;
  }

  const { width, height, left, top } = getChildWindowDimensions(wideLayout);
  __childWindow && __childWindow.close && __childWindow.close();
  __childWindow = window.open(redirectURL, "", `${CHILD_WINDOW_FEATURES}, width=${width}, height=${height}, left=${left}, top=${top}`);

  return new Promise((resolve, reject) => {
    const timer = setInterval(() => checkChildWindow(timer, reject), CHECK_INTERVAL);
    window.addEventListener('message', (e) => handleWindowMessage(e, timer, resolve, reject));
  });
};

const getChildWindowDimensions = (wideLayout) => {
  const CHILD_WINDOW_WIDTH = wideLayout ? DESKTOP_WINDOW_WIDTH : MOBILE_CHILD_WINDOW_WIDTH;
  const CHILD_WINDOW_HEIGHT = wideLayout ? DESKTOP_WINDOW_HEIGHT : MOBILE_CHILD_WINDOW_HEIGHT;
  const width = window.innerWidth > WINDOW_WIDTH_THRESHOLD ? CHILD_WINDOW_WIDTH : window.innerWidth;
  const height = window.innerWidth > WINDOW_WIDTH_THRESHOLD ? CHILD_WINDOW_HEIGHT : window.innerHeight;
  const left = window.innerWidth > WINDOW_WIDTH_THRESHOLD ? (screen.width - width) / 2 : 0;
  const top = window.innerWidth > WINDOW_WIDTH_THRESHOLD ? (screen.height - height) / 2 : 0;
  return { width, height, left, top };
};

const checkChildWindow = (timer, reject) => {
  if (!__childWindow || __childWindow.closed) {
    reject({ code: 'CANCELED' });
    clearInterval(timer);
  }
};

const handleWindowMessage = (e, timer, resolve, reject) => {
  const { type, error, status } = e.data || {};
  const errorCode = type === 'SETUP_PAYMENT_METHOD_CANCELED' ? 'CANCELED' : error || status || 'UNKNOWN';

  if (['SETUP_PAYMENT_METHOD_SUCCESS', 'SETUP_PAYMENT_METHOD_FAILED', 'SETUP_PAYMENT_METHOD_CANCELED'].includes(type)) {
    clearInterval(timer);
    window.removeEventListener('message', handleWindowMessage);
    type === 'SETUP_PAYMENT_METHOD_SUCCESS' ? resolve(e.data) : reject({ code: errorCode });
  }
};

const getSetupPaymentMethodUrl = (accountId, autoRenew, inSameWindow) => {
  const uri = new URI(`${window.K.config.apiBaseUrl}/subscription-v4/subscriptions/${accountId}/setup-payment-method`);
  if (autoRenew) uri.setSearch('auto-renew', autoRenew);

  const redirect = getRedirectUrl(accountId, autoRenew, inSameWindow);
  if (redirect) uri.setSearch('redirect', redirect);

  return uri.toString();
};

const getRedirectUrl = (accountId, autoRenew, inSameWindow) => {
  if (!inSameWindow) {
    const uri = new URI(getFallbackPageUrl());
    if (accountId) uri.setSearch('account-id', accountId);
    if (autoRenew) uri.setSearch('auto-renew', autoRenew);
    return uri.toString();
  }

  const sameWindowUri = new URI();
  sameWindowUri.setSearch('setup-payment-method-in-same-window', true);
  sameWindowUri.removeSearch('status');
  sameWindowUri.removeSearch('error');
  return sameWindowUri.toString();
};

const getFallbackPageUrl = () => {
  return `${window.location.protocol}//${window.location.host}/setup-payment-method-fallback.html`;
};

export default setupPaymentMethod;

import { put, takeEvery, take, call, select, all, debounce, fork, cancel, delay } from 'redux-saga/effects';

import * as app from '../app';
import * as auth from '../auth';
import * as router from '../router';
import { store } from '../../store';
import { requestApi, isNetworkError } from '../../request-api';
import { show as showSnackbar } from '../../components/kerika-snackbar';
import cloneDeep from 'lodash-es/cloneDeep';
import i18next from '@dw/i18next-esm';
import isEqual from 'lodash-es/isEqual.js';
import isEmpty from 'lodash-es/isEmpty.js';
import forEach from 'lodash-es/forEach';
import get from 'lodash-es/get';
import find from 'lodash-es/find';
import merge from 'lodash-es/merge';
import difference from 'lodash-es/difference';
import * as views from '../views';
import * as selecotrs from './selectors.js';
import * as board from '../board';
import * as multipleLanguage from '../multiple-language';
import { FILTERS_LOCAL_STORAGE_KEY } from './reducers.js';
import { logEvent as amplitudeLogEvent } from '../../analytics/amplitude.js';
import firestoreRedux from '@dreamworld/firestore-redux';
import entityIdProvider from '../../entity-id-provider';
import { getIdWoPrefix } from '../../utils';
import * as actions from './actions.js';

// Global firestoreRedux for testing.
if(window) {
  window.__firestoreRedux = firestoreRedux;
}

let previousPage;
let currentFilters;
let previousFilters;
function* routeChangeHandler() {
  const state = yield select();
  const currentPage = state.router.page.name;
  if (currentPage === 'BOARD_EXPLORER') {
    currentFilters = state.router.page.params.filters;
    if (previousPage !== currentPage) {
      yield put({ type: actions.PAGE_OPENED });
    } else {
      if (!isEqual(currentFilters, previousFilters)) {
        yield put({ type: actions.FILTER_CHANGED });
      }
    }
  } else if (previousPage === 'BOARD_EXPLORER') {
    yield put({ type: actions.PAGE_CLOSED });
  }
  previousPage = state.router.page.name;
  previousFilters = currentFilters;
}

/**
 * Watch router change.
 * Dispatch PAGE_OPENED, PAGE_CLOSED or FILTER_CHANGED actions based on previous & current URL.
 */
function* watchRouter() {
  //If page is already opened, check once.
  yield call(routeChangeHandler);
  yield takeEvery(router.actions.UPDATE_ROUTER, routeChangeHandler);
}

/**
 * Loads account & it's owner details.
 */
function* loadAccountDetail() {
  const state = yield select();
  const accountId = router.selectors.accountId(state);
  if (accountId) {
    const query = firestoreRedux.getDocById('accounts', accountId, { requesterId: 'explorer-account-details' });
    const doc = yield query.result;
    const ownerId = get(doc, 'ownerId');
    firestoreRedux.getDocById('users', ownerId, { requesterId: 'explorer-account-details' });
  }
}

let lastLoadedAccounts = [];
/**
 * When filtered boards are chanegd, loads boards details & it's members.
 */
function* loadExplorePageDetails() {
  try {
    const state = yield select();
    const page = router.selectors.page(state);
    const filters = get(page, 'params.filters');
    if(filters && filters.views) {
      yield call(store.dispatch, views.actions.loadSummary());
      return;
    }
    
    const requesterId = 'explorer-boards-details';
    const accessibleAccounts = auth.selectors.accessibleAccounts(state);
    const browseFilters = selecotrs.browseFilters(state);
    const browseFiltersAccounts = browseFilters && browseFilters.accounts || {};
    const accounts = [];
    forEach(accessibleAccounts, (id) => {
      if(browseFiltersAccounts[id]) {
        accounts.push(id);
      }
    });

    if(isEmpty(accounts)) {
      return;
    }

    const diffAccounts = difference(accounts, lastLoadedAccounts);
    const removeAccounts = difference(lastLoadedAccounts, accounts);
    lastLoadedAccounts = accounts;
    forEach(removeAccounts, (id) => {
      store.dispatch(board.actions.unsubscribeAccountMembers(id));
    });

    if(isEmpty(diffAccounts)) {
      return;
    }

    forEach(diffAccounts, (id) => {
      store.dispatch(board.actions.subscribeAccountMembers(id));
    });
    const accountTeamMembersQuery = firestoreRedux.query('account-team-members', { where: [['accountId', 'in', diffAccounts]], requesterId });
    yield accountTeamMembersQuery.waitTillResultAvailableInState();
  } catch (error) {
    console.error("board-exporer > loadExplorePageDetails > is failed due to this: ", error);
  }
}

/**
 * Loads app templates.
 */
let appTemplatedLoaded = false;
function* loadAppTemplates(action) {
  const state = yield select();
  const templateAcId = app.selectors.templateAcId(state);
  if(!templateAcId) {
    return;
  }
  
  const actionType = action && action.type;
  if(actionType === multipleLanguage.actions.CHANGE_LANGUAGE_SUCCESS) {
    appTemplatedLoaded = false;
  }

  if(appTemplatedLoaded) {
    return;
  }

  yield call(disconnectAppTemplateDetails);
  appTemplatedLoaded = true;
  try {
    const requesterId = 'explorer-template-details';
    const defaultLanguage = multipleLanguage.selectors.defaultLanguage(state) || 'en';
    const currentLanguage = multipleLanguage.selectors.currentLanguage(state);
    const langs = defaultLanguage === currentLanguage ? [defaultLanguage] : [defaultLanguage, currentLanguage];
    const query = firestoreRedux.query('boards', {
      id: 'app-active-public-templates',
      where: [
        ['template', '==', true],
        ['accountId', '==', templateAcId],
        ['status', '==', 'ACTIVE'],
        ['privacy', '==', 'PUBLIC'],
        ['lang', 'in', langs],
      ],
      requesterId
    });
    yield query.result;
  } catch (error) {
    appTemplatedLoaded = false;
    console.error("board-exporer > loadAppTemplates > is failed due to this: ", error);
  }
}

let previousLoadedBoards = [];
function* loadBoardsDetails(action) {
  const state = yield select();
  const boards = action.boardIds || [];
  if(!boards.length) {
    return;
  }

  const requesterId = action.requesterId || 'explorer-boards-details';
  try {
    const diffBoards = difference(boards, previousLoadedBoards);
    if(!diffBoards || !diffBoards.length) {
      return;
    }

    previousLoadedBoards = [...boards, ...previousLoadedBoards];
    let promises = [];
    for (let i = 0; i < diffBoards.length; i++) {
      const id = diffBoards[i];
      const boardsQuery = firestoreRedux.getDocById('boards', id, { requesterId });
      promises.push(boardsQuery.waitTillResultAvailableInState());
    }

    const chunkSize = 10;
    const userId = auth.selectors.currentUserId(state);
    for (let i = 0; i < diffBoards.length; i += chunkSize) {
      const boardIds = diffBoards.slice(i, i + chunkSize);
      const boardMoveRequest = firestoreRedux.query('board-move-requests', { where: [['boardId', 'in', boardIds]], requesterId });
      const boardTeamQuery = firestoreRedux.query('board-team-members', { where: [['boardId', 'in', boardIds]], requesterId });
      const boardUnreadUserDetailQuery = firestoreRedux.query('board-unread-user-details', { where: [['boardId', 'in', boardIds], ['userId', '==', userId], ['anyUnread', '==', true]], requesterId });
      promises.push(boardMoveRequest.waitTillResultAvailableInState());
      promises.push(boardTeamQuery.waitTillResultAvailableInState());
      promises.push(boardUnreadUserDetailQuery.waitTillResultAvailableInState());
      try {
        yield Promise.all(promises);
        promises = [];
      } catch (error) {
        const code = error && error.code || 'UNKNOWN';
        yield put(actions._loadBoardsDetailsFailed(boards, requesterId, code));
        console.error("board-exporer > loadBoardsDetails > is failed due to this: ", error);
        promises = [];
      }
    }
    
    yield put(actions._loadBoardsDetailsDone(boards, requesterId));
  } catch (error) {
    const code = error && error.code || 'UNKNOWN';
    yield put(actions._loadBoardsDetailsFailed(boards, requesterId, code));
    console.error("board-exporer > loadBoardsDetails > is failed due to this: ", error);
  }
}

function* disconnectAppTemplateDetails() {
  appTemplatedLoaded = false;
  firestoreRedux.cancelQueryByRequester('explorer-template-details');
}

/**
 * Stops live listening from the firestore for the loaded board-details.
 */
function* disconnect() {
  firestoreRedux.cancelQueryByRequester('explorer-boards-details');
  yield call(disconnectAppTemplateDetails);
  lastLoadedAccounts = [];
  previousLoadedBoards = [];
}

/**
 * Manages loading boards details from the forestpre based on the current account and the filters
 *  (favorite?, status: [archived, trashed, active], template?).
 */
function* boardListFlow() {
  while (yield take(actions.PAGE_OPENED)) {
    const task = yield fork(function* () {
      yield all([
        call(loadAccountDetail),
        call(loadAppTemplates),
        call(loadExplorePageDetails),
        debounce(50, [actions.FILTER_CHANGED, actions.UPDATE_CURRENT_LANGUAGE_FILTERS_DONE, multipleLanguage.actions.CHANGE_LANGUAGE_SUCCESS], loadAppTemplates),
        debounce(50, [actions.UPDATE_ACCOUNTS_FILTERS_DONE, auth.actions.ACCESSIBLE_ACCOUNTS_CHANGED], loadExplorePageDetails)
      ]);
    })
    yield take(actions.PAGE_CLOSED);
    yield call(disconnect);
    yield call(views.actions.disconnectSummary);
    yield cancel(task);
  }
}

/**
 * Send request to archive Board.
 * Show toast with undo action if provided in action.
 * @param {Object} action action payload. e.g {type: 'ARCHIVE', id, showToast}
 * API: PUT /board/boards/${id}/status
 */
function* archive({ id, showToast }) {
  const state = yield select();
  const attrs = board.selectors.attrs(state, id);
  const allUserAccessibleBoards = firestoreRedux.selectors.collection(state, 'user-accessible-boards');
  const userAccessibleBoard = find(allUserAccessibleBoards, { boardId: id });
  if (isEmpty(userAccessibleBoard)) {
    throw `archive > ${id} board is not found in user-accessible-boards collection.`;
  }

  try {
    const status = 'ARCHIVED';
    firestoreRedux.save('user-accessible-boards', { ...userAccessibleBoard, status }, { localWrite: true });
    firestoreRedux.save('boards', { ...attrs, status, statusChangedAt: Date.now() }, { localWrite: true });

    if (showToast) {
      yield call(showSnackbar, {
        id, message: i18next.t('board-explorer:boardCard.toast.archivedMessage'), actionButton: {
          caption: i18next.t('buttons.undo'),
          callback: attrs.status === 'ACTIVE' ? undoArchiveForActive : undoArchiveForTrash
        }
      });
    }

    yield call(requestApi, `/board/boards/${id}/status`, { method: 'PUT', body: { status } });
    yield put({ type: actions.ARCHIVE_DONE, id });
  } catch (err) {
    yield put({ type: actions.ARCHIVE_FAILED, id });
    firestoreRedux.save('user-accessible-boards', userAccessibleBoard, { localWrite: true });
    firestoreRedux.save('boards', attrs, { localWrite: true });
  }
}

/**
 * Sends request to restore Board.
 * @param {Object} action Action payload e.g {type: 'RESTORE', id, showToast}
 * API: PUT /board/boards/${id}/status
 */
 function* restore({ id, showToast }) {
  const state = yield select();
  const attrs = board.selectors.attrs(state, id);
  const allUserAccessibleBoards = firestoreRedux.selectors.collection(state, 'user-accessible-boards');
  const userAccessibleBoard = find(allUserAccessibleBoards, { boardId: id });
  if (isEmpty(userAccessibleBoard)) {
    throw `restore > ${id} board is not found in user-accessible-boards collection.`;
  }

  try {
    const status = 'ACTIVE';
    firestoreRedux.save('user-accessible-boards', { ...userAccessibleBoard, status }, { localWrite: true });
    firestoreRedux.save('boards', { ...attrs, status, statusChangedAt: Date.now() }, { localWrite: true });

    if (showToast) {
      yield call(showSnackbar, {
        id, message: i18next.t('board-explorer:boardCard.toast.restoreMessage'), actionButton: {
          caption: i18next.t('buttons.undo'),
          callback: attrs.status === 'ARCHIVED' ? undoRestoreForArchive : undoRestoreForTrash
        }
      });
    }

    yield call(requestApi, `/board/boards/${id}/status`, { method: 'PUT', body: { status } });
    yield put({ type: actions.RESTORE_DONE, id });
  } catch (err) {
    yield put({ type: actions.RESTORE_FAILED, id });
    firestoreRedux.save('user-accessible-boards', userAccessibleBoard, { localWrite: true });
    firestoreRedux.save('boards', attrs, { localWrite: true });
  }
}

/**
 * Undo archive action for active board/template. Dispatch `RESTORE` redux action.
 * @param {Number} id Board Id
 */
function undoArchiveForActive(id) {
  store.dispatch({ type: actions.RESTORE, id });
};

/**
 * Undo archive action for trashed board/template. Dispatch `TRASH` redux action.
 * @param {Number} id Board Id
 */
function undoArchiveForTrash(id) {
  store.dispatch({ type: actions.TRASH, id });
};

/**
 * Undo restore action for archived board/template. Dispatch `ARCHIVE` redux action.
 * @param {Number} id Board Id
 */
function undoRestoreForArchive(id) {
  store.dispatch({ type: actions.ARCHIVE, id });
}

/**
 * * Undo restore action from trashed board/template. Dispatch `TRASH` redux action.
 * @param {Number} id Board Id
 */
function undoRestoreForTrash(id) {
  store.dispatch({ type: actions.TRASH, id });
}

/**
 * Send request to trash Board.
 * @param {Object} action action payload. e.g {type: 'TRASH', id, showToast}
 * API: PUT /board/boards/${id}/status
 */
function* trash({ id, showToast }) {
  const state = yield select();
  const attrs = board.selectors.attrs(state, id);
  const allUserAccessibleBoards = firestoreRedux.selectors.collection(state, 'user-accessible-boards');
  const userAccessibleBoard = find(allUserAccessibleBoards, { boardId: id });

  if (isEmpty(userAccessibleBoard)) {
    throw `trash > ${id} board is not found in user-accessible-boards collection.`;
  }

  try {
    const status = 'TRASHED';
    firestoreRedux.save('user-accessible-boards', { ...userAccessibleBoard, status }, { localWrite: true });
    firestoreRedux.save('boards', { ...attrs, status, statusChangedAt: Date.now() }, { localWrite: true });

    if (showToast) {
      yield call(showSnackbar, {
        id, message: i18next.t('board-explorer:boardCard.toast.trashMessage'), actionButton: {
          caption: i18next.t('buttons.undo'),
          callback: attrs.status === 'ACTIVE' ? undoTrashForActive : undoTrashForArchive
        }
      });
    }

    yield call(requestApi, `/board/boards/${id}/status`, { method: 'PUT', body: { status } });
    yield put({ type: actions.TRASH_DONE, id });
  } catch (err) {
    yield put({ type: actions.TRASH_FAILED, id });
    firestoreRedux.save('user-accessible-boards', userAccessibleBoard, { localWrite: true });
    firestoreRedux.save('boards', attrs, { localWrite: true });
  }
}

/**
 * Undo trash action for active board/template. Dispatch `RESTORE` redux action.
 * @param {Number} id Board Id
 */
function undoTrashForActive(id) {
  store.dispatch({ type: actions.RESTORE, id });
};

/**
 * Undo trash action for archived board/template. Dispatch `ARCHIVE` redux action.
 * @param {Number} id Board Id
 */
function undoTrashForArchive(id) {
  store.dispatch({ type: actions.ARCHIVE, id });
};


/**
 * Send request to delete board permanent.
 * @param {Object} action action payload. e.g {type: 'PERMANENT_DELETE', id}
 * API: DELETE /board/boards/{id}
 */
function* permanentDelete({id, hasTranslatedBoards}) {
  const state = yield select();
  const attrs = board.selectors.attrs(state, id);
  hasTranslatedBoards = hasTranslatedBoards || false;
  const allUserAccessibleBoards = firestoreRedux.selectors.collection(state, 'user-accessible-boards');
  const userAccessibleBoard = find(allUserAccessibleBoards, { boardId: id });
  if (isEmpty(userAccessibleBoard)) {
    throw `permanentDelete > ${id} board is not found in user-accessible-boards collection.`;
  }

  try {
    firestoreRedux.delete('user-accessible-boards', userAccessibleBoard.id, { localWrite: true });
    firestoreRedux.delete('boards', id, { localWrite: true });
    yield call(requestApi, hasTranslatedBoards ? `/board/boards/${id}?delete-translation=true` : `/board/boards/${id}`, { method: 'DELETE' });
  } catch (error) {
    firestoreRedux.save('user-accessible-boards', userAccessibleBoard, { localWrite: true });
    firestoreRedux.save('boards', attrs, { localWrite: true });
    yield put({ type: actions.PERMANENT_DELETE_FAILED, id });
    console.error('Failed to delete board permanantly', error);
  }
}

/**
 * Update archive status filters in local storage.
 */
function* updateArchiveFilters(action) {
  const state = yield select();
  let value = selecotrs.browseFilters(state);
  value = cloneDeep(value);
  value.status.includeArchive = action.value;
  localStorage.setItem(FILTERS_LOCAL_STORAGE_KEY, JSON.stringify(value));
  yield put({ type: actions.UPDATE_ARCHIVE_FILTERS_DONE, value });
}

/**
 * Update trash status filters in local storage.
 */
function* updateTrashFilters(action) {
  const state = yield select();
  let value = selecotrs.browseFilters(state);
  value = cloneDeep(value);
  value.status.includeTrash = action.value;
  localStorage.setItem(FILTERS_LOCAL_STORAGE_KEY, JSON.stringify(value));
  yield put({ type: actions.UPDATE_TRASH_FILTERS_DONE, value });
}

/**
 * Update current language filters in local storage.
 */
function* updateCurrentLanguageFilter(action) {
  const state = yield select();
  let value = selecotrs.browseFilters(state);
  value = cloneDeep(value);
  value.currentLanguage = action.value;
  localStorage.setItem(FILTERS_LOCAL_STORAGE_KEY, JSON.stringify(value));
  yield put({ type: actions.UPDATE_CURRENT_LANGUAGE_FILTERS_DONE, value });
}

/**
 * Update accounts filters in local storage.
 */
function* updateAccountsFilters(action) {
  const state = yield select();
  let value = selecotrs.browseFilters(state);
  value = cloneDeep(value);
  value.accounts[action.accountId] = action.value;
  localStorage.setItem(FILTERS_LOCAL_STORAGE_KEY, JSON.stringify(value));
  yield put({ type: actions.UPDATE_ACCOUNTS_FILTERS_DONE, value });
}

/**
 * Send request to create a new template.
 * Open template if successfully create a template.
 * @param {Object} action action payload.
 */
 function* createNewTemplate({ name, templateType, accountId, templateCategory, templatePrivacy }) {
  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  try {
    amplitudeLogEvent('template created');
    const body = {
      name,
      type: templateType,
      accountId,
      templateCategory,
      privacy: templatePrivacy,
      template: true,
      lang: 'en'
    };

    const response = yield call(requestApi, `/board/boards`, { method: 'POST', body });

    // Saves last used account in user-ui.
    firestoreRedux.save(`users/${userId}/user-ui`, {
      id: `uctp_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`,
      userId,
      type: 'user-ui-create-template-preferences',
      lastUsedAccount: accountId,
    }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });

    // Saves last used template type & last used catetegory into user-account-ui.
    const allUserAccUiDocs = firestoreRedux.selectors.collection(state, 'user-account-ui');
    const createTemplatePreference = find(allUserAccUiDocs, { accountId, userId, type: 'account-ui-create-template-preferences' }) || {};
    const id = get(createTemplatePreference, 'id', `actp_${entityIdProvider.getId()}`);

    firestoreRedux.save(`users/${userId}/user-account-ui`, {
      id,
      userId,
      accountId,
      type: 'account-ui-create-template-preferences',
      lastUsedType: templateType,
      lastUsedCategory: templateCategory,
    }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });

    //Open template.
    yield call(openTemplate, response.id, accountId, templateType, templateCategory);
  } catch (err) {
    console.error(err)
    yield put({ type: actions.CREATE_TEMPLATE_FAILED });
  }
}

/**
 * Open board
 * @param {Number} templateId template Id
 * @param {Number} accountId Account Id
 * @param {String} templateType template Type
 */
 function* openTemplate(templateId, accountId, templateType, category) {
  //Wait for board details & it's members.
  const boardRequest = firestoreRedux.getDocById('boards', templateId, { once: true, waitTillSucceed: true });
  const membersQuery = firestoreRedux.query('board-team-members', {
    where: [['boardId', '==', templateId]],
    once: true,
    waitTillSucceed: true,
  });

  yield boardRequest.result;
  yield membersQuery.result;

  yield put({ type: actions.CREATE_TEMPLATE_DONE, accountId, templateId, templateType, category });
  router.actions.navigate(`/${accountId}/board/${templateId}`, true);
}

/**
 * Mark `favoritesTabPresented` for current user.
 */
function* favoritesTabPresented() {
  const state = yield select();
  if(selecotrs.favoritesTabPresented(state)) {
    return;
  }

  const userId = auth.selectors.currentUserId(state);
  if(!userId) {
    return;
  }

  const impersonatedUser = auth.selectors.impersonatedUser(state);
  const id = `uexp_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`
  let doc = firestoreRedux.selectors.doc(state, 'user-ui', id);
  doc = doc || { id, userId, type: 'explorer-page' };
  firestoreRedux.save(`users/${userId}/user-ui`, { ...doc, favoritesTabPresented: true }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
}

/**
 * Mark `allOthersTabPresented` for current user.
 */
function* allOthersTabPresented() {
  const state = yield select();
  if(selecotrs.allOthersTabPresented(state)) {
    return;
  }

  const userId = auth.selectors.currentUserId(state);
  if(!userId) {
    return;
  }

  const impersonatedUser = auth.selectors.impersonatedUser(state);
  const id = `uexp_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`
  let doc = firestoreRedux.selectors.doc(state, 'user-ui', id);
  doc = doc || { id, userId, type: 'explorer-page' };
  firestoreRedux.save(`users/${userId}/user-ui`, { ...doc, allOthersTabPresented: true }, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
}

/**
 * Mark given category collapse for current user.
 */
function* markAllOthersTabCategoryCollapse(action) {
  const state = yield select();
  const category = action.category;
  if(!category) {
    return;
  }

  const userId = auth.selectors.currentUserId(state);
  if(!userId) {
    return;
  }

  const impersonatedUser = auth.selectors.impersonatedUser(state);
  const id = `uexp_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`
  let doc = firestoreRedux.selectors.doc(state, 'user-ui', id) || { id, userId, type: 'explorer-page' };
  const allOthersTemplates = doc.allOthersTemplates || {};
  doc = merge({}, doc, {allOthersTemplates}, {allOthersTemplates: {collapsedCategories: {[category]: true}}});
  firestoreRedux.save(`users/${userId}/user-ui`, doc, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
}

/**
 * Mark given category expand for current user.
 */
function* markAllOthersTabCategoryExpand(action) {
  const state = yield select();
  const category = action.category;
  if(!category) {
    return;
  }

  const userId = auth.selectors.currentUserId(state);
  if(!userId) {
    return;
  }
  const impersonatedUser = auth.selectors.impersonatedUser(state);
  const id = `uexp_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`
  let doc = firestoreRedux.selectors.doc(state, 'user-ui', id) || { id, userId, type: 'explorer-page' };
  const allOthersTemplates = doc.allOthersTemplates || {};
  doc = merge({}, doc, {allOthersTemplates}, {allOthersTemplates: {collapsedCategories: {[category]: false}}});
  firestoreRedux.save(`users/${userId}/user-ui`, doc, { localWrite: impersonatedUser, remoteWrite: !impersonatedUser });
}

/**
 * Mark given category collapse for current user.
 */
function* markFavoritesTabCategoryCollapse(action) {
  const state = yield select();
  const category = action.category;
  if(!category) {
    return;
  }

  const userId = auth.selectors.currentUserId(state);
  if(!userId) {
    return;
  }

  const impersonatedUser = auth.selectors.impersonatedUser(state);
  const id = `uexp_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`
  let doc = firestoreRedux.selectors.doc(state, 'user-ui', id) || { id, userId, type: 'explorer-page' };
  const favoritesTemplates = doc.favoritesTemplates || {};
  doc = merge({}, doc, {favoritesTemplates}, {favoritesTemplates: {collapsedCategories: {[category]: true}}});
  firestoreRedux.save(`users/${userId}/user-ui`, doc, { localWrite: true, remoteWrite: !impersonatedUser });
}

/**
 * Mark given category expand for current user.
 */
function* markFavoritesTabCategoryExpand(action) {
  const state = yield select();
  const category = action.category;
  if(!category) {
    return;
  }
  
  const userId = auth.selectors.currentUserId(state);
  if(!userId) {
    return;
  }

  const impersonatedUser = auth.selectors.impersonatedUser(state);
  const id = `uexp_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`
  let doc = firestoreRedux.selectors.doc(state, 'user-ui', id) || { id, userId, type: 'explorer-page' };
  const favoritesTemplates = doc.favoritesTemplates || {};
  doc = merge({}, doc, {favoritesTemplates}, {favoritesTemplates: {collapsedCategories: {[category]: false}}});
  firestoreRedux.save(`users/${userId}/user-ui`, doc, { localWrite: true, remoteWrite: !impersonatedUser });
}

function* markImportBoardStatusAck({boardId}) {
  if(!boardId) {
    return;
  }

  const state = yield select();
  const userId = auth.selectors.currentUserId(state);
  const attrs = board.selectors.attrs(state, boardId);
  const importBoardDetail = get(attrs, 'importBoardDetail') || {};
  if(!userId) {
    return;
  }

  try {
    const newImportBoardDetail = merge({}, importBoardDetail, {statusAck: true});
    firestoreRedux.save('boards', { ...attrs, ...{ importBoardDetail: newImportBoardDetail } }, { localWrite: true });
    yield call(requestApi, `/board/boards/${boardId}/import-board-status-ack`, { method: 'POST' });
  } catch (error) {
    firestoreRedux.save('boards', attrs, { localWrite: true });
    if(isNetworkError(error)) {
      return;
    }
    console.error('Failed to mark import board status acknowledge', error);
  }
}

/**
 * Init Saga.
 */
function* saga() {
  yield all([
    call(watchRouter),
    call(boardListFlow),
    takeEvery(actions.ARCHIVE, archive),
    takeEvery(actions.RESTORE, restore),
    takeEvery(actions.TRASH, trash),
    takeEvery(actions.PERMANENT_DELETE, permanentDelete),
    takeEvery(actions.UPDATE_ARCHIVE_FILTERS, updateArchiveFilters),
    takeEvery(actions.UPDATE_TRASH_FILTERS, updateTrashFilters),
    takeEvery(actions.UPDATE_ACCOUNTS_FILTERS, updateAccountsFilters),
    takeEvery(actions.UPDATE_CURRENT_LANGUAGE_FILTERS, updateCurrentLanguageFilter),
    takeEvery(actions.CREATE_TEMPLATE, createNewTemplate),
    takeEvery(actions.MARK_FAVORITES_TAB_PRESENTED, favoritesTabPresented),
    takeEvery(actions.MARK_ALL_OTHERS_TAB_PRESENTED, allOthersTabPresented),
    takeEvery(actions.MARK_ALL_OTHERS_TAB_CATEGORY_COLLAPSE, markAllOthersTabCategoryCollapse),
    takeEvery(actions.MARK_ALL_OTHERS_TAB_CATEGORY_EXPAND, markAllOthersTabCategoryExpand),
    takeEvery(actions.MARK_FAVORITES_TAB_CATEGORY_COLLAPSE, markFavoritesTabCategoryCollapse),
    takeEvery(actions.MARK_FAVORITES_TAB_CATEGORY_EXPAND, markFavoritesTabCategoryExpand),
    takeEvery(actions.CREATE_TEMPLATE_DONE, markFavoritesTabCategoryExpand),
    takeEvery(actions.MARK_IMPORT_BOARD_STATUS_ACKNOWLEDGE, markImportBoardStatusAck),
    takeEvery(actions.LOAD_BOARDS_DETAILS, loadBoardsDetails)
  ]);
}

export default saga;